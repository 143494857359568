import React, {Component} from 'react';
import {Row, Col,ButtonGroup,Button,Spinner,Nav,Image} from 'react-bootstrap';
import M from 'materialize-css';
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard_Return';
import EditProductOrderReturn from './EditProductItem_Return';
import CustomerDetailCard from './CustomerDetailCard'
import { base_url } from '../../globalConstants';
import "./createorder.css";


class EditOrder extends Component{


constructor(props, context){
        super (props, context);
        console.log("construct ",this.props.productlist)
        this.state =({
            order:this.props.order,
            pageName: this.props.pagename,
            // current_date: date,
           country_list: new Array(),
            country_codelist: new Array(),
            statedata: new Array(),
            stateCode_list: new Array(),
            stateName_list:  new Array(),
            statelist: new Array(),
            statelistto: new Array(), 
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :<></>,
            product_category:"",ordernumber:"",
         customer_id:"",order_date:"",
        received_date:"",tracking_number:"",shiper_order_number:"",
        weight:"",height:"",fedex_charge:"",width:"",length:"",actual_charge:"",homedelivery_charge:"",promo_type:"",promo_value:0,processing_fee:"",brokerage_charge:"",insurance_charge:"",
          orderStatusSelected:"",
          paymentTypeSelected:"",showSpinner:false,
          shippingTypeSelected:"0",
          customerinfo:{},
          error:"",
          selectItemDetails:{},
      
          currentOrderStatus: '',
		  sendNotification:"YES",
		  printLabel:"YES",
       from_country_filled	:"",
          to_country_filled	:"",
      from_country:"",
    from_company :"",
    from_contact :"",
    from_address1 :"",
    from_address2 :"",
    from_zip :"",
    from_city :"",
    from_state :"",
    from_phoneno :"",
    to_country:"",
    to_company :"",
    to_contact :"",
    to_address1 :"",
    to_address2 :"",
    to_zip :"",
    to_city :"",
    to_state :"",
    to_phoneno :""
        
        })
    
    }

    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

  componentDidUpdate(prevProps) {


    if (this.props.orderdata.id !== prevProps.orderdata.id) {
       //console.log("Product errorrr",this.props.orderdata.shipping_method)
      // console.log("customerinfo",this.props.customerinfo)
      // console.log("catefory options",this.props.productCategory)
        this.setState({order:this.props.orderdata,
            //   current_date: this.props.orderdata.order_date,
            country_list: this.props.countrylist,
            pageName: this.props.pagename,
            country_codelist: this.props.countrycodelist,
            countrynamelist: this.props.country_name_list,
            countrycodelist: this.props.country_code_list,
              statedata: this.props.statedata, 

            orderid :this.props.orderdata.id,
            customerinfo:this.props.customerinfo,
            productlist:this.props.productlist ,
            productlisthtml:this.props.productlisthtml ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :this.props.productCategory,
            // showSpinner:false,
            product_category:"",ordernumber:this.props.orderdata.order_id,
         customer_id:this.props.orderdata.ezz_id,order_date:this.props.orderdata.order_date,
        received_date:this.props.orderdata.received_date,tracking_number:this.props.orderdata.inbound_tracking_number,
        shiper_order_number:this.props.orderdata.shipper_number,
        weight:this.props.orderdata.weight,height:this.props.orderdata.height,
        quantity:this.props.orderdata.qty,
        fedex_charge:this.props.orderdata.freight_charge, width:this.props.orderdata.breadth,
        length:this.props.orderdata.length,actual_charge:this.props.orderdata.actual_charge,
        homedelivery_charge:this.props.orderdata.homedelivery_charge,
        promo_type:this.props.orderdata.promo_type,
        promo_value:this.props.orderdata.promo_value,
        orderStatusSelected:this.props.orderdata.current_status,
        paymentTypeSelected:this.props.orderdata.billing_type,
        shippingTypeSelected:this.props.orderdata.shipping_method.toString(),
		from_country:this.props.orderdata.from_country,
		from_company:this.props.orderdata.from_company,
		from_contact:this.props.orderdata.from_contact,
    		from_address1:this.props.orderdata.from_address1,
		from_address2:this.props.orderdata.from_address2,
		 from_zip:this.props.orderdata. from_zip,
    		from_city:this.props.orderdata.from_city,
		from_state:this.props.orderdata.from_state,
		from_phoneno:this.props.orderdata. from_phoneno,

    		to_country:this.props.orderdata.to_country,
		to_company:this.props.orderdata.to_company,
		to_contact:this.props.orderdata.to_contact,
    		to_address1:this.props.orderdata.to_address1,
		to_address2:this.props.orderdata.to_address2,
		to_zip:this.props.orderdata.to_zip,
    		to_city:this.props.orderdata.to_city,
		to_state:this.props.orderdata.to_state,
		to_phoneno:this.props.orderdata.to_phoneno,
          error:"",
          currentOrderStatus: this.props.orderdata.current_status

      
    
        })

        // let country_name_from = this.props.countrylist[this.props.countrycodelist.indexOf(this.props.orderdata.from_country)]
        // //this.setState({from_country: country_name_from.name})
        // this.setState({from_country: country_name_from.country_id})
        // let country_name_to = this.props.countrylist[this.props.countrycodelist.indexOf(this.props.orderdata.to_country)]
        // //this.setState({to_country: country_name_to.name})
        // this.setState({to_country: country_name_to.country_id})
 } 

        var elems_order = document.getElementById('order_date');
      var context = this
      var newdate = this.newDate()
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
               let selecteddata =  context.formatDate(date)
              context.setState({ order_date: selecteddata});
              //console.log("order",selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_order, options_order);
        instances.toString()
        var elems_recieved = document.getElementById('received_date');
		
        var options_recieved={
            //selectMonths: true, // Enable Month Selection
            selectYears: 1, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              let selecteddata =  context.formatDate(date)
              context.setState({ received_date: selecteddata});
              //console.log("recived", selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_recieved, options_recieved);
 
 instances.toString()

    


  }

handleCountryNameChange = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelist: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    console.log(this.state.statelist)
   

  }

handleCountryNameChangeTo = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelistto: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    //console.log(this.state.statelist)
   

  }


handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      //var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      var patt = /^[0-9]/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    
    }

handletableClick=(clickedrow)=>{
  console.log(clickedrow)
  // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
 let productdetail = this.state.productlist[clickedrow]
 let categoryoptions = this.state.product_category_options
 let order_id = this.state.orderid
 console.log("handle product category",categoryoptions)

 this.setState({rowNumber:clickedrow})
 this.setState({selectItemDetails:productdetail})

 
   this.refs.editproductorderreturn.openModal(clickedrow,productdetail,categoryoptions);
}

updateProduct= async (rownumber,item)=>{
  console.log("UPDATING LIST HERE ",rownumber,item)
let productlist = this.state.productlist
productlist.splice(rownumber, 1, item)
await this.setState({productlist:productlist})
// this.refs.editproduct.closeModal();
// M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});


}

deleteProduct= async (rownumber,item_id)=>{
  let tempItemId = item_id.toString()
  let updated_productlist = this.state.productlist
  console.log("DELETING ITEM HERE ",rownumber,item_id)
var purl
if (this.state.pageName == "usreturn")
  {
purl = `products_return_us/${item_id}/`
  }
  else
  {
         purl = `products_return/${item_id}/`
  }

  if(!tempItemId.includes('item_')){
    await Axios({
      method: 'DELETE',
      url: base_url + purl

    }).then(function (response) {
      // console.log(`DELETED${tempProductId}`, response);
      M.toast({ html: `Product deleted successfully`, classes: "white-text blue rounded" })
    }).catch(function (response) {
      // console.log("NOT DELETED ERROR",response);
      M.toast({ html: `Product deletion failed`, classes: "white-text red rounded" })
    })
  }  

  updated_productlist.splice(rownumber, 1)
  await this.setState({productlist:updated_productlist})
  console.log(`ITEM ${item_id} DELETED AT ${rownumber}`)
  // this.refs.editproduct.closeModal();
  // M.toast({html: 'item deleted ' +rownumber,classes:"white-text blue rounded"});

}

// @TODO: updateOrderProdcts Method will used to update products in future
updateOrderProducts=async(header)=>{
    this.state.productlist.forEach(async(item)=>{
      // rowclick={this.handletableClick}
        console.log(item)
        try{
          var productformdata = new FormData()
          productformdata.append("id",item.id)
          productformdata.append("description",item.productName)
          productformdata.append("quantity",item.productQuantity)
          productformdata.append("unit_price",item.productPrice)
          productformdata.append("inspection_status","okay")
          productformdata.append("status","Active")
          productformdata.append("category",item.productCategory)
    
    var response_products
if (this.state.pageName == "usreturn")
  {
response_products = await Axios.patch(base_url+"products_return_us/",productformdata,
          header)
  }
  else
  {
        response_products = await Axios.patch(base_url+"products_return/",productformdata,
          header)
  }
          //const response_products = await Axios.patch(base_url+"products_return/",productformdata,
         // header)
          // alert(response.data)
          console.log("Product added Successfully")
          this.setState({error:<Success message=" Order Status Updated"/>})
          M.toast({html: 'Product Addition Successfull',classes:"white-text blue rounded"});
        }catch(error){
          M.toast({html: 'Product Addition Failed',classes:"white-text red rounded"});
            console.log(error)
        }
       
      });
}

startRefresh=async()=>{
this.props.refreshpage();
}

updateOrder= async(header)=>{
  const  orderid= this.state.ordernumber

  // const found = this.state.productlist.find(element => element["productPrice"]==0);

  //console.log('found', found)

  // if(found && this.state.orderStatusSelected === 'RTM' ){
  //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
  //   return
  // }
  //allow freight charge modified by operator 10-03-2024
  //var slabcharge =  await this.getReturnSlabRate()
  var slabcharge = this.state.fedex_charge
  ////////////////////////
  var totalprice = 0;
  this.state.productlist.forEach(async (item) => {
          console.log("productlist -----", item.id)
          console.log(item)
          totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
          });

    try{
        
        var orderupdateForm = new FormData()
        orderupdateForm.append("order_id",this.state.ordernumber)
        orderupdateForm.append("ezz_id",this.state.customer_id)
        orderupdateForm.append("shipper_number",this.state.shiper_order_number)
        orderupdateForm.append("billing_id","2121")
        orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
        orderupdateForm.append("shipping_method",parseInt(this.state.shippingTypeSelected))

        orderupdateForm.append("order_date",this.state.order_date)
        orderupdateForm.append("received_date",this.state.received_date)
        orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
        orderupdateForm.append("qty", this.state.quantity)
        orderupdateForm.append("weight",this.state.weight)
        orderupdateForm.append("length",this.state.length)
        orderupdateForm.append("breadth",this.state.width)
        orderupdateForm.append("height",this.state.height)
        orderupdateForm.append("charge_type","Due")
        orderupdateForm.append("freight_charge",slabcharge)
        orderupdateForm.append("actual_charge",totalprice)
        orderupdateForm.append("current_status",this.state.orderStatusSelected)
        orderupdateForm.append("previous_status",this.props.orderdata.current_status)
        orderupdateForm.append("status","Deactive")
        orderupdateForm.append("flag","1")
        orderupdateForm.append("current_status",this.state.orderStatusSelected)
        
         orderupdateForm.append("from_country", this.state.from_country)
          orderupdateForm.append("from_company", this.state.from_company)
        orderupdateForm.append("from_contact", this.state.from_contact)
        orderupdateForm.append("from_address1", this.state.from_address1)
        orderupdateForm.append("from_address2", this.state.from_address2)
        orderupdateForm.append("from_zip", this.state.from_zip)
        orderupdateForm.append("from_city", this.state.from_city)
        orderupdateForm.append("from_state", this.state.from_state)
        orderupdateForm.append("from_phoneno", this.state.from_phoneno)

        orderupdateForm.append("to_country", this.state.to_country)
        orderupdateForm.append("to_company", this.state.to_company)
        orderupdateForm.append("to_contact", this.state.to_contact)
        orderupdateForm.append("to_address1", this.state.to_address1)
        orderupdateForm.append("to_address2", this.state.to_address2)
        orderupdateForm.append("to_zip", this.state.to_zip)
        orderupdateForm.append("to_city", this.state.to_city)
        orderupdateForm.append("to_state", this.state.to_state)
        orderupdateForm.append("to_phoneno", this.state.to_phoneno)
      

        // orderupdateForm.append("from_state", "1")
        // orderupdateForm.append("to_state", "1")
let urlforOrderStatusUpdate
if (this.state.pageName == "usreturn")
  {
urlforOrderStatusUpdate = base_url+`boxes_received_return_us/${this.state.ordernumber}/`
  }
  else
  {
         urlforOrderStatusUpdate = base_url+`boxes_received_return/${this.state.ordernumber}/`
  }
        //let urlforOrderStatusUpdate = base_url+`boxes_received_return/${this.state.ordernumber}/`
        const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
  
        console.log(`order status update succesfully ${response}`)
        this.setState({error:<Success message=" Order Updated"/>})
      
        M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});


        //Updating The Product 

        this.state.productlist.forEach(async(item)=>{
          console.log("productitem")
          console.log(item)
          console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')

          

          try{
            var productformdata = new FormData()
            productformdata.append("order_id",this.state.ordernumber)
            productformdata.append("description",item.productName)
            productformdata.append("quantity",item.productQuantity)
            productformdata.append("unit_price",item.productPrice)
            productformdata.append("inspection_status","okay")
            productformdata.append("status","Active")
            productformdata.append("category",item.productCategory)

            if( typeof(item.id) === "number"  ){
              var response_products
if (this.state.pageName == "usreturn")
  {
response_products = await Axios.patch(base_url+`products_return_us/${item.id}/`, productformdata,
                header)
  }
  else
  {
         response_products = await Axios.patch(base_url+`products_return/${item.id}/`, productformdata,
                header)
  }
              //const response_products = await Axios.patch(base_url+`products_return/${item.id}/`, productformdata,
                //header)
              // alert(response.data)
              console.log("Product added Successfully")
              this.setState({ error: <Success message=" Order Status Updated" /> })
              M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
              
            }else{
               var response_addProduct
if (this.state.pageName == "usreturn")
  {
response_addProduct = await Axios.post(base_url+`products_return_us/`, productformdata,header)
  }
  else
  {
         response_addProduct = await Axios.post(base_url+`products_return/`, productformdata,header )
  }
              //const response_addProduct = await Axios.post(base_url+`products_return/`, productformdata,
              //header )

              M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
              
            }

            
            
          }catch(error){
            M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
              console.log(error)
          }
         
        });
        

		
		console.log('this.state.sendNotification',this.state.sendNotification)
		
		//if (this.state.sendNotification === "YES" )
		//{
			try{
			
			  var email_subject = "invoice_missing"
			   const status = this.state.orderStatusSelected
			   switch(status){
				 case "IM":
				  email_subject ="invoice_missing";
				  break;
				 case "WFC":
				  email_subject ="consolidation";
				  break;
		  
		  
				   
			   }

			   console.log('this.state.currentOrderStatus',this.state.currentOrderStatus)
			   console.log('this.state.orderStatusSelected',this.state.orderStatusSelected)

			  if ((status===  "IM" || status === "WFC") && (this.state.currentOrderStatus !== this.state.orderStatusSelected) ){
				const  emaildata= {"type" : email_subject,
				"ezz_id" : this.state.customer_id,
				"order_id" : orderid}

				if (this.state.printLabel === "YES" )
				{
					Axios({
					  method:'post',
					  url: base_url+'status_printer',
					  data: {
						"order_id": orderid
					  }
					}).then(function(response){
					  console.log(response)
					  console.log("printer APi success");
					}).catch(function(response){
					  console.log(response)
					  console.log('printer API failed');
					})
				}
				
				if (this.state.sendNotification === "YES" )
				{
					Axios({
					  method:'post',
					  url: base_url+'whatsapp_templates',
					  data: emaildata,
					}).then(
					  function(response){ 
						console.log(response)
						console.log("whatsapp sent done");
					  }
					).catch(
					  function(response){ 
						console.log(response)
						console.log("whatsapp sent Failure");
					  }
					)


					const response = await Axios.post(base_url+"send_email/",emaildata,
								header)
					console.log(response)
					M.toast({html: 'Email Sent to '+response.data["Email to"],classes:"white-text red rounded"});
				}
			  }
			 
			}catch(error){
		  
			  M.toast({html: 'Email Sending Failed to'+ this.state.customer_id ,classes:"white-text red rounded"});
			}
		//}
        
      

        this.setState({ currentOrderStatus: this.state.orderStatusSelected })
        

        // window.location.reload(false);
      }catch(error){
        M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
          console.log(error)
          this.setState({error:<Error/>})
      }

      this.startRefresh();
}

updateOrderDB=()=>{
    let token =localStorage.getItem("token")
    const  header = {'Content-Type': 'application/json',
    'Authorization':'Token '+token,
    }
    this.updateOrder(header)


}

getReturnSlabRate = async ()=>{
    try{
  let token =localStorage.getItem("token")
    var config = {
    
	};

//console.log(this.state.order_date);
  // Fetching user list on input change
  //var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
  var url
if (this.state.pageName == "usreturn")
  {
url= base_url+`get_return_slab_rate_us/?weight=${this.state.weight}&method=${this.state.shippingTypeSelected}&ezz_id=${this.state.customer_id}`
  }
  else
  {
         url= base_url+`get_return_slab_rate/?weight=${this.state.weight}&method=${this.state.shippingTypeSelected}&ezz_id=${this.state.customer_id}`
  }
  //var url= base_url+`get_return_slab_rate/?weight=${this.state.weight}&method=${this.state.shippingTypeSelected}&ezz_id=${this.state.customer_id}`

  var response = await Axios.get(url,config)
  // console.log(response.data)
  return response.data
}catch(error){
  console.log(error.response.data)
   if (error.response.status ===503 ){
    M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
   }
   if (error.response.status === 403 ){
    M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
   }
  return error.response.data
}



  }
  
getfedexActualRate = async (weight,height,length,breadth)=>{
    


  try{
let token =localStorage.getItem("token")
let config={ headers : {'Content-Type': 'multipart/form-data',
'Authorization':'Token '+token,
}
}

// Fetching user list on input change
//var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
//var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}`
var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}&orderno=${this.state.ordernumber}&method=${this.state.shippingTypeSelected}`
var response = await Axios.get(url,config)
console.log(response.data)
return response.data
}catch(error){



console.log(error.response.data)
  
if (error.response.status ===503 ){
 M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
}

// return response.data
return error.response.data
}

}

handleFormChange= async (ev) =>{
  const target = ev.target
  const name = target.name
  console.log(ev.target.name)
  console.log(ev.target.value)
 
  // hide and show on YTA
  if (target.value === "YTA"){
	document.getElementById("received_date").disabled = true;
    this.setState({
      [name]: target.value,
      weight: 0,
      height:0,
      fedex_charge:0,
      width:0,
      length:0,
      actual_charge:0,
      homedelivery_charge:0,
	  processing_fee:0,
	  insurance_charge:0,
	  brokerage_charge:0,
      promo_type:"Not",
      promo_value:0
    });
  }else{
	document.getElementById("received_date").disabled = false;
    if(target.name ==="weight" || 
    target.name ==="height" || target.name === "width"
  || target.name === "length"
    ){

    this.setState({
        [name]: target.value
      });

      var weight = this.state.weight
      var width = this.state.width
      var height = this.state.height
      var length = this.state.length
      var field = target.name;

      switch (field) {
        case "weight":
          weight = target.value;
          break;
        case "height" :
          height = target.value;
          break;
        case "width" :
          width = target.value;
          break;
        case "length" :
          length = target.value;
          break;
       
      }

      // if(width < 0 || height <0 || weight < 0 || length <0 || this.state.actual_charge <0){
      //   M.toast({html: "values can not be negative",classes:"white-text green rounded"});
      //   return;
      // }
      if(target.name ==="weight" && weight > 0){
        var slabcharge =  await this.getReturnSlabRate()
        this.setState({
               fedex_charge:slabcharge
            })
        }
  
      var slabcharge =  await this.getReturnSlabRate()
      // if((width!=="" && parseInt(width)!==0) && (height !=="" && parseInt(height) !==0) && (width !=="" && parseInt(width) !==0 ) && (length!=="" && parseInt(length)!==0)){
      //   await this.setState({
      //     showSpinner:true
      //   })
      //   var response =  await this.getfedexActualRate(weight,height,length,width)
      //   console.log(response["freight_charges"] + response["actual_charges"])
      //     this.setState({
      //       fedex_charge:response["freight_charges"],
      //       actual_charge: response["actual_charges"],
      //       homedelivery_charge: response["homedelivery_charge"],
			// processing_fee: response["processing"],
			// insurance_charge: response["insurance"],
			// brokerage_charge: response["brokerage"],
      //       promo_type: response["promo_type"],
      //       promo_value: response["promo_value"],
      //       showSpinner:false
      //     })
      // }
     
    }else{
      this.setState({
        [name]: target.value
      });
    }

 
  }

 
}



addProductTolist = ()=>{
  console.log(this.state.productname + this.state.productprice +this.state.productquantity)
   var prodname =this.state.product_name
   var prodquant =this.state.product_quantity
   var prodcat =this.state.product_category
   var prodprice =this.state.productprice
   console.log("prody")
   console.log(prodname + prodquant+prodcat )

   if(prodname === "" || prodquant === 0 || prodprice === ''){
    M.toast({html: 'Incorrect or Empty details',classes:"white-text red rounded"});
  }else{
    
    var elem = document.getElementById('addProductModal')
    var instance = M.Modal.getInstance(elem);
    instance.close();
    let totalproducts = this.state.productlist.length + 1
    
    let newitem = {id:'item_'+totalproducts,productName:this.state.product_name,
    productPrice:this.state.product_price ,
    productQuantity:this.state.product_quantity,
  productCategory:this.state.product_category
  }

    console.log("Product List",this.state.productlist)
   this.state.productlist.push(newitem)
    this.setState({productlist: this.state.productlist})
    
  try {
    // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
    this.setState({product_name:"",product_price:0,product_quantity:0 })
   M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
  } catch (error) {
    M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
  }
   

   }

}

 changeTo= (e) =>{

	  switch (e.target.name) {
		case "weight":
		  this.setState({weight: e.target.value})
		  break;
		case "height" :
		  this.setState({height: e.target.value})
		  break;
		case "width" :
		  this.setState({width: e.target.value})
		  break;
		case "length" :
		  this.setState({length: e.target.value})
		  break;
		case "fedex_charge":
			this.setState({fedex_charge: e.target.value})
			break;
	  }
			//this.setState({weight: e.target.value})
			 //// console.log("is change to");
			  console.log(e.target.value);
			  this.handleFormChange(e);
  }

  updateValue= (e) =>{

	  switch (e.target.name) {
		case "weight":
		  this.setState({weight: e.target.value})
		  break;
		case "height" :
		  this.setState({height: e.target.value})
		  break;
		case "width" :
		  this.setState({width: e.target.value})
		  break;
		case "length" :
		  this.setState({length: e.target.value})
		  break;
		case "fedex_charge":
			this.setState({fedex_charge: e.target.value})
			break;
	  }
			   //this.setState({weight: e.target.value})
			  //console.log("is updtevalue");
			  //console.log(e.target.value);
	  
  }


 componentDidMount(){
    M.AutoInit();
}

 openModal = (products,product_category)=>{
    //  console.log("inside editiordemodal");
    //  console.log(orderdata);
    var elems = document.getElementById("editorderModel");
    var instance = M.Modal.init(elems);
    console.log("openmodel",products)
    this.setState({productlist:products,
      product_category_options:product_category})
   

    instance.open()
    // orderselectionmodal
}
closeModal = ()=>{
    var elems = document.getElementById("editorderModel");
    var instance = M.Modal.init(elems);
    instance.close()
}

    render() {
        const opts={};
        opts['readOnly'] = 'readOnly';

      // const catlist = this.state.product_category_options;
      // if (catlist.length > 0) {
      //   var categoryList = catlist.map((item, i) => {
      //     return (
      //       <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
      //     )
      //   }, this);
      // }
  const catlist = this.state.country_list;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.country_id}>{item.name}</option>
      )
    }, this);
  }

var states = [];
 var catlist1 = [];
 var catlistto = [];
// var catlistcust = [];

//  if (this.state.cust_country_filled != this.props.orderdata.cust_country)
//  {
//   if (this.state.statedata.length>0)
//       {
//       //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
//       catlist1 = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.cust_country)].slice()
//       this.setState({  statelist: catlist1 })
//       this.setState({  cust_country_filled: this.props.orderdata.cust_country })
//     }
//  }
//   catlist1 = this.state.statelist;
//   if (catlist1.length >0){
//    var selectStateOptionsCust = catlist1.map((state,statekey) => {
//       console.log("state", state)

//       return (

//         <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
//       )
//     })
//   }
//   else
//   {
//     // if (this.state.statedata.length>0)
//     // {
//     // //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
//     // const states = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
//     // this.setState({  statelist: states })
//     // }
//   }

 if (this.state.from_country_filled != this.props.orderdata.from_country)
 {
  if (this.state.statedata.length>0)
      {
      //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
      catlist1 = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
      this.setState({  statelist: catlist1 })
      this.setState({  from_country_filled: this.props.orderdata.from_country })
      //this.setState({  from_state: this.props.orderdata.from_state })
    }
 }
  catlist1 = this.state.statelist;
  if (catlist1.length >0){
   var selectStateOptions = catlist1.map((state,statekey) => {
      console.log("state", state)

      return (

        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    // if (this.state.statedata.length>0)
    // {
    // //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    // const states = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
    // this.setState({  statelist: states })
    // }
  }

if (this.state.to_country_filled != this.props.orderdata.to_country)
 {
  if (this.state.statedata.length>0)
      {
      //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
      catlistto = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.to_country)].slice()
      this.setState({  statelistto: catlistto })
      this.setState({  to_country_filled: this.props.orderdata.to_country })
    }
 }
catlistto = this.state.statelistto;
  if (catlistto.length >0){
   var selectStateOptionsTo = catlistto.map((state,statekey) => {
      console.log("state", state)

      return (

        //<option  key={statekey++} value={state.state_id}>{state.state_name}</option>
        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    // if (this.state.statedata.length>0)
    // {
    // //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    // const states = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
    // this.setState({  statelist: states })
    // }
  }

        return(<>              {/* Edit product Table items */}
          <EditProductOrderReturn ref="editproductorderreturn" rowNumber={this.state.rowNumber} status={this.state.orderStatusSelected}  orderId ={ this.state.orderid }
          productDetails={this.state.selectItemDetails}
           productcategory={this.state.product_category_options}
           updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}
           />
            <div id="editorderModel" className="modal editorderModel modal-fixed-footer">
            <div className="modal-content">
              
            {/* <span><h4 className="center orange-text"> Edit Order </h4><h4>Order id: { this.state.ordernumber }</h4></span> */}
            <h4 className="center orange-text"> Edit Return Order </h4>
            <h4>Order id: <span className="orange-text">{this.state.ordernumber }</span> </h4>
            
            <Row>

<Col xs={12}md={7} lg={7}>
<div className="row">
<form className="col s12">
{this.state.error}
<div className="row inputbox-height">
<div className="input-field col s6">
<input name="customer_id" id="customer_id" type="text" className="autocomplete" 
value={this.state.customer_id} readOnly 
// onChange={this.handleCustomerIDChange}
/>

<span className="helper-text " data-error="wrong" data-success="">Customer Ezzid ex: Ezz000000</span>
</div>

</div>
<div className="row inputbox-height">
<div className="input-field col s6">

<input name="order_date" id="order_date" type="text"  className="datepicker" 
value={this.state.order_date} 
// onChange={this.handleFormChange}
/>


<span className="helper-text " data-error="wrong" data-success="">Select the date Order Date </span>
</div>

<div className="input-field col s6">
<input name="tracking_number" id="tracking_number" type="text" className=""
value={this.state.tracking_number} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Enter Tracking inbound Number</span>

</div>

{/*<div className="input-field col s6">
<input name="shiper_order_number" id="shiper_order_number" type="text" className=""
value={this.state.shiper_order_number} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" >Enter Shipper Order Number</span>
</div> */}
{/*<div className="input-field col s6">
<input name="received_date" id="received_date" type="text" className="datepicker"
value={this.state.received_date} disabled={this.state.orderStatusSelected === 'YTA'} 
// onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Select date, the order arrived at warehouse</span>
</div>*/}
</div>
{/*<div className="row inputbox-height">
<div className="input-field col s6">
<input name="tracking_number" id="tracking_number" type="text" className=""
value={this.state.tracking_number} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Enter Tracking inbound Number</span>

</div>
</div>*/}
<div className="input-field col s6">
          <Nav className="right">
          <Nav.Link className="red-text modal-trigger "  href="#addOriginModal" style={{ fontSize: "19px", fontWeight: "bold" }}>
          Enter Origin Here<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
          </Nav>
        </div>
        <div className="input-field col s6">
          <Nav className="right">
          <Nav.Link className="red-text modal-trigger "  href="#addDestinationModal" style={{ fontSize: "19px", fontWeight: "bold" }}>
          Enter Destination Here<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
          </Nav>
        </div>
{
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }

                  {this.state.orderStatusSelected !=="YTA" ?
      <div className="row">
          {/*<div className="input-field col s4">
          <input name="quantity" id="quantity" min="0" type="number" className=""
           value={this.state.quantity} onChange={this.handleFormChange}
          />
          <label className="active" htmlFor="quantity">Quantity </label>
          <span className="helper-text" data-error="wrong" >Enter Quantity</span>
        </div>*/}
        
        <div className="input-field col s4">
          <input name="weight" id="weight" min="0" type="number" className=""
           value={this.state.weight}  onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="weight">Weight</label>
          <span className="helper-text" data-error="wrong" >Enter Weight in pounds</span>
        </div>       

<div className="input-field col s4">
          <input name="length"  id="length" min="0" type="number" className=""
           value={this.state.length} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="length">Length</label>
          <span className="helper-text" data-error="wrong" >Enter Length in inches</span>
        </div>
        {/*<div className="input-field col s4">
          <input placeholder="" name="actual_charge" id="actual_charge" type="number"  className=""
          value={this.state.actual_charge} onChange={this.handleFormChange} min="0" 
          />
          <label className="active"htmlFor="actual_charge">Actual charges </label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
          <span className="helper-text green-text" >{this.state.Weight_type}</span>
        </div>*/}
      </div> : null}

      {this.state.orderStatusSelected !=="YTA" ?
      <div className="row">
        
        <div className="input-field col s4">
          <input name="width" id="width" min="0" type="number" className=""
           value={this.state.width} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="width">Width</label>
          <span className="helper-text" data-error="wrong" >Enter Width in inches</span>
        </div>
        <div className="input-field col s4">
          <input name="height" id="height" min="0" type="number" className=""
           value={this.state.height} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="height">Height</label>
          <span className="helper-text" data-error="wrong" >Enter Height in inches</span>
        </div>
      </div> : null}
	  
	  <div className="row">
	  <div className="input-field col s4">
	  <input name="fedex_charge" id="fedex_charge" min="0" type="number" className=""
           value={this.state.fedex_charge} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="width">Actual Charges</label>
	  </div>
	  </div>

<div className="row">
<div className="col s6">
{/* Order Status Radio Buttons */}
<h4>Order Status</h4>
            <label>
                <input name="orderStatusSelected" value ="DISCARD" type="radio" 
                 checked={this.state.orderStatusSelected ==="DISCARD"} onChange={this.handleFormChange}
                />
                <span>Discard</span>
            </label>

            <label>
                <input name="orderStatusSelected" value ="READY" type="radio" 
                 checked={this.state.orderStatusSelected ==="READY"} onChange={this.handleFormChange}
                />
                <span>Ready</span>
            </label>
</div>

</div>
<div className="row">
<div className="col s6">
{/* Payment Tyoe Radio Buttons */}
<h4>Payment Type</h4>
<p>
<label>
<input name="paymentTypeSelected" value="cash" type="radio"  
checked={this.state.paymentTypeSelected ==="cash"} onChange={this.handleFormChange}
/>
<span>Cash</span>
</label>
</p>
<p>
<label>
<input name="paymentTypeSelected" value="card" type="radio" 
checked={this.state.paymentTypeSelected ==="card"} onChange={this.handleFormChange} />
<span>Card</span>
</label>
</p>
{/* {this.state.orderStatusSelected}
{this.state.paymentTypeSelected}
{this.state.order_date}
{this.state.received_date}
{ this.state.ordernumbermerge} */}

</div>

<div className="col s6">
{/* Payment Tyoe Radio Buttons */}
<h4>Shipping Type</h4>
<p>
<label>
<input name="shippingTypeSelected" value="2" type="radio"  
checked={this.state.shippingTypeSelected ==="2"} onChange={this.handleFormChange}
/>
<span>New</span>
</label>
</p>
<p>
<label>
<input name="shippingTypeSelected" value="3" type="radio" 
checked={this.state.shippingTypeSelected ==="3"} onChange={this.handleFormChange} />
<span>Return</span>
</label>
</p>
{/* {this.state.orderStatusSelected}
{this.state.paymentTypeSelected}
{this.state.order_date}
{this.state.received_date}
{ this.state.ordernumbermerge} */}

</div>

</div>

{/*<div className="row">
<div className="col s6">

<h4>Send Notification</h4>
<p>
<label>
<input name="sendNotification" value="YES" type="radio"  
checked={this.state.sendNotification ==="YES"} onChange={this.handleFormChange}
/>
<span>YES(Default)</span>
</label>
</p>
<p>
<label>
<input name="sendNotification" value="NO" type="radio" 
checked={this.state.sendNotification ==="NO"} onChange={this.handleFormChange} />
<span>NO</span>
</label>
</p>

</div>

</div>
<div className="row">
<div className="col s6">

<h4>Print Label</h4>
<p>
<label>
<input name="printLabel" value="YES" type="radio"  
checked={this.state.printLabel ==="YES"} onChange={this.handleFormChange}
/>
<span>YES(Default)</span>
</label>
</p>
<p>
<label>
<input name="printLabel" value="NO" type="radio" 
checked={this.state.printLabel ==="NO"} onChange={this.handleFormChange} />
<span>NO</span>
</label>
</p>

</div>

</div>*/}
</form>

</div>
</Col>
{/* right side column starts here */}
<Col xs={12}md={5} lg={5}>


     <CustomerDetailCard 
     info = {this.state.customerinfo}
      />

 <Row>
 <Col>
 <h4>Product Details</h4>
 </Col>
 {/* add product column for future use */}
 <Col>

{
  this.state.orderStatusSelected != 'SP' && this.state.orderStatusSelected != 'NEW' && this.state.orderStatusSelected != 'DISC'
  ? <Nav className="right">
      <Nav.Link className="red-text modal-trigger " href="#addProductModal">
        Add<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg" /></Nav.Link>
    </Nav>  
  :""

}

 
 </Col>

</Row>
{/* product card to be shown here */}
{/* <div className="productlistEditOrderDiv">{this.state.productlisthtml}</div> */}
<div className="productlistDiv ">
  <ProductItemsList orderid={this.state.orderid} products={this.state.productlist} rowclick={this.handletableClick}
    order_status = {this.state.orderStatusSelected}
  />
  </div>
                    <div className="right"><b>Total Price: </b>$
                    {
                    ( ()=>{
                      var totalprice =0;
                      this.state.productlist.forEach(function(item){
                        totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                      })
                      return parseFloat(totalprice).toFixed(2)
                    })()
                      
                      }
</div>
</Col>

{/* right side columns ends here */}

</Row> 
        
         
     
            </div>
            <div className="modal-footer">
           
            <ButtonGroup className="mr-2" aria-label="First group">
            <Button id="closeEditOrderBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button> 
            {
              this.state.orderStatusSelected != 'SP' && this.state.orderStatusSelected != 'NEW' && this.state.orderStatusSelected != 'DISC'
              ?<Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderDB}> Update</Button>
              :""
            }
            
        
          </ButtonGroup>
            </div>
          </div>



          {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addProductModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Product </h4>
            <Row>
              <Col xs={12} >
                <form>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name" id="product_name" type="text" value={this.state.product_name} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_name" className="black-text" >Product Name</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter a valid Product Name</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_quantity" className="black-text">Quantity</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter number of products items</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_price" disabled={this.state.orderStatusSelected === "IM"} id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_price" className="black-text">Product Price</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter numbers</span>
                      </div>
                    </Col>
                  </Row>


                  {/*<Row>
                    <Col>

                      <div id="categroydiv" className="input-field col s6 offset-s3 center">
                       
                        <select id='category_name_select' name="product_category" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Category</option>

                          {categoryList}

                        </select>
                      

                      </div>


                    </Col>

                  </Row>*/}


                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" >
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
              <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addProductTolist}> Add</Button>

            </ButtonGroup>

          </div>
        </div>

        {/* Model for adding produts ENDS here */}  

         {/* Model for adding Origin STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addOriginModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Enter Origin Country and Address Details</h4>
            <Row>
              <Col xs={12} >
                <form>
                    {/*<Row>
                    <Col xs={3}>
                <h4 className="blue-text">From</h4>
                    </Col>
                    </Row>*/}
                <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='from_country' name="from_country"  value={this.state.from_country} className="browser-default" onChange={this.handleCountryNameChange}>
                          <option value=""  selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                   
                   <Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_company" id="from_company" type="text" 
            value={this.state.from_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_contact" id="from_contact" type="text" 
            value={this.state.from_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address1" id="from_address1" type="text" 
            value={this.state.from_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address2" id="from_address2" type="text" 
            value={this.state.from_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_zip" id="from_zip" type="number" 
            value={this.state.from_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_city" id="from_city" type="text" 
            value={this.state.from_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>

          <select id="from_state" name="from_state" className="browser-default" value={this.state.from_state} onChange={this.handleCustomerIDChange}>
                                <option  selected>Choose state </option>
                                {selectStateOptions}
                              </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_phoneno" id="from_phoneno" type="number" 
            value={this.state.from_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                   </form>
              </Col>
            </Row>
 </div>
          <div className="modal-footer" >
            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button>
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Save</Button>
            </ButtonGroup>
          </div>
        </div>
   {/* Model for adding Origin ENDS here */}

{/* Model for adding Destintion STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addDestinationModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Destination Country and Address Details</h4>
            <Row>
              <Col xs={12} >
                <form>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='to_country' name="to_country" value={this.state.to_country} className="browser-default" onChange={this.handleCountryNameChangeTo}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_company" id="to_company" type="text" 
            value={this.state.to_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_contact" id="to_contact" type="text" 
            value={this.state.to_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address1" id="to_address1" type="text" 
            value={this.state.to_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address2" id="to_address2" type="text" 
            value={this.state.to_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_zip" id="to_zip" type="number" 
            value={this.state.to_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_city" id="to_city" type="text" 
            value={this.state.to_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>

            <select id="to_state" name="to_state" value={this.state.to_state}  className="browser-default"
           onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptionsTo}
                              </select>
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_phoneno" id="to_phoneno" type="number" 
            value={this.state.to_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                   </form>
              </Col>
            </Row>
 </div>
          <div className="modal-footer" >
            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button>
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Save</Button>
            </ButtonGroup>
          </div>
        </div>
   {/* Model for adding Destination ENDS here */}
      


              </>
        )
    }
}

export default EditOrder;
