import React,{Component} from 'react';
import {Row, Col,Card,Container,Button} from 'react-bootstrap'
import { base_url } from '../../globalConstants'
import M from "materialize-css";
import Axios from "axios";
import { Height } from '@material-ui/icons';
// import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
// import { Bar } from "react-chartjs-2"
//import Line from './Line';
import LineChart from './LineChart';
import LineChartMulti from './LineChartMulti'

class Dashboard extends Component{
    constructor(props){
        super (props);
        this.state = {
            countryName: '',
            periodName: '',
            country_list: this.props.countryList
            ,IM: 0
          ,RTM: 0
          ,WFC: 0
          ,YTA: 0
          ,SHIP: 0
          ,SIGNUP: 0
          ,VOL_TOTAL: 0
          ,VOL_TOTAL_PRIOR: 0
          ,VOL_TOTAL_ECON: 0
          ,REV_TOTAL: 0
          ,REV_TOTAL_PRIOR: 0
          ,REV_TOTAL_ECON: 0
          ,VOL_DATA: {}
          ,REV_DATA:{},
          chartData:{}
        }
        
    }
	
    handleCountryChange = e => {
        console.log(e.target.value);
        this.setState({countryName: e.target.value })
    }
	
    handlePeriodChange = e => {
      console.log(e.target.value);
      this.setState({periodName: e.target.value })
  }

  formatDate(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
    var day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    return year + '-' + month + '-' + day;
  }

  refreshPage = async () => {
    this.setState({
      countryName: '',
      periodName: '',
      country_list: this.props.countryList
      ,IM: 0
    ,RTM: 0
    ,WFC: 0
    ,YTA: 0
    ,SHIP: 0
    ,SIGNUP: 0
    ,VOL_TOTAL: 0
    ,VOL_TOTAL_PRIOR: 0
    ,VOL_TOTAL_ECON: 0
    ,REV_TOTAL: 0
    ,REV_TOTAL_PRIOR: 0
    ,REV_TOTAL_ECON: 0
    ,VOL_DATA: {}
    ,REV_DATA:{},
    chartData:{}
  })
  }

	showDashboard = async () => {
    try{
      if (document.getElementById("country_name_select").value == "")
      {
        document.getElementById("country_name_select").style.borderColor = "red";
        return
      }
      else{
        document.getElementById("country_name_select").style.borderColor = "black";
      }
      if (document.getElementById("period_name_select").value == "")
      {
        document.getElementById("period_name_select").style.borderColor = "red";
        return
      }
      else{
        document.getElementById("period_name_select").style.borderColor = "black";
      }


var selectedValue = document.getElementById("period_name_select").value;
    var endDate = new Date();
    var startDate;

    switch(selectedValue) {
      case "today":
        startDate = new Date(endDate);
        break;
      case "yesterday":
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 1);
        break;
      case "currentWeek":
        var dayOfWeek = endDate.getDay();
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - dayOfWeek); // Start of the current week
        break;
      case "previousWeek":
        var dayOfWeek = endDate.getDay();
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - dayOfWeek - 7);
        endDate.setDate(startDate.getDate() + 6); // End date is 6 days after start date
        break;
      case "currentMonth":
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        break;
      case "previousMonth":
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
        break;
      case "Q1":
        startDate = new Date(endDate.getFullYear(), 0, 1);
        endDate = new Date(endDate.getFullYear(), 2, 31);
        break;
      case "Q2":
        startDate = new Date(endDate.getFullYear(), 3, 1);
        endDate = new Date(endDate.getFullYear(), 5, 30);
        break;
      case "Q3":
        startDate = new Date(endDate.getFullYear(), 6, 1);
        endDate = new Date(endDate.getFullYear(), 8, 30);
        break;
      case "Q4":
        startDate = new Date(endDate.getFullYear(), 9, 1);
        endDate = new Date(endDate.getFullYear(), 11, 31);
        break;
      case "year2024":
        startDate = new Date(2024, 0, 1);
        endDate = new Date(2024, 11, 31);
        break;
      case "year2023":
        startDate = new Date(2023, 0, 1);
        endDate = new Date(2023, 11, 31);
        break;
      case "year2022":
        startDate = new Date(2022, 0, 1);
        endDate = new Date(2022, 11, 31);
        break;
      case "year2021":
        startDate = new Date(2021, 0, 1);
        endDate = new Date(2021, 11, 31);
        break;
      case "year2020":
        startDate = new Date(2020, 0, 1);
        endDate = new Date(2020, 11, 31);
        break;
      default:
        startDate = endDate;
    }

    console.log("Start Date:", this.formatDate(startDate));
    console.log("End Date:", this.formatDate(endDate));

      let token =localStorage.getItem("token")
        var config = {
        
      };
      var country = document.getElementById("country_name_select").value
      var frmdate = this.formatDate(startDate)
      var todate = this.formatDate(endDate)
      console.log("country:", country);
      var url= base_url+`get_dashboard_count/?country=${country}&frmdate=${frmdate}&todate=${todate}`
    
      var result = await Axios.get(url,config)
      console.log(result.data)
      var response = result.data
      this.setState({
        IM:response["IM"],
        WFC: response["WFC"],
        RTM:response["RTM"],
        YTA: response["YTA"],
        SHIP: response["SHIP"],
        SIGNUP: response["SIGNUP"],
        VOL_TOTAL: response["VOL_TOTAL"],
        VOL_TOTAL_PRIOR:response["VOL_TOTAL_PRIOR"],
        VOL_TOTAL_ECON: response["VOL_TOTAL_ECON"],
        REV_TOTAL:response["REV_TOTAL"],
        REV_TOTAL_PRIOR: response["REV_TOTAL_PRIOR"],
        REV_TOTAL_ECON: response["REV_TOTAL_ECON"],
        VOL_DATA: response["VOL_DATA"],
        REV_DATA: response["REV_DATA"]
      })
      var datap = response["VOL_DATA"];
      console.log("data:" + datap)
      //let myChart = document.getElementById("myChart0").getContext("2d");
    const CountValue = datap.map(a => a.dtotal);
    const CountMonth = datap.map(a => a.country);

    
    // var data = {
    //   labels: CountMonth,
    //   datasets: [
    //     {
    //       label: "Count",
    //       data: CountValue
    //     }
    //   ]
    // };

    this.setState({chartData : {
      labels: CountMonth,
      values: CountValue,
    }})
    console.log("after:" + this.state.chartData)

    var datarev = response["REV_DATA"];
      console.log("dataRev:" + datarev)
      //let myChart = document.getElementById("myChart0").getContext("2d");
    const CountValueRev_pri = datarev.filter(x=> x.shipping_method_id === 1).map(a => a.dcount);
    const CountMonth_pri = datarev.filter(x=> x.shipping_method_id === 1).map(a => a.box_id__shipped_on__month);
    const CountValueRev_eco = datarev.filter(x=> x.shipping_method_id === 0).map(a => a.dcount);
    const CountMonth_eco = datarev.filter(x=> x.shipping_method_id === 0).map(a => a.box_id__shipped_on__month);

    this.setState({ datasets : [
      {
        label: 'Priority',
        labels: CountMonth_pri,
        values: CountValueRev_pri,
        borderColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Economy',
        labels: CountMonth_eco,
        values: CountValueRev_eco,
        borderColor: 'rgb(255, 99, 132)',
      },
      
      // Add more datasets as needed
    ]
  })
    // var option = {
    //   responsive: true
    //   // Options
    // };

    //let massPopChart = new Chart.Line(myChart, { data: data, options: option });

    }catch(error){
    
      console.log(error.response.data)
      
       if (error.response.status ===503 ){
        M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
       }
       if (error.response.status === 403 ){
        M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
       }
      return error.response.data
    }
    
	}

    render(){
      // const chartData = {
      //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      //   values: [65, 59, 80, 81, 56, 55, 40],
      // };
      
		 const selectCounrtyOptions = this.state.country_list.map((country, index) => {

            return (
              <option key={index} value={country}>{country}</option>
            )
          })
        return(
             <Container>
              <Row><Col>
            <Row>
			              <Col xs={6} lg={3}>
                <Button  onClick={this.refreshPage}  style={{backgroundColor: "grey" , color: "white"}}>
                    REFRESH
                </Button>
                </Col>
                <Col xs={6} lg={3}>
                <Button onClick={this.showDashboard} style={{ backgroundColor: "blue" , color: "white"}}>
                    GENERATE
                </Button>
              </Col>
			                <Col xs={12} lg={6} style={{ color: "navyblue"}}>
                <h3>EZZYSHIP DASHBOARD</h3>
              </Col>
			  </Row>
        </Col>
        </Row>
			  <Row>
			    <Col xs={12} lg={5}>
                    <h5>Select Country</h5>
                    
                    <div>
                    <select id="country_name_select" name="country_name" className="browser-default country_name_select" value={this.state.countryName} onChange={this.handleCountryChange}>
                        <option value=""  >Choose Country</option>
                        <option value="ALL"  >ALL</option>
                        {selectCounrtyOptions}
                    </select>
                </div>
                </Col>
				<Col xs={12} lg={5}>
        <h5>Select Time Period</h5>
        
                <div>
                    <select id="period_name_select" name="period_name" className="browser-default period_name_select" value={this.state.periodName} onChange={this.handlePeriodChange}>
                        <option value=""  >Choose Period</option>
                        <option value="today"  >Today</option>
                        <option value="yesterday"  >Yesterday</option>
                        <option value="currentWeek"  >Current Week</option>
                        <option value="previousWeek"  >Previous Week</option>
                        <option value="currentMonth"  >Current Month</option>
                        <option value="previousMonth"  >Previous Month</option>
                        <option value="Q1"  >Q1</option>
                        <option value="Q2"  >Q2</option>
                        <option value="Q3"  >Q3</option>
                        <option value="Q4"  >Q4</option>
                        <option value="year2024"  >2024</option>
                        <option value="year2023"  >2023</option>
                        <option value="year2022"  >2022</option>
                        <option value="year2021"  >2021</option>
                        <option value="year2020"  >2020</option>
                    </select>
                </div>
              </Col>

              {/* <Col xs={12} lg={2}><Card style={{ height: '4rem' , backgroundColor: "lightpink" , color: "white"}}>
                  <Card.Body>
                    <Card.Text className="center">
                       <h5>Members</h5> 
                      
                    </Card.Text>
                  </Card.Body>
                </Card></Col> */}
           
            </Row>

            <Row><Col xs={12} lg={10}>
            <Container>
            <Row><Col xs={12} lg={12}>
            <Container>
            <Row><Col xs={12} lg={6}>

            <Container>
            <Row><Col xs={12} lg={12}>
              <Card style={{ height: '4rem' , backgroundColor: "lightblue" , color: "white"}}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>Volume Shipped(In LBS)</h4>
                      
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col></Row>
              
              <Row>
                  <Col xs={4}>
              <Card>
                  <Card.Body>
                    <Card.Text className="center">
                      <small style={{fontSize:'1rem'}}>{this.state.VOL_TOTAL}</small>
                      <p style={{fontSize:'.9rem'}}>Total</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Body>
                    <Card.Text className="center">
                    <small style={{fontSize:'1rem'}}>{this.state.VOL_TOTAL_PRIOR}</small>
                      <p style={{fontSize:'.9rem'}}>Priority</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
              <Col xs={4}>
                <Card>
                  <Card.Body>
                    <Card.Text className="center">
                      <small style={{fontSize:'1rem'}}>{this.state.VOL_TOTAL_ECON}</small>
                      <p style={{fontSize:'.9rem'}}>Standard</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                
                </Row>
              
              
              </Container>
                </Col>


                <Col xs={12} lg={6}>
              
              <Card>
                <Card.Body>
                  <Card.Text className="center dashboardDummy">
                  <LineChart data={this.state.chartData} />
                  </Card.Text>
                </Card.Body>
              </Card>
            
            </Col>
            </Row>

            <Row><Col xs={12} >
            <Container>
            <Row><Col xs={12} lg={6}>

            <Container>
            <Row><Col xs={12} lg={12}>
              <Card style={{ height: '4rem' , backgroundColor: "lightgreen" , color: "white"}}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>Revenue(In USD)</h4>
                      
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col></Row>
              
              <Row>
                  <Col xs={4}>
              <Card>
                  <Card.Body>
                    <Card.Text className="center">
                      <small style={{fontSize:'1rem'}}>{this.state.REV_TOTAL}</small>
                      <p style={{fontSize:'.9rem'}}>Total</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Body>
                    <Card.Text className="center">
                      <small style={{fontSize:'1rem'}}>{this.state.REV_TOTAL_PRIOR}</small>
                      <p style={{fontSize:'.9rem'}}>Priority</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
              <Col xs={4}>
                <Card>
                  <Card.Body>
                    <Card.Text className="center">
                      <small style={{fontSize:'1rem'}}>{this.state.REV_TOTAL_ECON}</small>
                      <p style={{fontSize:'.86rem'}}>Standard</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                
                </Row>
              
              
              </Container>
                </Col>


                <Col xs={12} lg={6}>
              
              <Card>
                <Card.Body>
                  <Card.Text className="center dashboardDummy">
                  {/* <Line /> */}
                  <LineChartMulti datasets={this.state.datasets}  />
                  </Card.Text>
                </Card.Body>
              </Card>
            
            </Col>
            </Row>

            
             
            
             </Container>
            
            
              </Col>
             
              
            </Row>
             
            
             </Container>
            
            
              </Col>
             
              
            </Row>
            
           
            </Container>
            </Col>
            
            <Col  xs={12} lg={2} >
            <Row>
            <Col><Card style={{ height: '4rem' , backgroundColor: "lightpink" , color: "white"}}>
                  <Card.Body>
                    <Card.Text className="center">
                      <h5>Members</h5>
                      
                    </Card.Text>
                  </Card.Body>
                </Card></Col>
              </Row> 
            <Row>
            <Col>
                <Card style={{ height: '6rem' }}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>{this.state.SIGNUP}</h4>
                      <p>Sign Ups</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

                <Row>
            <Col>
                <Card style={{ height: '5rem' , backgroundColor: "#F4C430" , color: "white"}}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>Order Status</h4>
                      
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

                <Row>
            <Col>
                <Card style={{ height: '6rem' }}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>{this.state.SHIP}</h4>
                      <p>Shipped</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

                <Row>
            <Col>
                <Card style={{ height: '6rem' }}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>{this.state.RTM}</h4>
                      <p>Ready</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

                <Row>
            <Col>
                <Card style={{ height: '6rem' }}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>{this.state.IM}</h4>
                      <p>Invoice Missing</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

                <Row>
            <Col>
                <Card style={{ height: '6rem' }}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>{this.state.WFC}</h4>
                      <p>Waiting</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

                <Row>
            <Col>
                <Card style={{ height: '6rem' }}>
                  <Card.Body>
                    <Card.Text className="center dashboardDummy">
                      <h4>{this.state.YTA}</h4>
                      <p>Yet to Arrive</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                </Row>

            </Col>

            </Row>
            </Container>
        )
    }
}

export default Dashboard;