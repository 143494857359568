import React,{Component, Fragment} from 'react';
import {Row, Col,Card,Container, Button, Table, Image, Nav, Spinner,ButtonGroup} from 'react-bootstrap'
import { base_url } from '../../globalConstants'
import axios from 'axios';
import M from "materialize-css";
import { EditOutlined } from "@material-ui/icons";
import { saveAs } from 'file-saver';
import './addReferral.css';

class Reconreports extends Component{
    constructor(props){
        super (props);

        this.state = {
            countryName: '',
            startDate: "", 
            endDate: "",
            image: null,
            showSpinner: false,
            country_list: this.props.countryList
        }

        // console.log('selectCounrtyOptions',this.state.selectCounrtyOptions)

    }

 handleDateChange = ev => {
      alert("date")
      const target = ev.target
      const name = target.name
      console.log(ev.target.name)
      console.log(ev.target.value)
    this.setState({
      [name]: target.value
    });
  }

    handleCountryChange = e => {
        console.log(e.target.value);
        this.setState({countryName: e.target.value })
    }
    
    downloadReport = async () => {
        let report_url = base_url + `get_recon_report?quarter=${this.state.countryName}`
        console.log('url',report_url);
        console.log('this.state.countryName',this.state.countryName);
        var FileSaver = require('file-saver');
this.setState({
      showSpinner: true,
    })
        await axios({
            url: report_url,
            method: 'GET',
            responseType: 'arraybuffer'
        })
        .then((response)=>{
            {
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, `${this.state.countryName}.xlsx`);
            }
            console.log('response',response);
        }).catch((err)=>{
            console.log('err',err);
        })
        this.setState({
      showSpinner: false,
    })
    }

componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();

    let context = this

    var elems_startDate = document.getElementById('start_date');

    var options_startDate = {
      //selectMonths: true, // Enable Month Selection
      selectYears: 10, // Creates a dropdown of 10 years to control year
      format: 'yyyy-mm-dd',
      autoClose: true,
      defaultDate: new Date(),
      setDefaultDate: true,
      onSelect: function (date) {
        let selecteddata = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
        context.setState({ startDate: selecteddata });
        console.log("orderpk",selecteddata); // Selected date is logged
      },
    }
    var instances = M.Datepicker.init(elems_startDate, options_startDate);

    var elems_endDate = document.getElementById('end_date');

    var options_endDate = {
      //selectMonths: true, // Enable Month Selection
      selectYears: 10, // Creates a dropdown of 10 years to control year
      format: 'yyyy-mm-dd',
      autoClose: true,
      defaultDate: new Date(),
      setDefaultDate: true,
      onSelect: function (date) {
        let selecteddata = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
        context.setState({ endDate: selecteddata });
        // console.log("order",selecteddata); // Selected date is logged
      },
    }
    var instances = M.Datepicker.init(elems_endDate, options_endDate);

  }

handleImageChange = (e) => {
  const target = e.target
  const name = target.name
   if (e.target.name === "quarter_number")
     {
       this.setState({
          [name]: target.value,
        })
     }
     else
     {
        this.setState({
          image: e.target.files[0]
        })
     }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    let form_data = new FormData();
    form_data.append('image', this.state.image, this.state.image.name);
    form_data.append('quarter', this.state.quarter_number);  //this parametre showing error
    // form_data.append('content', this.state.content);
    //let url = 'http://localhost:8000/api/posts/';
    let url = base_url + `upload_filee`
    axios.post(url, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
        .then(res => {
          console.log(res.data);
          M.toast({ html: 'File uploaded successfully.' , classes: "white-text orange rounded" })
        })
        .catch(err => {
          console.log(err)
          M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });
        })
  };

    render(){
        const selectCounrtyOptions = this.state.country_list.map((country, index) => {

            return (
              <option key={index} value={country}>{country}</option>
            )
          })
        return(
            <Container>
               {
          this.state.showSpinner
            ? < div className="center">

              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="success" size="sm" />
              <Spinner animation="grow" variant="warning" size="sm" />
            </div>
            : null
        }

        
                <Row>
                    <Col>
                        <h5>Upload Fedex File</h5> <br/>
                    </Col>
                <Col>
                <div>
                    
                    <input type="file"
                   id="image"
                   accept=".xlsx"  onChange={this.handleImageChange} required/>
                    
                </div>
                </Col>
                </Row>
            <Row>
              <Col>

        <div className="input-field col s6">
          <input name="quarter_number" id="quarter_number" type="text" className=""
           value={this.state.quarter_number} onChange={this.handleImageChange}
          />
          <label htmlFor="quarter_number">Enter Quarter Name</label>
          <span className="helper-text" data-error="wrong" data-success="">Enter Quarter Name Ex: I,II....</span>

        </div>
        <Button onClick={this.handleSubmit}>
                    Proceed File Uploading
                </Button>
              </Col>
              </Row>
              <Row style={{ visibility: "hidden" }}>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="startDate" id="start_date" type="text" className="datepicker" 
                          value = { this.state.startDate } onChange={this.handleDateChange} />
                        <label htmlFor="start_date" className="black-text">Start Date</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Select Starting Date</span>
                      </div>
                    </Col>
                  </Row>
                  
              <Row>
                
                {/*<Col>
                    <h5>Countrywise</h5> <br/>
                </Col>*/}
              {/* <Col >
                <div>
                    <h5>Select country</h5>
                </div>
              </Col> */}
              <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 left">
                    <select id="country_name_select" name="country_name" className="browser-default country_name_select" value={this.state.countryName} onChange={this.handleCountryChange}>
                        <option value=""  >Choose Quarter</option>
                        {selectCounrtyOptions}
                    </select>
                </div>
              </Col>
              
                {/*<Button onClick={this.downloadReport}>
                    Download
                </Button>*/}
             
            </Row>
                              
            <Row>
              <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                <Button onClick={this.downloadReport}>
                    Download Report For Selected Quarter
                </Button>
                </div>
              </Col>
            </Row>

            </Container>
        )
    }
}

export default Reconreports;