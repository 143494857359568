import React, { Component } from 'react';
import { Card, Button, ButtonGroup, Nav, Image, Row, Col, Container, Spinner, Accordion, InputGroup, FormControl } from 'react-bootstrap';
import Axios from "axios";
import M from "materialize-css";
import { EditOutlined, Search, Print, NavigateBefore, NavigateNext, Info, EmojiFlagsRounded, } from "@material-ui/icons";
import { ModalHeader, ModalBody, ModalFooter, Modal  } from 'reactstrap';
import EditOrder from './editRole';
import ViewOrder from './viewOrder';
import ProductItemsList from './ProductItemListCard';
// import CountryFrag from './Country';
import Icon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { base_url } from '../../globalConstants';
import './orderlist.css';


class OrderList extends Component {
  constructor(props) {

    super(props);

    //  this.state=({country:<CountryFrag countrytable = {this.props.countrydata}/>})
    // this.fetchCountryData =  this.fetchCountryData.bind(this)
    // console.log(this.props.orderdata);
    // console.log('operatorId',this.props.operator_id);

    this.state = ({
      orderslist: this.props.orderdata,
      previouSelectedOrder: "",
      previousSelectedOrderMerge: "",
      productlistforMerge: [],
      orderweights: {},
      ordercustomer: {},
      orderproducts: {},
      totalweight: 0,
      manifestlist: [],
      mergelist: [],
      orderforedit: {},
      customerinfo: {},
      productlist: [],
      productlisthtml: <div></div>,
      parentOrderSelected: null,
      showSpinner: false,
      product_category_options: [],
      searchTerm: '',
      ManifestDisable: false,
      EditOrderDisabled: false,
      statusIDtoName: {},
      orderfilterString:"",

      tempOrderId: "",
      tempOrderId2: "",
      remarksTextValue: "",
      OnHoldValue: {},
      remarksOnHoldList: {},
      isCardAuth: {},

      tempCardAuth:"",
      tempBillingType:"",
      order_response: this.props.orderResponse,

      previousPageUrl: this.props.orderResponse.previous,
      nextPageUrl: this.props.orderResponse.next,
      currentPageNumber: 1,

      currentOrderListCount: this.props.orderdata.length,
      searchResultsCount: -1,
      totalOrderListCount: this.props.orderResponse.count,
      orderlistCopy: this.props.orderdata,

      fileUrl: '',
      modalFile: false,
      // isOpenFile: false

      // operatorId: this.props.operator_id

    })

    // console.log('typeoforderslist',typeof(this.state.orderslist));
    // console.log('operatorId',this.state.operatorId);

    // this.handleChangeRemarksText = this.handleChangeRemarksText.bind(this);
    // this.handleChangeRemarksTextEdit = this.handleChangeRemarksTextEdit.bind(this);

  }


  componentDidMount() {
    // Auto initialize all the things!
    // @TODO: fetch data from server to show list and update the state orderslist
    M.AutoInit();
  }

  updateOrderList = async (updatedUrl, pg) => {

    var context = this
    var tempPageNumber = this.state.currentPageNumber


    this.setState({
      showSpinner: true,
    })

    // console.log('UPDATED URL',updatedUrl)

    let response_data = []
    var prevUrl 
    var nextUrl 

    await Axios({
      method: "GET",
      url: updatedUrl
    }).then(function(response){
      // console.log("response",response);
      // console.log("response.data.results",response.data.results);
      response_data = response.data.results
      // context.setState({ updatedOrdersList: response.data.results })
      // console.log("UPDATED ORDELIST",this.state.updatedOrdersList);      
      // console.log("UPDATED ORDELIST",response_data);   
      prevUrl = response.data.previous   
      nextUrl = response.data.next   

      // tempPageNumber = updatedUrl.toString().split('=')[1] ?? 1

      if(pg === '+')
        tempPageNumber = tempPageNumber + 1
      else if(pg === '-')
        tempPageNumber = tempPageNumber - 1
      else
        tempPageNumber = ''

      context.setState({
        showSpinner: false,
        currentPageNumber: tempPageNumber
      })
    }).catch(function(response){
      console.log("response",response);
      console.log("ERROR UPDATING ORDERLIST");
    })

    await this.setState({orderslist: response_data})
    this.setState({previousPageUrl: prevUrl})
    this.setState({nextPageUrl: nextUrl})

  }

  // previousPage = async () => {

  // }

  nextPageTemp = () => {
    // alert(`NEXT PAGE ${this.state.order_response.next}`)
    // this.props.orderList_url = this.state.order_response.next
    console.log("this.props.orderList_url",this.props.orderList_url);
    console.log("this.state.order_response.next", this.state.order_response.next);

    var tempUrl = this.state.order_response.next
    this.props.orderList_url(tempUrl)

  }


 handleKeyDownSearch=(eventKey)=>{
  //  console.log('orderfilterString',this.state.orderfilterString);
  
  var filterString = this.state.orderfilterString
  let context = this

   // if(eventKey.key ==="Backspace"){
    // //  this.setState({orderslist: this.state.orderlistCopy})    
    // this.setState({currentPageNumber: 1})

    // this.setState({
      // showSpinner: true,
    // })
     // var orderCount
        // var prevUrl    
        // var nextUrl    
        // var search_results
  
        // let searchUrl = base_url+`roledetails/`   
         // Axios({
          // method: 'GET',
          // url: searchUrl
        // }).then( async (response) =>{
          // // console.log(response);
          // // console.log(response.data.results);
          // // console.log(response.data.count);
          // // console.log(response.data.next);
          // // console.log(response.data.previous);
          
          // orderCount = response.data.count
          // prevUrl = response.data.previous   
          // nextUrl = response.data.next  
          // search_results = response.data.results

          // context.setState({currentPageNumber: 1})
  
          // if(!response.data.results){
            // this.setState({orderslist: []}) 
            // this.setState({searchResultsCount: orderCount})
            // this.setState({currentOrderListCount: response.data.results.length})
            // return
          // }
  
          // this.setState({searchResultsCount: orderCount})
          // this.setState({orderslist: search_results})
          // this.setState({previousPageUrl: prevUrl})
          // this.setState({nextPageUrl: nextUrl})
          // this.setState({searchResultsCount: orderCount, showSpinner: false})
          
          // console.log('orderlist.length', this.state.orderslist.length);
          // this.setState({currentOrderListCount: response.data.results.length})
          
        // }).catch(  (response) =>{
          // console.log(response)
         // this.setState({showSpinner:false})
        // //  M.toast({ html: ""+response.Error, classes: "white-text red rounded" })
       
        // })
   // }
  if (eventKey.key ==="Enter"){
  // if (filterString.trim()===""){
  //   return
  // }
  this.setState({
    showSpinner: true,
    currentPageNumber: 1
  })

  var orderCount
  var prevUrl    
  var nextUrl    
  var search_results

  let searchUrl = base_url+`roledetails/?search=${filterString}`   
  Axios({
    method: 'GET',
    url: searchUrl
  }).then( async (response) =>{
    // console.log(response);
    // console.log(response.data.results);
    // console.log(response.data.count);
    // console.log(response.data.next);
    // console.log(response.data.previous);
    
    orderCount = response.data.count
    prevUrl = response.data.previous   
    nextUrl = response.data.next  
    search_results = response.data.results

    context.setState({currentPageNumber: 1})

    if(!response.data.results){
      this.setState({orderslist: []}) 
      this.setState({searchResultsCount: orderCount})
      this.setState({currentOrderListCount: response.data.results.length})
      return
    }

    this.setState({searchResultsCount: orderCount})
    this.setState({orderslist: search_results})
    this.setState({previousPageUrl: prevUrl})
    this.setState({nextPageUrl: nextUrl})
    this.setState({searchResultsCount: orderCount, showSpinner: false})
    
    console.log('orderlist.length', this.state.orderslist.length);
    this.setState({currentOrderListCount: response.data.results.length})
    
  }).catch(  (response) =>{
    console.log(response)
    this.setState({showSpinner:false})
    // M.toast({ html: response.Error, classes: "white-text red rounded" })
   
  })
}
 }

  editSearchTerm = async (e) => {
      // console.log(e.target.value)
      
      let word = e.target.value
      let bucket = word.split(' ')
      
      // console.log(bucket)
      
      let searchString = bucket.reduce((prevVal, currVal)=>{ return prevVal+=currVal+' ' }, ' ')

      console.log('searchString',searchString);
      this.setState({
          orderfilterString:searchString
      })
      
    
      
      // console.log('URL', base_url+`roledetails/?search=${searchString}`);
      
      // const response=await Axios.get(searchUrl)
      // const {data}=response.results;

      if(searchString ===""){
        this.setState({
          showSpinner: true,
        })
      
        var orderCount
        var prevUrl    
        var nextUrl    
        var search_results
  
        let searchUrl = base_url+`roledetails/?search=${searchString}`   
        await  Axios({
          method: 'GET',
          url: searchUrl
        }).then( async (response) =>{
          // console.log(response);
          // console.log(response.data.results);
          // console.log(response.data.count);
          // console.log(response.data.next);
          // console.log(response.data.previous);
          
          orderCount = response.data.count
          prevUrl = response.data.previous   
          nextUrl = response.data.next  
          search_results = response.data.results
  
          if(!response.data.results){
            this.setState({orderslist: []}) 
            this.setState({searchResultsCount: orderCount})
            this.setState({currentOrderListCount: response.data.results.length})
            return
          }
  
          this.setState({searchResultsCount: orderCount})
          this.setState({orderslist: search_results})
          this.setState({previousPageUrl: prevUrl})
          this.setState({nextPageUrl: nextUrl})
          this.setState({searchResultsCount: orderCount, showSpinner: false})
          
          console.log('orderlist.length', this.state.orderslist.length);
          this.setState({currentOrderListCount: response.data.results.length})
          
        }).catch(  (response) =>{
          console.log(response)
         this.setState({showSpinner:false})
        //  M.toast({ html: ""+response.Error, classes: "white-text red rounded" })
       
        })
      }
  }

  refreshpage = async () => {
    let token = localStorage.getItem("token")
    let header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token
    }
    let context = this
    this.setState({
      showSpinner: true,
      orderslist:[],
      mergelist: []
    })

    let url = base_url+`roledetails/`
    await Axios({
      method: 'GET',
      url: url,
      headers: header
    }).then(function (response) {
      console.log(response.status)
      console.log(response)

      context.setState({
        orderslist: response.data.results,
        showSpinner: false,
        manifestlist: [],
        previouSelectedOrder: ""
      })
      
      context.setState({
        searchResultsCount: -1,
        currentOrderListCount: response.data.results.length,
        totalOrderListCount: response.data.count
      })
      
      M.toast({ html: 'Refreshed ', classes: "white-text green rounded" })
      
    }).catch(function (response) {
      console.log(response)
      M.toast({ html: "Failed to refresh", classes: "white-text red rounded" })
      context.setState({

        showSpinner: false
      })
    })
  }


  getcustomer = async (ezzid) => {
    // http://localhost:8000/api/v1/ezzytrace/products/?order_id=
    try {
      let token = localStorage.getItem("token")
      let header = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Token ' + token,

      }

      var url = base_url+`customers/?ezz_id=${ezzid}`
      let customer = await Axios.get(url, header)
      console.log(customer.data.results)


      // M.toast({html: 'Pending Ordersa fetched',classes:"white-text orange rounded"});
      // this.setState({error:<Success/>})
      // this.setState({productlist: this.state.productlist})
      this.setState({ customerinfo: customer.data.results[0] })


    } catch (error) {
      console.log(error)
      M.toast({ html: 'Failed to fetch cutomer details', classes: "white-text red rounded" });
      // this.setState({error:<Error/>})
    }
  }
 
  click = () => {
    this.props.orderList();
  }
 
  openeditordermodal = async (order) => {

    // // await this.getproducts(order.order_id)
    // await this.getcustomer(order.ezz_id)
    await this.setState({ orderforedit: order });

    // let country = this.state.customerinfo.country
    // // await this.getCategories(country)

    // // let productcatgry = this.state.product_category_options
    // // let products = this.state.productlist
    // // console.log("orderlistopent", products)
    let productcatgry = null;
    let products = null;
    this.refs.editorder.openModal(products, productcatgry);
    // this.refs.editorder.openModal(order);

  }

  openviewordermodal = async (order) => {


    await this.getproducts(order.order_id)
    await this.getcustomer(order.ezz_id)
    await this.setState({ orderforedit: order });

    let country = this.state.customerinfo.country
    await this.getCategories(country)

    let productcatgry = this.state.product_category_options
    let products = this.state.productlist
    console.log("orderlistopent", products)
    this.refs.vieworder.openModal(products, productcatgry);

  }

DownloadCashInvoice = async (orderDetails) => {

    var currentOrderId = "CASH-" + orderDetails.order_id //"CASH-170" 

    var invoiceFormData = new FormData()
    invoiceFormData.append("invoice", currentOrderId)

    await Axios({
      method: "POST",
      url: base_url+`/download_cash_invoice`,
      data: invoiceFormData,
      responseType: 'blob'
    }).then((response)=>{
      console.log("RESPONSE DONE", response);  
      console.log("RESPONSE DONE", response.data);  
      console.log("RESPONSE DONE", response.headers['content-type']);  
      const file_url = window.URL.createObjectURL(new Blob([response.data], {type:'application/pdf'}));
      console.log("fileURL",file_url);
      this.setState({fileUrl: file_url})
      this.setState({modalFile: true})
    }).catch((response)=>{
      console.log("RESPONSE NOT DONE", response);        
      M.toast({html: `Error while Downloading.`,classes: "white-text red rounded"})
    })
  }

  

  render() {

    var idNo = 0


    
    const orderCardList = this.state.orderslist.map((order) => {
        
      


          this.state.orderweights[order.order_id] = order.weight
      this.state.ordercustomer[order.order_id] = order.ezz_id
      this.state.orderproducts[order.order_id] = order.product_details
      this.state.statusIDtoName[order.current_status] = order.current_status_name
      this.state.OnHoldValue[order.order_id] = order.on_hold
      this.state.remarksOnHoldList[order.order_id] = order.remarks
      this.state.isCardAuth[order.order_id] = order.card_authorize

      idNo = idNo + 1

      return (
        <div>

                {(() => {
                    // Function to set icons with respect to order status
                    // if (order.current_status !== "NEW") {
                    //if (order.current_status ) {

                      return (


                        <>
                        
                        <Card key={order.id} className="orderItemdetailsCard">
            <Card.Header className="smallheader">
              <Row >
                <Col sm={4} md={4} lg={3}>
                  <Card.Text>{order.name}</Card.Text>
                </Col>
                {/*<Col >
                  <Card.Text>{order.ezz_id}</Card.Text>
                </Col>
                <Col  >
                  <Card.Text >
                    {order.country}
                  </Card.Text>
                </Col>*/}
                <Col>
                  <Card.Text>
                    <Nav.Link className="green-text modal-trigger " onClick={() => this.openeditordermodal(order)}  >
                                      View Role<Image className="red-text ml-1" src="assets/images/edit.svg" /></Nav.Link>
                                    
                  </Card.Text>
                </Col>
                {/*<Col>
                  <Card.Text>
                    {order.outbound_number}
                  </Card.Text>
                </Col>*/}
              </Row>
              

            </Card.Header>


           
    
              </Card>


                </>
              )

            //}
          })()}
          
        </div>
      );
      
    });

    return (
      <Container>


        {
          this.state.showSpinner
            ? < div className="center">

              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="success" size="sm" />
              <Spinner animation="grow" variant="warning" size="sm" />
            </div>
            : null
        }

        <>
          <div id="parentOrderModel" className="modal parentOrderModel  modal-fixed-footer">
            <div className="modal-content ">
              <div className="">


                <h4 className="center orange-text"> Parent Order </h4>



                <form >


                  <div className="row ">
                    <div className="col  s6 l6">
                      {/* Order Status Radio Buttons */}
                      <h5>Select Parent Order</h5>
                      {(() => {


                        return this.state.mergelist.map((ordernumber, index) => {

                          return (
                            <p key={index}>
                              <label>
                                <input name="parentOrderSelected" value={ordernumber} type="radio"
                                  checked={this.state.parentOrderSelected === ordernumber} onChange={this.handleParentOrderChange} />
                                <span><b>Order Number.</b> {ordernumber}</span>
                              </label>
                            </p>


                          )

                        })



                      })()}
                      {/* { this.state.parentboxSelected} */}

                    </div>
                  </div>


                </form>
              </div>
              {this.state.parentOrderSelected}
            </div>
            <div className="modal-footer">

              <ButtonGroup className="mr-2" aria-label="First group">
                <Button id="closeEditBoxBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button>
                <Button id="mergeOrderBtn" variant="outline-primary" className="mr-2 btn modal-close"
                  onClick={this.mergeOrders} > Proceed</Button>
              </ButtonGroup>
            </div>
          </div>


          {/* ON HOLD MODAL */}

                     {/* ADD NEW REMARKS  */}
          <div id="remarksForOnHold" className="modal remarksForOnHold  modal-fixed-footer">
            <div className="modal-content ">
              <div className="">


                <h4 className="center orange-text"> Remarks </h4>

                <form  >
                  <div className="row ">
                    <div className="col  s6 l6">
                      <h5>Please give reason for On Hold <p style = {{ fontSize : "11px" }} >Order Id: {this.state.tempOrderId }</p></h5>

                      {(() => {
                          return (
                            <>

                              <label htmlFor="remarksText">
                                Remarks:
                              </label>
                              <textarea
                                id="remarksText"
                                className=""
                                name=""
                                value={this.state.value}
                                onChange={this.handleChangeRemarksText}
                                placeholder={this.props.placeholder} />

                            </>                          
                          )
                      })()}

                    </div>
                  </div>


                </form>
              </div>
            </div>
            <div className="modal-footer">

              <ButtonGroup className="mr-2" aria-label="First group">
                <Button id="closeRemarksModalBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
                <Button id="saveRemarksModalBtn" variant="outline-primary" type="submit" className="mr-2 btn modal-close" onClick={this.onHoldRemarks} >Save</Button>
              </ButtonGroup>
            </div>
          </div>

                      {/* UPDATE REMARKS */}

          <div id="updateRemarksOnHold" className="modal updateRemarksOnHold  modal-fixed-footer">
            <div className="modal-content ">
              <div className="">


                <h4 className="center orange-text"> Remarks </h4>

                <form  >
                  <div className="row ">
                    <div className="col  s6 l6">
                      <h5>Please give reason for On Hold  <p style = {{ fontSize : "11px" }}  >Order Id: {this.state.tempOrderId2 }</p></h5>

                      {(() => {
                          return (
                            <>

                              <label htmlFor="remarksTextEdit">
                                Remarks:
                              </label>
                              <textarea
                                id="remarksTextEdit"
                                className=""
                                name=""
                                value={this.state.value}
                                onChange={this.handleChangeRemarksTextEdit}
                                placeholder={this.props.placeholder} />

                            </>                          
                          )
                      })()}

                    </div>
                  </div>


                </form>
              </div>
            </div>
            <div className="modal-footer">

              <ButtonGroup className="mr-2" aria-label="First group">
                <Button id="closeRemarksModalBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
                <Button id="saveRemarksModalBtn" variant="outline-primary" type="submit" className="mr-2 btn modal-close" 
                onClick= { () => this.updateOnHoldRemarks(document.getElementById('remarksTextEdit').name)} >Update</Button>
              </ButtonGroup>
            </div>
          </div>
          

          {/* ON HOLD MODAL */}


        </>


        <div className="row">
          <div className="col s3">
            <h4 className=" orange-text">Role Listing </h4>
          </div>

          <div className="col s4">

          </div>

          {/* <div className="col s7 l7 offset-4 offset-l4">                       */}
          <div className="col s7 l7 ">                      
            {/* hide Manifest button because we not using selection things RN */}

            {/*<ButtonGroup>
              <Button variant="outline-secondary " className="grey white-text mr-2" onClick={this.refreshpage}>Refresh </Button>
              <Button variant="outline-primary " className="indigo white-text mr-2" onClick={this.selectParentOrder}>Merge </Button>
              {this.state.ManifestDisable ? <Button disabled variant="outline-primary " className="orange white-text " onClick={this.manifestOrders}>Manifest</Button> : <Button variant="outline-primary " className="orange white-text " onClick={this.manifestOrders}>Manifest</Button>}
            </ButtonGroup>*/}

          </div>
        </div>

        <Row>
          <Col>
            <div>
                {
                  this.state.previousPageUrl
                    ? <Button variant="outline-secondary " title="Previous Page" className="teal white-text mr-2" onClick={() => this.updateOrderList(this.state.previousPageUrl,'-')} ><NavigateBefore /></Button>
                    : <Button variant="outline-secondary " title="Previous Page" className="teal white-text mr-2" disabled><NavigateBefore /></Button>
                }
                {
                  this.state.nextPageUrl
                    ? <Button variant="outline-secondary " title="Next Page" className="teal white-text mr-2" onClick={() => this.updateOrderList(this.state.nextPageUrl,'+')}><NavigateNext /></Button>
                    : <Button variant="outline-secondary " title="Next Page" className="teal white-text mr-2" disabled><NavigateNext /></Button>
                }          
            </div><br/>
            <div>              
                {
                  this.state.currentPageNumber  != ''
                  ?<h5>Page: {this.state.currentPageNumber}</h5>
                  :""
                }              
            </div>
          </Col>
          <Col>
            <div>
              <InputGroup className="mb-3" style={{ float: 'right', width: '270px' }}>
                <InputGroup.Prepend >
                  <InputGroup.Text style={{ border: '1px solid grey', background: 'white' }} id="inputGroup-sizing-default"><Search /></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ border: '1px solid gray', borderRadius: '2px', paddingLeft: '10px' }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="  Search here....."
                  onChange={this.editSearchTerm.bind(this)}
                  onKeyDown={this.handleKeyDownSearch}
                // onKeyUp={this.onKeyDown}
                />
              </InputGroup>
            </div><br/>
            <div style={{ float: 'right', width: '270px' }}>
              {
                this.state.searchResultsCount > -1
                ? <h5>Showing 
                    <span style={{ color: 'orange' }} > {this.state.currentOrderListCount} </span> of
                    <span style={{ color: 'orange' }} > {this.state.searchResultsCount} </span> results
                  </h5>
                : <h5>Showing
                    <span style={{ color: 'orange' }} > {this.state.currentOrderListCount} </span> of
                    <span style={{ color: 'orange' }} > {this.state.totalOrderListCount} </span> orders
                  </h5>                
              }
            </div>

          </Col>
        </Row>

        <Row>
          <Col xs={6} md lg={6}>
            

          </Col>
          <Col xs={6} md lg={6}>

          </Col>
        </Row>
        {/* {console.log("orderlist productlist", this.state.productlist)} */}
        <EditOrder orderdata={this.state.orderforedit} customerinfo={this.state.customerinfo}
        
          ref="editorder" refresh={this.props.refresh} refreshpage={this.refreshpage}  />

        <ViewOrder orderdata={this.state.orderforedit} customerinfo={this.state.customerinfo}
          productlist={this.state.productlist} productlisthtml={this.state.productlisthtml}
          ref="vieworder" refresh={this.props.refresh} refreshpage={this.refreshpage} productCategory={this.state.product_category_options} />

        <Row>
          <Col xs={12} sm={12} lg={12}>
            <div >

              <div id="orderlistdiv" >
                <Row id=" orderlistrow">
                  <Col>
                    <p> <Image className="mr-1" src="assets/images/down.svg" />Role Name</p>
                  </Col>
                  
                  {/*<Col >
                    <p className="left"> <Image className="mr-1" src="assets/images/down.svg" />Created On</p>
                  </Col>*/}
                  {/*<Col >
                    <p> <Image className="mr-1" src="assets/images/down.svg" />Outbound No.</p>
                  </Col>*/}

                </Row>
                <div className="divider"></div>
              </div>
              <div className="orderlistwrapperdiv">

                <Accordion>
                  <form>
                    {orderCardList}
                  </form>

                </Accordion>
              </div>


            </div>
          </Col>


        </Row>

                 

      </Container>

    )
  }
}

export default OrderList;
