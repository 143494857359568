import React from 'react'
import { base_url } from '../../globalConstants'
import { useState, useEffect, useContext} from 'react'
import { Row, Col,ButtonGroup, InputGroup, FormControl, Table, Spinner, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import M from "materialize-css";
import InfoIcon from '@material-ui/icons/Info';
import {DeleteOutlined, EditOutlined,Search, NavigateBefore, NavigateNext, Close } from "@material-ui/icons";
import "./temp.css";  
import Axios from "axios";
import SearchIcon from '@material-ui/icons/Search';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function CustomerInfo() {

    const [shippedData, setShippedData] = useState([]);  
    const [shippedResponse, setShippedResponse] = useState([]);
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [boxId, setBoxId] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);  
    const [url, setUrl] = useState(base_url+'coupondetails/')
    const [prevPageUrl, setPrevPageUrl] = useState('')
    const [nextPageUrl, setNextPageUrl] = useState('')
    const [searchResultsCount, setSearchResultsCount] = useState(-1)
    const [currentDisplayCount, setCurrentDisplayCount] = useState(-1)
    const [totalDisplayCount, setTotalDisplayCount] = useState(-1)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    
     
    const viewShippedDetails = (tempBoxId) => {
      // alert(tempBoxId)
      setBoxId(tempBoxId)
    }

    const fetchDetails = async () => {
      setShowSpinner(true)
      
      
      const response = await Axios.get(url)
      // console.log(response);
      const { data } = response 
      console.log(data.results);
      // console.log(base_url);
      setShowSpinner(false)
      await setShippedResponse(data);
      await setShippedData(data.results);
      setCurrentDisplayCount(data.results.length)
      setTotalDisplayCount(data.count)   
      setPrevPageUrl(data.previous)
      setNextPageUrl(data.next) 
    }

    //Handle page navigation
  const handlePageNavigation = async (updatedUrl, pg) =>{

    var tempPageNumber = currentPageNumber

    setShowSpinner(true)
    let updated_url = updatedUrl
    console.log('updatedUrl', updated_url);
    await Axios({
      method: 'GET',
      url: updatedUrl
    }).then( (response) => {
      console.log('RESPONSE', response);
     setShowSpinner(false)
      setShippedResponse(response.data);
      setShippedData(response.data.results);
      setCurrentDisplayCount(response.data.results.length)
      setTotalDisplayCount(response.data.count)   
      setPrevPageUrl(response.data.previous)
      setNextPageUrl(response.data.next) 
      
      // setCurrentPageNumber(updatedUrl.toString().split('=')[2].split('&')[0]?updatedUrl.toString().split('=')[2].split('&')[0]:1)

      if(pg === '+')
        tempPageNumber = tempPageNumber + 1
      else if(pg === '-')
        tempPageNumber = tempPageNumber - 1
      else
        tempPageNumber = ''

      setCurrentPageNumber(tempPageNumber)  

    }).catch((response)=>{
      console.log('CATCH RESPONSE',response);
    })

  }   
  //Handle page navigation end

    //SEARCH START

const handleEditOption  = async (e) => {
     //alert("done")
     let selectedRowIndex ;
      e = e || window.event;
      var data = [];
      var target = e.srcElement || e.target;
      var deleteid;
      while (target && target.nodeName !== "TR") {
          target = target.parentNode;
          selectedRowIndex= target.sectionRowIndex
          console.log("Clicked row",target.sectionRowIndex)
      }
      if (target) {
          var cells = target.getElementsByTagName("td");
          deleteid = cells[8].innerHTML;
          // for (var i = 0; i < cells.length; i++) {
          //     data.push(cells[i].innerHTML);
          // }
      }

      var doc;
      var result = window.confirm("Are you sure want to delete coupon!");
      if (result == true) {
          //doc = "OK was pressed.";
          let token = localStorage.getItem("token")
          const header = {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + token,

          }
          let urlfordeletion = base_url+`coupondetails/${deleteid}/`
          const response = await Axios.delete(urlfordeletion,
          header)
          M.toast({ html: 'Coupon deletion Successful', classes: "white-text red rounded" });
          fetchDetails();
      } else {
          //doc = "Cancel was pressed.";
      }
      //alert(doc);
  }

  const refreshpagenew = async () => {
      let searchUrl = base_url + `coupondetails/`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        console.log(response.data);
        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
 
 
      }).catch(async (response) => {
        console.log(response.status)

      })
}

  const handleKeyDownSearch = async (eventKey) => {

    console.log('searchKeyword', searchKeyword);

    var filterString = searchKeyword

    if (eventKey.key === "Enter") {
      if (filterString.trim().length === 0) {
        return
      }

      let searchUrl = base_url + `coupondetails/?search=${filterString}`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        console.log(response.data);
        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
 
 
      }).catch(async (response) => {
        console.log(response.status)

      })

    }

  }

  const editSearchTerm = async e => {
    console.log('SEARCH KEY', e.target.value);

    let word = e.target.value
    let bucket = word.split(' ')

    console.log(bucket)

    let searchString = bucket.reduce((prevVal, currVal) => { return prevVal += currVal + ' ' }, '')

    console.log('searchString', searchString);

    setSearchKeyword(searchString)


  }

  // const onKeyDown = (e) => {
  //   if (e.keyCode === 8) {
  //     setShippedData(shippedData)
  //   }
  // }

  //SEARCH END
    
    useEffect(() => {
      fetchDetails();
    }, [url])

    return (
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
        {
          showSpinner
            ?
            < div className="center">

              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="success" size="sm" />
              <Spinner animation="grow" variant="warning" size="sm" />
            </div>
            :
            null
        }
      <Row >
        <Col>
          <h3 className="orange-text">Coupon List</h3>
        </Col>
        <Col xs={5}>

        </Col>
        <Col>
		<ButtonGroup>
              <Button variant="outline-secondary " className="grey white-text mr-2" onClick={refreshpagenew}>Refresh </Button>
		</ButtonGroup>
          {/* <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-lg"><SearchIcon/></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
          </InputGroup> */}
        </Col>
      </Row>
      <Row>
        <Col>
            <div>
              {
                shippedResponse.previous
                  ? <Button style={{ backgroundColor: "orange" }} onClick={()=>handlePageNavigation(prevPageUrl, '-')}><NavigateBefore /></Button>
                  : <Button disabled><NavigateBefore /></Button>
              }{' '}
              {
                shippedResponse.next
                  ? <Button style={{ backgroundColor: "orange" }} onClick={()=>handlePageNavigation(nextPageUrl, '+')}><NavigateNext /></Button>
                  : <Button onClick={() => setUrl(shippedResponse.next)} disabled><NavigateNext /></Button>
              }
            </div><br />
            <div>
              <h5>Page: {currentPageNumber}</h5>
            </div>
        </Col>
        <Col>
            <div>
              <InputGroup className="mb-3" style={{ float: 'right', width: '270px' }}>
                <InputGroup.Prepend >
                  <InputGroup.Text style={{ border: '1px solid grey', background: 'white' }} id="inputGroup-sizing-default"><Search /></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ border: '1px solid gray', borderRadius: '2px', paddingLeft: '10px' }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="  Search here....."
                  onChange={editSearchTerm}
                  //onKeyUp={handleKeyDownSearch}
				  onKeyDown={handleKeyDownSearch}
                />
              </InputGroup>
            </div>
            <div style={{ float: 'right', width: '270px' }}>
              {
                searchResultsCount > -1
                  ?
                  <h5>Showing
                    <span style={{ color: 'orange' }} > {currentDisplayCount} </span> of
                    <span style={{ color: 'orange' }} > {searchResultsCount} </span> results
                  </h5>
                  : <h5>Showing
                      <span style={{ color: 'orange' }} > {currentDisplayCount} </span> of
                      <span style={{ color: 'orange' }} > {totalDisplayCount} </span> results
                    </h5>
              }
            </div>
        </Col>
      </Row>
      <Row>
        <Col >
          <Table bordered hover size="lg">
            <thead>
              <tr style = {{ backgroundColor: "#676760", color: "white"  }} >
                {/* <th>S.No.</th> */}
                <th>EZZID</th> 
                <th>Coupon Code</th>
                <th>Amount</th>
                <th>CreatedOn</th>
                {/*<th>Country</th>*/}
                <th>ExpiredOn</th>
                <th>RedeemOn</th>
                <th>Status</th>   
				<th>OrderNo</th> 				
                <th>Delete</th>     
              </tr>
            </thead>
            <tbody>
              {
                // shippedData.filter(key => key.custom_charge_transaction_id).map((filterkey, index) => (
                shippedData.map((filterkey, index) => (
                  <tr key={filterkey.id}>
                      <td>{filterkey.ezz_id}</td>
                      <td>{filterkey.coupon_code}</td>
                      <td>
                        {
                          filterkey.amount
                        }
                      </td>
                      <td>
                        {
                          filterkey.created_on
                        }
                      </td>
                      {/*<td>
                        {
                          filterkey.country_name
                        }
                        
                      </td>*/}
                      <td>
                        {
                          filterkey.expired_on
                        }
                      </td>
                      <td>
                        {
                          filterkey.redeem_on
                        }
                      </td>
                      <td>
                        {filterkey.curr_status}
                      </td>
					   <td>
                        {
                          filterkey.order_no
                        }
                      </td>
                      {filterkey.curr_status === 'New'
                        ?<td onClick={handleEditOption} ><a title = "Delete"><DeleteOutlined/></a></td>
                        :<td></td>
                     }
                     
                    <td className="hide">{filterkey.id}</td>
                    </tr>
                ))               
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
    )
}
