import React, { Component } from 'react';
import { Row, Col, ButtonGroup, Button, Spinner } from 'react-bootstrap';
import M from 'materialize-css';
import Axios from "axios";
import { base_url } from '../../globalConstants';

//css is in orderlist.css

class EditProductReturn extends Component {
    constructor(props) {
        super(props);
        this.state = ({

            row_number: this.props.rowNumber,
            product_details: {},
            product_category_options: [],
            product_template_options :[],
            product_name: "",
            product_price: 0,
            product_quantity: 0,
            product_category: "",
            product_sku: "",
            product_template: "",
            product_status:"",
            search_name_select:"",
            product_carriage:"",
            product_selected:""
        })

    }

    componentDidMount() {
        M.AutoInit()
    }

    componentDidUpdate(prevProps) {


        if (this.props.productDetails !== prevProps.productDetails) {
            let product = this.props.productDetails;
        if (product.productStatus != "Active")
        {
          this.setState({
                row_number: this.props.rowNumber,
                product_details: this.props.productDetails,
                product_category_options: this.props.productcategory,
                product_template_options: this.props.productTemplate,
                product_name: product.productName,
                product_price: 0,
                product_quantity: 0,
                product_category: "Template",
                product_sku: "",
                product_template: product.productName,
                product_status: product.productStatus,
                product_carriage:product.productCarriage,
                search_name_select:product.productSelect
            })
        }
        else{
             
          this.setState({
                row_number: this.props.rowNumber,
                product_details: this.props.productDetails,
                product_category_options: this.props.productcategory,
                product_template_options: this.props.productTemplate,
                product_name: product.productName,
                product_price: product.productPrice,
                product_quantity: product.productQuantity,
                product_category: product.productCategory,
                product_sku: product.productSKU,
                product_template: product.productName,
                product_status: product.productStatus,
                product_carriage:product.productCarriage,
                search_name_select:product.productSelect
            })
        }
            // this.setState({
            //     row_number: this.props.rowNumber,
            //     product_details: this.props.productDetails,
            //     product_category_options: this.props.productcategory,
            //     product_template_options: this.props.productTemplate,
            //     product_name: product.productName,
            //     product_price: product.productPrice,
            //     product_quantity: product.productQuantity,
            //     product_category: product.productCategory,
            //     product_sku: product.productSKU,
            // })
            console.log("Row for updatation", this.props.rowNumber)
            product = this.props.productDetails;
        }
    }
    openModal = (rowNum, prodDetails, countryoptions,templateoptions,typee) => {
        console.log("inside update Product no. ", rowNum);
        //  console.log(orderdata);
        if (typee === 1)
        {
        var elems = document.getElementById("updateProductModalReturn");
        var instance = M.Modal.init(elems);
          instance.open()
    }
    else
    {
                var elems = document.getElementById("updateProductModalReturn");
        var instance = M.Modal.init(elems);
          instance.open()
    }
        // console.log("categlry", this.state.countryoptions)
        console.log(this.props.rowNumber);
        this.setState({
            row_number: this.props.rowNumber,
            product_details: prodDetails,
            product_category_options: countryoptions,
            product_template_options: templateoptions
        })
      
        // orderselectionmodal
    }
    closeModal = () => {
        var elems = document.getElementById("updateProductModalReturn");
        var instance = M.Modal.init(elems);
        instance.close()
    }

    handleFormChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        console.log(value)
        this.setState(
            { [name]: value }
        )
    // if (event.target.name === "product_name")
    //  {
    //     console.log("search product")
    //   let search_input = event.target.value
    //   var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
    //   // var patt =  /^[EZez]\d{0,4}/g;
    //   var result = search_input.match(patt);

    //   this.setState({
    //     [name]: event.target.value,
    //     searchInput: search_input,
    //     searchResult: result
    //   })

    //   try{
      
    //     let token =localStorage.getItem("token")
    //     let header = {'Content-Type': 'multipart/form-data',
    //     'Authorization':'Token '+token,

    //     }
    //     var url
    //     var searchType

    //     url= base_url+`items/?GC_Description=${this.state.searchInput}`
    //     searchType = "email"
    //     var customer_data = await Axios.get(url,header)
    //     // console.log(customer_data.data)
    //     let ezzidlist = {}
    //     let emaillist = {}
    //     let customerdataObject ={}
        
    //     // document.getElementById("errCustomerNotAuthorized").style.display = "none"
    //     // if (searchType === "ezzid") {
    //     //   for (let customer of customer_data.data.results) {
    //     //     ezzidlist[customer.ezz_id] = customer.email;
    //     //     customerdataObject[customer.ezz_id] = customer;
    //     //   }
    //     // }
    //     // // console.log();

    //     // if (searchType === "email") {
    //     //   for (let customer of customer_data.data) {
    //     //     ezzidlist[customer.name] = customer.id;
    //     //     var tempEzId = ezzidlist[customer.description]
    //     //     customerdataObject[tempEzId] = customer;

    //     //   }
    //     // }     
    //   if (searchType === "email") {
    //       for (let customer of customer_data.data) {
    //         ezzidlist[customer.GC_Description] = customer.id;
    //         //var tempEzId = ezzidlist[customer.GC_Description]
    //         var tempEzId = customer.GC_Description
    //         customerdataObject[tempEzId] = customer;

    //       }
    //     }

    //     // this.setState({customer_data:customerdataObject})
    //     // Autoselect starts here
    //     var context = this;
    //     // /await context.setState({pendingOrders:[]})
    //     const elems_auto_customer = document.getElementById('product_name');
    //     var options={
    //       data:ezzidlist,
    //       limit:20,
    //       onAutocomplete : async function(select_customer) {
    //          var tempEzzId 
    //         // if (searchType === "ezzid"){
    //         //   tempEzzId = select_customer
    //         //   context.setState({product_name1:select_customer})                
    //         // }
    //         if (searchType === "email"){
    //           // tempEzzId = ezzidlist[select_customer]
    //           // context.setState({product_name1:tempEzzId})       
    //              tempEzzId = select_customer
    //            context.setState({product_name:select_customer, product_selected:select_customer})   
    //            context.setState({product_price:customerdataObject[tempEzzId].Quote_Amount})                 
    //         }            

    //         // context.setState({customer_selected_data:customerdataObject[tempEzzId]})
    //         // await context.setState({pendingOrders:[]})
           
    //         // context.getCategories(customerdataObject[tempEzzId].country)
    //         //       await context.getPendingOrder(tempEzzId,"IM")
    //         //        await context.getPendingOrder(tempEzzId,"YTA")
    //         // //context.set
         
    //         // // @TODO show model only when there is any orders pending 
    //         // //like IM or BoX YET to arrive
    //         // if (context.state.pendingOrders.length >0){
    //         //   context.openOrderModel();
    //         // }
           
    //         // const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize

    //         // if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )          
    //         //   document.getElementById("errCustomerNotAuthorized").style.display = "inline-grid"
    //         // else  
    //         //   document.getElementById("errCustomerNotAuthorized").style.display = "none"
    //       },
        
    //       sortFunction:  function (a, b, inputString) {
    //         return a.indexOf(inputString) - b.indexOf(inputString);
    //       },
    //     }
    //     var instance = M.Autocomplete.init(elems_auto_customer,options)
    //     instance.open()
    //   }catch(err){

    //     M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
    //     // console.log(err)
    //     // this.setState({error:<Error/>})
    //   }

    //  }

    }
    updateItemInlist = () => {

        // M.toast({html: 'item updation clicked'+this.state.product_details.id,classes:"white-text blue rounded"});
        let rowNum = this.state.row_number
        console.log("ID OF proudct", this.state.product_details.id)
        // let updatedItem = {
        //     id: this.state.product_details.id,
        //     productName: this.state.product_template,
        //     productPrice: this.state.product_price,
        //     productQuantity: this.state.product_quantity,
        //     productCategory: this.state.product_category,
        //     productSKU: this.state.product_sku,
        //     productTemplate: this.state.product_template,
        // }
        var templateprice = 0
        var templateqty = 0
        let updatedItem = []
        if (this.state.product_status != "Active")
        {
          const found = this.state.product_template_options.find(element => element["name"]==this.state.product_template);
          if(found){
              templateprice = found.totalprice
              templateqty = found.totalquantity
            }

            updatedItem = {
            id: this.state.product_details.id,
            productName: this.state.product_template,
            productPrice: templateprice,
            productQuantity: templateqty,
            productCategory: "Template",
            productSKU: "",
            productTemplate: this.state.product_template,
            productStatus: this.state.product_template,
            productCarriage:this.state.product_carriage,
            productSelect:this.state.search_name_select
            }
        }
        else{
            updatedItem = {
            id: this.state.product_details.id,
            productName: this.state.product_name,
            productPrice: this.state.product_price,
            productQuantity: this.state.product_quantity,
            productCategory: this.state.product_category,
            productSKU: this.state.product_sku,
            productTemplate: "",
            productStatus: "Active",
            productCarriage:this.state.product_carriage,
            productSelect:this.state.search_name_select
            }
        }
        // M.toast({html: 'item updation clicked'+rowNum + updatedItem,classes:"white-text blue rounded"})
        this.props.updateBtnClick(rowNum, updatedItem)

    }

    deleteProductFromDB = async (tempProductId) =>{
        // alert(`delete product id ${tempProductId}` )

        await Axios({
            method: 'DELETE',
            url: base_url+`products/${tempProductId}/`
        }).then(function(response){
            // console.log(`DELETED${tempProductId}`, response);
            M.toast({html: `Product deleted successfully`,classes: "white-text blue rounded"})
        }).catch(function(response){
            // console.log("NOT DELETED ERROR",response);
            M.toast({html: `Product deletion failed`,classes: "white-text red rounded"})
        })
    }

    deleteItemInlist = () => {
        // console.log("PRODUCT DETAILS",this.state.product_details)
        // alert(`delete product id ${tempProductId}` )
        // let rowNum = this.state.row_number
        // let updatedProductList=this.state.productlist.splice(rowNum, 1)
        // this.setState({productlist: updatedProductList})
        // this.deleteProductFromDB(this.state.product_details.id)

        let rowNum = this.state.row_number
        let delete_ItemId = this.state.product_details.id
        this.props.deleteBtnClick(rowNum, delete_ItemId)

    }

    render() {
        const product = this.state.product_details;
        // console.log(product);
        // console.log("categlry", this.state.product_category_options)
        const catlist = this.state.product_category_options;
        var categoryList;
        try {


            if (catlist.length > 0) {
                categoryList = catlist.map((item, i) => {
                    return (
                        <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
                    )
                }, this);
            }

        } catch (error) {
            console.log("categories can't be added in product")
        }

        // const templist = this.state.product_template_options;
        // var templateList;
        //     if (templist.length >0){
        //         templateList = templist.map((itemtemp, i) => {
        //         return (
        //         <option className="black-text" key={i} value={itemtemp.name}>{itemtemp.name}</option>
        //         )
        //         }, this);
        //     }

return (
    <form>
            <div id="updateProductModalReturn" className="modal modal-fixed-footer">
                <div className="modal-content">
                    <h4 className="center orange-text"> Edit Product Details12 </h4>
                  
                    <Row>
                        <Col xs={12} >
                                                           <Row>
                                    <Col>

                                        <div id="categroydiv" className="input-field col s6 offset-s3 center">
                                              <input name="search_name_select" id="search_name_select" type="text" value={this.state.search_name_select} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="search_name_select" className="black-text" >BoxId</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter BoxId</span>


                                        </div>


                                    </Col>

                                </Row>

                                                            <Row>
                                    <Col>

                                        <div id="categroydiv" className="input-field col s6 offset-s3 center">
                                              <input name="search_name_select" id="search_name_select" type="text" value={this.state.search_name_select} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="search_name_select" className="black-text" >Quantity</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter quantity</span>


                                        </div>


                                    </Col>

                                </Row>

                                <Row>
                                    <Col className=" rowdivPading " xs={12}>
                                        <div className="input-field col s6 offset-s3 center">
                                            <input name="product_name" id="product_name" type="text" value={this.state.product_name} onChange={this.handleFormChange} className="" />

                                            <span className="helper-text left" data-error="wrong" data-success="">Enter a valid Product Name</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className=" rowdivPading ">
                                        <div className="input-field col s6 offset-s3 center">
                                            <label className="active" htmlFor="product_quantity"  style={{ marginLeft: '20%' }}>Quantity</label>
                                            <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />

                                            <span className="helper-text left" data-error="wrong" data-success="">Enter number of products items</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className=" rowdivPading ">
                                        <div className="input-field col s6 offset-s3 center">
                                            <label className="active" htmlFor="product_price" style={{ marginLeft: '20%' }}>Product Price</label>
                                            <input name="product_price"  id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />

                                            <span className="helper-text left" data-error="wrong" data-success="">Enter Price</span>
                                        </div>
                                    </Col>
                                </Row>
                                


                            <Row>
                                    <Col className=" rowdivPading " xs={12}>
                                        <div className="input-field col s6 offset-s3 center">
                                            <input name="product_sku" id="product_sku" type="text" value={this.state.product_sku} onChange={this.handleFormChange} className="" />

                                            <span className="helper-text left" data-error="wrong" data-success="">Enter SKU Number</span>
                                        </div>
                                    </Col>
                                </Row>

                           <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_carriage"  id="product_carriage" min="0" type="text" value={this.state.product_carriage} onChange={this.handleFormChange} className="" />
                        {/*<label htmlFor="product_carriage" className="black-text active">Carriage Value</label>*/}
                        <span className="helper-text left" data-error="wrong" data-success="">Enter carriage value</span>
                      </div>
                    </Col>
                  </Row>

                                                     <Row>
                                    <Col>

                                        <div id="categroydiv" className="input-field col s6 offset-s3 center">
                                              <input name="search_name_select" id="search_name_select" type="text" value={this.state.search_name_select} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="search_name_select" className="black-text" >Freight Charge</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter Freight Charge</span>


                                        </div>


                                    </Col>

                                </Row>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer" >
                    {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



                    <ButtonGroup className="mr-2" aria-label="First group">
                        <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
                        <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateItemInlist}> Update</Button>{' '}
                        {
                            this.state.product_details.id
                            ?<Button id="deleteItemBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.deleteItemInlist}> Delete</Button>
                            :""
                        }
                        

                    </ButtonGroup>

                </div>
            </div>
</form>

        )
    }

}
export default EditProductReturn;