import React from 'react'
import { base_url } from '../../globalConstants'
import { useState, useEffect, useContext} from 'react'
import { Row, Col, InputGroup, FormControl, Table, Spinner, Card, ButtonGroup,ListGroup, ListGroupItem } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import M from "materialize-css";
import InfoIcon from '@material-ui/icons/Info';
import {Search, NavigateBefore, NavigateNext, Close } from "@material-ui/icons";
import "./temp.css";
import Axios from "axios";
import SearchIcon from '@material-ui/icons/Search';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function CustomerInfo() {

    const [shippedData, setShippedData] = useState([]);  
    const [shippedResponse, setShippedResponse] = useState([]);
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [boxId, setBoxId] = useState('')
	const [ezzId, setEzzId] = useState('')
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [mobile, setMobile] = useState('')
	const [shippingaddress1, setAddress1] = useState('')
	const [shippingaddress2, setAddress2] = useState('')
	const [country, setCountry] = useState('')
	const [state, setState] = useState('')
	const [shippingType, setshippingType] = useState('Economy')
	const [paymentType, setpaymentType] = useState('Cash')
    const [showSpinner, setShowSpinner] = useState(false);  
    const [url, setUrl] = useState(base_url+'customers/')
    const [prevPageUrl, setPrevPageUrl] = useState('')
    const [nextPageUrl, setNextPageUrl] = useState('')
    const [searchResultsCount, setSearchResultsCount] = useState(-1)
    const [currentDisplayCount, setCurrentDisplayCount] = useState(-1)
    const [totalDisplayCount, setTotalDisplayCount] = useState(-1)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    
	const onSaveClick = async () => {
		let payment = 0;
		let method = 0;
		let token = localStorage.getItem("token")
		let header = {
		  'Content-Type': 'application/json',
		  'AUTHORIZATION': token,
		}
		if ( shippingType == "Economy")
			method = 0;
		else
			method = 1;
		if ( paymentType == "Card")
			payment = 1;
		else
			payment = 0;
		
		Axios({
            method:'post',
            url:base_url+'update_shipping_payment_method/',
            data: {
            "method" : method, 
            "ezzid" : ezzId,
			"payment": payment,
            },
            headers: {'Content-Type': 'application/json' }
          }).then(function(email){
            M.toast({html: 'Information updated successfully!. Please refresh list.',classes:"white-text green rounded"});
			toggle();
			window.location.reload();
          }).catch(function(email){
            console.log('updation failed')
			M.toast({html: 'Oh Snap! Something is Wrong.Please try again',classes:"white-text green rounded"});
          })
		
    }
	
	
	const toggle = () => setModal(!modal);
    const updateCustomer = (tempEzzId,tmpshipping,tmppayment,name,email,mobile,add1, add2, country) => {
		if ( tmpshipping == 1 )
			setshippingType('Priority');
		else
			setshippingType('Economy');
		if ( tmppayment == 1 )
			setpaymentType("Card");
		else
			setpaymentType("Cash");
		
      setEzzId(tempEzzId);
	  setName(name);
	  setEmail(email);
	  setMobile(mobile);
	  setAddress1(add1);
	  setAddress2(add2);
	  setCountry(country);
	  toggle();
    }
	
    const viewShippedDetails = (tempBoxId) => {
      // alert(tempBoxId)
      setBoxId(tempBoxId)
    }
	
	const handleFormChange1= async (ev) =>{
		const target = ev.target;
		setshippingType(target.value);
	}
	
	const handleFormChange2= async (ev) =>{
		const target = ev.target;
		setpaymentType(target.value);
	}
	
	const handleNameChange= async (ev) =>{
		const target = ev.target;
		setName(target.value);
	}
	
	const handleEmailChange= async (ev) =>{
		const target = ev.target;
		setEmail(target.value);
	}
	
	const handleMobileChange= async (ev) =>{
		const target = ev.target;
		setMobile(target.value);
	}
	
	const handleAdd1Change= async (ev) =>{
		const target = ev.target;
		setAddress1(target.value);
	}
	
	const handleAdd2Change= async (ev) =>{
		const target = ev.target;
		setAddress2(target.value);
	}
	
	const handleCountryChange= async (ev) =>{
		const target = ev.target;
	}
	
	const handleEzzChange= async (ev) =>{
		const target = ev.target;
	}
	
    const fetchDetails = async () => {
      setShowSpinner(true)
      
      
      const response = await Axios.get(url)
      // console.log(response);
      const { data } = response 
      console.log(data.results);
      // console.log(base_url);
      setShowSpinner(false)
      await setShippedResponse(data);
      await setShippedData(data.results);
      setCurrentDisplayCount(data.results.length)
      setTotalDisplayCount(data.count)   
      setPrevPageUrl(data.previous)
      setNextPageUrl(data.next) 
    }

    //Handle page navigation
  const handlePageNavigation = async (updatedUrl, pg) =>{

    var tempPageNumber = currentPageNumber

    setShowSpinner(true)
    let updated_url = updatedUrl
    console.log('updatedUrl', updated_url);
    await Axios({
      method: 'GET',
      url: updatedUrl
    }).then( (response) => {
      console.log('RESPONSE', response);
      setShowSpinner(false)
      setShippedResponse(response.data);
      setShippedData(response.data.results);
      setCurrentDisplayCount(response.data.results.length)
      setTotalDisplayCount(response.data.count)   
      setPrevPageUrl(response.data.previous)
      setNextPageUrl(response.data.next) 
      // setCurrentPageNumber(updatedUrl.toString().split('=')[2].split('&')[0]?updatedUrl.toString().split('=')[2].split('&')[0]:1)

      if(pg === '+')
        tempPageNumber = tempPageNumber + 1
      else if(pg === '-')
        tempPageNumber = tempPageNumber - 1
      else
        tempPageNumber = ''

      setCurrentPageNumber(tempPageNumber)  

    }).catch((response)=>{
      console.log('CATCH RESPONSE',response);
    })

  }   
  //Handle page navigation end

    //SEARCH START

  const handleKeyDownSearch = async (eventKey) => {

    console.log('searchKeyword', searchKeyword);

    var filterString = searchKeyword

    if (eventKey.key === "Enter") {
      if (filterString.trim() === "") {
        return
      }

      let searchUrl = base_url + `customers/?search=${filterString}`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        console.log(response.data);

        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
 
      }).catch(async (response) => {
        console.log(response.status)

      })

    }

  }
  
  const  fnrefresh = async () => {
	        let searchUrl = base_url + `customers/`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        console.log(response.data);

        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
 
      }).catch(async (response) => {
        console.log(response.status)

      })
  }

  const  downloadReport = async () => {
        let report_url = base_url + `get_customer_list`
        console.log('url',report_url);
        //console.log('this.state.countryName',this.state.countryName);
        var FileSaver = require('file-saver');

        await Axios({
            url: report_url,
            method: 'GET',
            responseType: 'arraybuffer'
        })
        .then((response)=>{
            {
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, `customer_list.xlsx`);
            }
            console.log('response',response);
        }).catch((err)=>{
            console.log('err',err);
        })
    }

  const editSearchTerm = async e => {
    console.log('SEARCH KEY', e.target.value);

    let word = e.target.value
    let bucket = word.split(' ')

    console.log(bucket)

    let searchString = bucket.reduce((prevVal, currVal) => { return prevVal += currVal + ' ' }, '')

    console.log('searchString', searchString);

    setSearchKeyword(searchString)


  }

  // const onKeyDown = (e) => {
  //   if (e.keyCode === 8) {
  //     setShippedData(shippedData)
  //   }
  // }

  //SEARCH END
    
    useEffect(() => {
      fetchDetails();
    }, [url])

    return (
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
        {
			
          showSpinner
            ?
            < div className="center">

              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="success" size="sm" />
              <Spinner animation="grow" variant="warning" size="sm" />
            </div>
            :
            null
        }
      <Row >
        <Col>
          <h3 className="orange-text">Customer List</h3>
        </Col>
        <Col xs={5}>
		<ButtonGroup>
			<Button variant="outline-primary " className="indigo white-text mr-2" onClick={downloadReport}>
				Download
			</Button>
			<Button variant="outline-secondary " className="grey white-text mr-2" onClick={fnrefresh}>
				Refresh
			</Button>
			</ButtonGroup>
        </Col>
        <Col>
          {/* <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-lg"><SearchIcon/></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
          </InputGroup> */}
        </Col>
      </Row>
      <Row>
        <Col>
            <div>
              {
                shippedResponse.previous
                  ? <Button style={{ backgroundColor: "orange" }} onClick={()=>handlePageNavigation(prevPageUrl, '-')}><NavigateBefore /></Button>
                  : <Button disabled><NavigateBefore /></Button>
              }{' '}
              {
                shippedResponse.next
                  ? <Button style={{ backgroundColor: "orange" }} onClick={()=>handlePageNavigation(nextPageUrl, '+')}><NavigateNext /></Button>
                  : <Button onClick={() => setUrl(shippedResponse.next)} disabled><NavigateNext /></Button>
              }
            </div><br />
            <div>
              <h5>Page: {currentPageNumber}</h5>
            </div>
        </Col>
        <Col>
            <div>
              <InputGroup className="mb-3" style={{ float: 'right', width: '270px' }}>
                <InputGroup.Prepend >
                  <InputGroup.Text style={{ border: '1px solid grey', background: 'white' }} id="inputGroup-sizing-default"><Search /></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ border: '1px solid gray', borderRadius: '2px', paddingLeft: '10px' }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="  Search here....."
                  onChange={editSearchTerm}
                  //onKeyUp={handleKeyDownSearch}
				  onKeyDown={handleKeyDownSearch}
                />
              </InputGroup>
            </div>
            <div style={{ float: 'right', width: '270px' }}>
              {
                searchResultsCount > -1
                  ?
                  <h5>Showing
                    <span style={{ color: 'orange' }} > {currentDisplayCount} </span> of
                    <span style={{ color: 'orange' }} > {searchResultsCount} </span> results
                  </h5>
                  : <h5>Showing
                      <span style={{ color: 'orange' }} > {currentDisplayCount} </span> of
                      <span style={{ color: 'orange' }} > {totalDisplayCount} </span> results
                    </h5>
              }
            </div>
        </Col>
      </Row>
      <Row>
        <Col >
          <Table bordered hover size="lg">
            <thead>
              <tr style = {{ backgroundColor: "#676760", color: "white"  }} >
                {/* <th>S.No.</th> */}
                <th>EZZID</th> 
                <th>Name</th>
                <th>Phone No</th>
                <th>Email</th>
                <th>Country</th>
				<th>Address</th>
                <th>State</th>
                <th>City</th>  
				<th>Shipping Method</th> 
				<th>Payment Method</th> 
				<th>Action</th> 				
              </tr>
            </thead>
            <tbody>
              {
                // shippedData.filter(key => key.custom_charge_transaction_id).map((filterkey, index) => (
                shippedData.map((filterkey, index) => (
                  <tr key={filterkey.id}>
                      <td>{filterkey.ezz_id}</td>
                      <td>{filterkey.name}</td>
                      <td>
                        {
                          filterkey.phone_number
                        }
                      </td>
                      <td>
                        {
                          filterkey.email
                        }
                      </td>
                      <td>
                        {
                          filterkey.country_name
                        }
                        {/* {filterkey.orders[0].order_id} */}
                      </td>
					   <td>
                        {
                          filterkey.address
                        }
                      </td>
                      <td>
                        {
                          filterkey.state_name
                        }
                      </td>
                      <td>
                        {filterkey.city}
                      </td>
                      <td>
                        {filterkey.shipping_method == 1 ? 'Priority':'Economy'}
                      </td>
					  <td>
                        {filterkey.payment_method == 1 ? 'Card':'Cash'}
                      </td>
					  <td>
                        <span title = "Update Method">
                          <InfoIcon 
                            style={{ cursor: "pointer" }}                              
                            onClick = { () => updateCustomer(filterkey.ezz_id,filterkey.shipping_method, filterkey.payment_method,filterkey.name,filterkey.email,filterkey.phone_number,filterkey.address,filterkey.address_2, filterkey.country_name) } />
                        </span>
                      </td>
                    </tr>
                ))               
              }
            </tbody>
          </Table>
		  <Modal id="customerUpdate" className = "customerUpdate" size="lg" isOpen={modal} toggle={toggle} >
            <span style={{textAlign: 'right'}}><Close onClick={()=>setModal(false)} style={{cursor: 'pointer'}}  /></span>            
            <ModalBody id="customerUpdateModalBody" className = "customerUpdateModalBody" >
				<div className="modal-content">
					<h4 className="center orange-text"> {"Update Address"}</h4>
					<Row>
					  <Col xs={12} >
						<form>
							<Row>
							<Col className=" rowdivPading ">
							  <div className="input-field col s6 offset-s3 center">
								<input id="ezzid" type="text" disable value={ezzId} onChange={handleEzzChange} className="" />
								<label htmlFor="ezzid"  className={ezzId?"black-text active":"black-text"}>Enter EZZID</label>
								<span className="helper-text left" data-error="wrong" data-success="">EZZID</span>
							  </div>
							</Col>
						  </Row>
						  <Row>
							<Col className=" rowdivPading ">
							  <div className="input-field col s6 offset-s3 center">
								<input id="name" type="text" disable value={name} onChange={handleNameChange} className="" />
								<label htmlFor="name" className={name?"black-text active":"black-text"}>Enter Name</label>
								<span className="helper-text left" data-error="wrong" data-success="">Name</span>
							  </div>
							</Col>
						  </Row>
							<Row>
							<Col className=" rowdivPading ">
							  <div className="input-field col s6 offset-s3 center">
								<input id="email" disable type="text" value={email} onChange={handleEmailChange} className="" />
								<label htmlFor="email" className={email?"black-text active":"black-text"}>Enter Email</label>
								<span className="helper-text left" data-error="wrong" data-success="">Email</span>
							  </div>
							</Col>
						  </Row>
<Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="mobile" type="text" value={mobile} onChange={handleMobileChange} className="" />
                        <label htmlFor="mobile" className={mobile?"black-text active":"black-text"}>Enter Mobile number</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Mobile number</span>
                      </div>
                    </Col>
                  </Row>
                <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="shippingaddress1" type="text" value={shippingaddress1} onChange={handleAdd1Change} className="" />
                        <label htmlFor="shippingaddress1" className={shippingaddress1?"black-text active":"black-text"}>Enter Shipping Address1</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Address1</span>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="shippingaddress2" type="text" value={shippingaddress2} onChange={handleAdd2Change} className="" />
                        <label htmlFor="shippingaddress2" className={shippingaddress2?"black-text active":"black-text"}>Enter Shipping Address2</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Address2</span>
                      </div>
                    </Col>
                  </Row>
				  
				  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="country" type="text" value={country} onChange={handleCountryChange} className="" />
                        <label htmlFor="country" className={country?"black-text active":"black-text"}>Enter Shipping Address2</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Country</span>
                      </div>
                    </Col>
                  </Row>
						 </form>
						</Col>
					</Row>
            
				</div>
				              <div className="center">
				<h4>Shipping Type</h4>
              
				<label>
					<input name="shippingType" value="Economy" type="radio" checked={shippingType ==="Economy"}
					 onChange={handleFormChange1}/>
					<span>Economy</span>
				</label>
			  
				<label>
					<input name="shippingType" value="Priority" type="radio" checked={shippingType ==="Priority"}
					  onChange={handleFormChange1}/>
					<span>Priority</span>
				</label>
              </div><br/>
			                <div className="center">
				<h4>Shipping Type</h4>
              
				<label>
					<input name="paymentType" value="Cash" type="radio" checked={paymentType ==="Cash"}
					onChange={handleFormChange2}/>
					<span>Cash</span>
				</label>
			  
				<label>
					<input name="paymentType" value="Card" type="radio" checked={paymentType ==="Card"}
					  onChange={handleFormChange2}/>
					<span>Card</span>
				</label>
              </div><br/>
              <div className="right">
				<Button color="info" onClick={onSaveClick} className="mr-4 btn modal-close mr-auto"  > Save </Button>
                <Button color="danger" onClick={toggle} className="mr-2 btn modal-close"> Cancel </Button>
				
              </div> 

            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </div>
    )
}
