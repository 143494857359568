// @ts-nocheck
import React, {Component,Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import { Image,Nav,Row,Col,Card,Container,Spinner} from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom'
import Dashboard from './Dashboard';
import CreateOrder from './CreateOrder';
import OrderList from './OrderList';
import ReadyShip from './ReadyToShip';
import BoxList from './BoxList';
import CustomSettlement from './CustomSettlement';
import Shipped from "./Shipped";
import CustomerInfo from "./CustomerInfo";
import Reports from './Reports'
import ReportsOneClick from './ReportsOneClick'
import ReconReports from './Reconreports'
import PropTypes from 'prop-types';
import { base_url } from '../../globalConstants'
import './operator.css';
import { subject } from '../../service';
import IdleTimer from 'react-idle-timer';
import { ModalHeader, ModalBody, ModalFooter, Modal,Button  } from 'reactstrap';
import { ListAlt } from "@material-ui/icons";
import Referral from "./Referrallist";
import { IdleTimeOutModal } from './IdleTimeModal';
import Logout from '../Logout';
import Coupon from './Coupon'
import CouponInfo from './CouponInfo'
import CashInvoice from './CreateCashInvoice'
import CashInvoiceInfo from './CashInvList'
import ShippingRates from './Shippingrates';
import CreateShipment from './CreateShipment';
import OrderListShipment from './OrderList_Shipment';
import CurCustomListInfo from './CurCustomList'
import CashReports from './CashReports'
import Role from './CreateRole'
import RoleInfo from './RoleList'
import OrderListShipmentReturn from './OrderList_ShipmentReturn';
import CreateOrderReturn from './CreateOrder_Return';
import OrderListReturn from './OrderList_Return';
import VolumeReports from './VolumeReports';
import NewCustomerInfo from "./NewCustomerInfo";

class Operator extends Component {

  constructor(props) {
    super(props)

console.log(sessionStorage.getItem('user_type'))
// dummy Dashboard rows




    // var loggedIn = false
     let dummydashboard =<Dashboard/>
  
    this.logout = this.logout.bind(this)
    // let order = [{ordernumber:'9874434',customerid:'Ezzy000111',country:"India",
    // inboundnumber:"1232400000434",outboundnumber:"0871111166"}]
  
      //  this.timeout= 1000 * 10
      if(sessionStorage.getItem('user_type')==="Operator"){

      }
    this.state = {
      loggedIn:false,
      // timeout:1000 * 1 ,
        displayContent:<Dashboard/>,
        dummydashboard:dummydashboard,
        showSpinner:false,
        // operatorId: this.props.location.state.operator_id
        redirectoLogin:false
    }

    // console.log("USER_ID", this.props.location.state.operator_id)
    // console.log("USER_ID", this.state.operatorId)

    this.state = {
      
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      sessionTimeoutModalOpen:false
    }

    this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    
}

_onAction(e) {
  // console.log('user did something', e)
  this.setState({isTimedOut: false})
}

_onActive(e) {
  // console.log('user is active', e)
  this.setState({isTimedOut: false})
}

_onIdle(e) {
  // console.log('user is idle', e)
  const isTimedOut = this.state.isTimedOut
  if (isTimedOut) {
      this.props.history.push('/logout')
  } else {
    this.setState({sessionTimeoutModalOpen: true})
    this.idleTimer.reset();
    this.setState({isTimedOut: true})
  }
  
}


handleLogout() {
  this.setState({sessionTimeoutModalOpen: false})
  this.props.history.push('/logout')

  localStorage.removeItem('token')
  window.location.reload()
}

componentDidMount() {
  // Auto initialize all the things!
  const token = sessionStorage.getItem("token")
  console.log("super token operator",token)
  console.log(token !== null)
  if (token!== null) {this.setState ({
    loggedIn:true
  })
  }else{
    this.setState ( {
      loggedIn:false
    })
  }
  M.AutoInit();
    // this.subscription = subject.subscribe(res => 
    // console.log('RECEIVED TOKEN',res))


}

componentWillUnmount(){
  // this.subscription.unsubscribe()

}



logout() {
  this.setState({
      loggedIn: false
  })
}

dashboardDummy = async ()=>{
    let url = base_url+`countries/?list=True/`

  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key].name)
  }
  console.log('countrylist',countrylist)
  this.setState({displayContent:<Dashboard countryList = {countrylist} />})
}

getReports = async ()=>{

  let url = base_url+`countries/?list=True/`

  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key].name)
  }
  console.log('countrylist',countrylist)
  this.setState({displayContent:<Reports countryList = {countrylist} />})
}

getReportsOneClick = async ()=>{

  let url = base_url+`countries/?list=True/`

  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key].name)
  }
  console.log('countrylist',countrylist)
  this.setState({displayContent:<ReportsOneClick countryList = {countrylist} />})
}

getVolumeReports = async ()=>{

  let url = base_url+`countries/?list=True/`

  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key].name)
  }
  console.log('countrylist',countrylist)
  this.setState({displayContent:<VolumeReports countryList = {countrylist} />})
}

cashreport = async ()=>{

  let url = base_url+`countries/?list=True/`

  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key].name)
  }
  console.log('countrylist',countrylist)
  this.setState({displayContent:<CashReports countryList = {countrylist} />})
}

getReconReports = async ()=>{

  //let url = base_url+`countries/?list=True/`
  let url = base_url+`get_quarter`
  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
//    countrylist.push(response_countrylist.data[key].name)
    countrylist.push(response_countrylist.data[key])
  }
  console.log('countrylist',countrylist)
  this.setState({displayContent:<ReconReports countryList = {countrylist} />})
}

createOrder = ()=>{
  this.setState({displayContent:<CreateOrder refresh={this.createOrder}/>})
}

cashinvoice = ()=>{
  this.setState({displayContent:<CashInvoice refresh={this.cashinvoice}/>})
}

curcustomlistinfo = async (props)=>{
  await this.setState({
    showSpinner:true
  })
try{
  //const response_homedelivery = await Axios.get(base_url+'homedelivery/')
  const response = await Axios.get(base_url+"boxes_out_customlist/")
      // console.log(response.data.results)
      // console.log("COutnry data"+response.data )
      //homedelivery_data = {response_homedelivery.data}
      this.setState({
        displayContent: <CurCustomListInfo orderdata = {response.data.results} orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

cashinvoiceinfo = async (props)=>{
  await this.setState({
    showSpinner:true
  })
try{
  const response = await Axios.get(base_url+"getCashInvoice/")
      // console.log(response.data.results)
      // console.log("COutnry data"+response.data )
      this.setState({
        displayContent: <CashInvoiceInfo orderdata = {response.data.results} orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

 customSettlement=()=>{
  this.setState({displayContent: <CustomSettlement refresh={this.customSettlement}/>})
}


shipped=()=>{
  this.setState({displayContent: <Shipped refresh={this.shipped}/>})
}

referral=()=>{
  this.setState({displayContent: <Referral refresh={this.referral}/>})
}

customerinfo=()=>{
  this.setState({displayContent: <CustomerInfo refresh={this.customerinfo}/>})
}

newcustomerinfo=()=>{
  this.setState({displayContent: <NewCustomerInfo refresh={this.newcustomerinfo}/>})
}

readyToShip= ()=>{

  this.setState({displayContent: <ReadyShip/>})
}

role = ()=>{
  this.setState({displayContent:<Role refresh={this.cashinvoice}/>})
}

roleinfo = async (props)=>{
  await this.setState({
    showSpinner:true
  })
try{
  const response = await Axios.get(base_url+"roledetails/")
      // console.log(response.data.results)
      // console.log("COutnry data"+response.data )
      this.setState({
        displayContent: <RoleInfo orderdata = {response.data.results} orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

coupon = ()=>{
  this.setState({displayContent:<Coupon refresh={this.coupon}/>})
}

couponinfo=()=>{
  this.setState({displayContent: <CouponInfo refresh={this.couponinfo}/>})
}

orderList = async (props)=>{
  await this.setState({
    showSpinner:true
  })

  // const operatorId = this.state.operatorId
  // const operatorId = this.props.location.state.operator_id
  // console.log('OPT ID', operatorId);

try{
  const response = await Axios.get(base_url+"boxes_received/")
      // console.log(response.data.results)
      // console.log("COutnry data"+response.data )
      this.setState({
        displayContent: <OrderList orderdata = {response.data.results} orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}


boxList = async ()=>{
  await this.setState({
    showSpinner:true
  })

try{
  // const response = await Axios.get(base_url+"boxes_out/")
  //const response = await Axios.get(base_url+"boxes_out/?box_id=&ezz_id=&not_shipped=Shipped")
  const response = await Axios.get(base_url+"boxes_out/?box_id=&ezz_id=&not_shipped=&status=New")
      // console.log(response.data)
      // console.log("COutnry data"+response.data )
	  //this.setState({displayContent:<Dashboard/>})
	let url = base_url+`countries/?list=True/`

	const response_countrylist = await Axios.get(url)
	console.log(response_countrylist.data)
	let countrylist = []
	for (var key in response_countrylist.data) {
	countrylist.push(response_countrylist.data[key].name)
	}
	//console.log('countrylist',countrylist)
	this.setState({displayContent:<Dashboard countryList = {countrylist} />})
  
      this.setState({
        displayContent: <BoxList pagename='box' boxdata = {response.data.results} boxResponse = {response.data} />,
        showSpinner:false
      })
      // this.setState({
        // displayContent: <BoxList boxdata = {response.data.results} boxResponse = {response.data} />,
        // showSpinner:false
      // })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}


boxListPending = async ()=>{
  await this.setState({
    showSpinner:true
  })

try{
  // const response = await Axios.get(base_url+"boxes_out/")
  const response = await Axios.get(base_url+"boxes_out/?box_id=&ezz_id=&not_shipped=&status=Completed")
      // console.log(response.data)
      // console.log("COutnry data"+response.data )
      //this.setState({displayContent:<Dashboard/>}) //for loading same page line added
	let url = base_url+`countries/?list=True/`

	const response_countrylist = await Axios.get(url)
	console.log(response_countrylist.data)
	let countrylist = []
	for (var key in response_countrylist.data) {
	countrylist.push(response_countrylist.data[key].name)
	}
	//console.log('countrylist',countrylist)
	this.setState({displayContent:<Dashboard countryList = {countrylist} />})
      
      this.setState({
        displayContent: <BoxList pagename='boxpending' boxdata = {response.data.results} boxResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}


createShippingRates = async ()=>{
  let url = base_url+`countries/?list=True/`
  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
//    countrylist.push(response_countrylist.data[key].name)
    countrylist.push(response_countrylist.data[key])
  }
  console.log('countrylist',countrylist)
  //this.setState({displayContent:<ReconReports countryList = {countrylist} />})
    const response_countryStatelist = await Axios.get(base_url+'country_state_details/')

       let country_namelist = []
       let country_codelist = []
      let state_list = []
      let stateCode_list = []
      for (var key in response_countryStatelist.data) {

        country_codelist.push(response_countryStatelist.data[key].country_id)
        country_namelist.push(response_countryStatelist.data[key].name)
        state_list.push(response_countryStatelist.data[key].states)
        
        // console.log(response_countryStatelist.data[key].country_id)
        // console.log(response_countryStatelist.data[key].name)
        // console.log(response_countryStatelist.data[key].states)
        // console.log(response_countryStatelist.data[key].states[2])
      }
  //this.setState({displayContent:<ReconReports countryList = {countrylist} />})
  /*this.setState({displayContent:<CreateShipment refresh={this.createShippment}
 countryList = {countrylist} country_name_list={country_namelist} country_code_list={country_codelist} statedata={state_list}/>})*/


  this.setState({displayContent:<ShippingRates refresh={this.createShippingRates} countryList = {countrylist} country_name_list={country_namelist} country_code_list={country_codelist} statedata={state_list}/>})
}
createShippment = async ()=>{
  let url = base_url+`countries/?list=True/`
  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key])
  }
  console.log('countrylist',countrylist)

  const response_countryStatelist = await Axios.get(base_url+'country_state_details/')

       let country_namelist = []
       let country_codelist = []
      let state_list = []
      let stateCode_list = []
      for (var key in response_countryStatelist.data) {

        country_codelist.push(response_countryStatelist.data[key].country_id)
        country_namelist.push(response_countryStatelist.data[key].name)
        state_list.push(response_countryStatelist.data[key].states)
        
        // console.log(response_countryStatelist.data[key].country_id)
        // console.log(response_countryStatelist.data[key].name)
        // console.log(response_countryStatelist.data[key].states)
        // console.log(response_countryStatelist.data[key].states[2])
      }
  //this.setState({displayContent:<ReconReports countryList = {countrylist} />})
  this.setState({displayContent:<CreateShipment refresh={this.createShippment}
 countryList = {countrylist} country_name_list={country_namelist} country_code_list={country_codelist} statedata={state_list}/>})
}

orderListShipment = async (props)=>{
  await this.setState({
    showSpinner:true
  })

try{
 
  //this.setState({displayContent:<ReconReports countryList = {countrylist} />})
//this.setState({displayContent:<CreateShipment refresh={this.createShippment} countryList = {countrylist}/>})

   var url
    url = base_url+`shipment/`
      const response = await Axios.get(url)
      this.setState({
        displayContent: <OrderListShipment orderdata = {response.data.results}  orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

orderListShipmentReturn = async (props)=>{
  await this.setState({
    showSpinner:true
  })

try{
 
  //this.setState({displayContent:<ReconReports countryList = {countrylist} />})
//this.setState({displayContent:<CreateShipment refresh={this.createShippment} countryList = {countrylist}/>})

   var url
    url = base_url+`shipmentreturn/`
      const response = await Axios.get(url)
      this.setState({
        displayContent: <OrderListShipmentReturn pagename='othreturn' orderdata = {response.data.results}  orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

createOrderReturn = async ()=>{
  let url = base_url+`countries/?list=True/`
  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key])
  }
  console.log('countrylist',countrylist)

  const response_countryStatelist = await Axios.get(base_url+'country_state_details/')

  let country_namelist = []
  let country_codelist = []
  let state_list = []
  let stateCode_list = []
  for (var key in response_countryStatelist.data) {

  country_codelist.push(response_countryStatelist.data[key].country_id)
  country_namelist.push(response_countryStatelist.data[key].name)
  state_list.push(response_countryStatelist.data[key].states)
  }

  this.setState({displayContent:<CreateOrderReturn pagename='othreturn' refresh={this.createOrderReturn} 
countryList = {countrylist} country_name_list={country_namelist} 
country_code_list={country_codelist} statedata={state_list}/>})
}

orderListReturn = async (props)=>{
  await this.setState({
    showSpinner:true
  })

  // const operatorId = this.state.operatorId
  // const operatorId = this.props.location.state.operator_id
  // console.log('OPT ID', operatorId);

try{
  const response = await Axios.get(base_url+"boxes_received_return/")
      // console.log(response.data.results)
      // console.log("COutnry data"+response.data )
      this.setState({
        displayContent: <OrderListReturn pagename='othreturn' orderdata = {response.data.results} orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

createOrderReturnUS = async ()=>{
  let url = base_url+`countries/?list=True/`
  const response_countrylist = await Axios.get(url)
  console.log(response_countrylist.data)
  let countrylist = []
  for (var key in response_countrylist.data) {
    countrylist.push(response_countrylist.data[key])
  }
  console.log('countrylist',countrylist)

  const response_countryStatelist = await Axios.get(base_url+'country_state_details/')

  let country_namelist = []
  let country_codelist = []
  let state_list = []
  let stateCode_list = []
  for (var key in response_countryStatelist.data) {

  country_codelist.push(response_countryStatelist.data[key].country_id)
  country_namelist.push(response_countryStatelist.data[key].name)
  state_list.push(response_countryStatelist.data[key].states)
  }

  this.setState({displayContent:<CreateOrderReturn pagename='usreturn' refresh={this.createOrderReturnUS} 
countryList = {countrylist} country_name_list={country_namelist} 
country_code_list={country_codelist} statedata={state_list}/>})
}

orderListReturnUS = async (props)=>{
  await this.setState({
    showSpinner:true
  })

  // const operatorId = this.state.operatorId
  // const operatorId = this.props.location.state.operator_id
  // console.log('OPT ID', operatorId);

try{
  const response = await Axios.get(base_url+"boxes_received_return_us/")
      // console.log(response.data.results)
      // console.log("COutnry data"+response.data )
      this.setState({
        displayContent: <OrderListReturn pagename='usreturn' orderdata = {response.data.results} orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

orderListShipmentReturnUS = async (props)=>{
  await this.setState({
    showSpinner:true
  })

try{
 
  //this.setState({displayContent:<ReconReports countryList = {countrylist} />})
//this.setState({displayContent:<CreateShipment refresh={this.createShippment} countryList = {countrylist}/>})

   var url
    url = base_url+`shipmentreturn_us/`
      const response = await Axios.get(url)
      this.setState({
        displayContent: <OrderListShipmentReturn pagename='usreturn' orderdata = {response.data.results}  orderResponse = {response.data} />,
        showSpinner:false
      })
}catch(err){
 
  M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
  
  console.log(err)
  this.setState({error:<Error/>})
      }
    
}

functionalityNotAvailable= ()=>{

    M.toast({html: 'Functionality Not Available',classes:"white-text red rounded"})
  
 


}

login=()=>{
  localStorage.removeItem("token")
  console.log('token',localStorage.getItem("token"))
this.logout()
  // this.setState({redirectoLogin:true})

  // this.setState({
  //   redirectoLogin:true
  // })
  // window.location.reload();
}


  
    
    render() {
      const { match } = this.props
      // if (this.state.redirectoLogin === true ){
      //   return <Redirect to = "/"/>
      // }
      {
        if(sessionStorage.getItem('user_type')==="Admin"){
          return <Redirect to = "/logout"/>
        }
      }
      
      if (this.state.loggedIn === false ){
        return <Redirect to = "/logout"/>
      }
      
        return(
        <Fragment>
           {/* Dropdown Structure */}

            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={1000 * 600}  
              // timeout={1000 * 6000}             //WHEN TESTING/DEV 
            />          
            <IdleTimeOutModal
                    showModal={this.state.sessionTimeoutModalOpen} 
                    // handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
   <Switch>
                    <Route 
                        exact path={`${match.path}logout`}
                        render={(props) => <Logout {...props} /> }/>
                  
                </Switch>
<ul id="profile" className="dropdown-content">
  <li><a onClick = { this.logout }href="">Logout</a></li>
</ul>
    <nav className="white black-text " role="navigation">
    <ul className="left ">
        <li><a href="#" data-target="slide-out" className="sidenav-trigger show-on-large">
          <i className="material-icons black-text">menu</i></a>
        </li>
      </ul>

    {/* <div className="nav-wrapper  "><p id="logo-container"  className="brand-logo center compamny_title indigo-text ">Ezzy<span className="orange-text">Ship</span></p> */}
    <div className="nav-wrapper  "><p id="logo-container"  className="brand-logo center compamny_title orange-text ">Operator</p>
<ul className="right ">
  <li className="grey-text text-darken-4">{sessionStorage.getItem('user_id')}</li>
<li><a className="dropdown-trigger " href="#!" data-target="profile">
  <Image src="assets/images/avatar.svg" className="" /></a></li>

  </ul>      
    </div>
  </nav>
  <ul id="slide-out" className="sidenav sidenav-fixed ">
    <br></br>
      <li style={{ textAlign: "center" }}><Image src="assets/images/Ezzyship.png" className="" /></li>
  
      <Fragment> 
      <br></br>
              

  <Nav defaultActiveKey="/Dashboard" className="flex-column ">
            {sessionStorage.getItem('dashboard_create')=== "1"
                ?<Nav.Link href="" onClick={this.dashboardDummy} className="sidenav_item_hover sidenav-close black-text"><Image className="mr-2 " src = "assets/images/dashboard.png"/>Dashboard</Nav.Link>
                :""}
                <Nav.Link href=""
                //  onClick={this.databaseOption.bind(this)} 
                onClick={this.customerinfo}
                className="sidenav_item_hover sidenav-close black-text"><Image className="mr-2 " src = "assets/images/userprofile.svg"/>Users</Nav.Link>
				<Nav.Link href=""
                //  onClick={this.databaseOption.bind(this)} 
                onClick={this.newcustomerinfo}
                className="sidenav_item_hover sidenav-close black-text"><Image className="mr-2 " src = "assets/images/userprofile.svg"/>Pending Users</Nav.Link>
                
                <ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>Orders</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                {sessionStorage.getItem('order_create')=== "1"
                ?<li ><Nav.Link id="createorder" onClick={this.createOrder} className="ml-3" href="" >Create Order</Nav.Link></li>
                :""}
                {sessionStorage.getItem('order_view')=== "1"
                ?<li ><Nav.Link  onClick={this.orderList}>Order List</Nav.Link></li>
                :""}
				{sessionStorage.getItem('order_chargelist')=== "1"
                ?<li><Nav.Link id="bp" onClick={this.boxList} className="mr-3" href="">Charge List</Nav.Link></li>
                :""}
                {sessionStorage.getItem('order_boxlist')=== "1"
                ?<li><Nav.Link id="dd" onClick={this.boxListPending} className="mr-3" href="">Box List</Nav.Link></li>
                :""}
                {/* <li><Nav.Link onClick={this.readyToShip} className="ml-3" href="">Ready to Ship</Nav.Link></li> */}
                {sessionStorage.getItem('order_custsett')=== "1"
                ?<li><Nav.Link onClick={this.customSettlement} className="ml-3" href="">Custom Settlement</Nav.Link></li>
                :""}
                {sessionStorage.getItem('order_shipped')=== "1"
                ?<li><Nav.Link onClick={this.shipped} className="ml-3" href="">Shipped</Nav.Link></li>
                :""}
				{sessionStorage.getItem('order_refrral')=== "1"
                ?<li><Nav.Link onClick={this.referral} className="ml-3" href="">Referral</Nav.Link></li>
                :""}
				{sessionStorage.getItem('order_recon')=== "1"
                ?<li><Nav.Link onClick={this.getReconReports} className="ml-3" href="">Reconcillation</Nav.Link></li>
                :""}
				{sessionStorage.getItem('coupon_create')=== "1"
                ?<li ><Nav.Link  id="createcoupon" onClick={this.coupon}>Create Coupon</Nav.Link></li>
                :""}
                {sessionStorage.getItem('coupon_view')=== "1"
                ?<li ><Nav.Link  id="couponlist" onClick={this.couponinfo}>View Coupon</Nav.Link></li>
                :""}
				{sessionStorage.getItem('order_curcustlist')=== "1"
                ?<li ><Nav.Link  onClick={this.curcustomlistinfo} className="ml-3" href="">Custom List</Nav.Link></li>
                :""}
			  </ul>
            </div>
          </li>
        </ul>
		
		<ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>Cash Invoice</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                {sessionStorage.getItem('cash_create')=== "1"
                ?<li ><Nav.Link  id="createcashinvoice" onClick={this.cashinvoice}>Create Invoice</Nav.Link></li>
                :""}
                {sessionStorage.getItem('cash_view')=== "1"
                ?<li ><Nav.Link  id="cashinvoicelist" onClick={this.cashinvoiceinfo}>Invoice List</Nav.Link></li>
                :""}
				{sessionStorage.getItem('cash_invreport')=== "1"
                ?<li ><Nav.Link  id="cashreport" onClick={this.cashreport}>Invoice Report</Nav.Link></li>
                :""}
                </ul>
            </div>
          </li>
        </ul>
                {/* <Nav.Link eventKey="link-1" className="sidenav_item_hover sidenav-close"><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>Orders</Nav.Link> */}
       {/*
       <ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>FedEx Manager</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                {sessionStorage.getItem('fedex_shiprates')=== "1"
                ?<li ><Nav.Link id="createorder" onClick={this.createShippingRates} className="ml-3" href="" >Shipping Rates</Nav.Link></li>
                :""}
                {sessionStorage.getItem('fedex_createship')=== "1"
                ?<li ><Nav.Link id="createshipment"  onClick={this.createShippment}>Create Shipment</Nav.Link></li>
                :""}
                {sessionStorage.getItem('fedex_viewship')=== "1"
                ?<li ><Nav.Link id="viewShippment"  onClick={this.orderListShipment}>View Shipment</Nav.Link></li>
                :""}
                </ul>
            </div>
          </li>
        </ul> */}
        
		
        <ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>Return Shipping Manager</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                 {sessionStorage.getItem('order_create')=== "1"
                ?<li ><Nav.Link id="createorderreturn" onClick={this.createOrderReturn} className="ml-3" href="" >Create Return Order</Nav.Link></li>
                :""}
                {sessionStorage.getItem('order_view')=== "1"
                ?<li ><Nav.Link  onClick={this.orderListReturn}>Return Order List</Nav.Link></li>
                :""}
                {sessionStorage.getItem('fedex_shiprates')=== "1"
                ?<li ><Nav.Link id="createorder" onClick={this.createShippingRates} className="ml-3" href="" >Shipping Rates</Nav.Link></li>
                :""}
                {/*{sessionStorage.getItem('fedex_createship')=== "1"
                ?<li ><Nav.Link id="createshipment"  onClick={this.createShippment}>Create Shipment</Nav.Link></li>
                :""}*/}
                {sessionStorage.getItem('fedex_viewship')=== "1"
                ?<li ><Nav.Link id="viewShippment"  onClick={this.orderListShipmentReturn}>View Shipment</Nav.Link></li>
                :""}
                </ul>
            </div>
          </li>
        </ul>

		 <ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>US Domestic Shipping</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                 {sessionStorage.getItem('order_create')=== "1"
                ?<li ><Nav.Link id="createorderreturn" onClick={this.createOrderReturnUS} className="ml-3" href="" >Create US Domestic Order</Nav.Link></li>
                :""}
                {sessionStorage.getItem('order_view')=== "1"
                ?<li ><Nav.Link  onClick={this.orderListReturnUS}>US Domestic Order List</Nav.Link></li>
                :""}
                {sessionStorage.getItem('fedex_viewship')=== "1"
                ?<li ><Nav.Link id="viewShippment"  onClick={this.orderListShipmentReturnUS}>View US Domestic Shipment</Nav.Link></li>
                :""}
                </ul>
            </div>
          </li>
        </ul>
		
		<ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>Role</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                {sessionStorage.getItem('role_create')=== "1"
                ?<li ><Nav.Link  id="createrole" onClick={this.role}>Create Role</Nav.Link></li>
                :""}
                {sessionStorage.getItem('role_view')=== "1"
                ?<li ><Nav.Link  id="rolelist" onClick={this.roleinfo}>Role List</Nav.Link></li>
                :""}
                </ul>
            </div>
          </li>
        </ul>
		
		<ul className="collapsible collapsible-accordion">
          <li className="sidenav_item_hover ">
            <Nav.Link className="collapsible-header sidenav_item_hover black-text "><Image className="mr-1"  src = "assets/images/ls_dropdown.svg"/><Image className="mr-2 "  src = "assets/images/shopping-bags.svg"/>Report</Nav.Link>
            <div className="collapsible-body">
              <ul className="center grey lighten-2">
                {sessionStorage.getItem('report')=== "1"
                ?<Nav.Link href="" onClick={this.getReports} className="sidenav_item_hover sidenav-close black-text"><ListAlt/> Reports</Nav.Link>
                :""}
                {sessionStorage.getItem('pendorder')=== "1"
                ?<Nav.Link href="" onClick={this.getReportsOneClick} className="sidenav_item_hover sidenav-close black-text"><ListAlt/> Pending Orders</Nav.Link>
                :""}
				{sessionStorage.getItem('pendorder')=== "1"
                ?<Nav.Link href="" onClick={this.getVolumeReports} className="sidenav_item_hover sidenav-close black-text"><ListAlt/> Volume Report</Nav.Link>
                :""}
                </ul>
            </div>
          </li>
        </ul>
		
                
                </Nav>
            
				   
			
 
                {/* <Image src="assets/images/operatorside.png" className="sidenav_botom_Image"/> */}
            
            </Fragment>
    </ul>
    <main>
    <div className=" ">
    <br></br>
    <div className="" >
    {
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }
           {this.state.displayContent}
   

    </div>
   <br></br>
  </div>  
      </main>
  
        </Fragment>
        )
    }
}
Operator.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}

export default Operator;