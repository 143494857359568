import React from 'react'
import { base_url } from '../../globalConstants'
import { useState, useEffect, useContext} from 'react'
import { Row, Col, InputGroup,ButtonGroup, FormControl, Table, Spinner, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import M from "materialize-css";
import InfoIcon from '@material-ui/icons/Info';
import {Search, NavigateBefore, NavigateNext, Close } from "@material-ui/icons";
import "./temp.css";
import Axios from "axios";
import SearchIcon from '@material-ui/icons/Search';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function Shipped() {

    const [shippedData, setShippedData] = useState([]);  
    const [shippedResponse, setShippedResponse] = useState([]);
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [boxId, setBoxId] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);  
    const [url, setUrl] = useState(base_url+`customerreferral/`)
    const [prevPageUrl, setPrevPageUrl] = useState('')
    const [nextPageUrl, setNextPageUrl] = useState('')
    const [searchResultsCount, setSearchResultsCount] = useState(-1)
    const [currentDisplayCount, setCurrentDisplayCount] = useState(-1)
    const [totalDisplayCount, setTotalDisplayCount] = useState(-1)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    
    const toggle = () => setModal(!modal);
    
 

    const fetchDetails = async () => {
      setShowSpinner(true)
      
      // let url = base_url+'custom_settlement_details'
      
      const response = await Axios.get(url)
      // console.log(response);
      const { data } = response 
      console.log(data.results);
      // console.log(base_url);
      setShowSpinner(false)
      await setShippedResponse(data);
      await setShippedData(data.results);
      setCurrentDisplayCount(data.results.length)
      setTotalDisplayCount(data.count)   
      setPrevPageUrl(data.previous)
      setNextPageUrl(data.next) 
    }

    //Handle page navigation
  const handlePageNavigation = async (updatedUrl, pg) =>{

    var tempPageNumber = currentPageNumber

    setShowSpinner(true)
    let updated_url = updatedUrl
    console.log('updatedUrl', updated_url);
    await Axios({
      method: 'GET',
      url: updatedUrl
    }).then( (response) => {
      console.log('RESPONSE', response);
      setShowSpinner(false)
      setShippedResponse(response.data);
      setShippedData(response.data.results);
      setCurrentDisplayCount(response.data.results.length)
      setTotalDisplayCount(response.data.count)   
      setPrevPageUrl(response.data.previous)
      setNextPageUrl(response.data.next) 
      // setCurrentPageNumber(updatedUrl.toString().split('=')[2].split('&')[0]?updatedUrl.toString().split('=')[2].split('&')[0]:1)

      if(pg === '+')
        tempPageNumber = tempPageNumber + 1
      else if(pg === '-')
        tempPageNumber = tempPageNumber - 1
      else
        tempPageNumber = ''

      setCurrentPageNumber(tempPageNumber)  

    }).catch((response)=>{
      console.log('CATCH RESPONSE',response);
    })

  }   
  
  const refreshnew = async () => {
    
      let searchUrl = base_url + `customerreferral/`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        // console.log(response);
        // console.log(response.data.results);
        console.log(response.data.count);
        // console.log(response.data.next);
        // console.log(response.data.previous);

        // if(response.data.count === 0){
        //   this.setState({orderslist: []})      
        //   return
        // }
        
        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
        // console.log('SHIPPED DATA LENGTH', shippedData.length);

        // console.log('orderlist.length', this.state.orderslist.length);
        // this.setState({currentOrderListCount: orderCount})

      }).catch(async (response) => {
        console.log(response.status)

      })
    
  }
  //Handle page navigation end

    //SEARCH START

  const handleKeyDownSearch = async (eventKey) => {

    console.log('searchKeyword', searchKeyword);

    var filterString = searchKeyword

    if (eventKey.key === "Backspace") {
      let searchUrl = base_url + `customerreferral/`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        // console.log(response);
        // console.log(response.data.results);
        console.log(response.data.count);
        // console.log(response.data.next);
        // console.log(response.data.previous);

        // if(response.data.count === 0){
        //   this.setState({orderslist: []})      
        //   return
        // }
        
        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
        // console.log('SHIPPED DATA LENGTH', shippedData.length);

        // console.log('orderlist.length', this.state.orderslist.length);
        // this.setState({currentOrderListCount: orderCount})

      }).catch(async (response) => {
        console.log(response.status)

      })
    }

    if (eventKey.key === "Enter") {
      if (filterString.trim().length === 0) {
        return
      }

      let searchUrl = base_url + `customerreferral/?search=${filterString}`
      setUrl(searchUrl)
      console.log(searchUrl);

      await Axios({
        method: 'GET',
        url: searchUrl
      }).then(async (response) => {
        console.log(response.data);
        // console.log(response.data.results);
        // console.log(response.data.count);
        // console.log(response.data.next);
        // console.log(response.data.previous);

        // if(response.data.count === 0){
        //   this.setState({orderslist: []})      
        //   return
        // }

        setShippedResponse(response.data)
        setShippedData(response.data.results)
        setSearchResultsCount(response.data.count)
        setCurrentDisplayCount(response.data.results.length)
        setPrevPageUrl(response.data.previous)
        setNextPageUrl(response.data.next)   
        setCurrentPageNumber(1)
        // console.log('SHIPPED DATA LENGTH', shippedData.length);

        // console.log('orderlist.length', this.state.orderslist.length);
        // this.setState({currentOrderListCount: orderCount})

      }).catch(async (response) => {
        console.log(response.status)

      })

    }

  }

  const editSearchTerm = async e => {
    console.log('SEARCH KEY', e.target.value);

    let word = e.target.value
    let bucket = word.split(' ')

    console.log(bucket)

    let searchString = bucket.reduce((prevVal, currVal) => { return prevVal += currVal + ' ' }, '')

    console.log('searchString', searchString);

    setSearchKeyword(searchString)


  }

  // const onKeyDown = (e) => {
  //   if (e.keyCode === 8) {
  //     setShippedData(shippedData)
  //   }
  // }

  //SEARCH END
    
    useEffect(() => {
      fetchDetails();
    }, [url])

    return (
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
        {
          showSpinner
            ?
            < div className="center">

              <Spinner animation="grow" variant="primary" size="sm" />
              <Spinner animation="grow" variant="success" size="sm" />
              <Spinner animation="grow" variant="warning" size="sm" />
            </div>
            :
            null
        }
      <Row >
        <Col>
          <h3 className="orange-text">Referral</h3>
        </Col>
        <Col xs={5}>

        </Col>
        <Col>
		<ButtonGroup>
              <Button variant="outline-secondary " className="grey white-text mr-2" onClick={refreshnew}>Refresh </Button>
		</ButtonGroup>
          {/* <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-lg"><SearchIcon/></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
          </InputGroup> */}
        </Col>
      </Row>
      <Row>
        <Col>
            <div>
              {
                shippedResponse.previous
                  ? <Button style={{ backgroundColor: "orange" }} onClick={()=>handlePageNavigation(prevPageUrl, '-')}><NavigateBefore /></Button>
                  : <Button disabled><NavigateBefore /></Button>
              }{' '}
              {
                shippedResponse.next
                  ? <Button style={{ backgroundColor: "orange" }} onClick={()=>handlePageNavigation(nextPageUrl, '+')}><NavigateNext /></Button>
                  : <Button onClick={() => setUrl(shippedResponse.next)} disabled><NavigateNext /></Button>
              }
            </div><br />
            <div>
              <h5>Page: {currentPageNumber}</h5>
            </div>
        </Col>
        <Col>
            <div>
              <InputGroup className="mb-3" style={{ float: 'right', width: '270px' }}>
                <InputGroup.Prepend >
                  <InputGroup.Text style={{ border: '1px solid grey', background: 'white' }} id="inputGroup-sizing-default"><Search /></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ border: '1px solid gray', borderRadius: '2px', paddingLeft: '10px' }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="  Search here....."
                  onChange={editSearchTerm}
                  onKeyUp={handleKeyDownSearch}
                />
              </InputGroup>
            </div>
            <div style={{ float: 'right', width: '270px' }}>
              {
                searchResultsCount > -1
                  ?
                  <h5>Showing
                    <span style={{ color: 'orange' }} > {currentDisplayCount} </span> of
                    <span style={{ color: 'orange' }} > {searchResultsCount} </span> results
                  </h5>
                  : <h5>Showing
                      <span style={{ color: 'orange' }} > {currentDisplayCount} </span> of
                      <span style={{ color: 'orange' }} > {totalDisplayCount} </span> results
                    </h5>
              }
            </div>
        </Col>
      </Row>
      <Row>
        <Col >
          <Table bordered hover size="lg">
            <thead>
              <tr style = {{ backgroundColor: "#676760", color: "white"  }} >
                {/* <th>S.No.</th> */}

                <th>Sr. No</th>
				<th>EZZID</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>CreatedOn</th>
                <th>Status</th>
                <th>Friend OrderNo</th>
                <th>Friend ReferralOn</th>
                <th>Customer OrderNo</th>
                <th>Customer ReferralOn</th>
              </tr>
            </thead>
            <tbody>
              {
                // shippedData.filter(key => key.custom_charge_transaction_id).map((filterkey, index) => (
                shippedData.map((filterkey, index) => (
                  <tr key={filterkey.id}>
        <th scope="row" >{index+1}</th>
		<td>{filterkey.ezz_id}</td>
        <td>{filterkey.friend_name}</td>
        <td>{filterkey.friend_phone}</td>
        <td>{filterkey.friend_email}</td>
        {/*<td><a href={`https://www.fedex.com/fedextrack/?trknbr=${key.date_created}`}>{key.date_created}</a></td>*/}
                {/*<td>{key.date_created}</td>*/}
        	<td>{new Intl.DateTimeFormat('en-GB', { 
                month: 'short', 
                day: '2-digit',
                year: 'numeric', 
                /*hour: 'numeric',
                minute: 'numeric',*/
            }).format(new Date(filterkey.date_created))}</td>

        {filterkey.order_id && filterkey.customer_order_id
        ?<td>Completed</td>
        :
        <td>{filterkey.status}</td>
        }
        <td>{filterkey.order_id}</td>
		<td>{
			filterkey.customer_referral_takenon
			?
			new Intl.DateTimeFormat('en-GB', { 
                month: 'short', 
                day: '2-digit',
                year: 'numeric', 
            }).format(new Date(filterkey.customer_referral_takenon)):''}</td>
    <td>{filterkey.customer_order_id}</td>
    <td>{filterkey.friend_referral_takenon
		?
		new Intl.DateTimeFormat('en-GB', { 
                month: 'short', 
                day: '2-digit',
                year: 'numeric', 
            }).format(new Date(filterkey.friend_referral_takenon)):''}</td> 

                    </tr>
                ))               
              }
            </tbody>
          </Table>
          
        </Col>
      </Row>
    </div>
    )
}
