import React, {Component} from 'react';
import {Row, Col,ButtonGroup,Button,Spinner,Nav,Image} from 'react-bootstrap';
import M from 'materialize-css';
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCardCur';
import EditProduct from './EditProductItemCur';
// import CustomerDetailCard from './CustomerDetailCard'
import { base_url } from '../../globalConstants';
import "./createorder.css";


class EditOrder extends Component{


constructor(props, context){
        super (props, context);
        console.log("construct ",this.props.productlist)
        this.state =({
            order:this.props.order,
            // current_date: date,
           
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :<></>,
            product_category:"",ordernumber:"",
         customer_id:"",order_date:"",
        received_date:"",tracking_number:"",shiper_order_number:"",
        weight:"",height:"",fedex_charge:"",width:"",length:"",actual_charge:"",homedelivery_charge:"",promo_type:"",promo_value:0,processing_fee:"",brokerage_charge:"",insurance_charge:"",
          cust_margin:"",cust_tax:"",cust_conversion:"",inv_total:"",inv_total_est:"",custom_factor:"",
          parenttrackno:"",
          orderStatusSelected:"",
          paymentTypeSelected:"",showSpinner:false,
          shippingTypeSelected:"0",
          customerinfo:{},
          error:"",
          selectItemDetails:{},
      
          currentOrderStatus: '',
		  sendNotification:"YES",
		  printLabel:"YES"
        
        })
    
    }

    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

  componentDidUpdate(prevProps) {


    if (this.props.orderdata.id !== prevProps.orderdata.id) {
       //console.log("Product errorrr",this.props.orderdata.shipping_method)
      // console.log("customerinfo",this.props.customerinfo)
      // console.log("catefory options",this.props.productCategory)
        this.setState({order:this.props.orderdata,
            //   current_date: this.props.orderdata.order_date,
            parenttrackno: this.props.orderdata.parent_tracking_number,
            // inv_total_est: this.props.orderdata.estimated_cost,
            // inv_total: this.props.invoiceinfo.inv_total,
            orderid :this.props.orderdata.box_id,
            customerinfo:this.props.customerinfo,
            productlist:this.props.productlist ,
            productlisthtml:this.props.productlisthtml ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :this.props.productCategory,
            // showSpinner:false,
            product_category:"",ordernumber:this.props.orderdata.box_id,
         customer_id:this.props.orderdata.ezz_id,order_date:this.props.orderdata.order_date,
        received_date:this.props.orderdata.received_date,tracking_number:this.props.orderdata.box_id,
        shiper_order_number:this.props.orderdata.shipper_number,
        cust_conversion:this.props.orderdata.cust_conversion,cust_tax:this.props.orderdata.cust_tax,
        fedex_charge:this.props.orderdata.freight_charge, custom_factor:this.props.orderdata.cust_factor,
        cust_margin:this.props.orderdata.cust_margin,actual_charge:this.props.orderdata.actual_charge,
        homedelivery_charge:this.props.orderdata.homedelivery_charge,
        promo_type:this.props.orderdata.promo_type,
        promo_value:this.props.orderdata.promo_value,
        orderStatusSelected:this.props.orderdata.current_status,
        paymentTypeSelected:this.props.orderdata.billing_type,
        shippingTypeSelected:this.props.orderdata.shipping_method.toString(),
		insurance_charge:this.props.orderdata.insurance,
		brokerage_charge:this.props.orderdata.brokerage,
		processing_fee:this.props.orderdata.processing,
          error:"",
          currentOrderStatus: this.props.orderdata.current_status

      
    
        })
 } 
//         var elems_order = document.getElementById('order_date');
//       var context = this
//       var newdate = this.newDate()
//         var options_order={
//             //selectMonths: true, // Enable Month Selection
//             selectYears: 10, // Creates a dropdown of 10 years to control year
//             format:'yyyy-mm-dd',
//             autoClose :true,
//             defaultDate:newdate,
//             setDefaultDate :true,
//             onSelect: function(date) {
//                let selecteddata =  context.formatDate(date)
//               context.setState({ order_date: selecteddata});
//               //console.log("order",selecteddata); // Selected date is logged
//             },
//           }
//         var instances = M.Datepicker.init(elems_order, options_order);
//         instances.toString()
//         var elems_recieved = document.getElementById('received_date');
		
//         var options_recieved={
//             //selectMonths: true, // Enable Month Selection
//             selectYears: 1, // Creates a dropdown of 10 years to control year
//             format:'yyyy-mm-dd',
//             autoClose :true,
//             defaultDate:newdate,
//             setDefaultDate :true,
//             onSelect: function(date) {
//               let selecteddata =  context.formatDate(date)
//               context.setState({ received_date: selecteddata});
//               //console.log("recived", selecteddata); // Selected date is logged
//             },
//           }
//         var instances = M.Datepicker.init(elems_recieved, options_recieved);
 
//  instances.toString()

    


  }



handletableClick=(clickedrow)=>{
  console.log(clickedrow)
  // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
 let productdetail = this.state.productlist[clickedrow]
 let categoryoptions = this.state.product_category_options
 let order_id = this.state.orderid
 console.log("handle product category",categoryoptions)

 this.setState({rowNumber:clickedrow})
 this.setState({selectItemDetails:productdetail})

 
   this.refs.editproduct.openModal(clickedrow,productdetail,categoryoptions);
}

updateProduct= async (rownumber,item)=>{
  console.log("UPDATING LIST HERE ",rownumber,item)
let productlist = this.state.productlist
productlist.splice(rownumber, 1, item)
await this.setState({productlist:productlist})
// this.refs.editproduct.closeModal();
// M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});


}

deleteProduct= async (rownumber,item_id)=>{
  let tempItemId = item_id.toString()
  let updated_productlist = this.state.productlist
  console.log("DELETING ITEM HERE ",rownumber,item_id)

  if(!tempItemId.includes('item_')){
    await Axios({
      method: 'DELETE',
      url: base_url + `products/${item_id}/`
    }).then(function (response) {
      // console.log(`DELETED${tempProductId}`, response);
      M.toast({ html: `Product deleted successfully`, classes: "white-text blue rounded" })
    }).catch(function (response) {
      // console.log("NOT DELETED ERROR",response);
      M.toast({ html: `Product deletion failed`, classes: "white-text red rounded" })
    })
  }  

  updated_productlist.splice(rownumber, 1)
  await this.setState({productlist:updated_productlist})
  console.log(`ITEM ${item_id} DELETED AT ${rownumber}`)
  // this.refs.editproduct.closeModal();
  // M.toast({html: 'item deleted ' +rownumber,classes:"white-text blue rounded"});

}

// @TODO: updateOrderProdcts Method will used to update products in future
updateOrderProducts=async(header)=>{
    this.state.productlist.forEach(async(item)=>{
      // rowclick={this.handletableClick}
        console.log(item)
        try{
          var productformdata = new FormData()
          productformdata.append("id",item.id)
          productformdata.append("description",item.productName)
          productformdata.append("quantity",item.productQuantity)
          productformdata.append("unit_price",item.productPrice)
          productformdata.append("inspection_status","okay")
          productformdata.append("status","Active")
          productformdata.append("category",item.productCategory)
    
          const response_products = await Axios.patch(base_url+"products/",productformdata,
          header)
          // alert(response.data)
          console.log("Product added Successfully")
          this.setState({error:<Success message=" Order Status Updated"/>})
          M.toast({html: 'Product Addition Successfull',classes:"white-text blue rounded"});
        }catch(error){
          M.toast({html: 'Product Addition Failed',classes:"white-text red rounded"});
            console.log(error)
        }
       
      });
}

startRefresh=async()=>{
this.props.refreshpage();
}

updateOrder= async(header)=>{
  const  orderid= this.state.ordernumber

  // const found = this.state.productlist.find(element => element["productPrice"]==0);

  // console.log('found', found)

  if(this.state.cust_margin.length <= 0 || parseFloat(this.state.cust_margin) <= 0 ){
    M.toast({ html: 'Margin value can not be zero', classes: "white-text red rounded" });
    return
  }

    try{
        
        var orderupdateForm = new FormData()
        // orderupdateForm.append("order_id",this.state.ordernumber)
        // orderupdateForm.append("ezz_id",this.state.customer_id)
        // orderupdateForm.append("shipper_number",this.state.shiper_order_number)
        // orderupdateForm.append("billing_id","2121")
        // orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
        // orderupdateForm.append("shipping_method",parseInt(this.state.shippingTypeSelected))

        // orderupdateForm.append("order_date",this.state.order_date)
        // orderupdateForm.append("received_date",this.state.received_date)
        // orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
        orderupdateForm.append("cust_conversion",this.state.cust_conversion)
        orderupdateForm.append("cust_margin",this.state.cust_margin)
        orderupdateForm.append("cust_tax",this.state.cust_tax)
        orderupdateForm.append("cust_factor",this.state.custom_factor)
    //     orderupdateForm.append("charge_type","VAT")
    //     orderupdateForm.append("freight_charge",this.state.fedex_charge)
    //     orderupdateForm.append("actual_charge",this.state.actual_charge)
    //     orderupdateForm.append("current_status",this.state.orderStatusSelected)
    //     orderupdateForm.append("previous_status",this.props.orderdata.current_status)
    //     orderupdateForm.append("status","Deactive")
    //     orderupdateForm.append("flag","1")
    //     orderupdateForm.append("current_status",this.state.orderStatusSelected)
    //     orderupdateForm.append("homedelivery_charge",this.state.homedelivery_charge)
		// orderupdateForm.append("insurance", this.state.insurance_charge)
		// orderupdateForm.append("brokerage", this.state.brokerage_charge)
		// orderupdateForm.append("processing", this.state.processing_fee)
    //     orderupdateForm.append("promo_type",this.state.promo_type)
    //     orderupdateForm.append("promo_value",this.state.promo_value)

        let urlforOrderStatusUpdate = base_url+`boxes_out/${this.state.ordernumber}/`
        const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
  
        console.log(`order status update succesfully ${response}`)
        this.setState({error:<Success message=" Order Updated"/>})
      
        M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});


        //Updating The Product 

        this.state.productlist.forEach(async(item)=>{
          console.log("productitem")
          console.log(item)
          console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')

          

          try{
            var productformdata = new FormData()
            productformdata.append("order_id",item.orderno)
            // productformdata.append("description",item.productName)
            // productformdata.append("quantity",item.productQuantity)
            productformdata.append("customs_category_duty",item.productPrice)
            // productformdata.append("inspection_status","okay")
            // productformdata.append("status","Active")
            productformdata.append("customs_category",item.productCategory)
//alert(item.productCategory);
            if( typeof(item.id) === "number"  ){
              const response_products = await Axios.patch(base_url+`products/${item.id}/`, productformdata,
                header)
              // alert(response.data)
              console.log("Product added Successfully")
              this.setState({ error: <Success message=" Order Status Updated" /> })
              M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
              
            }else{
              
              const response_addProduct = await Axios.post(base_url+`products/`, productformdata,
              header )

              M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
              
            }

            
            
          }catch(error){
            M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
              console.log(error)
          }
         
        });
        

		
		console.log('this.state.sendNotification',this.state.sendNotification)
		
		//if (this.state.sendNotification === "YES" )
		//{
			try{
			
			  var email_subject = "invoice_missing"
			   const status = this.state.orderStatusSelected
			   switch(status){
				 case "IM":
				  email_subject ="invoice_missing";
				  break;
				 case "WFC":
				  email_subject ="consolidation";
				  break;
		  
		  
				   
			   }

			   console.log('this.state.currentOrderStatus',this.state.currentOrderStatus)
			   console.log('this.state.orderStatusSelected',this.state.orderStatusSelected)

			  if ((status===  "IM" || status === "WFC") && (this.state.currentOrderStatus !== this.state.orderStatusSelected) ){
				const  emaildata= {"type" : email_subject,
				"ezz_id" : this.state.customer_id,
				"order_id" : orderid}

				if (this.state.printLabel === "YES" )
				{
					Axios({
					  method:'post',
					  url: base_url+'status_printer',
					  data: {
						"order_id": orderid
					  }
					}).then(function(response){
					  console.log(response)
					  console.log("printer APi success");
					}).catch(function(response){
					  console.log(response)
					  console.log('printer API failed');
					})
				}
				
				if (this.state.sendNotification === "YES" )
				{
					Axios({
					  method:'post',
					  url: base_url+'whatsapp_templates',
					  data: emaildata,
					}).then(
					  function(response){ 
						console.log(response)
						console.log("whatsapp sent done");
					  }
					).catch(
					  function(response){ 
						console.log(response)
						console.log("whatsapp sent Failure");
					  }
					)


					const response = await Axios.post(base_url+"send_email/",emaildata,
								header)
					console.log(response)
					M.toast({html: 'Email Sent to '+response.data["Email to"],classes:"white-text red rounded"});
				}
			  }
			 
			}catch(error){
		  
			  M.toast({html: 'Email Sending Failed to'+ this.state.customer_id ,classes:"white-text red rounded"});
			}
		//}
        
      

        this.setState({ currentOrderStatus: this.state.orderStatusSelected })
        

        // window.location.reload(false);
      }catch(error){
        M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
          console.log(error)
          this.setState({error:<Error/>})
      }

      this.startRefresh();
}

updateOrderDB=()=>{
    let token =localStorage.getItem("token")
    const  header = {'Content-Type': 'application/json',
    'Authorization':'Token '+token,
    }
    this.updateOrder(header)


}

calculatefactor = ()=>{
  var nconv = parseFloat(this.state.cust_conversion)
  var ntax = parseFloat(this.state.cust_tax)
  var nmargin = parseFloat(this.state.cust_margin)
  var ninvtotal = parseFloat(this.state.inv_total)
  var ninvestm = parseFloat(this.state.inv_total_est)

  var ncustomfactor =  (ninvtotal + ninvestm) * nconv / (ninvtotal)
  var nmargin =  (ncustomfactor * nmargin)/ 100
  ncustomfactor = ncustomfactor + nmargin
  this.setState({custom_factor:ncustomfactor.toFixed(2)})
  //alert(ncustomfactor);
}

getfedexActualRate = async (weight,height,length,breadth)=>{
    


  try{
let token =localStorage.getItem("token")
let config={ headers : {'Content-Type': 'multipart/form-data',
'Authorization':'Token '+token,
}
}

// Fetching user list on input change
//var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
//var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}`
var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}&orderno=${this.state.ordernumber}&method=${this.state.shippingTypeSelected}`
var response = await Axios.get(url,config)
console.log(response.data)
return response.data
}catch(error){



console.log(error.response.data)
  
if (error.response.status ===503 ){
 M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
}

// return response.data
return error.response.data
}

}

handleFormChange= async (ev) =>{
  const target = ev.target
  const name = target.name
  console.log(ev.target.name)
  console.log(ev.target.value)
 
  // hide and show on YTA
  if (target.value === "YTA"){
	document.getElementById("received_date").disabled = true;
    this.setState({
      [name]: target.value,
      weight: 0,
      height:0,
      fedex_charge:0,
      width:0,
      length:0,
      actual_charge:0,
      homedelivery_charge:0,
	  processing_fee:0,
	  insurance_charge:0,
	  brokerage_charge:0,
      promo_type:"Not",
      promo_value:0
    });
  }else{
	document.getElementById("received_date").disabled = false;
    if(target.name ==="weight" || 
    target.name ==="height" || target.name === "width"
  || target.name === "length"
    ){

    this.setState({
        [name]: target.value
      });

      var weight = this.state.weight
      var width = this.state.width
      var height = this.state.height
      var length = this.state.length
      var field = target.name;

      switch (field) {
        case "weight":
          weight = target.value;
          break;
        case "height" :
          height = target.value;
          break;
        case "width" :
          width = target.value;
          break;
        case "length" :
          length = target.value;
          break;
       
      }

      if(width < 0 || height <0 || weight < 0 || length <0 || this.state.actual_charge <0){
        M.toast({html: "values can not be negative",classes:"white-text green rounded"});
        return;
      }


      if((width!=="" && parseInt(width)!==0) && (height !=="" && parseInt(height) !==0) && (width !=="" && parseInt(width) !==0 ) && (length!=="" && parseInt(length)!==0)){
        await this.setState({
          showSpinner:true
        })
        var response =  await this.getfedexActualRate(weight,height,length,width)
        console.log(response["freight_charges"] + response["actual_charges"])
          this.setState({
            fedex_charge:response["freight_charges"],
            actual_charge: response["actual_charges"],
            homedelivery_charge: response["homedelivery_charge"],
			processing_fee: response["processing"],
			insurance_charge: response["insurance"],
			brokerage_charge: response["brokerage"],
            promo_type: response["promo_type"],
            promo_value: response["promo_value"],
            showSpinner:false
          })
      }
     
    }else{
      this.setState({
        [name]: target.value
      });
    }

 
  }

 
}



addProductTolist = ()=>{
  console.log(this.state.productname + this.state.productprice +this.state.productquantity)
   var prodname =this.state.product_name
   var prodquant =this.state.product_quantity
   var prodcat =this.state.product_category
   var prodprice =this.state.productprice
   console.log("prody")
   console.log(prodname + prodquant+prodcat )

   if(prodname === "" || prodquant === 0 || prodcat ==="" || prodprice === ''){
    M.toast({html: 'Incorrect or Empty details',classes:"white-text red rounded"});
  }else{
    
    var elem = document.getElementById('addProductModal')
    var instance = M.Modal.getInstance(elem);
    instance.close();
    let totalproducts = this.state.productlist.length + 1
    
    let newitem = {id:'item_'+totalproducts,productName:this.state.product_name,
    productPrice:this.state.product_price ,
    productQuantity:this.state.product_quantity,
  productCategory:this.state.product_category
  }

    console.log("Product List",this.state.productlist)
   this.state.productlist.push(newitem)
    this.setState({productlist: this.state.productlist})
    
  try {
    // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
    this.setState({product_name:"",product_price:0,product_quantity:0 })
   M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
  } catch (error) {
    M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
  }
   

   }

}

 changeTo= (e) =>{

	  switch (e.target.name) {
		case "weight":
		  this.setState({weight: e.target.value})
		  break;
		case "height" :
		  this.setState({height: e.target.value})
		  break;
		case "width" :
		  this.setState({width: e.target.value})
		  break;
		case "length" :
		  this.setState({length: e.target.value})
		  break;
	  }
			//this.setState({weight: e.target.value})
			 //// console.log("is change to");
			  console.log(e.target.value);
			  this.handleFormChange(e);
  }

  updateValue= (e) =>{

	  switch (e.target.name) {
		case "weight":
		  this.setState({weight: e.target.value})
		  break;
		case "height" :
		  this.setState({height: e.target.value})
		  break;
		case "width" :
		  this.setState({width: e.target.value})
		  break;
		case "length" :
		  this.setState({length: e.target.value})
		  break;
	  }
			   //this.setState({weight: e.target.value})
			  //console.log("is updtevalue");
			  //console.log(e.target.value);
	  
  }


 componentDidMount(){
    M.AutoInit();
}

 openModal = (products,product_category,invtotal,invtotalest)=>{
    //  console.log("inside editiordemodal");
      console.log("invtotal:", invtotal);
    var elems = document.getElementById("editorderModel");
    var instance = M.Modal.init(elems);
    console.log("openmodel",products)
    this.setState({productlist:products,
      product_category_options:product_category})
   this.setState({inv_total:invtotal,inv_total_est:invtotalest})

    instance.open()
    // orderselectionmodal
}
closeModal = ()=>{
    var elems = document.getElementById("editorderModel");
    var instance = M.Modal.init(elems);
    instance.close()
}

    render() {
        const opts={};
        opts['readOnly'] = 'readOnly';

      const catlist = this.state.product_category_options;
      if (catlist.length > 0) {
        var categoryList = catlist.map((item, i) => {
          return (
            <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
          )
        }, this);
      }



        return(<>              {/* Edit product Table items */}
          <EditProduct ref="editproduct" rowNumber={this.state.rowNumber} status={this.state.orderStatusSelected}  orderId ={ this.state.orderid }
          productDetails={this.state.selectItemDetails}
           productcategory={this.state.product_category_options}
           updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}
           />
            <div id="editorderModel" className="modal editorderModel modal-fixed-footer">
            <div className="modal-content">
              
            {/* <span><h4 className="center orange-text"> Edit Order </h4><h4>Order id: { this.state.ordernumber }</h4></span> */}
            <h4 className="center orange-text"> Edit Customs </h4>
            <h4>Parent Tracking No.: <span className="orange-text">{this.state.parenttrackno }</span> </h4>
            
            <Row>

<Col xs={12}md={12} lg={12}>
<div className="row">
<form className="col s12">
{this.state.error}

<div className="row inputbox-height">
<div className="input-field col s6">
<input name="cust_conversion" id="cust_conversion" type="number" className=""
value={this.state.cust_conversion} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Enter Conversion Rate</span>

</div>
<div className="input-field col s6">
<input name="cust_tax" id="cust_tax" type="number" className=""
value={this.state.cust_tax} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" >Enter Tax</span>
</div> 
</div>
<div className="row inputbox-height">
<div className="input-field col s6">
<input name="cust_margin" id="cust_margin" type="number" className=""
value={this.state.cust_margin} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Enter Margin</span>

</div>
<div className="input-field col s6">
<input name="inv_total" id="inv_total" type="number" className=""
value={this.state.inv_total} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" >Invoice Total Value</span>
</div> 
</div>

<div className="row inputbox-height">
<div className="input-field col s6">
<input name="inv_total_est" id="inv_total_est" type="number" className=""
value={this.state.inv_total_est} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Estimated Total Freight</span>

</div>
<div className="input-field col s6">
 <ButtonGroup className="mr-2" aria-label="First group">
          <Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn" onClick={this.calculatefactor}> Calculate Customs Factor</Button>
            
        
          </ButtonGroup>
</div> 
</div>
<div className="row inputbox-height">
<div className="input-field col s6">
<input name="custom_factor" id="custom_factor" type="number" className=""
value={this.state.custom_factor} onChange={this.handleFormChange}
/>

<span className="helper-text" data-error="wrong" data-success="">Calculated Customs Factor</span>

</div>
<div className="input-field col s6">

</div> 
</div>
{
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }

                      
</form>

</div>
</Col>
{/* right side column starts here */}
<Col xs={12}md={12} lg={12}>


     {/*<CustomerDetailCard 
     info = {this.state.customerinfo}
      />*/}

 <Row>
 <Col>
 <h4>Product Details</h4>
 </Col>
 {/* add product column for future use */}
 <Col>

{/*{
  this.state.orderStatusSelected != 'SP' && this.state.orderStatusSelected != 'NEW' && this.state.orderStatusSelected != 'DISC'
  ? <Nav className="right">
      <Nav.Link className="red-text modal-trigger " href="#addProductModal">
        Add<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg" /></Nav.Link>
    </Nav>  
  :""

}*/}

 
 </Col>

</Row>
{/* product card to be shown here */}
{/* <div className="productlistEditOrderDiv">{this.state.productlisthtml}</div> */}
<div className="productlistDiv ">
  <ProductItemsList orderid={this.state.orderid} products={this.state.productlist} rowclick={this.handletableClick}
    order_status = {this.state.orderStatusSelected}
  />
  </div>
                    {/*<div className="right"><b>Total Price1: </b>$
                    {
                    ( ()=>{
                      var totalprice =0;
                      this.state.productlist.forEach(function(item){
                        totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                      })
                      return parseFloat(totalprice).toFixed(2)
                    })()
                      
                      }
</div>*/}
</Col>

{/* right side columns ends here */}

</Row> 
        
         
     
            </div>
            <div className="modal-footer">
           
            <ButtonGroup className="mr-2" aria-label="First group">
            <Button id="closeEditOrderBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button> 
            {
              this.state.orderStatusSelected != 'SP' && this.state.orderStatusSelected != 'NEW' && this.state.orderStatusSelected != 'DISC'
              ?<Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderDB}> Update</Button>
              :""
            }
            
        
          </ButtonGroup>
            </div>
          </div>



          {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addProductModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Product </h4>
            <Row>
              <Col xs={12} >
                <form>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name" id="product_name" type="text" value={this.state.product_name} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_name" className="black-text" >Product Name</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter a valid Product Name</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_quantity" className="black-text">Quantity</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter number of products items</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_price" disabled={this.state.orderStatusSelected === "IM"} id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_price" className="black-text">Product Price</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter numbers</span>
                      </div>
                    </Col>
                  </Row>


                  <Row>
                    <Col>

                      <div id="categroydiv" className="input-field col s6 offset-s3 center">
                        {/* <p>Select Category</p> */}
                        <select id='category_name_select' name="product_category" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Category</option>

                          {categoryList}

                        </select>
                        {/* <label>Select Category</label> */}

                      </div>


                    </Col>

                  </Row>


                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" >
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
              <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addProductTolist}> Add</Button>

            </ButtonGroup>

          </div>
        </div>

        {/* Model for adding produts ENDS here */}            


              </>
        )
    }
}

export default EditOrder;
