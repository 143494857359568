import React, {useState,Component,} from 'react';
import {Redirect, useHistory } from 'react-router-dom';
import {Card,Button,ButtonGroup,Nav,Image,Row,Col,Container,Spinner} from 'react-bootstrap';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard';
import OrderModal from './OrderSelectionModal';
import EditProduct from './EditProductItem';
import "./createorder.css";
import { base_url } from '../../globalConstants';
import { subject } from '../../service'


class CustomerDetailCard extends Component{

    constructor(props){
        super(props);
        
  this.state = ({title:"***",name:"********",
              email:"**********",address:"********",phone:"********",country:"********"

              })

    }

}



class CreateRole extends Component{
    constructor(props){
        super(props);
     
        var today = new Date();
       

         
          var month = '' + (today.getMonth() + 1)
            var   day = '' + today.getDate()
           var    year = today.getFullYear()
        
           if (month.length < 2) {
             month = '0' + month;
           }
            
           if (day.length < 2) {
             day = '0' + day;
           }
           
        
           let date = [year, month, day].join('-');


        this.state = ({
          
          Lang: false,
          current_date: date,
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :[],
            product_edit_category :[],
            product_category:"",ordernumber:"",
         customer_id:"",order_date:date,
        received_date:"",tracking_number:"",shiper_order_number:"",inv_on:date,
        weight:0,height:0,fedex_charge:0,width:0,length:0,actual_charge:0,homedelivery_charge:0,promo_type:"Not",promo_value:0,processing_fee:0,brokerage_charge:0,insurance_charge:0,
        Weight_type:null,
          orderStatusSelected:"YTA",
          paymentTypeSelected:"card",showSpinner:false,
          customer_selected_data:{},
          pendingOrders:[],ordersSelectedInModal:[],
          createNewOrder:true,holdmergedorderstatus:"RTM",
          ordernumbermerge:"",
          error:"",
          rowNumber:"",
          selectItemDetails:{},
      
          generatedOrderId: "",
          isOrderUpdate: false,
          customer_email: "",
          serachInput: '',
          searchResult:''
      
          
          })

    }


    handleFormChange= async (ev) =>{
     
      const target = ev.target
      const name = target.name
     
          this.setState({
            [name]: target.value
          });
      
    }
  
  
    cancelOrder = ()=>{
        // @TODO fucntionality not clear yet
        // M.toast({html: 'Pending for Implemention',classes:"white-text black rounded"})
        // return (<Redirect to="/Admin"/>
        window.location.reload();
   }

createOrderNormal = async () => {
  var markedCheckbox = document.querySelectorAll('input[type="checkbox"]:checked');
  var scheck = "";
for (var checkbox of markedCheckbox) {
  if (scheck.length == 0)
  {
  scheck = checkbox.value;
}
else
{
  scheck = scheck + ',' + checkbox.value;
}
  
}
//this.state.shiper_order_number = scheck;
    this.setState({isOrderUpdate: true})
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    console.log("create new order" + this.state.createNewOrder)

    if (this.state.createNewOrder) {
      var Orderformdata = new FormData()
      Orderformdata.append("name", this.state.received_date)
      Orderformdata.append("formid", scheck)
      Orderformdata.append("status", "Active")
      Orderformdata.append("flag", "1")
      try {

        const response = await Axios.post(base_url+"roledetails/", Orderformdata,
          header)

        const orderid = response.data.id



        
        this.setState({ error: <Success message=" New order foproduct items created" /> })
        M.toast({ html: 'Order created successfully', classes: "white-text orange rounded" })
        this.setState({ error: "" })
        this.setState({ generatedOrderId: orderid })
        console.log('generatedOrderId',this.state.generatedOrderId);
 
      } catch (err) {

        M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

        console.log(err)
        this.setState({ error: <Error /> })
      }




    } 
    // else {


    //   try {
    //     let token = localStorage.getItem("token")
    //     const header = {
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Token ' + token,

    //     }
    //     var orderupdateForm = new FormData()
    //     orderupdateForm.append("shipper_number", this.state.shiper_order_number)
    //     orderupdateForm.append("billing_type", this.state.paymentTypeSelected)
    //     orderupdateForm.append("order_date", this.state.order_date)
    //     orderupdateForm.append("received_date", this.state.received_date)
    //     orderupdateForm.append("inbound_tracking_number", this.state.tracking_number)
    //     orderupdateForm.append("weight", this.state.weight)
    //     orderupdateForm.append("length", this.state.length)
    //     orderupdateForm.append("breadth", this.state.width)
    //     orderupdateForm.append("height", this.state.height)
    //     // orderupdateForm.append("charge_type","VAT")
    //     orderupdateForm.append("freight_charge", this.state.fedex_charge)
    //     orderupdateForm.append("actual_charge", this.state.actual_charge)
    //     orderupdateForm.append("current_status", this.state.orderStatusSelected)
    //     let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
    //     const response = await Axios.patch(urlforOrderStatusUpdate, orderupdateForm, header)

    //     console.log(`order status update succesfully ${response.data}`)
    //     this.setState({ error: <Success message=" Order Status Updated" /> })
    //     M.toast({ html: 'Updated SUccessfull', classes: "white-text orange rounded" });
    //   } catch (error) {
    //     M.toast({ html: 'Updation Failed', classes: "white-text red rounded" });
    //     console.log(error)
    //     this.setState({ error: <Error /> })
    //   }

    // }
    
    document.getElementById('saveOrderBtn').disabled = true

  }
   addOrderToDB = async ()=>{
              let token =localStorage.getItem("token")
              const  header = {'Content-Type': 'application/json',
              'Authorization':'Token '+token,
            
              }
            
                  this.createOrderNormal()
                  var btn = document.getElementById('createrole')
                  btn.click()

  }


  updateOrderDB= async(header)=>{
    const  orderid= this.state.generated_orderId

      try{
          
          var orderupdateForm = new FormData()
          // orderupdateForm.append("order_id",this.state.generated_orderId)
          orderupdateForm.append("inv_number","INV-" + this.state.customer_id)
          orderupdateForm.append("inv_date",this.state.order_date)
          orderupdateForm.append("inv_conv_rate",this.state.received_date)
          orderupdateForm.append("inv_freight",this.state.tracking_number)
          orderupdateForm.append("inv_taxes",this.state.shiper_order_number)
          orderupdateForm.append("inv_on",this.state.inv_on)
          
          let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.customer_id}/`
          const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
    
          var boxid = this.state.customer_data[this.state.customer_id].box_id

          const emaildata = {
            "order_id": this.state.customer_id,
            "box_id": boxid
          }
          Axios({
              method: 'post',
              url: base_url+'create_cashinvoice/',
              data: emaildata,
            }).then(
              function (response) {
              // console.log(response)
              
              console.log("Role Saved done");
              M.toast({ html: 'Role Saved!', classes: "white-text green rounded" });
            }).catch(
              function (response) {
              // console.log(response)
              console.log("Role Saved Failure");
              M.toast({ html: 'Invoice creation failed to' + this.state.customer_id, classes: "white-text red rounded" });
            }) 
          // console.log(`order status update succesfully ${response.data}`)
          this.setState({error:<Success message=" Role saved successfully!"/>})
          this.setState({ generatedOrderId: "INV-" + this.state.customer_id })
          M.toast({html: 'Role Saved Successfull',classes:"white-text orange rounded"});
  
  
        }catch(error){
          M.toast({html: 'Role Saved Failed',classes:"white-text red rounded"});
            console.log(error)
            this.setState({error:<Error/>})
        }
  
  }
  
  updateOrder=()=>{
      let token =localStorage.getItem("token")
      const  header = {'Content-Type': 'application/json',
      'Authorization':'Token '+token,
      }
      this.updateOrderDB(header)
  
  
  }

  //--UPDATE ORDER END


render(){
  
  const languageList = [
  { value: "1", label: "Create Order" },
  { value: "2", label: "Order List" },
  { value: "3", label: "Charge List" },
  { value: "4", label: "Box List" },
  { value: "5", label: "Custom Settlement" },
  { value: "6", label: "Shipped" },
  { value: "7", label: "Referral" },
  { value: "8", label: "Reconcillation" },
  { value: "9", label: "Create Coupon" },
  { value: "10", label: "View Coupon" },
  { value: "17", label: "Curacao Customs List" },
  { value: "11", label: "Create Cash Invoice" },
  { value: "12", label: "Cash Invoice List" },
  { value: "18", label: "Cash Invoice Report" },
  { value: "13", label: "Create Role" },
  { value: "14", label: "Role List" },
  { value: "15", label: "Reports" },
  { value: "16", label: "Pending Orders" },
  { value: "19", label: "Shipping Rates" },
  { value: "20", label: "Create Shipment" },
  { value: "21", label: "View Shipment" },
  { value: "22", label: "Dashboard" }
];

// const listData = [
//     { id: "1", value: "Javascript" },
//     { id: "2", value: "Python" },
//     { id: "3", value: "Java" },
//     { id: "4", value: "Kotlin" },
//     { id: "5", value: "Dart" },
//     { id: "6", value: "C#" },
//   ];

  const opts={};
   opts['readOnly'] = 'readOnly';
  const catlist = this.state.product_category_options;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
      )
    }, this);
  }
  

 const selecItem = [];
  const handleChange = e => {
  //   this.setState({
  //   Lang: e.target.checked
  //  })
    //alert(e.target.checked);
  if (e.target.checked)
    {
    selecItem.push(e.target.value);
  }
  else
  {
    for( var i = 0; i < selecItem.length; i++){ 
                                   
        if ( selecItem[i] === e.target.value) { 
            selecItem.splice(i, 1); 
            i--; 
        }
    }
  }
  console.log("arra:" + selecItem.toString());
  // this.setState({shiper_order_number: selecItem.toString()});
  // console.log("set state form list:" + this.state.shiper_order_number);
    //alert(selecItem.toString());
    // const { value, checked } = e.target;
    // if (checked) {
      
    //   // push selected value in list
    //   //setLang(prev => [...prev, value]);
    // } else {
    //   // remove unchecked value from the list
    //   //setLang(prev => prev.filter(x => x !== value));
    // }
  }
    return(
        <Container>
        
          {/*<div className='title'>Select languages from the list</div>
      {languageList.map((x, i) => <Row key={i}>
      <label><Col><div className="row"><div className="col s3"><input
          type="checkbox"
          name="lang"
          
          value={x.value}
          onChange={handleChange}
        /> <span className="orange-text">{x.label}</span></div></div>
      </Col></label></Row>)}*/}
          
                                        
                                        {/*<input type="checkbox"  id="statecash" name="statecash" value="False"  />
                                        <span className="orange-text">Cash</span>*/}
        

<div className="row">
          <div className="col s3">
          <h4 className=" orange-text">Create Role</h4>
          </div>
       
</div>
<div className="row inputbox-height">
        <div className="input-field col s12">
<div className="input-field col s6">
          <input name="received_date" id="received_date" type="text" className=""
           value={this.state.received_date}  onChange={this.handleFormChange}
          />
          <label htmlFor="received_date">Enter Role</label>
          <span className="helper-text" data-error="wrong" data-success="">Define role name ex. admin</span>

        </div>
</div>
      
      </div>

       <div className="row">
          <div className="col s3">  
<div className="container">
      <div className="card">
        <div className="card-header">
          <p className="title">Select Menu </p>
        </div>
 
        
 
        <div className="card-body">
          {languageList.map((item, index) => {
            return (
              <div key={item.id} className="checkbox-container">
                <label><input
                  type="checkbox"
                  name="languages"
                  value={item.value}
                  onChange={handleChange}
                />
                <span>{item.label}</span></label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
</div>
</div>

    
          <Row>
            <Col>
              <div className="center">
                <ButtonGroup aria-label="create order buttons" className="">
                  <Button id="cancelOrderBtn"onClick={this.cancelOrder} variant="secondary"  className="">Cancel</Button>
                  <Button className="ml-5 " id="saveOrderBtn" onClick={this.addOrderToDB} variant="primary"  >Save</Button>
                  {/*<Button className="ml-5 white-text" id="updateOrder" variant="success" onClick={this.updateOrder} >Update</Button>*/}                        
                  {/*<Button className="ml-5 white-text" id="printLabel" variant="warning"  onClick={this.printLabelManually}>Print Invoice</Button>                        */}
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>


    )
}

}

export default CreateRole;
