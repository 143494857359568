import React, {Component,} from 'react';
import {Redirect, useHistory } from 'react-router-dom';
import {Card,Button,ButtonGroup,Nav,Image,Row,Col,Container,Spinner} from 'react-bootstrap';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard';
import OrderModalCash from './OrderSelectionModalCash';
import EditProduct from './EditProductItem';
import "./createorder.css";
import { base_url } from '../../globalConstants';
import { subject } from '../../service'


class CustomerDetailCard extends Component{

    constructor(props){
        super(props);
        
  this.state = ({title:"***",name:"********",
              email:"**********",address:"********",phone:"********",country:"********"

              })

    }

    componentDidUpdate(prevProps) {

      if (this.props.info.ezz_id !== prevProps.info.ezz_id || this.props.info.email !== prevProps.info.email ) {
        
         this.setState({title:"",name:this.props.info.customer_name,
            email:this.props.info.customer_email,address: this.props.info.ezz_id,phone:this.props.info.customer_phone_number,country:this.props.info.country
        
        })

      }



    }

    render(){ 
        return(
            <Row>
            <Col>
            
			
            <Card className="" id="customerbasicdetailsCard">
        <Card.Body>
            <Card.Text>
                <Row>
                    <Col xs={8}>
        <h6 >Name:</h6><p className="inline">{this.state.title + " " + this.state.name}</p>
                    </Col>
                    <Col className="">
                    <Image src="assets/images/avatar-big.svg"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <h6 >EZZ ID:</h6><p>{this.state.address}</p>
                    </Col>
                    <Col xs={4} className="right">
                    <h6 >Phone:</h6><p>{this.state.phone}</p>
                    </Col>
                    <Col xs = {8} className="">
                    <h6 >Email:</h6><p>{this.state.email}</p>
                    </Col>
                    <Col xs = {4} className="right">
                      <h6>Country:</h6><p>{this.state.country}</p></Col>
                </Row>
               
            
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

        )
    }
}



class CreateOrder extends Component{
    constructor(props){
        super(props);
     
        var today = new Date();
       

         
          var month = '' + (today.getMonth() + 1)
            var   day = '' + today.getDate()
           var    year = today.getFullYear()
        
           if (month.length < 2) {
             month = '0' + month;
           }
            
           if (day.length < 2) {
             day = '0' + day;
           }
           
        
           let date = [year, month, day].join('-');


        this.state = ({
          resultset:[],
          current_date: date,
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :[],
            product_edit_category :[],
            product_category:"",ordernumber:"",
         customer_id:"",order_date:date,box_id:0,
         phone_id:"",handling_fee:0,homedelivery_fee:0,insurance_fee:0,processing_fee:0,
        received_date:"",tracking_number:"",shiper_order_number:"",inv_on:date,
        weight:0,height:0,fedex_charge:0,width:0,length:0,actual_charge:0,homedelivery_charge:0,promo_type:"Not",promo_value:0,processing_fee:0,brokerage_charge:0,insurance_charge:0,
        Weight_type:null,
          orderStatusSelected:"YTA",
          paymentTypeSelected:"card",showSpinner:false,
          customer_selected_data:{},
          pendingOrders:[],ordersSelectedInModal:[],
          createNewOrder:true,holdmergedorderstatus:"RTM",
          ordernumbermerge:"",
          error:"",
          rowNumber:"",
          selectItemDetails:{},
      
          generatedOrderId: "",
          isOrderUpdate: false,
          customer_email: "",
          serachInput: '',
          searchResult:''
      
          
          })

    }



    openOrderModel = ()=>{
      this.refs.ordermodelcash.openModal();
    }


    // showSelectedModalOrders=(orderselected)=>{
    //   // console.log(`order selected ${orderselected}`)
    //   this.setState({ordersSelectedInModal:orderselected})
    // }


//     getCategories = async (countryid)=>{

//       let token =localStorage.getItem("token")
//       let header = {'Content-Type': 'multipart/form-data',
//       'Authorization':'Token '+token,

//       }

//       var url= base_url+`categories/?country=${countryid}`
//       var categories = await Axios.get(url,header)
//       // console.log(categories.data)
      
     

//       await this.setState({product_category_options:categories.data})
//       await this.setState({product_edit_category:categories.data})

//     }

    getConversionRate = async (ezzid)=>{
//alert(ezzid)
      let token =localStorage.getItem("token")
      let header = {'Content-Type': 'multipart/form-data',
      'Authorization':'Token '+token,

      }
      //this.setState({received_date:''})
      var url= base_url+`get_conversion_rate/?ezz_id=${ezzid}`
      var categories = await Axios.get(url,header)
      console.log("convrate" ,categories.data)
      //alert(categories.data.Rate)
      await this.setState({received_date:categories.data.Rate})
     //this.state.received_date

      // await this.setState({product_category_options:categories.data})
      // await this.setState({product_edit_category:categories.data})

    }

getPendingOrder= async (customerid,orderstatus)=>{
try{
  let token =localStorage.getItem("token")
  let header = {'Content-Type': 'multipart/form-data',
  'Authorization':'Token '+token,

  }
  //var url= base_url+`boxes_received/?search=${this.state.searchInput}`
  //var url = base_url + `boxes_received/?ezz_id=${customerid}&current_status=${orderstatus}`
  var url = base_url + `getPendCashOrders?ezz_id=${customerid}`
  //var url= base_url+`products/?ezz_id=${customerid}&status=${orderstatus}`
  let orders = await Axios.get(url,header)
  // console.log(orders.data)
  //await this.setState({pendingOrders: this.state.pendingOrders.concat(orders.data.results)})
  await this.setState({pendingOrders: this.state.pendingOrders.concat(orders.data)})
  // M.toast({html: 'Pending Ordersa fetched',classes:"white-text orange rounded"});
  this.setState({error:""})
}catch(error){
  console.log(error)
  M.toast({html: 'Pending Ordersa cannot be fetched',classes:"white-text red rounded"});
  this.setState({error:<Error/>})
}

}

// handletableClick=(clickedrow)=>{
//   //console.log(clickedrow)
//  // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
//  let productdetail = this.state.productlist[clickedrow]
//  let countryoptions = this.state.product_category_options
//  //console.log("handle cat",countryoptions)
//  //console.log("handle cat",this.state.product_edit_category)
//  this.setState({rowNumber:clickedrow})
//  this.setState({selectItemDetails:productdetail})

 
//    this.refs.editproduct.openModal(clickedrow,productdetail,countryoptions);
// }

// updateProduct=(rownumber,item)=>{
//   console.log("updatig list",rownumber,item)
// let productlist = this.state.productlist
// productlist.splice(rownumber, 1, item)
// this.setState({productlist:productlist})
// // this.refs.editproduct.closeModal();
// // M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});

// }

// deleteProduct = () => {
//   console.log('YEAH WE WILL WORK ON THIS');
// }

 handleCustomerIDChangeGen = async (ev)=>{  
   //alert("tes")     
      const target = ev.target;
      const name = target.name;
//alert(name)
      let search_input = target.value
      var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    // }      
    // handleCustomerInfo = async e => {  
      // if(e.key !== 'Enter'){
      //   return
      // }

      try{
      
        let token =localStorage.getItem("token")
        let header = {'Content-Type': 'multipart/form-data',
        'Authorization':'Token '+token,

        }
        this.setState({received_date:''})
        // Fetching user list on input change
        // var url= base_url+`customers/?ezz_id__icontains=${target.value}`
        // var url= base_url+`customers/?ezz_id=&ezz_id__icontains=${target.value}&email__icontains=${target.value}&phone_number=`

        var url
        var searchType

        if(this.state.searchResult){
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=${this.state.searchInput}&email__icontains=&phone_number__icontains=`
          searchType = "ezzid"
        }        
        else{
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=&email__icontains=&phone_number__icontains=${this.state.searchInput}`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,header)
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"
        // DEFAULT WORKING EZZID
        // for (let customer of customer_data.data) {
        //   ezzidlist[customer.ezz_id] = customer.email;
        //   customerdataObject[customer.ezz_id] = customer;
        // }

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.ezz_id] = customer.phone_number;
            customerdataObject[customer.ezz_id] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.phone_number] = customer.ezz_id;
            var tempEzId = ezzidlist[customer.phone_number]
            customerdataObject[tempEzId] = customer;

          }
        }     

        // console.log(ezzidlist)
        // console.log(customerdataObject)
        this.setState({box_id:0}) 
        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        // /await context.setState({pendingOrders:[]})
        const elems_auto_customer = document.getElementById('phone_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {
            // context.setState({customer_id:select_customer})                // DEFAULT WORKING EZZID

            // context.setState({customer_email: select_customer})

            // console.log("select_customer",select_customer);

            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({phone_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({phone_id:tempEzzId})                
            }            
        
            // DEFAULT WORKING EZZID
            // context.setState({customer_selected_data:customerdataObject[select_customer]})
            // await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[select_customer].country)
            //       await context.getPendingOrder(select_customer,"IM")
            //        await context.getPendingOrder(select_customer,"YTA")





            context.setState({customer_selected_data:customerdataObject[tempEzzId]})
            await context.setState({pendingOrders:[]})
           context.getConversionRate(tempEzzId)
            //context.getCategories(customerdataObject[tempEzzId].country)
                  //await context.getPendingOrder(tempEzzId,"IM")
                   await context.getPendingOrder(tempEzzId,"NEW")
           
           context.setState({shippingTypeSelected:customerdataObject[tempEzzId].shipping_method.toString()})







            //context.set
         
            // @TODO show model only when there is any orders pending 
            //like IM or BoX YET to arrive
            if (context.state.pendingOrders.length >0){
              context.openOrderModel();
            }
            // console.log("selected cust ID AUTH", customerdataObject[select_customer].card_authorize  )
           
            const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize
			

            if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )   {       
              document.getElementById("errCustomerNotAuthorized").style.display = "none"
				//this.setState({card_not_authorized:true}) 
				//context.setState({card_not_authorized:true}) 				
			}
            else  {
              document.getElementById("errCustomerNotAuthorized").style.display = "none"
			}
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        this.setState({error:<Error/>})
      }

    }

    handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    // }      
    // handleCustomerInfo = async e => {  
      // if(e.key !== 'Enter'){
      //   return
      // }

      try{
      
        let token =localStorage.getItem("token")
        let header = {'Content-Type': 'multipart/form-data',
        'Authorization':'Token '+token,

        }
        this.setState({received_date:''})
        // Fetching user list on input change
        // var url= base_url+`customers/?ezz_id__icontains=${target.value}`
        // var url= base_url+`customers/?ezz_id=&ezz_id__icontains=${target.value}&email__icontains=${target.value}&phone_number=`

        var url
        var searchType

        if(this.state.searchResult){
          return
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=${this.state.searchInput}&email__icontains=&phone_number=`
          searchType = "ezzid"
        }        
        else{
          //url= base_url+`customers/?ezz_id=&ezz_id__icontains=&email__icontains=${this.state.searchInput}&phone_number=`
          url= base_url+`boxes_received/?search=${this.state.searchInput}`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,header)
        this.state.resultset = customer_data.data.results
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"
        // DEFAULT WORKING EZZID
        // for (let customer of customer_data.data) {
        //   ezzidlist[customer.ezz_id] = customer.email;
        //   customerdataObject[customer.ezz_id] = customer;
        // }

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.ezz_id] = customer.email;
            customerdataObject[customer.ezz_id] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            if (customer.inv_number == null || customer.inv_number == "")
            {
              if (customer.current_status_name == "New")
              {
                ezzidlist[customer.order_id] = customer.order_id;
                var tempEzId = ezzidlist[customer.order_id]
                customerdataObject[tempEzId] = customer;
              }
            }

          }
        }     

        // console.log(ezzidlist)
        // console.log(customerdataObject)
        this.setState({box_id:0})
        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        // /await context.setState({pendingOrders:[]})
        const elems_auto_customer = document.getElementById('customer_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {
            // context.setState({customer_id:select_customer})                // DEFAULT WORKING EZZID

            // context.setState({customer_email: select_customer})

            // console.log("select_customer",select_customer);

            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({customer_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({customer_id:tempEzzId}) 
              //alert(customerdataObject[tempEzzId].box_id)               
            }            
            
            for (let customer of context.state.resultset) {
              if (customer.order_id == tempEzzId)
              {
                //alert(customer.actual_charge);
                context.setState({tracking_number:customer.actual_charge})
                context.setState({insurance_fee:customer.insurance})
                context.setState({processing_fee:customer.processing})
                context.setState({handling_fee:customer.brokerage})
                context.getConversionRate(customer.ezz_id)
              }
             }
            // DEFAULT WORKING EZZID
            // context.setState({customer_selected_data:customerdataObject[select_customer]})
            // await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[select_customer].country)
            //       await context.getPendingOrder(select_customer,"IM")
            //        await context.getPendingOrder(select_customer,"YTA")





            context.setState({customer_selected_data:customerdataObject[tempEzzId]})
             
            // await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[tempEzzId].country)
            //       await context.getPendingOrder(tempEzzId,"IM")
            //        await context.getPendingOrder(tempEzzId,"YTA")
           
           







            //context.set
         
            // @TODO show model only when there is any orders pending 
            //like IM or BoX YET to arrive
            // if (context.state.pendingOrders.length >0){
            //   context.openOrderModel();
            // }
            // console.log("selected cust ID AUTH", customerdataObject[select_customer].card_authorize  )
           
            const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize

            if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )          
              document.getElementById("errCustomerNotAuthorized").style.display = "none"
            else  
              document.getElementById("errCustomerNotAuthorized").style.display = "none"
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        this.setState({error:<Error/>})
      }

    }
  //  fillFormAutomatically=(data)=>{
  //   //  console.log(`fill auto maticaly ${data}`)
  //   this.setState({
  //     ordernumber: data.order_id,
  //     order_date: data.order_date,
  //     received_date: data.received_date,
  //     tracking_number: data.inbound_tracking_number,
  //     shiper_order_number : data.shipper_number,
  //     weight: data.weight,
  //     height: data.height,
  //     fedex_charge: data.freight_charge,
  //     width: data.breadth,
  //     length: data.length,
  //     actual_charge: data.actual_charge,
  //     orderStatusSelected: data.current_status,
  //     paymentTypeSelected: data.billing_type,

      
  //   });
    
  
  // }

//   getfedexActualRate = async (weight,height,length,breadth)=>{
    


//     try{
//   let token =localStorage.getItem("token")


//   var config = {
//     headers: { 
//       'Content-Type': 'multipart/form-data' , 
//       'Authorization':'Token '+token},
    
//   };
  

// //console.log(this.state.order_date);
//   // Fetching user list on input change
//   //var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
//   var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}`
//   console.log(url)
//   var response = await Axios.get(url,config)
//   console.log(response.data)
//   return response.data
// }catch(error){

//   console.log(error.response.data)
  
//    if (error.response.status ===503 ){
//     M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
//    }
//    if (error.response.status === 403 ){
//     M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
//    }
   
  
//   // return response.data
//   return error.response.data
// }



//   }

//  changeTo= (e) =>{

//           switch (e.target.name) {
//             case "weight":
//               this.setState({weight: e.target.value})
//               break;
//             case "height" :
//               this.setState({height: e.target.value})
//               break;
//             case "width" :
//               this.setState({width: e.target.value})
//               break;
//             case "length" :
//               this.setState({length: e.target.value})
//               break;
//           }
//                 //this.setState({weight: e.target.value})
//                  //// console.log("is change to");
//                   console.log(e.target.value);
//                   this.handleFormChange(e);
//       }

//       updateValue= (e) =>{

//           switch (e.target.name) {
//             case "weight":
//               this.setState({weight: e.target.value})
//               break;
//             case "height" :
//               this.setState({height: e.target.value})
//               break;
//             case "width" :
//               this.setState({width: e.target.value})
//               break;
//             case "length" :
//               this.setState({length: e.target.value})
//               break;
//           }
//                    //this.setState({weight: e.target.value})
//                   //console.log("is updtevalue");
//                   //console.log(e.target.value);
          
//       }


    handleFormChange= async (ev) =>{
     
      const target = ev.target
      const name = target.name
      // // console.log(ev.target.name)
      // // console.log(ev.target.value)
     
      // // hide and show on YTA
      // if (target.value === "YTA"){
      //   document.getElementById("received_date").disabled = true;
      //   this.setState({
      //     [name]: target.value,
      //     weight: 0,
      //     height:0,
      //     fedex_charge:0,
      //     width:0,
      //     length:0,
      //     actual_charge:0,
      //     homedelivery_charge:0,
		  //     processing_fee:0,
		  //     brokerage_charge:0,
		  //     insurance_charge:0,
      //     promo_type:"Not",
      //     promo_value:0
      //   });
      // }else{
      //   document.getElementById("received_date").disabled = false;
//         if(target.name ==="weight" || 
//         target.name ==="height" || target.name === "width"
//       || target.name === "length"
//         ){

//         this.setState({
//             [name]: target.value
//           });

//           var weight = this.state.weight
//           var width = this.state.width
//           var height = this.state.height
//           var length = this.state.length
//           var field = target.name;

//           switch (field) {
//             case "weight":
//               weight = target.value;
//               break;
//             case "height" :
//               height = target.value;
//               break;
//             case "width" :
//               width = target.value;
//               break;
//             case "length" :
//               length = target.value;
//               break;
              
           
//           }
// if(width <= 0 || height <=0 || weight <= 0 || length <=0 || this.state.actual_charge <0){
//   M.toast({html: "Dimension values can not be 0/negative.",classes:"white-text green rounded"});
//   return;
// }

//           if((width.toString()!=="" && width!==0) && (height.toString() !==""&& height!==0) && 
//           (weight.toString() !==""&& weight!==0) &&( length.toString()!==""&& length !==0)){
//             await this.setState({
//               showSpinner:true
//             })
//             var response =  await this.getfedexActualRate(weight,height,length,width)
//             console.log(response["freight_charges"] + response["actual_charges"])
//               this.setState({
//                 fedex_charge:response["freight_charges"],
//                 actual_charge: response["actual_charges"],
//                 Weight_type:response["Weight_type"],
//                 homedelivery_charge: response["homedelivery_charge"],
// 				insurance_charge: response["insurance"],
// 				processing_fee: response["processing"],
// 				brokerage_charge: response["brokerage"],
// 				promo_type: response["promo_type"],
//                 promo_value: response["promo_value"],
//                 showSpinner:false
//               })
//           }
         
//         }else{
          this.setState({
            [name]: target.value
          });
        //}

       
     
     
     
     // }
   
     
    }
    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

    componentDidMount(){
      
        M.AutoInit();




        //document.getElementById("received_date").disabled = false;
        console.log("is thissssssss working");
        

        // var elems_recieved = document.getElementById('received_date');
        var elems = document.querySelectorAll('.modal');
        let model_options = {
          dismissible:false,
        }
        var instances = M.Modal.init(elems, model_options);
        
        var elems_auto_customer = document.querySelectorAll('.autocomplete');

        var options_auto_customer={
      
          data: {}, 
          limit:20,
          onAutocomplete :function(select_customer) {
           
            // context.setState({ order_date: selecteddata});
            //  console.log("slect autocomplete",select_customer); 
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        } 
        var instances_auto = M.Autocomplete.init(elems_auto_customer, options_auto_customer);
      

         
        var context = this;

       var newdate = this.newDate()
      //  console.log('new date',newdate)
        var elems_order = document.getElementById('order_date');
      
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              // console.log(context.formatDate(date))
               let selecteddata = context.formatDate(date)
              // console.log('selectedate',selecteddata)
              context.setState({ order_date: context.formatDate(selecteddata)})
              // console.log("order_date",selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_order, options_order);
        // var elems_recieved = document.getElementById('received_date');
        // elems_recieved.disabled = true;  //220223

        // var options_recieved={
        //     //selectMonths: true, // Enable Month Selection
        //     selectYears: 1, // Creates a dropdown of 10 years to control year
        //     format:'yyyy-mm-dd',
        //     autoClose :true,
        //     defaultDate:newdate,
        //     setDefaultDate :true,
        //     onSelect: function(date) {
        //       let selecteddata = context.formatDate(date)
        //       context.setState({ received_date: selecteddata.toString()});
        //       //  console.log("recived_datae"+ selecteddata); // Selected date is logged
        //     },
        //   }
        // var instances = M.Datepicker.init(elems_recieved, options_recieved);
        // instances.toString()
        
    
    }


// @TODO: handlechange for create order is pending Please implement it


    //  addProductTolist = ()=>{
    //   // console.log(this.state.productname + this.state.productprice +this.state.productquantity)
    //    var prodname =this.state.product_name
    //    var prodquant =this.state.product_quantity
    //    var prodcat =this.state.product_category
    //   //  console.log("prody")
    //   //  console.log(prodname + prodquant+prodcat )

    //    if(prodname === "" || prodquant === 0 || prodcat ===""){
    //     M.toast({html: 'Incorrect or Empty details',classes:"white-text red rounded"});
    //    }else{
    //     var elem = document.getElementById('addProductModal')
    //     var instance = M.Modal.getInstance(elem);
    //     instance.close();
    //     let totalproducts = this.state.productlist.length + 1
        
    //     let newitem = {id:'item_'+totalproducts,productName:this.state.product_name,
    //     productPrice:this.state.product_price ,
    //     productQuantity:this.state.product_quantity,
    //   productCategory:this.state.product_category
    //   }

    //     // console.log("Product List",this.state.productlist)
    //    this.state.productlist.push(newitem)
    //     this.setState({productlist: this.state.productlist})
        
    //   try {
    //     // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
    //     this.setState({product_name:"",product_price:0,product_quantity:0 })
    //    M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
    //   } catch (error) {
    //     M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
    //   }
       
    
    //    }

    // }

  
    cancelOrder = ()=>{
        // @TODO fucntionality not clear yet
        // M.toast({html: 'Pending for Implemention',classes:"white-text black rounded"})
        // return (<Redirect to="/Admin"/>
        window.location.reload();
   }


// handleMergeCheckbox= (event)=>{
//   // event.target.checked
//   // console.log(this.state.holdmergedorderstatus)
//   // console.log(event.target.checked)
//   const name = event.target.name
//   if (event.target.checked){
//     this.setState({[name]:"WFC"})
//   }else{
//     this.setState({[name]:"RTM"})
//   }
// }

// mergeOrder=()=>{
  
//   // var elems = document.getElementById('merge_order_model');
//   // var instance = M.Modal.init(elems);
//   // instance.close();
//   this.createOrderMerged();



// }
// mergerOrderCancel = ()=>{



//   // var elems = document.getElementById('merge_order_model');
//   // var instance = M.Modal.init(elems);
//   // instance.close();
//   this.createOrderNormal();







// }


//   createOrderMerged = async () => {
//     let token = localStorage.getItem("token")
//     const header = {
//       'Content-Type': 'application/json',
//       'Authorization': 'Token ' + token,

//     }

//     if (this.state.createNewOrder) {

//       // new order creation
//       console.log("Deleting previous orders if any")
//       this.state.productlist.forEach(async (item) => {
//         console.log("productlist Merged order-----")
//         // console.log(item)
//         try {

//           let urlfordeletion = base_url+`products/${item.id}/`
//           const response = await Axios.post(urlfordeletion,
//             header)
//           // console.log(`deletion response ${response.data}`)
//           console.log("Product delete success")
//           M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
//         } catch (error) {
//           M.toast({ html: 'No old products found', classes: "white-text green rounded" });
//           console.log('error in deletion',error)
//         }

//       });



//       const orderid = this.state.ordernumber
//       const existing_order = this.state.ordernumbermerge
//       // this.setState({ordernumber:orderid})

//       var current_status = this.state.holdmergedorderstatus
//       var Orderformdata = new FormData()
//       console.log("orderid" + orderid)
//       console.log(this.state.fedex_charge)
//       console.log(this.state.actual_charge)
//       Orderformdata.append("order_id", orderid.toString())
//       Orderformdata.append("ezz_id", this.state.customer_id)
//       Orderformdata.append("shipper_number", this.state.shiper_order_number)
//       Orderformdata.append("billing_id", "2121")
//       Orderformdata.append("billing_type", this.state.paymentTypeSelected)
//       Orderformdata.append("order_date", this.state.order_date)
//       Orderformdata.append("received_date", this.state.received_date)
//       Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
//       Orderformdata.append("weight", this.state.weight)
//       Orderformdata.append("length", this.state.length)
//       Orderformdata.append("breadth", this.state.width)
//       Orderformdata.append("height", this.state.height)
//       Orderformdata.append("charge_type", "VAT")
//       Orderformdata.append("freight_charge", this.state.fedex_charge)
//       Orderformdata.append("actual_charge", this.state.actual_charge)
//       Orderformdata.append("current_status", "DISC")
//       Orderformdata.append("previous_status", "DISC")
//       Orderformdata.append("status", "Deactive")
//       Orderformdata.append("flag", "1")

//       // console.log(JSON.stringify(Orderformdata))
//       // console.log(JSON.stringify(this.state))

//       const orderstatus_merged = {
//         "remarks": "discarded after merging with order" + existing_order,
//         "order_id": orderid,
//         "status_id": "DISC"
//       }

//       const orderstatus_mergedwith = {
//         "remarks": "",
//         "order_id": existing_order,
//         "status_id": current_status
//       }

//       // @Todo: APi to be called to save data for state

//       try {

//         const response = await Axios.post("http://localhost:8000/api/v1/ezzytrace/boxes_received/", Orderformdata,
//           header)
//         const response_newdiscrded = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_merged,
//           header)
//         const response_existingupdated = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_mergedwith,
//           header)

//         var existingOrderUpdateForm = new FormData()
//         existingOrderUpdateForm.append("current_status", current_status)
//         let urlforexistingOrderStatusUpdate = base_url+`boxes_received/${existing_order}/`
//         await Axios.patch(urlforexistingOrderStatusUpdate, existingOrderUpdateForm, header)


//         this.state.productlist.forEach(async (item) => {
//           // console.log("productitemmerged")
//           // console.log(item)
//           try {
//             var productformdata = new FormData()
//             productformdata.append("order_id", existing_order)
//             productformdata.append("description", item.productName)
//             productformdata.append("quantity", item.productQuantity)
//             productformdata.append("unit_price", item.productPrice)
//             productformdata.append("inspection_status", "okay")
//             productformdata.append("status", "Active")
//             productformdata.append("category", item.productCategory)

//             const response_products = await Axios.post(base_url+"products/", productformdata,
//               header)

//             console.log("Product added Successfully")
//             this.setState({ error: <Success message=" Order Status Updated" /> })
//             M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
//           } catch (error) {
//             M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
//             console.log(error)
//           }

//         });


// this.setState({error:<Success message=" New order for product items created"/>})
//  M.toast({html: 'Order created successfully',classes:"white-text orange rounded"})
// this.setState({error:""})    
// }catch(err){
  
//     M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
    
//     console.log(err)
//     this.setState({error:<Error/>})
//         }




// }else{


//   try{
  
//     var orderupdateForm = new FormData()
//     orderupdateForm.append("shipper_number",this.state.shiper_order_number)
//     // orderupdateForm.append("billing_id","2121")
//     orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
//     orderupdateForm.append("order_date",this.state.order_date)
//     orderupdateForm.append("received_date",this.state.received_date)
//     orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
//     orderupdateForm.append("weight",this.state.weight)
//     orderupdateForm.append("length",this.state.length)
//     orderupdateForm.append("breadth",this.state.width)
//     orderupdateForm.append("height",this.state.height)
//     // orderupdateForm.append("charge_type","VAT")
//     orderupdateForm.append("freight_charge",this.state.fedex_charge)
//     orderupdateForm.append("actual_charge",this.state.actual_charge)
//     orderupdateForm.append("current_status",this.state.orderStatusSelected)
//     let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
//     const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm,
//     header)

//     console.log(`order status update succesfully ${response.data}`)
//     this.setState({error:<Success message=" Order Status Updated"/>})
//     M.toast({html: 'Updated SUccessfull',classes:"white-text orange rounded"});
//   }catch(error){
//      M.toast({html: 'Updation Failed',classes:"white-text red rounded"});
//       console.log(error)
//       this.setState({error:<Error/>})
//   }

// }
// }





//   createOrderNormal = async () => {

    
//     // console.log('this.state.productlist', this.state.productlist);

//     // const tempProductList = this.state.productlist
    
//     const found = this.state.productlist.find(element => element["productPrice"]==0);

//     console.log('found', found)

//     if((this.state.width <= 0 || this.state.height <=0 || this.state.weight <= 0 || this.state.length <=0 || this.state.actual_charge <0)
//       && this.state.orderStatusSelected !== 'YTA' ){
//       M.toast({html: "Dimension values can not be 0/negative.",classes:"white-text red rounded"});
//       return;
//     }

//     if(found && this.state.orderStatusSelected === 'RTM' ){
//       M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
//       return
//     }
    
//     this.setState({isOrderUpdate: true})
//     let token = localStorage.getItem("token")
//     const header = {
//       'Content-Type': 'application/json',
//       'Authorization': 'Token ' + token,

//     }

//     console.log("create new order" + this.state.createNewOrder)

//     if (this.state.createNewOrder) {

//       // new order creation
//       console.log("Deleting previous orders if any")
//       this.state.productlist.forEach(async (item) => {
//         console.log("productlist -----", item.id)
//         console.log(item)
//         try {

//           let urlfordeletion = base_url+`products/${item.id}/`
//           const response = await Axios.delete(urlfordeletion,
//             header)
//           console.log(`deletion response ${response.data}`)
//           console.log("Product delete success")
//           M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
//         } catch (error) {
//           M.toast({ html: 'No old products found', classes: "white-text green rounded" });
//           console.log(error)
//         }

//       });



//       // const orderid = Math.floor(1000 + Math.random() * 9000)
//       // this.setState({ordernumber:orderid})







//       var Orderformdata = new FormData()
//       // console.log("orderid"+orderid)
//       console.log(this.state.fedex_charge)
//       console.log(this.state.actual_charge)
//       // Orderformdata.append("order_id",orderid.toString())
//       Orderformdata.append("ezz_id", this.state.customer_id)
//       Orderformdata.append("shipper_number", this.state.shiper_order_number)
//       Orderformdata.append("billing_id", "2121")
//       Orderformdata.append("billing_type", this.state.paymentTypeSelected)
//       Orderformdata.append("order_date", this.state.order_date)
//       Orderformdata.append("received_date", this.state.received_date)
//       Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
//       Orderformdata.append("weight", this.state.weight)
//       Orderformdata.append("length", this.state.length)
//       Orderformdata.append("breadth", this.state.width)
//       Orderformdata.append("height", this.state.height)
//       Orderformdata.append("charge_type", "VAT")
//       Orderformdata.append("freight_charge", this.state.fedex_charge)
//       Orderformdata.append("actual_charge", this.state.actual_charge)
//       Orderformdata.append("current_status", this.state.orderStatusSelected)
//       Orderformdata.append("previous_status", this.state.orderStatusSelected)
//       Orderformdata.append("status", "Active")
//       Orderformdata.append("flag", "1")
//       Orderformdata.append("homedelivery_charge", this.state.homedelivery_charge)
// 	  Orderformdata.append("insurance", this.state.insurance_charge)
// 	  Orderformdata.append("brokerage", this.state.brokerage_charge)
// 	  Orderformdata.append("processing", this.state.processing_fee)
// 	  Orderformdata.append("promo_type", this.state.promo_type)
//       Orderformdata.append("promo_value", this.state.promo_value)
          

//       // console.log(JSON.stringify(Orderformdata))
//       // console.log("-------------------")
//       // console.log(JSON.stringify(this.state))
//       // console.log("-------------------")


//       // @Todo: APi to be called to save data for state

//       try {

//         const response = await Axios.post(base_url+"boxes_received/", Orderformdata,
//           header)

//         const orderid = response.data.order_id
//         console.log("orderid" + orderid)
//         this.state.productlist.forEach(async (item) => {
//           console.log("productitem")
//           console.log(item)
//           try {
//             var productformdata = new FormData()
//             productformdata.append("order_id", orderid.toString())
//             productformdata.append("description", item.productName)
//             productformdata.append("quantity", item.productQuantity)
//             productformdata.append("unit_price", item.productPrice)
//             productformdata.append("inspection_status", "okay")
//             productformdata.append("status", "Active")
//             productformdata.append("category", item.productCategory)

//             const response_products = await Axios.post(base_url+"products/", productformdata,
//               header)
//             // alert(response.data)
//             console.log("Product added Successfully")
//             this.setState({ error: <Success message=" Order Status Updated" /> })
//             M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
//           } catch (error) {
//             M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
//             console.log(error)
//           }

//         });


//         try {
//           var email_subject = "invoice_missing"
//           const status = this.state.orderStatusSelected
//           switch (status) {
//             case "IM":
//               email_subject = "invoice_missing";
//               break;
//             case "WFC":
//               email_subject = "consolidation";
//               break;
//           }
//           if (status === "IM" || status === "WFC") {
//             const emaildata = {
//               "type": email_subject,
//               "ezz_id": this.state.customer_id,
//               "order_id": orderid
//             }


//             Axios({
//               method: 'post',
//               url: base_url+'status_printer',
//               data: {
//                 "order_id": orderid
//               }
//             }).then(function (response) {
//               console.log(response)
//               console.log("printer APi success");
//             }).catch(function (response) {
//               console.log(response)
//               console.log('printer API failed');
//             })

//             Axios({
//               method: 'post',
//               url: base_url+'whatsapp_templates',
//               data: emaildata,
//             }).then(
//               function (response) {
//                 console.log(response)
//                 console.log("whatsapp sent done");
//               }
//             ).catch(
//               function (response) {
//                 console.log(response)
//                 console.log("whatsapp sent Failure");
//               }
//             )

//             const response = await Axios.post(base_url+"send_email/", emaildata,
//               header)
//             console.log(response)
//             M.toast({ html: 'Email Sent to ' + response.data["Email to"], classes: "white-text red rounded" });
//           }

//         } catch (error) {

//           M.toast({ html: 'Email Sending Failed to' + this.state.customer_id, classes: "white-text red rounded" });
//         }



//         this.setState({ error: <Success message=" New order foproduct items created" /> })
//         M.toast({ html: 'Order created successfully', classes: "white-text orange rounded" })
//         this.setState({ error: "" })
//         this.setState({ generatedOrderId: orderid })
//         console.log('generatedOrderId',this.state.generatedOrderId);
//         document.getElementById("printLabel").disabled = false

//         await this.getproducts(orderid)

//       } catch (err) {

//         M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

//         console.log(err)
//         this.setState({ error: <Error /> })
//       }




//     } else {


//       try {
//         let token = localStorage.getItem("token")
//         const header = {
//           'Content-Type': 'application/json',
//           'Authorization': 'Token ' + token,

//         }
//         var orderupdateForm = new FormData()
//         orderupdateForm.append("shipper_number", this.state.shiper_order_number)
//         // orderupdateForm.append("billing_id","2121")
//         orderupdateForm.append("billing_type", this.state.paymentTypeSelected)
//         orderupdateForm.append("order_date", this.state.order_date)
//         orderupdateForm.append("received_date", this.state.received_date)
//         orderupdateForm.append("inbound_tracking_number", this.state.tracking_number)
//         orderupdateForm.append("weight", this.state.weight)
//         orderupdateForm.append("length", this.state.length)
//         orderupdateForm.append("breadth", this.state.width)
//         orderupdateForm.append("height", this.state.height)
//         // orderupdateForm.append("charge_type","VAT")
//         orderupdateForm.append("freight_charge", this.state.fedex_charge)
//         orderupdateForm.append("actual_charge", this.state.actual_charge)
//         orderupdateForm.append("current_status", this.state.orderStatusSelected)
//         let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
//         const response = await Axios.patch(urlforOrderStatusUpdate, orderupdateForm, header)

//         console.log(`order status update succesfully ${response.data}`)
//         this.setState({ error: <Success message=" Order Status Updated" /> })
//         M.toast({ html: 'Updated SUccessfull', classes: "white-text orange rounded" });
//       } catch (error) {
//         M.toast({ html: 'Updation Failed', classes: "white-text red rounded" });
//         console.log(error)
//         this.setState({ error: <Error /> })
//       }

//     }
    
//     document.getElementById('saveOrderBtn').disabled = true

//   }
//    addOrderToDB = async ()=>{
//               let token =localStorage.getItem("token")
//               const  header = {'Content-Type': 'application/json',
//               'Authorization':'Token '+token,
            
//               }
            
//                   //@TODO: FOR FUTUREjust umcomment the code for merge ,discard and hold fucntionality 
     

//               // check whether there is any order which is in state of WAITING FOR CONSOLIDATION
//               // try {
//               //   let url_wfc_check = base_url+`boxes_received/?ezz_id=${this.state.customer_id}&current_status=WFC`
//               //   var  wfcResponse = await Axios.get(url_wfc_check,header)
//               //   console.log(`WFC Response ${wfcResponse.data}`)
//               //   if (wfcResponse.data.length >0){
//               //     console.log(wfcResponse.data[0].order_id)
//               //     this.setState({ordernumbermerge:wfcResponse.data[0].order_id})
//               //     var elems = document.getElementById('merge_order_model');
//               //     var instance = M.Modal.init(elems);
//               //     instance.open()

//               //     const orderid = Math.floor(1000 + Math.random() * 9000)
//               //     this.setState({ordernumber:orderid})

//               //   }else{
//                   this.createOrderNormal()
//                   var btn = document.getElementById('createorder')
//                   btn.click()
//                  // window.location.reload(false);
//               // this.props.refresh();
//               //   }
                
//               // } catch (error) {
//               //   console.log(error)
//               // }



            


            



//   }

//   printLabelManually = () => {

//     var order_id = this.state.generatedOrderId
//     console.log("generatedOrderId:", order_id)

//       Axios({
//         method: 'post',
//         url: base_url+'status_printer',
//         data: {
//           "order_id": order_id
//         }
//       }).then(function (response) {
//         console.log(response)
//         console.log("printer APi success");
//         M.toast({
//           html: `Print Label successful for orderId: ${order_id} `,
//           classes: "center white-text green rounded"
//         })
//       }).catch(function (response) {
//         console.log(response)
//         console.log('printer API failed');
//         M.toast({
//           html: `Print Label unsuccessful`,
//           classes: "white-text orange rounded"
//         })
//       })
      
//   }

//   //UPDATE ORDER START

//   getproducts = async (orderid) => {
//     // http://localhost:8000/api/v1/ezzytrace/products/?order_id=
//     try {
//       let token = localStorage.getItem("token")
//       // let header = {'Content-Type': 'multipart/form-data',
//       // 'Authorization':'Token '+token,

//       // }

//       var config = {
//         headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

//       };

//       var url = base_url+`products/?order_id=${orderid}`
//       let products = await Axios.get(url, config)
//       // console.log("***orderlist-openmodel")
//       // console.log(products.data)
//       // await this.setState({productlisthtml:<ProductItemsList products={new Array()}/>})
//       var items = new Array()
//       products.data.forEach((product) => {
//         let newitem = {
//           id: product.id, productName: product.description,
//           productPrice: product.unit_price, productQuantity: product.quantity,
//           productCategory: product.category
//         }
//         items.push(newitem)
//         // this.state.productlist.push(newitem)
//       })

//       M.toast({ html: 'Fetched products for selected order', classes: "white-text orange rounded" });
//       // this.setState({error:<Success/>})


//       await this.setState({ productlist: items })


//       // await this.setState({ productlisthtml: <ProductItemsList orderid={orderid} products={items} rowclick={this.handletableClick} /> })


//     } catch (error) {
//       console.log(error)
//       M.toast({ html: 'Failed to fetch products', classes: "white-text red rounded" });
//       // this.setState({error:<Error/>})
//     }
//   }

  updateOrderDB= async(header)=>{
    const  orderid= this.state.generated_orderId

    // const found = this.state.productlist.find(element => element["productPrice"]==0);

    // console.log('found', found)
 

    // if(found && this.state.orderStatusSelected === 'RTM' ){
    //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
    //   return
    // }
	
	if (this.state.received_date == '' || this.state.received_date == '0' || this.state.received_date == '-1' )
	{
		M.toast({ html: 'Conversion rate can not be left empty', classes: "white-text red rounded" });
		return
	}
	if (this.state.customer_id == '' || this.state.customer_id == '0' || this.state.customer_id == '-1' )
	{
		M.toast({ html: 'Order ID can not be left empty', classes: "white-text red rounded" });
		return
	}
	if (this.state.shiper_order_number == '' || this.state.shiper_order_number == '0' || this.state.shiper_order_number == '-1' )
	{
		M.toast({ html: 'Taxes can not be left empty', classes: "white-text red rounded" });
		return
	}

      try{
          
          var orderupdateForm = new FormData()
          // orderupdateForm.append("order_id",this.state.generated_orderId)
          orderupdateForm.append("inv_number","INV-" + this.state.customer_id)
          orderupdateForm.append("inv_date",this.state.order_date)
          orderupdateForm.append("inv_conv_rate",this.state.received_date)
          orderupdateForm.append("inv_freight",this.state.tracking_number)
          orderupdateForm.append("inv_taxes",this.state.shiper_order_number)
          orderupdateForm.append("inv_on",this.state.inv_on)
          orderupdateForm.append("inv_handling_fee",this.state.handling_fee)
          orderupdateForm.append("inv_homedelivery_fee",this.state.homedelivery_fee)
          orderupdateForm.append("inv_insurance_fee",this.state.insurance_fee)
          orderupdateForm.append("inv_processing_fee",this.state.processing_fee)

          
          // orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
          // orderupdateForm.append("weight",this.state.weight)
          // orderupdateForm.append("length",this.state.length)
          // orderupdateForm.append("breadth",this.state.width)
          // orderupdateForm.append("height",this.state.height)
          // orderupdateForm.append("charge_type","VAT")
          // orderupdateForm.append("freight_charge",this.state.fedex_charge)
          // orderupdateForm.append("actual_charge",this.state.actual_charge)
          // orderupdateForm.append("current_status",this.state.orderStatusSelected)
          // // orderupdateForm.append("previous_status",this.props.orderdata.current_status)
          // orderupdateForm.append("status","Deactive")
          // orderupdateForm.append("flag","1")
          // orderupdateForm.append("homedelivery_charge", this.state.homedelivery_charge)
          // orderupdateForm.append("insurance", this.state.insurance_charge)
          // orderupdateForm.append("brokerage", this.state.brokerage_charge)
          // orderupdateForm.append("processing", this.state.processing_fee)

          // orderupdateForm.append("promo_type", this.state.promo_type)
          // orderupdateForm.append("promo_value", this.state.promo_value)

          let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.customer_id}/`
          const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
    
          var boxid = 0 
          if (this.state.box_id > 0)
          {
            boxid = this.state.box_id
          }
          else
          {
            boxid = this.state.customer_data[this.state.customer_id].box_id
          }
          //alert(boxid)
          //return
          
          const emaildata = {
            "order_id": this.state.customer_id,
            "box_id": boxid
          }
          Axios({
              method: 'post',
              url: base_url+'create_cashinvoice/',
              data: emaildata,
            }).then(
              function (response) {
              // console.log(response)
              
              console.log("Invoice creation done");
              M.toast({ html: 'Invoice created!', classes: "white-text green rounded" });
            }).catch(
              function (response) {
              // console.log(response)
              console.log("Invoice create Failure");
              M.toast({ html: 'Invoice creation failed to' + this.state.customer_id, classes: "white-text red rounded" });
            }) 
          // console.log(`order status update succesfully ${response.data}`)
          this.setState({error:<Success message=" Invoice generated successfully!"/>})
          this.setState({ generatedOrderId: "INV-" + this.state.customer_id })
          M.toast({html: 'Invoice Generation Successfull',classes:"white-text orange rounded"});
  
  
        //   //Updating The Product 
  
        //   this.state.productlist.forEach(async(item)=>{
        //     // console.log("productitem")
        //     // console.log(item)
        //     // console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')
  
            
  
        //     try{
        //       var productformdata = new FormData()
        //       productformdata.append("order_id",this.state.generatedOrderId)
        //       productformdata.append("description",item.productName)
        //       productformdata.append("quantity",item.productQuantity)
        //       productformdata.append("unit_price",item.productPrice)
        //       productformdata.append("inspection_status","okay")
        //       productformdata.append("status","Active")
        //       productformdata.append("category",item.productCategory)
  
        //       console.log('ITEM-ID',item.id);

        //       if( typeof(item.id) === "number" && this.state.isOrderUpdate ){
        //         const response_products = await Axios.patch(base_url+`products/${item.id}/`, productformdata,
        //           header)
        //         // alert(response.data)
        //         console.log("Product added Successfully")
        //         this.setState({ error: <Success message=" Order Status Updated" /> })
        //         M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
                
        //       }else{
                
        //         const response_addProduct = await Axios.post(base_url+`products/`, productformdata,
        //         header )
  
        //         M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
                
        //       }
  
              
              
        //     }catch(error){
        //       M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
        //         console.log(error)
        //     }
           
        //   });
          
  
  
        //  await this.getproducts(this.state.generatedOrderId)
  
  
          // window.location.reload(false);
        }catch(error){
          M.toast({html: 'Invoice Generation Failed',classes:"white-text red rounded"});
            console.log(error)
            this.setState({error:<Error/>})
        }
  
  }
  
  updateOrder=()=>{
      let token =localStorage.getItem("token")
      const  header = {'Content-Type': 'application/json',
      'Authorization':'Token '+token,
      }
      this.updateOrderDB(header)
  
  
  }

  //--UPDATE ORDER END


render(){
  const opts={};
   opts['readOnly'] = 'readOnly';
  const catlist = this.state.product_category_options;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
      )
    }, this);
  }
  

    return(
        <Container>

  <OrderModalCash ref="ordermodelcash" orders={this.state.pendingOrders}
             onOrderSelection={ v => {this.setState({ordersSelectedInModal: v})
               //console.log("hey world")  
             /// console.log(v.order)
               //console.log(v.perordersdata[v.order])
               //console.log(v.orderdetails)
             //alert(v.orderdetails.freight_charge)
             //alert(v.orderdetails.box_id)
             this.setState({box_id:v.orderdetails.box_id})
             this.setState({customer_id:v.order})
             this.setState({tracking_number:v.orderdetails.freight_charge})
              if (v.selectedproductId.length < v.perordersdata[v.order].length){
                 
                  
                  this.setState({createNewOrder:true})

              }else{
                this.setState({createNewOrder:false})
              }
             }}/>

<div className="row">
          <div className="col s3">
          <h4 className=" orange-text">Create Invoice</h4>
          </div>
       
</div>
                <Row>

                       <Col xs={12}md={7} lg={7}>
                    <div className="row">
    <form className="col s12" autocomplete="off">
      {this.state.error}
      <div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="customer_id" id="customer_id" type="text" className="autocomplete"
            value={this.state.customer_id} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}

          />
          <label htmlFor="customer_id">Enter Order Number</label>
            <span id="errCustomerNotAuthorized" class="helper-text center yellow red-text " 
                  data-error="wrong" data-success="" 
                  style= {{ fontSize: "small", display: "none", fontWeight: "500" }} >Payment Method Not Added</span>
          <span className="helper-text" data-error="wrong" data-success="">Order Number ex: 23-000000-BS-000</span>
        </div>
      
      </div>

    
        <div className="row input-field center col s12">
        <span class="helper-text center red-text " 
                  style= {{ fontSize: "large", fontWeight: "500" }} >OR</span>
        </div>
      
     

       <div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="phone_id" id="phone_id" type="text" className="autocomplete"
            value={this.state.phone_id} 
            onChange={this.handleCustomerIDChangeGen}
            onKeyDown={this.handleCustomerInfo}

          />
          <label htmlFor="phone_id">Enter Ezzid or Phone number</label>
            <span id="errCustomerNotAuthorized" class="helper-text center yellow red-text " 
                  data-error="wrong" data-success="" 
                  style= {{ fontSize: "small", display: "none", fontWeight: "500" }} >Payment Method Not Added</span>
          <span className="helper-text" data-error="wrong" data-success="">Ezzid ex:Ezz0000000 Or Phone ex:93125</span>
        </div>
      
      </div>

      <div className="row inputbox-height">
        <div className="input-field col s12">
        
          <input name="order_date" id="order_date" type="text"  className="datepicker" 
          value={this.state.order_date}
          //  onChange={this.handleFormChange}
          />
          
          <label htmlFor="order_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i></label>
          <span className="helper-text" data-error="wrong" data-success="">Select the Created On Date </span>
        </div>
        <div className="input-field col s6">
          <input name="received_date" disabled id="received_date" type="text" className=""
           value={this.state.received_date}  onChange={this.handleFormChange}
          />
          <label htmlFor="received_date">Enter Conversion Rate</label>
          <span className="helper-text" data-error="wrong" data-success="">Conversion Rate 1 USD</span>

        </div>

     
      </div>
      <div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="tracking_number" id="tracking_number" type="text" className=""
           value={this.state.tracking_number} onChange={this.handleFormChange}
          />
          <label htmlFor="tracking_number">Enter Freight Cost</label>
          <span className="helper-text" data-error="wrong" data-success="">Enter Freight Cost in USD</span>

        </div>
        <div className="input-field col s6">
          <input name="shiper_order_number" id="shiper_order_number" type="text" className=""
           value={this.state.shiper_order_number} onChange={this.handleFormChange}
          />
          <label htmlFor="shiper_order_number">Enter Duty and Taxes</label>
          <span className="helper-text" data-error="wrong" >Enter Total Duty and Taxes in USD</span>
        </div> 

      </div>

       <div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="handling_fee" id="handling_fee" type="text" className=""
           value={this.state.handling_fee} onChange={this.handleFormChange}
          />
          <label htmlFor="handling_fee">Enter Brokerage Fee</label>
          <span className="helper-text" data-error="wrong" data-success="">Enter Brokerage Fee in USD (Optional)</span>

        </div>
        <div className="input-field col s6">
          <input name="homedelivery_fee" id="homedelivery_fee" type="text" className=""
           value={this.state.homedelivery_fee} onChange={this.handleFormChange}
          />
          <label htmlFor="homedelivery_fee">Enter Home Delivery Fee</label>
          <span className="helper-text" data-error="wrong" >Enter Home Delivery Fee in USD (Optional)</span>
        </div> 

      </div>

      
       <div className="row inputbox-height">
        <div className="input-field col s6">
          <input disabled name="insurance_fee" id="insurance_fee" type="text" className=""
           value={this.state.insurance_fee} onChange={this.handleFormChange}
          />
          <label htmlFor="insurance_fee">Enter Insurance Fee</label>
          <span className="helper-text" data-error="wrong" data-success="">Enter Insurance Fee in USD (Optional)</span>

        </div>
        <div className="input-field col s6">
          <input disabled name="processing_fee" id="processing_fee" type="text" className=""
           value={this.state.processing_fee} onChange={this.handleFormChange}
          />
          <label htmlFor="processing_fee">Enter Consolidation Fee</label>
          <span className="helper-text" data-error="wrong" >Enter Consolidation Fee in USD (Optional)</span>
        </div> 

      </div>

      {
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }
      
    </form>
  
  </div>
                    </Col>
                    {/* right side column starts here */}
                    <Col xs={12}md={5} lg={5}>


                            <CustomerDetailCard 
                            info = {this.state.customer_selected_data}
                             />

                       
                    </Col>

                    {/* right side columns ends here */}
      
                </Row> 

                <Row >
                  <Col>
                    <div className="left">
                        <h5 className="orange-text">Invoice Number: <span className="black-text" id='generated_orderId' >{this.state.generatedOrderId}</span></h5>
                    </div>
                  </Col>
                </Row>

          <Row>
            <Col>
              <div className="center">
                <ButtonGroup aria-label="create order buttons" className="">
                  <Button id="cancelOrderBtn"onClick={this.cancelOrder} variant="secondary"  className="">Cancel</Button>
                  {/*<Button className="ml-5 " id="saveOrderBtn" onClick={this.addOrderToDB} variant="primary"  >Generate Invoice</Button>*/}
                  <Button className="ml-5 white-text" id="updateOrder" variant="success" onClick={this.updateOrder} >Generate Invoice</Button>                        
                  {/*<Button className="ml-5 white-text" id="printLabel" variant="warning"  onClick={this.printLabelManually}>Print Invoice</Button>                        */}
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>


    )
}

}

export default CreateOrder;
