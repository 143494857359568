
import React,{Component,Fragment} from 'react';
import { base_url } from '../../globalConstants'
import Axios from "axios"
import {Table,Row,Col,Image,Nav,ButtonGroup,Button} from 'react-bootstrap';
import M from "materialize-css";
import { EditOutlined, NavigateBefore, NavigateNext } from "@material-ui/icons";

// import AddCountry from './CountryModal';
import './getcustomer.css';

class CustomerFrag extends Component{
        constructor(props){
            super (props);
            this.state=({ezzid:"",name:"",mobile:"",email:"" , 
            shippingaddress1:"",shippingaddress2:"",shippingcountryname:"",shippingstatename:"",shippingcity:"",shippingzip:"",
            billaddress1:"",billaddress2:"",billcountryname:"",billstatename:"",billcity:"",billzip:"",
            country_name:"",state_name:"",city:"",
            countrydata:this.props.countrytable,error:"",newCountrycode:"",
            editRow: false, main:"", keyId:"", thresh_value:"", def_language:"",
            previousPageUrl: this.props.countrydatapaging.previous,
            nextPageUrl: this.props.countrydatapaging.next,
            currentPageNumber:1,
            countrylist: this.props.countrylist,
            countrycodelist: this.props.country_code_list,
             statedata: this.props.statedata, stateCode_list: [], stateName_list:[],
              statelist: [],
              statelist_bill:[] 
            })
            console.log("Customer List")
            console.log(this.props.countrylist)
            console.log("pageurl: ",this.state.previousPageUrl)
        }
        
        componentDidMount() {
            // Auto initialize all the things!
            M.AutoInit();

        }

        handleStateChange = (e) => {
         // console.log("state of homedelivery", e.target.value)
          this.setState({ shippingstatename: e.target.value })
        // console.log(this.state.brokerState);
      }
      handleStateChange_bill = (e) => {
         // console.log("state of homedeliverybill", e.target.value)
          this.setState({ billstatename: e.target.value })
        // console.log(this.state.brokerState);
      }
  handleAdd1Change = (e) => {
    this.setState({ shippingaddress1: e.target.value })
  }
  
  handleAdd1Change_bill= (e) => {
    this.setState({ billaddress1: e.target.value })
  }
  handleAdd2Change = (e) => {
    this.setState({ shippingaddress2: e.target.value })
  }
  
  handleAdd2Change_bill = (e) => {
    this.setState({ billaddress2: e.target.value })
  }
  handleCityChange = (e) => {
    this.setState({ shippingcity: e.target.value })
  }
  
  handleCityChange_bill = (e) => {
    this.setState({ billcity: e.target.value })
  }
  handleZipChange = (e) => {
    this.setState({ shippingzip: e.target.value })
  }
  
  handleZipChange_bill = (e) => {
    this.setState({ billzip: e.target.value })
  }

 //UPDATE Customer LIST
  updateCustomerList = async (updatedUrl, pg) => {

    var context = this
    var tempPageNumber = this.state.currentPageNumber

    this.setState({
      showSpinner: true,
    })

    let response_data = []
    var prevUrl 
    var nextUrl 

    await Axios({
      method: "GET",
      url: updatedUrl
    }).then(function(response){
      console.log("response",response);
      console.log("response.data.results",response.data.results);
      response_data = response.data.results
      // context.setState({ updatedOrdersList: response.data.results })
      // console.log("UPDATED ORDELIST",this.state.updatedOrdersList);      
      console.log("UPDATED Customer",response_data);   
      prevUrl = response.data.previous   
      nextUrl = response.data.next   

      // tempPageNumber = updatedUrl.toString().split('=')[4]??1

      if(pg === '+')
        tempPageNumber = tempPageNumber + 1
      else if(pg === '-')
        tempPageNumber = tempPageNumber - 1
      else
        tempPageNumber = ''

      context.setState({
        showSpinner: false,
        currentPageNumber: tempPageNumber
      })

    }).catch(function(response){
      console.log("response",response);
      console.log("ERROR UPDATING ORDERLIST");
    })

    await this.setState({countrydata: response_data})
    this.setState({previousPageUrl: prevUrl})
    this.setState({nextPageUrl: nextUrl})

  }
  //UPDATE Customer LIST END
  
updateDetailsDB = async () => {

  let country_code = this.state.countrycodelist[this.state.countrylist.indexOf(this.state.shippingcountryname)]
  let country_code_bill = this.state.countrycodelist[this.state.countrylist.indexOf(this.state.billcountryname)]

  //let state_code = this.state.countrycodelist[this.state.countrylist.indexOf(this.state.shippingstatename)]
  //let state_code_bill = this.state.countrycodelist[this.state.countrylist.indexOf(this.state.billstatename)]

  let url = base_url+`update_user_address/`

  var userformdata = new FormData()
  userformdata.append("ezzid", this.state.ezzid)
  userformdata.append("scountry", country_code)
  userformdata.append("sadd1", this.state.shippingaddress1)
  userformdata.append("sadd2", this.state.shippingaddress2)
  userformdata.append("sstate", this.state.shippingstatename)
  userformdata.append("scity", this.state.shippingcity)
  userformdata.append("szip", this.state.shippingzip)
  userformdata.append("bcountry", country_code_bill)
  userformdata.append("badd1", this.state.billaddress1)
  userformdata.append("badd2", this.state.billaddress2)
  userformdata.append("bstate", this.state.billstatename)
  userformdata.append("bcity", this.state.billcity)
  userformdata.append("bzip", this.state.billzip)

  

  try {
    let token = localStorage.getItem("token")
    var config = {
      headers: { 'Content-Type': 'multipart/form-data' , 'Authorization':'Token '+token},
      
    };
    const response = await Axios.post(url, userformdata, config)
    
    console.log('push response data', response.data);
    
    // this.state.warehousedata.unshift(response.data)
    var elem = document.getElementById('addStateModal')
    var instance = M.Modal.getInstance(elem);
    instance.close();
    M.toast({ html: 'Updated Address Successfully.' , classes: "white-text orange rounded" })
    this.setState({
      error: "",
      editRow: false
    })
  } catch (err) {
    this.setState({
      error: "OH Snap! Something is Wrong.Please try again",
      editRow: false
    })
    M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

    console.log(err.message)
  }

}

        //EDIT OPTION START
  handleEditOption  = async (e) => {
    // alert("done")

    this.setState({editRow:true})
  

    var elems = document.getElementById("addStateModal");
    var instance = M.Modal.init(elems);
    instance.open()

    let selectedRowIndex ;
                e = e || window.event;
                var data = [];
                var target = e.srcElement || e.target;
                // console.log(target.nodeName)
                while (target && target.nodeName !== "TR") {
                    target = target.parentNode;
                    selectedRowIndex= target.sectionRowIndex
                    console.log("Clicked row",target.sectionRowIndex)
                    // console.log(target.parentNode.index)
                }
                if (target) {
                    var cells = target.getElementsByTagName("td");
                    for (var i = 0; i < cells.length; i++) {
                        data.push(cells[i].innerHTML);
                    }
                }
              let country_code = this.state.countrycodelist[this.state.countrylist.indexOf(data[5])]
              //let country_name = this.state.countrylist[this.state.countrycodelist.indexOf(data[5])]
              let country_name = data[5]   
              console.log(country_name);
              const statesOnEdit = this.state.statedata[this.state.countrylist.indexOf(country_name)].slice()
              console.log(statesOnEdit);
              this.setState({ statelist: statesOnEdit })
              let finalstatearr=[];
              for(var i=0;i<statesOnEdit.length;i++)
              {
                  let statedataobj={
                  state_id:statesOnEdit[i].state_id.toString(),
                  state_name:statesOnEdit[i].state_name,
                  state_code:statesOnEdit[i].state_code
                };
                finalstatearr.push(statedataobj);
              }

              console.log(finalstatearr);
            this.setState({ statelist: finalstatearr })
            var newArray = statesOnEdit.filter(function(item) {
              return item.state_name == data[6];
            });

            var setselectedstatevalue='';
            if(newArray && newArray.length>0)
            {
              setselectedstatevalue=newArray[0].state_id.toString();
            }

          this.setState({ezzid: data[1], name: data[2], email: data[4], mobile: data[3],  shippingcity: data[7],
          shippingaddress1: data[10], shippingaddress2: data[11], shippingzip: data[12],
          shippingcountryname:data[5], shippingstatename: setselectedstatevalue})

          const response_billing =  await Axios.get(base_url + 'customer_billing_address/' + data[1])
          console.log("biil data" + response_billing.data)
          let country_name_bill = this.state.countrylist[this.state.countrycodelist.indexOf(response_billing.data.country)]

           this.setState({billaddress1: response_billing.data.address_line_1,
              billaddress2: response_billing.data.address_line_2, 
              billcity: response_billing.data.city, 
              billzip: response_billing.data.zipcode,
              billcountryname:country_name_bill})


              const statesOnEdit_bill = this.state.statedata[this.state.countrylist.indexOf(country_name_bill)].slice()
              //console.log(statesOnEdit);
              this.setState({ statelist_bill: statesOnEdit_bill })
              let finalstatearr_bill=[];
              for(var i=0;i<statesOnEdit_bill.length;i++)
              {
                  let statedataobj_bill={
                  state_id:statesOnEdit_bill[i].state_id.toString(),
                  state_name:statesOnEdit_bill[i].state_name,
                  state_code:statesOnEdit_bill[i].state_code
                };
                finalstatearr_bill.push(statedataobj_bill);
              }

            console.log(finalstatearr_bill);
            this.setState({ statelist_bill: finalstatearr_bill })
            var newArray_bill = statesOnEdit_bill.filter(function(item) {
              return item.state_id == response_billing.data.state;
            });

            var setselectedstatevalue_bill='';
            if(newArray_bill && newArray_bill.length>0)
            {
              setselectedstatevalue_bill=newArray_bill[0].state_id.toString();
            }

            this.setState({billstatename : setselectedstatevalue_bill})

  }  

   handleCountryNameChange = async (e) => {
    this.setState({ shippingcountryname: e.target.value })
    console.log("country selected:- " + e.target.value)
    let selected_countryId = this.state.countrycodelist[this.state.countrylist.indexOf(e.target.value)]
    console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrylist.indexOf(e.target.value)].slice()
    console.log(states)

    this.setState({ countryId: selected_countryId, statelist: states, shippingstatename: states[0].state_id })

  }
  
  handleCountryNameChange_bill = async (e) => {
    this.setState({ billcountryname: e.target.value })
    console.log("country selected bill:- " + e.target.value)
    let selected_countryId = this.state.countrycodelist[this.state.countrylist.indexOf(e.target.value)]
    console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrylist.indexOf(e.target.value)].slice()
    console.log(states)

    this.setState({ countryId: selected_countryId, statelist_bill: states, billstatename: states[0].state_id })

  }
        handlePageNavigation = async (updatedUrl, pg) =>{
            
        }
        render(){
            var uniquekey = 0
            const selectCounrtyOptions = this.state.countrylist.map((country1) => {

              return (
                <option key={uniquekey++} disabled value={country1}>{country1}</option>
              )
            })

            const selectStateOptions = this.state.statelist.map((state, index) => {
              // console.log("state", state)

              return (

                <option key={index} value={state.state_id}>{state.state_name}</option>
              )
            })

          var uniquekey_bill = 0
          const selectCounrtyOptions_bill = this.state.countrylist.map((country_bill) => {

            return (
              <option key={uniquekey_bill++} disabled value={country_bill}>{country_bill}</option>
            )
          })

          const selectStateOptions_bill = this.state.statelist_bill.map((state, index) => {
            return (

              <option key={index} value={state.state_id}>{state.state_name}</option>
            )
          })
          
            var sr_no = 0
            const countrytable = this.state.countrydata.map((country)=>{
               sr_no=sr_no+1
              return(
                <Fragment key={sr_no}>
               <tr  >
                <td>{sr_no}</td>
                {/* <td>{country.country_id}</td> */}
                <td>{country.ezz_id}</td>
                <td>{country.name}</td>
                <td>{country.phone_number}</td>
                <td>{country.email}</td>
                <td>{country.country_name}</td>
                <td>{country.state_name}</td>
                <td>{country.city}</td>

                <td onClick={this.handleEditOption}><a title = "Edit"><EditOutlined/></a></td>
                <td className="hide">{country.id}</td>
                                  <td className="hide">{country.address}</td>
                    <td className="hide">{country.address_2}</td>
                      <td className="hide">{country.zipcode}</td>
              </tr>
                </Fragment>
              
              )
            })

            const table_render =  <Table hover> 
                    <thead>
                        <tr>        
                            <th>Sr.No.</th>
                            <th>EZZID</th> 
                            <th>Name</th>
                            <th>Phone No</th>
                            <th>Email</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>City</th>        
                
                            <th>Edit</th>       

                        </tr>
                    </thead>
                    <tbody>
                        {countrytable}
                    </tbody>
                </Table>

            return (
                <Fragment>
                         {/* <!-- Modal Trigger --> */}
        <div id="addStateModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            
          <h4 className="center orange-text"> {this.state.editRow ? "Update Address" : "Add Address "}</h4>
            <Row>
              <Col xs={12} >
                <form>
                  <p className="center red-text">{this.state.error}</p>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="ezzid" type="text" disable value={this.state.ezzid} onChange={this.handleStateCodeChange} className="" />
                        <label htmlFor="ezzid"  className={this.state.ezzid?"black-text active":"black-text"}>Enter EZZID</label>
                        <span className="helper-text left" data-error="wrong" data-success="">EZZID</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="name" type="text" disable value={this.state.name} onChange={this.handleStateNameChange} className="" />
                        <label htmlFor="name" className={this.state.name?"black-text active":"black-text"}>Enter Name</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Name</span>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="email" disable type="text" value={this.state.email} onChange={this.handleStateNameChange} className="" />
                        <label htmlFor="email" className={this.state.email?"black-text active":"black-text"}>Enter Email</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Email</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="mobile" type="text" value={this.state.mobile} onChange={this.handleStateNameChange} className="" />
                        <label htmlFor="mobile" className={this.state.mobile?"black-text active":"black-text"}>Enter Mobile number</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Mobile number</span>
                      </div>
                    </Col>
                  </Row>
                <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="shippingaddress1" type="text" value={this.state.shippingaddress1} onChange={this.handleAdd1Change} className="" />
                        <label htmlFor="shippingaddress1" className={this.state.shippingaddress1?"black-text active":"black-text"}>Enter Shipping Address1</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Address1</span>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="shippingaddress2" type="text" value={this.state.shippingaddress2} onChange={this.handleAdd2Change} className="" />
                        <label htmlFor="shippingaddress2" className={this.state.shippingaddress2?"black-text active":"black-text"}>Enter Shipping Address2</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Address2</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
  

                      <div className="input-field col s6 offset-s3">
                        <div id=''>
                        {this.state.editRow
                        ?<select id="shipping_selectCountry" name="shippingcountryname" className="browser-default country_name_select" value={this.state.shippingcountryname} onChange={this.handleCountryNameChange}>
                        <option value="" disabled >Choose your Country </option>
                         {selectCounrtyOptions}

                      </select>
                        :<select id="shipping_selectCountry" name="shippingcountryname" className="browser-default country_name_select" onChange={this.handleCountryNameChange}>
                            <option value="" disabled >Choose your Country </option>
                             {selectCounrtyOptions}

                          </select>}
                          <label>Select Country</label>

                        </div>
                      </div>

                    </Col>
                  </Row>

                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 ">
                                    <div id=''>         {
                            this.state.editRow
                              ? <select id="shipping_selectstate" name="shippingstatename" className="browser-default" value={this.state.shippingstatename} onChange={this.handleStateChange}>
                                <option value="" disabled >Choose State </option>
                                {selectStateOptions}
                              </select>
                              : <select id="shipping_selectstate" name="shippingstatename" className="browser-default " defaultValue={'DEFAULT'} onChange={this.handleStateChange}>
                                <option value="DEFAULT" disabled >Choose State </option>
                                {selectStateOptions}
                              </select>
                          }
                          <label>Choose State</label>
                           </div>

                      </div>
                    </Col>
                  </Row>


                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="shippingcity" type="text" value={this.state.shippingcity} onChange={this.handleCityChange} className="" />
                        <label htmlFor="  " className={this.state.shippingcity?"black-text active":"black-text"}>Enter Shipping City</label>
                        <span className="helper-text left" data-error="wrong" data-success="">City</span>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="shippingzip" type="text" value={this.state.shippingzip} onChange={this.handleZipChange} className="" />
                        <label htmlFor="shippingzip" className={this.state.shippingzip?"black-text active":"black-text"}>Enter Shipping Zip Code</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Zip Code</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="billaddress1" type="text" value={this.state.billaddress1} onChange={this.handleAdd1Change_bill} className="" />
                        <label htmlFor="billaddress1" className={this.state.billaddress1?"black-text active":"black-text"}>Enter Billing Address1</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Address1</span>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="billaddress2" type="text" value={this.state.billaddress2} onChange={this.handleAdd2Change_bill} className="" />
                        <label htmlFor="billaddress2" className={this.state.billaddress2?"black-text active":"black-text"}>Enter Billing Address2</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Address2</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>


                      <div className="input-field col s6 offset-s3" >
                        <div id=''>
                        {this.state.editRow
                        ?<select id="bill_selectCountry" className="browser-default country_name_select" value={this.state.billcountryname} onChange={this.handleCountryNameChange_bill}>
                        <option value="" disabled >Choose your Country </option>
                         {selectCounrtyOptions_bill}

                      </select>
                        :<select id="bill_selectCountry" className="browser-default country_name_select" onChange={this.handleCountryNameChange_bill}>
                            <option value="" disabled >Choose your Country </option>
                             {selectCounrtyOptions_bill}

                          </select>}
                          <label>Select Billing Country</label>

                        </div>
                      </div>

                    </Col>
                  </Row>

                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3">
                            <div id=''>         
                                       {
                            this.state.editRow
                              ? <select id="bill_selectstate" name="billstatename" className="browser-default " value={this.state.billstatename} onChange={this.handleStateChange_bill}>
                                <option value="" disabled >Choose State </option>
                                {selectStateOptions_bill}
                              </select>
                              : <select id="bill_selectstate" name="billstatename" className="browser-default " defaultValue={'DEFAULT'} onChange={this.handleStateChange_bill}>
                                <option value="DEFAULT" disabled >Choose State </option>
                                {selectStateOptions_bill}
                              </select>
                          }
                                <label>Select Billing State</label>

                        </div>
                      </div>
                    </Col>
                  </Row>

                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="billcity" type="text" value={this.state.billcity} onChange={this.handleCityChange_bill} className="" />
                        <label htmlFor="billcity" className={this.state.billcity?"black-text active":"black-text"}>Enter Billing City</label>
                        <span className="helper-text left" data-error="wrong" data-success="">City</span>
                      </div>
                    </Col>
                  </Row>
                    <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input id="billzip" type="text" value={this.state.billzip} onChange={this.handleZipChange_bill} className="" />
                        <label htmlFor="billzip" className={this.state.billzip?"black-text active":"black-text"}>Enter Billing Zip Code</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Zip Code</span>
                      </div>
                    </Col>
                  </Row>

                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}

            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id='cancelStatebtn' variant="outline-secondary" className="mr-4 btn modal-close " onClick={this.closeModal} >Cancel</Button>
              {this.state.editRow?<Button id="updateStateBtn" variant="outline-primary" className="mr-2 btn" onClick = {this.updateDetailsDB}>Update</Button>:<Button id="addStateBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addStateToDB}> Add</Button>}         
            </ButtonGroup>

          </div>
        </div>  
                    <div className="">
					         <Row>
          <Col xs={6} md lg={6}>
            <div>
                {
                  this.state.previousPageUrl
                    ? <Button variant="outline-secondary " className="teal white-text mr-2" onClick={() => this.updateCustomerList(this.state.previousPageUrl, '-')} ><NavigateBefore /></Button>
                    : <Button variant="outline-secondary " className="teal white-text mr-2" disabled><NavigateBefore /></Button>
                }
                {
                  this.state.nextPageUrl
                    ? <Button variant="outline-secondary " className="teal white-text mr-2" onClick={() => this.updateCustomerList(this.state.nextPageUrl, '+')}><NavigateNext /></Button>
                    : <Button variant="outline-secondary " className="teal white-text mr-2" disabled><NavigateNext /></Button>
                }          
            </div><br/>
            <div>
              <h5>Page: {this.state.currentPageNumber}</h5>
            </div>
          </Col>
          <Col xs={6} md lg={6}>
           
          </Col>
        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12} >
                            <div className="tableheight">
                                {/* {this.props.countrytable} */}
                                {table_render}
                            </div>
                            </Col>

                        </Row>
                        
                    </div>

                </Fragment>
            )

        }
}
export default CustomerFrag;