import React, {Component} from 'react';
import {Row, Col,ButtonGroup,Button,Spinner,Nav,Image} from 'react-bootstrap';
import M from 'materialize-css';
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard';
import EditProduct from './EditProductItem';
// import CustomerDetailCard from './CustomerDetailCard'
import { base_url } from '../../globalConstants';
//import "./createorder.css";


class EditOrder extends Component{


constructor(props, context){
        super (props, context);
        console.log("construct ",this.props.productlist)
        this.state =({
            order:this.props.order,
            // current_date: date,
           
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :<></>,
            product_category:"",ordernumber:"",
         customer_id:"",order_date:"",
        received_date:"",tracking_number:"",shiper_order_number:"",
        weight:"",height:"",fedex_charge:"",width:"",length:"",actual_charge:"",homedelivery_charge:"",promo_type:"",promo_value:0,processing_fee:"",brokerage_charge:"",insurance_charge:"",
          orderStatusSelected:"",
          paymentTypeSelected:"",showSpinner:false,
          customerinfo:{},
          error:"",
          selectItemDetails:{},
      
          currentOrderStatus: ''
          
        })

    //      this.state = {
    //   productsList :[
    //     {value: "1", label: "English", name: 'USS Seawolf class', isChecked: false},
    //     {value: "2", label: "Hindi", name: 'USS Skipjack', isChecked: false},
    //     {value: "3", label: "Spanish", name: 'USS Lafayette', isChecked: false},
    //     {value: "4", label: "Arabic", name: 'USS Ohio class', isChecked: false},
    //   ]
    // }

    this.state = {
      productsList : [
  { value: "1", label: "Create Order", isChecked: false},
  { value: "2", label: "Order List", isChecked: false },
  { value: "3", label: "Charge List", isChecked: false },
  { value: "4", label: "Box List", isChecked: false },
  { value: "5", label: "Custom Settlement", isChecked: false },
  { value: "6", label: "Shipped", isChecked: false },
  { value: "7", label: "Referral", isChecked: false },
  { value: "8", label: "Reconcillation", isChecked: false },
  { value: "9", label: "Create Coupon", isChecked: false },
  { value: "10", label: "View Coupon", isChecked: false },
  { value: "17", label: "Curacao Customs List", isChecked: false },
  { value: "11", label: "Create Cash Invoice", isChecked: false },
  { value: "12", label: "Cash Invoice List", isChecked: false },
  { value: "18", label: "Cash Invoice Report", isChecked: false },
  { value: "13", label: "Create Role", isChecked: false },
  { value: "14", label: "Role List", isChecked: false },
  { value: "15", label: "Reports", isChecked: false },
  { value: "16", label: "Pending Orders", isChecked: false },
  { value: "19", label: "Shipping Rates" },
  { value: "20", label: "Create Shipment" },
  { value: "21", label: "View Shipment" },
  { value: "22", label: "Dashboard" }
] }
    
    
    }

    onAddingItem = (i) => (event) => {
    this.setState((state, props) => {
      state.productsList[i].isChecked = !state.productsList[i].isChecked;
      state.isedit = true;
      return {
        productsList: state.productsList
      }
    })
  }
  componentDidUpdate(prevProps) {


    if (this.props.orderdata.id !== prevProps.orderdata.id) {
      // console.log("Product error",this.props.productlist)
      // console.log("customerinfo",this.props.customerinfo)
      // console.log("catefory options",this.props.productCategory)
       console.log("catefory options",this.props.orderdata.formid)
     
        this.setState({order:this.props.orderdata,isedit: false,
            //   current_date: this.props.orderdata.order_date,
            arrform :this.props.orderdata.formid.split(","),
            tracking_number:this.props.orderdata.name,
            shiper_order_number:this.props.orderdata.formid,
            orderid :this.props.orderdata.id,
            customerinfo:this.props.customerinfo,
            productlist:this.props.productlist ,
            productlisthtml:this.props.productlisthtml ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :this.props.productCategory,
            // showSpinner:false,
            product_category:"",ordernumber:this.props.orderdata.inv_number,
         customer_id:this.props.orderdata.ezz_id,order_date:this.props.orderdata.inv_date,
        received_date:this.props.orderdata.inv_conv_rate,
        weight:this.props.orderdata.weight,height:this.props.orderdata.height,
        fedex_charge:this.props.orderdata.freight_charge, width:this.props.orderdata.breadth,
        length:this.props.orderdata.length,actual_charge:this.props.orderdata.actual_charge,
        homedelivery_charge:this.props.orderdata.homedelivery_charge,
        promo_type:this.props.orderdata.promo_type,
        promo_value:this.props.orderdata.promo_value,
          orderStatusSelected:this.props.orderdata.current_status,
          paymentTypeSelected:this.props.orderdata.billing_type,
		  insurance_charge:this.props.orderdata.insurance,
		  brokerage_charge:this.props.orderdata.brokerage,
			processing_fee:this.props.orderdata.processing,

          error:"",
          currentOrderStatus: this.props.orderdata.current_status

      
    
        })
        
 } 
    
    


  }



startRefresh=async()=>{
this.props.refreshpage();
}

updateOrder= async(header)=>{
  const  orderid= this.state.orderid

  
    try{
        var markedCheckbox = document.querySelectorAll('input[type="checkbox"]:checked');
  var scheck = "";
for (var checkbox of markedCheckbox) {
  if (scheck.length == 0)
  {
  scheck = checkbox.value;
}
else
{
  scheck = scheck + ',' + checkbox.value;
}
  
}
 
        var orderupdateForm = new FormData()
        orderupdateForm.append("formid",scheck)
        orderupdateForm.append("name",this.state.tracking_number)
        //orderupdateForm.append("shipper_number",this.state.shiper_order_number)
        
       // orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
        
        //orderupdateForm.append("previous_status",this.props.orderdata.current_status)
        orderupdateForm.append("status","Active")
        orderupdateForm.append("flag","1")
       

        let urlforOrderStatusUpdate = base_url+`roledetails/${this.state.orderid}/`
        const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
  
        console.log(`order status update succesfully ${response}`)
        this.setState({error:<Success message=" Order Updated"/>})
      
        M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});


        
        this.setState({ currentOrderStatus: this.state.orderStatusSelected })
        

        // window.location.reload(false);
      }catch(error){
        M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
          console.log(error)
          this.setState({error:<Error/>})
      }

      this.startRefresh();
}

updateOrderDB=()=>{
    let token =localStorage.getItem("token")
    const  header = {'Content-Type': 'application/json',
    'Authorization':'Token '+token,
    }
    this.updateOrder(header)


}

handleFormChange= async (ev) =>{
  const target = ev.target
  const name = target.name
  console.log(ev.target.name)
  console.log(ev.target.value)
      this.setState({
        [name]: target.value
      });
 
}
 componentDidMount(){
    M.AutoInit();
}

 openModal = (products,product_category)=>{
    //  console.log("inside editiordemodal");
    //  console.log(orderdata);
    var elems = document.getElementById("editorderModel");
    var instance = M.Modal.init(elems);
    console.log("openmodel",products)
    this.setState({productlist:products,
      product_category_options:product_category})
   

    instance.open()
    // orderselectionmodal
}
closeModal = ()=>{
    var elems = document.getElementById("editorderModel");
    var instance = M.Modal.init(elems);
    instance.close()
}

    render() {
      let {productsList} =  this.state;

      const languageList = [
  { value: "1", label: "Create Order" },
  { value: "2", label: "Order List" },
  { value: "3", label: "Charge List" },
  { value: "4", label: "Box List" },
  { value: "5", label: "Custom Settlement" },
  { value: "6", label: "Shipped" },
  { value: "7", label: "Referral" },
  { value: "8", label: "Reconcillation" },
  { value: "9", label: "Create Coupon" },
  { value: "10", label: "View Coupon" },
  { value: "17", label: "Curacao Customs List" },
  { value: "11", label: "Create Cash Invoice" },
  { value: "12", label: "Cash Invoice List" },
  { value: "18", label: "Cash Invoice Report" },
  { value: "13", label: "Create Role" },
  { value: "14", label: "Role List" },
  { value: "15", label: "Reports" },
  { value: "16", label: "Pending Orders" },
  { value: "19", label: "Shipping Rates" },
  { value: "20", label: "Create Shipment" },
  { value: "21", label: "View Shipment" },
  { value: "22", label: "Dashboard" }
];

const selecItem = [];
  const handleChange = e => {
  //   this.setState({
  //   Lang: e.target.checked
  //  })
    //alert(e.target.checked);
    if (e.target.checked)
    {
    selecItem.push(e.target.value);
  }
  else
  {
    for( var i = 0; i < selecItem.length; i++){ 
                                   
        if ( selecItem[i] === e.target.value) { 
            selecItem.splice(i, 1); 
            i--; 
        }
    }
  }
    //alert(selecItem.toString());
    // const { value, checked } = e.target;
    // if (checked) {
      
    //   // push selected value in list
    //   //setLang(prev => [...prev, value]);
    // } else {
    //   // remove unchecked value from the list
    //   //setLang(prev => prev.filter(x => x !== value));
    // }
  }
 
        const opts={};
        opts['readOnly'] = 'readOnly';

      // const catlist = this.state.product_category_options;
      // if (catlist.length > 0) {
      //   var categoryList = catlist.map((item, i) => {
      //     return (
      //       <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
      //     )
      //   }, this);
      // }



        return(<>              {/* Edit product Table items */}
          {/*<EditProduct ref="editproduct" rowNumber={this.state.rowNumber} status={this.state.orderStatusSelected}  orderId ={ this.state.orderid }
          productDetails={this.state.selectItemDetails}
           productcategory={this.state.product_category_options}
           updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}
           />*/}
            <div id="editorderModel" className="modal editorderModel modal-fixed-footer">
            <div className="modal-content">
              
            {/* <span><h4 className="center orange-text"> Edit Order </h4><h4>Order id: { this.state.ordernumber }</h4></span> */}
            <h4 className="center orange-text"> Edit Role </h4>
            {/*<h4>Invoice Number: <span className="orange-text">{this.state.ordernumber }</span> </h4>*/}
            <div className="row inputbox-height">
        <div className="input-field col s12">
<div className="input-field col s6">
          <input name="tracking_number" id="tracking_number" type="text" className=""
           value={this.state.tracking_number}  onChange={this.handleFormChange}
          />
          <label htmlFor="tracking_number">Enter Role</label>
          <span className="helper-text" data-error="wrong" data-success="">Define role name ex. admin</span>

        </div>
</div>
      
      </div>

       <div className="row">
          <div className="col s3">  
<div className="container">
      <div className="card">
        <div className="card-header">
          <p className="title">Select Menu </p>
        </div>
 
        
 
        
         { productsList.map((product, i) =>{
          
            if (this.state.arrform != undefined || this.state.arrform != null)
            {
            //alert(this.state.arrform);
            this.state.arrform.map((itemdb, index) => {
              //alert(item);
              if (product.value === itemdb && this.state.isedit === false)
              {
                //checked = true;
                this.state.productsList[i].isChecked = true
                 {/*this.setState((state, props) => {
      state.productsList[i].isChecked = true;
      return {
        productsList: state.productsList
      }
    })*/}

              }
            })
            }

            return(
              <tr key={i+1}>
                <td>{i+1}</td>
                <td></td>
                <td>
                    <div class="checkbox checkbox-circle checkbox-color-scheme">
                        <label class="checkbox-checked">
                            <input type="checkbox" value={product.value} checked={product.isChecked} onChange={this.onAddingItem(i)}/> <span class="label-text">{product.label}</span>
                        </label>
                    </div>
                </td>
            </tr>
            )
          })}
      </div>
    </div>
</div>
</div>

            
        
         
     
            </div>
            <div className="modal-footer">
           
            <ButtonGroup className="mr-2" aria-label="First group">
            <Button id="closeEditOrderBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button> 
            <Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderDB}> Update</Button>
            {/*{
              this.state.orderStatusSelected != 'SP' && this.state.orderStatusSelected != 'NEW' && this.state.orderStatusSelected != 'DISC'
              ?<Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderDB}> Update</Button>
              :""
            }*/}
            
        
          </ButtonGroup>
            </div>
          </div>


        


              </>
        )
    }
}

export default EditOrder;
