import React, {Component} from 'react';
import {Card, Row, Col,ButtonGroup,Button,Spinner,Nav,Image} from 'react-bootstrap';
import M from 'materialize-css';
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsListReturn from './ProductItemListCard_Pack_Return';
import EditProductReturn from './EditProductItem_Pack_Return';
// import CustomerDetailCard from './CustomerDetailCard'
import { base_url } from '../../globalConstants';
import "./createorder.css";


class EditOrder extends Component{


constructor(props, context){
        super (props, context);
        console.log("construct ",this.props.productlist)
        this.state =({
            order:this.props.order,
            pageName: this.props.pagename,
            // current_date: date,
            country_list: new Array(),
            country_codelist: new Array(),
            statedata: new Array(),
            stateCode_list: new Array(),
            stateName_list:  new Array(),
            statelist: new Array(),
            statelistto: new Array(), 
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :<></>,
            product_category:"",ordernumber:"",
         customer_id:"",order_date:"",
        received_date:"",tracking_number:"",shiper_order_number:"",
        weight:"",height:"",fedex_charge:"",width:"",length:"",actual_charge:"",homedelivery_charge:"",promo_type:"",promo_value:0,processing_fee:"",brokerage_charge:"",insurance_charge:"",
          orderStatusSelected:"",
          paymentTypeSelected:"",showSpinner:false,
          customerinfo:{},
          error:"",
          selectItemDetails:{},
      
          currentOrderStatus: '',
          ship_date:'',
          from_country_filled	:"",
          to_country_filled	:"",
          from_country	:"",
from_company	:"",
from_contact	:"",
from_address1	:"",
from_address2	:"",
from_zip	:"",
from_city	:"",
from_state	:"",
from_phoneno	:"",
to_country	:"",
to_company	:"",
to_contact	:"",
to_address1	:"",
to_address2	:"",
to_zip	:"",
to_city	:"",
to_state	:"",
to_phoneno	:"",
packageno	:"",
weight_unit	:"LB",
declared_value_unit	:"USD",
total_weight	:"",
declared_value	:"",
total_custom	:"",
service_type	:"Economy",
package_type	:"",
package_content	:"commodities",
brokerselect:"yes",
package_identical:"",
purpose	:"",
broker_company	:"",
broker_accno	:"",
broker_comp_name	:"",
broker_contact	:"",
broker_address1	:"",
broker_address2	:"",
broker_city	:"",
broker_country	:"",
broker_phone	:"",
broker_taxid	:"",
commod_desc	:"",
commod_unit	:"",
commod_qty	:"",
commod_weight	:"",
commod_value	:"",
commod_country:"",
parenttrackno:""
        })
    
    }

    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   
// getproducts = async (orderid) => {
//     // http://localhost:8000/api/v1/ezzytrace/products/?order_id=
//     try {
//       let token = localStorage.getItem("token")
//       // let header = {'Content-Type': 'multipart/form-data',
//       // 'Authorization':'Token '+token,

//       // }

//       var config = {
//         headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

//       };

//       var url = base_url+`shipmentpackage/?order_id=22-000002`
//       let products = await Axios.get(url, config)
//       console.log("***orderlist-openmodel")
//       console.log(products.data)
//       // await this.setState({productlisthtml:<ProductItemsList products={new Array()}/>})
//       var items = new Array()
//       products.data.forEach((product) => {
//         let newitem = {
//             id: 1, productName: "",
//             productPrice: 0, productQuantity: 0,
//             productCategory: "",productSKU: "",productStatus: "",productExport:false,productSelect:""}
        
//             newitem = {
//             id: product.id, productName: product.weight,
//             productPrice: product.width, productQuantity: product.length,
//             productCategory: product.siteid,productSKU: product.height,productStatus: "Active",
//             productTemplate: "",productExport:false,productCarriage:product.carriage_value,productSelect:product.qty
//           }
//           items.push(newitem)
        


        
//         // this.state.productlist.push(newitem)
//       })

//       M.toast({ html: 'Fetched package detail for selected shipment', classes: "white-text orange rounded" });
//       // this.setState({error:<Success/>})


//       await this.setState({ productlist: items })

//       await this.setState({ productlisthtml: <ProductItemsList orderid="22-000002" products={items} rowclick={this.handletableClick} /> })


//     } catch (error) {
//       console.log(error)
//       M.toast({ html: 'Failed to fetch products', classes: "white-text red rounded" });
      
//     }
//   }

  componentDidUpdate(prevProps) {


    if (this.props.orderdata.id !== prevProps.orderdata.id) {
      console.log("Product error",this.props.productlist)
       console.log("order data",this.props.orderdata)
       //console.log("package is iden",this.props.package_type)
      //console.log("catefory options",this.props.orderdata.package_type)
      
        this.setState({order:this.props.orderdata,
            //   current_date: this.props.orderdata.order_date,
            country_list: this.props.countrylist,
            pageName: this.props.pagename,
            country_codelist: this.props.countrycodelist,
            countrynamelist: this.props.country_name_list,
            countrycodelist: this.props.country_code_list,
              statedata: this.props.statedata, 

            orderid :this.props.orderdata.id,
            //customerinfo:this.props.customerinfo,
            productlist:this.props.productlist ,
            productlisthtml:this.props.productlisthtml ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :this.props.productCategory,
            // showSpinner:false,
                

            product_category:"",ordernumber:this.props.orderdata.order_id,
           from_country	:this.props.orderdata.from_country,
           ship_date:this.props.orderdata.ship_date,
from_company	:this.props.orderdata.from_company,
from_contact	:this.props.orderdata.from_contact,
from_address1	:this.props.orderdata.from_address1,
from_address2	:this.props.orderdata.from_address2,
from_zip	:this.props.orderdata.from_zip,
from_city	:this.props.orderdata.from_city,
from_state	:this.props.orderdata.from_state,
from_phoneno	:this.props.orderdata.from_phoneno,
to_country	:this.props.orderdata.to_country,
to_company	:this.props.orderdata.to_company,
to_contact	:this.props.orderdata.to_contact,
to_address1	:this.props.orderdata.to_address1,
to_address2	:this.props.orderdata.to_address2,
to_zip	:this.props.orderdata.to_zip,
to_city	:this.props.orderdata.to_city,
to_state	:this.props.orderdata.to_state,
to_phoneno	:this.props.orderdata.to_phoneno,
packageno	:this.props.orderdata.packageno,
weight_unit	:this.props.orderdata.weight_unit,
declared_value_unit	:this.props.orderdata.declared_value_unit,
total_weight	:this.props.orderdata.total_weight,
declared_value	:this.props.orderdata.declared_value,
total_custom	:this.props.orderdata.total_custom,
service_type	:this.props.orderdata.service_type,
package_type	:this.props.orderdata.package_type,
package_content	:this.props.orderdata.package_content,
package_identical:this.props.orderdata.package_identical,
purpose	:this.props.orderdata.purpose,
broker_company	:this.props.orderdata.broker_company,
broker_accno	:this.props.orderdata.broker_accno,
broker_comp_name	:this.props.orderdata.broker_comp_name,
broker_contact	:this.props.orderdata.broker_contact,
broker_address1	:this.props.orderdata.broker_address1,
broker_address2	:this.props.orderdata.broker_address2,
broker_city	:this.props.orderdata.broker_city,
broker_country	:this.props.orderdata.broker_country,
broker_phone	:this.props.orderdata.broker_phone,
broker_taxid	:this.props.orderdata.broker_taxid,
commod_desc	:this.props.orderdata.commod_desc,
commod_unit	:this.props.orderdata.commod_unit,
commod_qty	:this.props.orderdata.commod_qty,
commod_weight	:this.props.orderdata.commod_weight,
commod_value	:this.props.orderdata.commod_value,
parenttrackno :this.props.orderdata.master_child_trackingno,
commod_country: this.props.orderdata.commod_country,
      //    customer_id:this.props.orderdata.ezz_id,order_date:this.props.orderdata.order_date,
      //   received_date:this.props.orderdata.received_date,tracking_number:this.props.orderdata.inbound_tracking_number,
      //   shiper_order_number:this.props.orderdata.shipper_number,
      //   weight:this.props.orderdata.weight,height:this.props.orderdata.height,
      //   fedex_charge:this.props.orderdata.freight_charge, width:this.props.orderdata.breadth,
      //   length:this.props.orderdata.length,actual_charge:this.props.orderdata.actual_charge,
      //   homedelivery_charge:this.props.orderdata.homedelivery_charge,
      //   promo_type:this.props.orderdata.promo_type,
      //   promo_value:this.props.orderdata.promo_value,
      //     orderStatusSelected:this.props.orderdata.current_status,
      //     paymentTypeSelected:this.props.orderdata.billing_type,
		  // insurance_charge:this.props.orderdata.insurance,
		  // brokerage_charge:this.props.orderdata.brokerage,
			// processing_fee:this.props.orderdata.processing,

          error:"",
          currentOrderStatus: this.props.orderdata.current_status

      
    
        })

       console.log("statedata1: ",this.state.statedata)
       if (this.props.orderdata.broker_comp_name != undefined)
       {
        if (this.props.orderdata.broker_comp_name.length > 0)
        {
            this.setState({brokerselect	:"yes"})
        }
        else{
          this.setState({brokerselect	:"no"})
        }
      }
       //alert(this.props.orderdata.broker_comp_name);

        if (this.props.orderdata.package_identical == 'yes')
        {
          
          document.getElementById("pnlitemdetail").style.display = "none"
          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 
        }
        else
        {
          document.getElementById("pnlitemdetail").style.display = "inline-grid"
          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 
        }
      
        // let country_name_from = this.props.countrylist[this.props.countrycodelist.indexOf(this.props.orderdata.from_country)]
        // this.setState({from_country: country_name_from.name})
        // let country_name_to = this.props.countrylist[this.props.countrycodelist.indexOf(this.props.orderdata.to_country)]
        // this.setState({to_country: country_name_to.name})
        // if (this.props.orderdata.broker_country != undefined)
        // {
        //   let country_name_broker = this.props.countrylist[this.props.countrycodelist.indexOf(this.props.orderdata.broker_country)]
        //   this.setState({broker_country: country_name_broker.name})
        //   let country_name_commod = this.props.countrylist[this.props.countrycodelist.indexOf(this.props.orderdata.commod_country)]
        //   this.setState({commod_country: country_name_commod.name})
        // }
 } 
        var elems_order = document.getElementById('ship_date');
      var context = this
      var newdate = this.newDate()
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
               let selecteddata =  context.formatDate(date)
              context.setState({ ship_date: selecteddata});
              //console.log("shipdateoforder",selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_order, options_order);
        instances.toString()
        var elems_recieved = document.getElementById('received_date');
        var options_recieved={
            //selectMonths: true, // Enable Month Selection
            selectYears: 1, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              let selecteddata =  context.formatDate(date)
              context.setState({ received_date: selecteddata});
              //console.log("recived", selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_recieved, options_recieved);
 
 instances.toString()

    


  }



// handletableClick=(clickedrow)=>{
//   console.log(clickedrow)
//   // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
//  let productdetail = this.state.productlist[clickedrow]
//  let categoryoptions = this.state.product_category_options
//  let order_id = this.state.orderid
//  console.log("handle product category",categoryoptions)

//  this.setState({rowNumber:clickedrow})
//  this.setState({selectItemDetails:productdetail})

 
//    this.refs.editproduct.openModal(clickedrow,productdetail,categoryoptions);
// }

// updateProduct= async (rownumber,item)=>{
//   console.log("UPDATING LIST HERE ",rownumber,item)
// let productlist = this.state.productlist
// productlist.splice(rownumber, 1, item)
// await this.setState({productlist:productlist})
// // this.refs.editproduct.closeModal();
// // M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});


// }

handletableClick=(clickedrow,exportstatus)=>{
  this.state.productlist[clickedrow].productExport = exportstatus
 // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
 let productdetail = this.state.productlist[clickedrow]
 let countryoptions = this.state.product_category_options
 let templateoptions = this.state.product_template_options
 //console.log("handle cat",countryoptions)
 //console.log("handle cat",this.state.product_edit_category)
 this.setState({rowNumber:clickedrow})
 this.setState({selectItemDetails:productdetail})
 
 if (productdetail.productStatus!= "Active")
   this.refs.editproductreturn.openModal(clickedrow,productdetail,countryoptions,templateoptions,1);
  else
   this.refs.editproductreturn.openModal(clickedrow,productdetail,countryoptions,templateoptions,2);
}

updateProduct=(rownumber,item)=>{
  console.log("updatig list",rownumber,item)
let productlist = this.state.productlist
productlist.splice(rownumber, 1, item)
this.setState({productlist:productlist})
// this.refs.editproduct.closeModal();
// M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});

}

deleteProduct= async (rownumber,item_id)=>{
  let tempItemId = item_id.toString()
  let updated_productlist = this.state.productlist
  console.log("DELETING ITEM HERE ",rownumber,item_id)

  if(!tempItemId.includes('item_')){
    await Axios({
      method: 'DELETE',
      url: base_url + `products/${item_id}/`
    }).then(function (response) {
      // console.log(`DELETED${tempProductId}`, response);
      M.toast({ html: `Product deleted successfully`, classes: "white-text blue rounded" })
    }).catch(function (response) {
      // console.log("NOT DELETED ERROR",response);
      M.toast({ html: `Product deletion failed`, classes: "white-text red rounded" })
    })
  }  

  updated_productlist.splice(rownumber, 1)
  await this.setState({productlist:updated_productlist})
  console.log(`ITEM ${item_id} DELETED AT ${rownumber}`)
  // this.refs.editproduct.closeModal();
  // M.toast({html: 'item deleted ' +rownumber,classes:"white-text blue rounded"});

}

// @TODO: updateOrderProdcts Method will used to update products in future
updateOrderProducts=async(header)=>{
    this.state.productlist.forEach(async(item)=>{
      // rowclick={this.handletableClick}
        console.log(item)
        try{
          var productformdata = new FormData()
          productformdata.append("id",item.id)
          productformdata.append("description",item.productName)
          productformdata.append("quantity",item.productQuantity)
          productformdata.append("unit_price",item.productPrice)
          productformdata.append("inspection_status","okay")
          productformdata.append("status","Active")
          productformdata.append("category",item.productCategory)
    
          const response_products = await Axios.patch(base_url+"products/",productformdata,
          header)
          // alert(response.data)
          console.log("Product added Successfully")
          this.setState({error:<Success message=" Order Status Updated"/>})
          M.toast({html: 'Product Addition Successfull',classes:"white-text blue rounded"});
        }catch(error){
          M.toast({html: 'Product Addition Failed',classes:"white-text red rounded"});
            console.log(error)
        }
       
      });
}

startRefresh=async()=>{
this.props.refreshpage();
}

updateOrder= async(header)=>{
  if (parseFloat(this.state.total_weight) != parseFloat(this.state.commod_weight))
    {
      alert("Total weight and commodity weight must be equal.")
      return;
    }
    
    if (parseFloat(this.state.total_custom) != parseFloat(this.state.commod_value))
    {
      alert("Total customs value and commodity declared value must be equal.")
      return;
    }

  const  orderid= this.state.ordernumber
   var countrycode_from="",countrycode_to="",countrycode_broker="",countrycode_commod="";
   countrycode_from = this.state.from_country;
   countrycode_to = this.state.to_country;
   countrycode_broker = this.state.broker_country;
   countrycode_commod = this.state.commod_country;

  //  this.state.country_list.map((country) => {
  //     if (country.name === this.state.from_country)
  //     {
  //       countrycode_from = country.country_id;
  //     }
  //     if (country.name === this.state.to_country)
  //     {
  //       countrycode_to = country.country_id;
  //     }
  //     if (country.name === this.state.broker_country)
  //     {
  //       countrycode_broker = country.country_id;
  //     }
  //     if (country.name === this.state.commod_country)
  //     {
  //       countrycode_commod = country.country_id;
  //     }
  //   })
  let country_code = this.state.country_codelist[this.state.country_list.indexOf(this.state.from_country)]
  // const found = this.state.productlist.find(element => element["productPrice"]==0);

  // console.log('found', found)

  // if(found && this.state.orderStatusSelected === 'RTM' ){
  //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
  //   return
  // }

    try{
        
        // var orderupdateForm = new FormData()
        // orderupdateForm.append("order_id",this.state.ordernumber)
       var Orderformdata = new FormData()
      console.log("shipdate:",this.state.ship_date)
      console.log(this.state.fedex_charge)
      console.log(this.state.actual_charge)
      // Orderformdata.append("order_id",orderid.toString())
      Orderformdata.append("ship_date", this.state.ship_date)
      Orderformdata.append("order_date", this.state.ship_date)
      Orderformdata.append("from_country", countrycode_from)
      Orderformdata.append("from_company", this.state.from_company)
      Orderformdata.append("from_contact", this.state.from_contact)
      Orderformdata.append("from_address1", this.state.from_address1)
      Orderformdata.append("from_address2", this.state.from_address2)
      Orderformdata.append("from_zip", this.state.from_zip)
      Orderformdata.append("from_city", this.state.from_city)
      Orderformdata.append("from_state", this.state.from_state)
      Orderformdata.append("from_phoneno", this.state.from_phoneno)
      Orderformdata.append("to_country", countrycode_to)
      Orderformdata.append("to_company", this.state.to_company)
      Orderformdata.append("to_contact", this.state.to_contact)
      Orderformdata.append("to_address1", this.state.to_address1)
      Orderformdata.append("to_address2", this.state.to_address2)
      Orderformdata.append("to_zip", this.state.to_zip)
      Orderformdata.append("to_city", this.state.to_city)
      Orderformdata.append("to_state", this.state.to_state)
      Orderformdata.append("to_phoneno", this.state.to_phoneno)
      Orderformdata.append("packageno", this.state.packageno)
      Orderformdata.append("weight_unit", this.state.weight_unit)


      Orderformdata.append("declared_value_unit", this.state.declared_value_unit)
      Orderformdata.append("total_weight", this.state.total_weight)
      Orderformdata.append("declared_value", this.state.declared_value)
      Orderformdata.append("total_custom", this.state.total_custom)
      Orderformdata.append("service_type", this.state.service_type)
      //Orderformdata.append("package_type", this.state.package_type)
      Orderformdata.append("package_type", "Your Packaging")
      Orderformdata.append("package_content", this.state.package_content)
      //Orderformdata.append("package_identical",this.state.package_identical)
      Orderformdata.append("package_identical","No")
      Orderformdata.append("purpose", this.state.purpose)
      Orderformdata.append("broker_company", this.state.broker_company)
      //Orderformdata.append("broker_company", this.state.broker_comp_name)
      Orderformdata.append("broker_accno", this.state.broker_accno)
      Orderformdata.append("broker_comp_name", this.state.broker_comp_name)
      Orderformdata.append("broker_contact", this.state.broker_contact)
      Orderformdata.append("broker_address1", this.state.broker_address1)
      Orderformdata.append("broker_address2", this.state.broker_address2)
      Orderformdata.append("broker_city", this.state.broker_city)
      Orderformdata.append("broker_country",countrycode_broker)
      Orderformdata.append("broker_phone", this.state.broker_phone)
      Orderformdata.append("broker_taxid", this.state.broker_taxid)
      Orderformdata.append("commod_desc", this.state.commod_desc)

      Orderformdata.append("commod_unit", this.state.commod_unit)
      Orderformdata.append("commod_qty", this.state.commod_qty)
      Orderformdata.append("commod_weight", this.state.commod_weight)
      Orderformdata.append("commod_value", this.state.commod_value)
      Orderformdata.append("commod_country", countrycode_commod)

let urlforOrderStatusUpdate
if (this.state.pageName == "usreturn")
  {
urlforOrderStatusUpdate = base_url+`shipmentreturn_us/${this.state.ordernumber}/`
  }
  else
  {
         urlforOrderStatusUpdate = base_url+`shipmentreturn/${this.state.ordernumber}/`
  }
        const response = await Axios.patch(urlforOrderStatusUpdate,Orderformdata, header)
  
        console.log(`order status update succesfully ${response}`)
        this.setState({error:<Success message=" Order Updated"/>})
      
        M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});


        //Updating The Product 

        this.state.productlist.forEach(async(item)=>{
          console.log("productitem")
          console.log(item)
          console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')

          

          try{
            // var productformdata = new FormData()
            // productformdata.append("order_id",this.state.ordernumber)
            
            var productformdata = new FormData()
            productformdata.append("order_id", orderid.toString())
           
              productformdata.append("qty", item.productSelect)
              productformdata.append("weight", item.productName)
              productformdata.append("length", item.productQuantity)
              productformdata.append("width", item.productPrice)
              productformdata.append("height", item.productSKU)
              productformdata.append("carriage_value", item.productCarriage)
              productformdata.append("return_order_id", item.productOrderId)
              productformdata.append("status", "Active")
              // const response_products = await Axios.post(base_url+"shipmentpackage/", productformdata,
              // header)

            if( typeof(item.id) === "number"  ){
              var response_products
if (this.state.pageName == "usreturn")
  {
response_products = await Axios.patch(base_url+`shipmentpackagereturn_us/${item.id}/`, productformdata,
                header)
  }
  else
  {
               response_products = await Axios.patch(base_url+`shipmentpackagereturn/${item.id}/`, productformdata,
                header)
  }
              // alert(response.data)
              console.log("Product added Successfully")
              this.setState({ error: <Success message=" Order Status Updated" /> })
              M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
              
            }else{
               var response_addProduct
if (this.state.pageName == "usreturn")
  {
response_addProduct = await Axios.post(base_url+`shipmentpackagereturn_us/`, productformdata,
              header )
  }
  else
  {
              response_addProduct = await Axios.post(base_url+`shipmentpackagereturn/`, productformdata,
              header )
  }
              //const response_addProduct = await Axios.post(base_url+`shipmentpackagereturn/`, productformdata,
              //header )

              M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
              
            }

            
            
          }catch(error){
            //M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
              console.log(error)
          }
         
        });
        



     
        // try{
        //   var email_subject = "invoice_missing"
        //    const status = this.state.orderStatusSelected
        //    switch(status){
        //      case "IM":
        //       email_subject ="invoice_missing";
        //       break;
        //      case "WFC":
        //       email_subject ="consolidation";
        //       break;
      
      
               
        //    }

        //    console.log('this.state.currentOrderStatus',this.state.currentOrderStatus)
        //    console.log('this.state.orderStatusSelected',this.state.orderStatusSelected)

        //   if ((status===  "IM" || status === "WFC") && (this.state.currentOrderStatus !== this.state.orderStatusSelected) ){
        //     const  emaildata= {"type" : email_subject,
        //     "ezz_id" : this.state.customer_id,
        //     "order_id" : orderid}


        //     Axios({
        //       method:'post',
        //       url: base_url+'status_printer',
        //       data: {
        //         "order_id": orderid
        //       }
        //     }).then(function(response){
        //       console.log(response)
        //       console.log("printer APi success");
        //     }).catch(function(response){
        //       console.log(response)
        //       console.log('printer API failed');
        //     })

        //     Axios({
        //       method:'post',
        //       url: base_url+'whatsapp_templates',
        //       data: emaildata,
        //     }).then(
        //       function(response){ 
        //         console.log(response)
        //         console.log("whatsapp sent done");
        //       }
        //     ).catch(
        //       function(response){ 
        //         console.log(response)
        //         console.log("whatsapp sent Failure");
        //       }
        //     )


        //     const response = await Axios.post(base_url+"send_email/",emaildata,
        //   header)
        //   console.log(response)
        //   M.toast({html: 'Email Sent to '+response.data["Email to"],classes:"white-text red rounded"});
    
        //   }
         
        // }catch(error){
      
        //   M.toast({html: 'Email Sending Failed to'+ this.state.customer_id ,classes:"white-text red rounded"});
        // }
      

        // this.setState({ currentOrderStatus: this.state.orderStatusSelected })
        

        // window.location.reload(false);
      }catch(error){
        M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
          console.log(error)
          this.setState({error:<Error/>})
      }

      this.startRefresh();
}

updateOrderDB=()=>{
    let token =localStorage.getItem("token")
    const  header = {'Content-Type': 'application/json',
    'Authorization':'Token '+token,
    }
    this.updateOrder(header)
}

updateOrderStatus= async()=>{
  //alert(this.state.parenttrackno)
  //return;
    let token =localStorage.getItem("token")
    const  header = {'Content-Type': 'application/json',
    'Authorization':'Token '+token,
    }
   try{
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if(dd<10) 
      {
      dd='0'+dd;
      } 

      if(mm<10) 
      {
      mm='0'+mm;
      } 
     
      today = yyyy+'-'+mm+'-'+dd;
      


      var productformdata = new FormData()
      productformdata.append("shipment_id", this.state.ordernumber)
var response_addProduct
if (this.state.pageName == "usreturn")
  {
 response_addProduct = await Axios.post(base_url+`fedex_ship_return_us`, productformdata,header )
  }
  else
  {
       response_addProduct = await Axios.post(base_url+`fedex_ship_return`, productformdata,header )
  }
//const response_addProduct = await Axios.post(base_url+`fedex_ship_return`, productformdata,header )
      var Orderformdata = new FormData()
      Orderformdata.append("shipped_on", today)
      Orderformdata.append("master_child_trackingno", response_addProduct.data.TrackingNo.toString())
      //Orderformdata.append("from_country", countrycode_from)
      let urlforOrderStatusUpdate
      if (this.state.pageName == "usreturn")
        {
            urlforOrderStatusUpdate = base_url+`shipmentreturn_us/${this.state.ordernumber}/`
        }
        else
        {
            urlforOrderStatusUpdate = base_url+`shipmentreturn/${this.state.ordernumber}/`
        }
        //let urlforOrderStatusUpdate = base_url+`shipmentreturn/${this.state.ordernumber}/`
        const response = await Axios.patch(urlforOrderStatusUpdate,Orderformdata, header)
  
        console.log(`Shipment status updated succesfully ${response}`)

      var invdata = new FormData()
      invdata.append("order_id", this.state.ordernumber)

var response_inv
if (this.state.pageName == "usreturn")
  {
 response_inv = await Axios.post(base_url+`create_return_consolidate_invoice_us/`, invdata,header )
  }
  else
  {
       response_inv = await Axios.post(base_url+`create_return_consolidate_invoice/`, invdata,header )
  }
      //const response_inv = await Axios.post(base_url+`create_return_consolidate_invoice/`, invdata,header )
      console.log(`Shipment consolidated invoice created succesfully ${response_inv}`)

          console.log(`Shipment status update succesfully1 ${response_addProduct}`)
          this.setState({error:<Success message=" Shipment Updated"/>})

          M.toast({html: 'Shipment Status Updated Successfull',classes:"white-text orange rounded"});
        }catch(error){
            M.toast({html: 'Shipment Status Updation Failed',classes:"white-text red rounded"});
            console.log(error)
            this.setState({error:<Error/>})
         }

      this.startRefresh();
}

getfedexActualRate = async (weight,height,length,breadth)=>{
    


  try{
let token =localStorage.getItem("token")
let config={ headers : {'Content-Type': 'multipart/form-data',
'Authorization':'Token '+token,
}
}

// Fetching user list on input change
//var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
//var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}`
var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}&orderno=${this.state.ordernumber}`
var response = await Axios.get(url,config)
console.log(response.data)
return response.data
}catch(error){



console.log(error.response.data)
  
if (error.response.status ===503 ){
 M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
}

// return response.data
return error.response.data
}

}

// handleFormChange= async (ev) =>{
//   const target = ev.target
//   const name = target.name
//   console.log(ev.target.name)
//   console.log(ev.target.value)
 
//   // hide and show on YTA
//   if (target.value === "YTA"){
//     this.setState({
//       [name]: target.value,
//       weight: 0,
//       height:0,
//       fedex_charge:0,
//       width:0,
//       length:0,
//       actual_charge:0,
//       homedelivery_charge:0,
// 	  processing_fee:0,
// 	  insurance_charge:0,
// 	  brokerage_charge:0,
//       promo_type:"Not",
//       promo_value:0
//     });
//   }else{

//     if(target.name ==="weight" || 
//     target.name ==="height" || target.name === "width"
//   || target.name === "length"
//     ){

//     this.setState({
//         [name]: target.value
//       });

//       var weight = this.state.weight
//       var width = this.state.width
//       var height = this.state.height
//       var length = this.state.length
//       var field = target.name;

//       switch (field) {
//         case "weight":
//           weight = target.value;
//           break;
//         case "height" :
//           height = target.value;
//           break;
//         case "width" :
//           width = target.value;
//           break;
//         case "length" :
//           length = target.value;
//           break;
       
//       }

//       if(width < 0 || height <0 || weight < 0 || length <0 || this.state.actual_charge <0){
//         M.toast({html: "values can not be negative",classes:"white-text green rounded"});
//         return;
//       }


//       if((width!=="" && parseInt(width)!==0) && (height !=="" && parseInt(height) !==0) && (width !=="" && parseInt(width) !==0 ) && (length!=="" && parseInt(length)!==0)){
//         await this.setState({
//           showSpinner:true
//         })
//         var response =  await this.getfedexActualRate(weight,height,length,width)
//         console.log(response["freight_charges"] + response["actual_charges"])
//           this.setState({
//             fedex_charge:response["freight_charges"],
//             actual_charge: response["actual_charges"],
//             homedelivery_charge: response["homedelivery_charge"],
// 			processing_fee: response["processing"],
// 			insurance_charge: response["insurance"],
// 			brokerage_charge: response["brokerage"],
//             promo_type: response["promo_type"],
//             promo_value: response["promo_value"],
//             showSpinner:false
//           })
//       }
     
//     }else{
//       this.setState({
//         [name]: target.value
//       });
//     }

 
//   }

 
// }
handleFormChange= async (ev) =>{
     
      const target = ev.target
      const name = target.name
      console.log(ev.target.name)
      this.setState({
            [name]: target.value
          });
      
      if (name == 'package_identical')
      {
        if (target.value == 'yes')
        {
          
          document.getElementById("pnlitemdetail").style.display = "none"
          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 
        }
        else
        {
          document.getElementById("pnlitemdetail").style.display = "inline-grid"
          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 
        }
      }
     
    }

    handleCountryNameChange = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelist: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    console.log(this.state.statelist)
   

  }

handleCountryNameChangeTo = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelistto: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    //console.log(this.state.statelist)
   

  }


handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      //var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      var patt = /^[0-9]/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    
    }


// addProductTolist = ()=>{
//   console.log(this.state.productname + this.state.productprice +this.state.productquantity)
//    var prodname =this.state.product_name
//    var prodquant =this.state.product_quantity
//    var prodcat =this.state.product_category
//    var prodprice =this.state.productprice
//    console.log("prody")
//    console.log(prodname + prodquant+prodcat )

//    if(prodname === "" || prodquant === 0 || prodcat ==="" || prodprice === ''){
//     M.toast({html: 'Incorrect or Empty details',classes:"white-text red rounded"});
//   }else{
    
//     var elem = document.getElementById('addProductModal')
//     var instance = M.Modal.getInstance(elem);
//     instance.close();
//     let totalproducts = this.state.productlist.length + 1
    
//     let newitem = {id:'item_'+totalproducts,productName:this.state.product_name,
//     productPrice:this.state.product_price ,
//     productQuantity:this.state.product_quantity,
//   productCategory:this.state.product_category
//   }

//     console.log("Product List",this.state.productlist)
//    this.state.productlist.push(newitem)
//     this.setState({productlist: this.state.productlist})
    
//   try {
//     // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
//     this.setState({product_name:"",product_price:0,product_quantity:0 })
//    M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
//   } catch (error) {
//     M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
//   }
   

//    }

// }

addProductTolist = ()=>{
      // console.log(this.state.productname + this.state.productprice +this.state.productquantity)
       var prodname =this.state.product_name1
       var prodquant =this.state.product_quantity
       var prodcat =this.state.product_category
       var templatename = this.state.product_template
      
       {
     

        let totalproducts = this.state.productlist.length + 1
        var templateprice = 0
        var templateqty = 0
        
          // if (this.state.product_selected !== this.state.product_name1)
          // {
          //   M.toast({ html: 'Product do not exist!Please contact your admin!', classes: "white-text red rounded" });
          //   return
          // }
          var elem = document.getElementById('addProductModal')
          let newitem = {id:'item_'+totalproducts,productName:this.state.product_name1,
          productPrice:this.state.product_price ,
          productQuantity:this.state.product_quantity,
          productCategory:this.state.product_category,
          productSKU:this.state.product_sku,
          productStatus: "Active",
          productTemplate:this.state.product_template,
          productExport:false,
          productCarriage:this.state.product_carriage,
          productSelect:this.state.search_name_select
          }
          this.state.productlist.push(newitem)
        
        var instance = M.Modal.getInstance(elem);
        instance.close();
        // console.log("Product List",this.state.productlist)
       //this.state.productlist.push(newitem)
        this.setState({productlist: this.state.productlist})
        
      try {
        // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
        this.setState({product_name1:"",product_price:0,product_quantity:0,product_sku:"",product_template:"" })
       M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
      } catch (error) {
        M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
      }
       
    
       }

    }
 changeTo= (e) =>{

	  switch (e.target.name) {
		case "weight":
		  this.setState({weight: e.target.value})
		  break;
		case "height" :
		  this.setState({height: e.target.value})
		  break;
		case "width" :
		  this.setState({width: e.target.value})
		  break;
		case "length" :
		  this.setState({length: e.target.value})
		  break;
	  }
			//this.setState({weight: e.target.value})
			 //// console.log("is change to");
			  console.log(e.target.value);
			  this.handleFormChange(e);
  }

  updateValue= (e) =>{

	  switch (e.target.name) {
		case "weight":
		  this.setState({weight: e.target.value})
		  break;
		case "height" :
		  this.setState({height: e.target.value})
		  break;
		case "width" :
		  this.setState({width: e.target.value})
		  break;
		case "length" :
		  this.setState({length: e.target.value})
		  break;
	  }
			   //this.setState({weight: e.target.value})
			  //console.log("is updtevalue");
			  //console.log(e.target.value);
	  
  }


 componentDidMount(){
    M.AutoInit();
}

 openModal = (products,countryist)=>{
    //  console.log("inside editiordemodal");
    //  console.log(orderdata);
    var elems = document.getElementById("editorderModelShipment");
    var instance = M.Modal.init(elems);
    console.log("openmodel",products)
    console.log("openmodal country",countryist)
    // this.setState({productlist:products,
    //   product_category_options:product_category})
       this.setState({productlist:products,country_list: countryist})
   

    instance.open()
    // orderselectionmodal
}

 openModalReturn = async(products,countryist,boxselectedlist,overridewarehouse,countrylist,country_codelist,country_namelist,country_codelist1,state_list)=>{
    //  console.log("inside editiordemodal");
    //  console.log(orderdata);
    //alert("No of Order Selected:" + boxselectedlist.length);
    this.setState({  packageno: boxselectedlist.length })
    console.log(boxselectedlist[0].toString());
    //this.setState({from_address1: boxselectedlist.toString()})
    this.setState({country_list: countrylist, country_codelist: country_codelist, countrynamelist:country_namelist,
 countrycodelist:country_codelist ,statedata:state_list})
    var elems = document.getElementById("editorderModelShipment");
    var instance = M.Modal.init(elems);
    console.log("openmodel",products);
    console.log("openmodal country",countryist);
    
    //await this.getproducts("20223");
    
    // this.setState({productlist:products,
    //   product_category_options:product_category})
    let searchUrl
if (this.state.pageName == "usreturn")
  {
     searchUrl = base_url+`boxes_received_return_us/${boxselectedlist[0].toString()}` 
  }
  else
  {
     searchUrl = base_url+`boxes_received_return/${boxselectedlist[0].toString()}` 
  }
    //let searchUrl = base_url+`boxes_received_return/${boxselectedlist[0].toString()}`   
  Axios({
    method: 'GET',
    url: searchUrl
  }).then( async (response) =>{
   let  resOrder = response.data
      this.setState({
          from_country	:resOrder.from_country,
          from_company	:resOrder.from_company,
          from_contact	:resOrder.from_contact,
          from_address1	:resOrder.from_address1,
          from_address2	:resOrder.from_address2,
          from_zip	:resOrder.from_zip,
          from_city	:resOrder.from_city,
          from_state	:resOrder.from_state,
          from_phoneno	:resOrder.from_phoneno,
          to_country	:resOrder.to_country,
          to_company	:resOrder.to_company,
          to_contact	:resOrder.to_contact,
          to_address1	:resOrder.to_address1,
          to_address2	:resOrder.to_address2,
          to_zip	:resOrder.to_zip,
          to_city	:resOrder.to_city,
          to_state	:resOrder.to_state,
          to_phoneno	:resOrder.to_phoneno,
          commod_desc: resOrder.product_details[0].description  //05-07-2024
        })


          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 

      var catlist1 = [];
      var catlistto = [];
      if (this.state.statedata.length>0)
      {
        //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
        if (resOrder.from_country != undefined)
        {
          catlist1 = this.state.statedata[this.state.countrycodelist.indexOf(resOrder.from_country)].slice()
        }
        // else
        // {
        //   catlist1 = this.state.statedata[this.state.countrycodelist.indexOf(resOrder.from_country)].slice()
          
        // }
        this.setState({  statelist: catlist1 })
        console.log("fromstate1", "start")
        this.setState({  from_state: resOrder.from_state })
        console.log("fromstate2",resOrder.from_state)
        //this.setState({  from_country_filled: this.props.orderdata.from_country })
      }

      if (this.state.statedata.length>0)
      {
        //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
        if (resOrder.to_country != undefined)
        {
        catlistto = this.state.statedata[this.state.countrycodelist.indexOf(resOrder.to_country)].slice()
        }
        // else
        // {
        //   catlistto = this.state.statedata[this.state.countrycodelist.indexOf('CO')].slice()
        // }
        this.setState({  statelistto: catlistto })
        console.log("state2", catlist1)
        this.setState({  to_state: resOrder.to_state })
        //this.setState({  from_country_filled: this.props.orderdata.from_country })
        //this.setState({  to_country_filled: this.props.orderdata.to_country })
    }
    
    if (overridewarehouse == true)
    {
      this.setState({
          to_country	:"US",
          to_company	:"Ezzyship",
          to_contact	:"Ezzyship",
          to_address1	:"12281 132ND CT",
          to_address2	:"",
          to_zip	:"33186",
          to_city	:"MIAMI,",
          to_state	:"3",
          to_phoneno	:"12341234123",
        })
    }

   
    //this.setState({currentOrderListCount: response.data.results.length})
    
  }).catch(  (response) =>{
    console.log(response)
   // this.setState({showSpinner:false})
    // M.toast({ html: response.Error, classes: "white-text red rounded" })
   
  })

       //this.setState({productlist:products,country_list: countryist})
       var totalweight = 0;
       var totaldecvalue = 0;
       var totalfreight = 0;
       var totalqty = 0; //25-0-/24
   products.forEach(async (item) => {
       totalweight = totalweight + parseInt(item.productName)
       totaldecvalue = totaldecvalue + parseFloat(item.productCarriage)
       totalfreight = totalfreight + parseFloat(item.productSelect )
       totalqty = totalqty + parseFloat(item.productQuantity ) //25-06-24
   });
   //total_custom : totalfreight,
this.setState({productlist:products,country_list: countryist, total_weight : totalweight,
          declared_value : totaldecvalue,
          total_custom : totaldecvalue,

          commod_weight : totalweight,
          commod_value : totaldecvalue,
          commod_qty: totalqty})
    instance.open()
    // orderselectionmodal
}
closeModal = ()=>{
    var elems = document.getElementById("editorderModelShipment");
    var instance = M.Modal.init(elems);
    instance.close()
}

createOrderNormal = async () => {

    // if (parseFloat(this.state.total_weight) != parseFloat(this.state.commod_weight))
    // {
    //   alert("Total weight and commodity weight must be equal.")
    //   return;
    // }
    
    // if (parseFloat(this.state.total_custom) != parseFloat(this.state.commod_value))
    // {
    //   alert("Total customs value and commodity declared value must be equal.")
    //   return;
    // }

    this.setState({isOrderUpdate: true})
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    console.log("create new order" + this.state.createNewOrder)

    // if (this.state.createNewOrder) {

     
      var Orderformdata = new FormData()
      // console.log("orderid"+orderid)
      console.log(this.state.fedex_charge)
      console.log(this.state.actual_charge)
      // Orderformdata.append("order_id",orderid.toString())
     //Orderformdata.append("ship_date", "2020-01-01")
      Orderformdata.append("ship_date", this.state.ship_date)
      Orderformdata.append("order_date", this.state.ship_date)
      Orderformdata.append("from_country", this.state.from_country)
      Orderformdata.append("from_company", this.state.from_company)
      Orderformdata.append("from_contact", this.state.from_contact)
      Orderformdata.append("from_address1", this.state.from_address1)
      Orderformdata.append("from_address2", this.state.from_address2)
      Orderformdata.append("from_zip", this.state.from_zip)
      Orderformdata.append("from_city", this.state.from_city)
      Orderformdata.append("from_state", this.state.from_state)
      Orderformdata.append("from_phoneno", this.state.from_phoneno)
      Orderformdata.append("to_country", this.state.to_country)
      Orderformdata.append("to_company", this.state.to_company)
      Orderformdata.append("to_contact", this.state.to_contact)
      Orderformdata.append("to_address1", this.state.to_address1)
      Orderformdata.append("to_address2", this.state.to_address2)
      Orderformdata.append("to_zip", this.state.to_zip)
      Orderformdata.append("to_city", this.state.to_city)
      Orderformdata.append("to_state", this.state.to_state)
      Orderformdata.append("to_phoneno", this.state.to_phoneno)
      Orderformdata.append("packageno", this.state.packageno)
      Orderformdata.append("weight_unit", this.state.weight_unit)

      Orderformdata.append("declared_value_unit", this.state.declared_value_unit)
      Orderformdata.append("total_weight", this.state.total_weight)
      Orderformdata.append("declared_value", this.state.declared_value)
      Orderformdata.append("total_custom", this.state.total_custom)
      Orderformdata.append("service_type", this.state.service_type)
      //Orderformdata.append("package_type", this.state.package_type)
      Orderformdata.append("package_type", "Your Packaging")
      Orderformdata.append("package_content", this.state.package_content)
      //Orderformdata.append("package_identical", this.state.package_identical)
      Orderformdata.append("package_identical", "No")
      Orderformdata.append("purpose", this.state.purpose)
      Orderformdata.append("broker_company", this.state.broker_company)
      //Orderformdata.append("broker_company", this.state.broker_comp_name)
      Orderformdata.append("broker_accno", this.state.broker_accno)
      Orderformdata.append("broker_comp_name", this.state.broker_comp_name)
      Orderformdata.append("broker_contact", this.state.broker_contact)
      Orderformdata.append("broker_address1", this.state.broker_address1)
      Orderformdata.append("broker_address2", this.state.broker_address2)
      Orderformdata.append("broker_city", this.state.broker_city)
      Orderformdata.append("broker_country",this.state.broker_country)
      Orderformdata.append("broker_phone", this.state.broker_phone)
      Orderformdata.append("broker_taxid", this.state.broker_taxid)
      Orderformdata.append("commod_desc", this.state.commod_desc)

      //Orderformdata.append("commod_unit", this.state.commod_unit)
      Orderformdata.append("commod_unit", "Pieces")
      Orderformdata.append("commod_qty", this.state.commod_qty)
      Orderformdata.append("commod_weight", this.state.commod_weight)
      Orderformdata.append("commod_value", this.state.commod_value)
      Orderformdata.append("commod_country", this.state.commod_country)
     
      try {

        // const response = await Axios.post(base_url+"shipment/", Orderformdata,
        //   header)
        var response
if (this.state.pageName == "usreturn")
  {
  response = await Axios.post(base_url+"shipmentreturn_us/", Orderformdata,
          header)
  }
  else
  {
      response = await Axios.post(base_url+"shipmentreturn/", Orderformdata,
          header)
  }
          // const response = await Axios.post(base_url+"shipmentreturn/", Orderformdata,
          // header)
          const orderid = response.data.order_id
          //console.log("orderid" + orderid)

          // var existingOrderUpdateForm = new FormData()
          // existingOrderUpdateForm.append("current_status", current_status)
          // let urlforexistingOrderStatusUpdate = base_url+`boxes_received_return/${existing_order}/`
          // await Axios.patch(urlforexistingOrderStatusUpdate, existingOrderUpdateForm, header)

          this.state.productlist.forEach(async (item) => {
          console.log("productitem")
          console.log(item)
          try {
              var existingOrderUpdateForm = new FormData()
              existingOrderUpdateForm.append("boxid", item.productStatus)
              console.log("boxid" + response.data.box_id)
              var response_status
if (this.state.pageName == "usreturn")
  {
  response_status = await Axios.post(base_url+`update_status_byboxid_us/`, existingOrderUpdateForm,header )
  }
  else
  {
      response_status = await Axios.post(base_url+`update_status_byboxid/`, existingOrderUpdateForm,header )
  }
              //const response_status = await Axios.post(base_url+`update_status_byboxid/`, existingOrderUpdateForm,header )


              var productformdata = new FormData()
              productformdata.append("order_id", orderid.toString())
              productformdata.append("qty", item.productQuantity)
              productformdata.append("weight", item.productName)
              productformdata.append("length", item.productCategory)
              productformdata.append("width", item.productPrice)
              productformdata.append("height", item.productSKU)
              productformdata.append("carriage_value", item.productCarriage)
              productformdata.append("box_id", item.productStatus)
              productformdata.append("freight_charge", item.productSelect)
              productformdata.append("return_order_id", item.productOrderId)
              productformdata.append("status", "Active")

               var response_products
if (this.state.pageName == "usreturn")
  {
  response_products = await Axios.post(base_url+"shipmentpackagereturn_us/", productformdata,
              header)
  }
  else
  {
      response_products = await Axios.post(base_url+"shipmentpackagereturn/", productformdata,
              header)
  }
              // const response_products = await Axios.post(base_url+"shipmentpackagereturn/", productformdata,
              // header)
            
            // alert(response.data)
            //console.log(productformdata)
            console.log("Product added Successfully")
            this.setState({ error: <Success message=" Order Status Updated" /> })
            M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
          } catch (error) {
            //M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
            console.log(error)
          }

        });

        this.setState({ error: <Success message=" New order foproduct items created" /> })
        M.toast({ html: 'Order created successfully', classes: "white-text orange rounded" })
        this.setState({ error: "" })
        this.setState({ generatedOrderId: orderid })
        console.log('generatedOrderId',this.state.generatedOrderId);
        // document.getElementById("printLabel").disabled = false

        //await this.getproducts(orderid)

      } catch (err) {

        //M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

        console.log(err)
        this.setState({ error: <Error /> })
      }




    // } else {


      

    // }
    
    document.getElementById('saveOrderBtn').disabled = true

  }
  

    render() {
  //         const opts={};
  //  opts['readOnly'] = 'readOnly';
  const catlist = this.state.country_list;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.country_id}>{item.name}</option>
      )
    }, this);
  }

 var states = [];
 var catlist1 = [];
 var catlistto = [];
 if (this.state.from_country_filled != this.props.orderdata.from_country)
 {
  if (this.state.statedata.length>0)
      {
      //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
      if (this.props.orderdata.from_country != undefined)
      {
        catlist1 = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
      }
      else
      {
        //catlist1 = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
        if (this.props.orderdata.from_country != undefined)
        {
        catlist1 = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
        }
      }
      this.setState({  statelist: catlist1 })
      this.setState({  from_country_filled: this.props.orderdata.from_country })
    }
 }
  catlist1 = this.state.statelist;
  if (catlist1.length >0){
   var selectStateOptions = catlist1.map((state,statekey) => {
      console.log("state1", catlist1)

      return (

        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
    
  }
  else
  {
    // if (this.state.statedata.length>0)
    // {
    // //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    // const states = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
    // this.setState({  statelist: states })
    // }
  }

if (this.state.to_country_filled != this.props.orderdata.to_country)
 {
  if (this.state.statedata.length>0)
      {
      //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
      if (this.props.orderdata.to_country != undefined)
      {
      catlistto = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.to_country)].slice()
      }
      else
      {
        catlistto = this.state.statedata[this.state.countrycodelist.indexOf('CO')].slice()
      }
      this.setState({  statelistto: catlistto })
      this.setState({  to_country_filled: this.props.orderdata.to_country })
    }
 }
catlistto = this.state.statelistto;
  if (catlistto.length >0){
   var selectStateOptionsTo = catlistto.map((state,statekey) => {
      console.log("state", state)

      return (

        //<option  key={statekey++} value={state.state_id}>{state.state_name}</option>
        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    // if (this.state.statedata.length>0)
    // {
    // //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    // const states = this.state.statedata[this.state.countrycodelist.indexOf(this.props.orderdata.from_country)].slice()
    // this.setState({  statelist: states })
    // }
  }

      // const catlist = this.state.product_category_options;
      // if (catlist.length > 0) {
      //   var categoryList = catlist.map((item, i) => {
      //     return (
      //       <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
      //     )
      //   }, this);
      // }



        return(<>              {/* Edit product Table items */}
          <EditProductReturn ref="editproductreturn" rowNumber={this.state.rowNumber} status={this.state.orderStatusSelected}  orderId ={ "22-000002" }
          productDetails={this.state.selectItemDetails}
           productcategory={this.state.product_category_options}
           updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}
           />
            <div id="editorderModelShipment" className="modal editorderModel modal-fixed-footer">
            <div className="modal-content">
              
            {/* <span><h4 className="center orange-text"> Edit Order </h4><h4>Order id: { this.state.ordernumber }</h4></span> */}
            <h4 className="center orange-text"> Edit Shipment </h4>
            <h4>Order id: <span className="orange-text">{this.state.ordernumber }</span> </h4>
            
            <Row>


{/* right side column starts here */}
<Col xs={12} md={12} lg={12}>


{/*<div className="row">
          <div className="col s3">
          <h4 className=" orange-text">Edit Shipment</h4>
          </div>
       
</div>*/}
<Row>
        <Col xs={12} md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" >
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">From</h4>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='from_country' name="from_country" value={this.state.from_country}  className="browser-default" onChange={this.handleCountryNameChange}>>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >*Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_company" id="from_company" type="text" 
            value={this.state.from_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_contact" id="from_contact" type="text" 
            value={this.state.from_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address1" id="from_address1" type="text" 
            value={this.state.from_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address2" id="from_address2" type="text" 
            value={this.state.from_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_zip" id="from_zip" type="number" 
            value={this.state.from_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_city" id="from_city" type="text" 
            value={this.state.from_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="from_state" id="from_state" type="text" 
            value={this.state.from_state} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          <select id="from_state" name="from_state" value={this.state.from_state}  className="browser-default"
           onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" disabled>Choose state </option>
                                {selectStateOptions}
                              </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_phoneno" id="from_phoneno" type="number" 
            value={this.state.from_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

<div className="row inputbox-height">
      <div className="col s6">
*Required Fields
        </div>
        </div>
                    {/* right side columns ends here */}
{/*<div className="row inputbox-height">
      <div className="col s6">
<Button className="ml-5 " id="saveOrderBtn" onClick={this.addOrderToDB} variant="primary"  >Reset origin</Button>
        </div>
        </div>*/}
                     </Col> 
      <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" >
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">To</h4>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='to_country' name="to_country"  value={this.state.to_country} className="browser-default" onChange={this.handleCountryNameChangeTo}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >*Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_company" id="to_company" type="text" 
            value={this.state.to_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_contact" id="to_contact" type="text" 
            value={this.state.to_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address1" id="to_address1" type="text" 
            value={this.state.to_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address2" id="to_address2" type="text" 
            value={this.state.to_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_zip" id="to_zip" type="number" 
            value={this.state.to_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_city" id="to_city" type="text" 
            value={this.state.to_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="to_state" id="to_state" type="text" 
            value={this.state.to_state} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          <select id="to_state" name="to_state" value={this.state.to_state}  className="browser-default"
           onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" disabled>Choose state </option>
                                {selectStateOptionsTo}
                              </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_phoneno" id="to_phoneno" type="number" 
            value={this.state.to_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>


                  <div className="row inputbox-height">
      <div className="col s6">
*Required Fields
        </div>
        </div>
                    {/* right side columns ends here */}
                          {/*<div className="row inputbox-height">
      <div className="col s6">
<Button className="ml-5 " id="saveOrderBtn" onClick={this.addOrderToDB} variant="primary"  >Reset Destination</Button>
        </div>
        </div>*/}

                     </Col>   
                </Row> 
{/*                
                <Row>
                  <Col xs={12}>
                  <div className="row inputbox-height center">
      <div className="col s6 center">
<Button className="ml-5 white-text orange" id="updateOrder" variant="success" onClick={this.showcard} >Continue</Button>                        
</div>
        </div>
                  </Col>
                  </Row>*/}

                   <Row>
            <Col>
            
            <Card className="" id="shipdetail" style={{width:"100%"}} >
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">Package and Shipment</h4>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >*Ship Date:</h6> 
                        </Col>
                        <Col xs={9}><div className="input-field col s6">
        
          <input name="ship_date" id="ship_date" type="text"  className="datepicker" 
          value={this.state.ship_date} onChange={this.handleCustomerIDChange}
         
          />
          
          <label htmlFor="order_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i></label>
          <span className="helper-text" data-error="wrong" data-success="">Select Ship Date </span>
        </div>
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >Number of Packages:</h6> 
                        </Col>
                        <Col xs={3}><input name="packageno" id="packageno" type="number" 
            value={this.state.packageno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row style={{visibility:"hidden"}}>
                    <Col xs={3}>
                        <h6 >Are packages identical:</h6> 
                        </Col>
                        <Col xs={9}>  
           
             
            <label>
                <input name="package_identical" value="yes" type="radio"  
                  checked={this.state.package_identical ==="yes"} onChange={this.handleFormChange}
                />
                <span>Yes</span>
            </label>
           
            <label>
                <input name="package_identical" value="no" type="radio" 
                  checked={this.state.package_identical ==="no"} onChange={this.handleFormChange} />
                <span>No</span>
            </label>
         
            
       
                    </Col>
                    </Row>
                    <Row id="pnlitemdetail" style={{width:"100%"}}>
 {/* right side column starts here */}
                    <Col xs={12}md={12} lg={12}>


                           

                        <Row>
                        <Col>
                        <h5>Package Details</h5>
                        </Col>
                        <Col>
                        {/*<Nav className="right">
            <Nav.Link className="red-text modal-trigger "  href="#addProductModalReturn">
                Add Package<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
                 
                
        </Nav>*/}
        
     
                        </Col>
                    </Row>
                    {/* product card to be shown here */}
                    {/* <div className="productlistDiv ">{this.state.productlisthtml}</div> */}
                    <div className="productlistDiv "><ProductItemsListReturn orderid="22-000002" products={this.state.productlist} rowclick={this.handletableClick}
    order_status = {this.state.orderStatusSelected}
  />
  </div>
                    {/*<div className="left" style={{width:"200px"}}><b>Total Package: </b>
                    {
                    ( ()=>{
                      var totalprice =0;
                      this.state.productlist.forEach(function(item){
                        //totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                         totalprice = totalprice + (parseFloat(item.productSelect))
                      })
                      return parseFloat(totalprice).toFixed(0)
                    })()
                      
                      }
                    </div>
                    <div className="left"><b>Total Weight: </b>
                    {
                    ( ()=>{
                      var totalweight =0;
                      this.state.productlist.forEach(function(item){
                        //totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                         totalweight = totalweight + (parseFloat(item.productName))
                      })
                      return parseFloat(totalweight).toFixed(2)
                    })()
                      
                      }
                    </div>*/}

                    </Col>
  </Row>

  <Row id="pnlweightunit" style={{width:"100%"}}>
                    <Col xs={3}>
                        <h6 >Weight Unit:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="weight_unit" id="weight_unit" type="text" 
            value={this.state.weight_unit} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row id="pnldeclaredvalue" style={{width:"100%"}}>
                    <Col xs={3}>
                        <h6 >Declared Value unit:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="declared_value_unit" id="declared_value_unit" type="text" 
            value={this.state.declared_value_unit} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>

                     <Row>
                    <Col xs={3}>
                        <h6 >*Total Weight(In LBS):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="total_weight" id="total_weight" type="number" 
            value={this.state.total_weight} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Declared Value(In USD):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="declared_value" id="declared_value" type="number" 
            value={this.state.declared_value} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*Service Type:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="service_type" id="service_type" type="text" 
            value={this.state.service_type} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          {
          this.state.pageName == "usreturn"
            ? <select id='service_type' name="service_type" className="browser-default" onChange={this.handleFormChange}>
              <option value="FEDEX_2_DAY"  selected={this.state.service_type ==="FEDEX_2_DAY"}>FEDEX_2_DAY</option>
              <option value="STANDARD_OVERNIGHT"  selected={this.state.service_type ==="STANDARD_OVERNIGHT"}>STANDARD_OVERNIGHT</option>
                <option value="FEDEX_GROUND" selected={this.state.service_type ==="FEDEX_GROUND"}>FEDEX_GROUND</option>
              </select>
              :<select id='service_type' name="service_type" className="browser-default" onChange={this.handleFormChange}>
                <option value="Economy" selected={this.state.service_type ==="Economy"}>Economy</option>
                <option value="Priority" selected={this.state.service_type ==="Priority"}>Priority</option>
              </select>
          }
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Package Type:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="package_type" id="package_type" type="text" 
            value={this.state.package_type} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          <select id='package_type' name="package_type" className="browser-default" onChange={this.handleFormChange}>
                          <option value="Your Packaging"  selected={this.state.package_type ==="Your Packaging"}>Your Packaging</option>
                          {/*<option value="Fedex Packaging" selected={this.state.package_type ==="Fedex Packaging"}>Fedex Packaging</option>*/}
                        </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Package Content:</h6> 
                        </Col>
                        <Col xs={9}>
 
    
           
             
            <label>
                <input name="package_content" value="documents" type="radio"  
                  checked={this.state.package_content ==="documents"} onChange={this.handleFormChange}
                />
                <span>*Documents</span>
            </label>
           
            <label>
                <input name="package_content" value="commodities" type="radio" 
                  checked={this.state.package_content ==="commodities"} onChange={this.handleFormChange} />
                <span>Products/Commodities</span>
            </label>
         
            
       
      
                    </Col>
                    </Row>
                    
                    <Row>
                    <Col xs={3}>
                        <h6 >*Shipment Purpose:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="purpose" id="purpose" type="text" 
            value={this.state.purpose} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
           <select id='purpose' name="purpose" className="browser-default" onChange={this.handleFormChange}>
                          <option value="Commercial"  selected={this.state.purpose ==="Commercial"}>Commercial</option>
                          <option value="Private" selected={this.state.purpose ==="Private"}>Private</option>
                        </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Total Customs Value(In USD):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="total_custom" id="total_custom" type="number" 
            value={this.state.total_custom} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Broker Select:</h6> 
                        </Col>
                        <Col xs={9}>
 
    
           
             
            <label>
                <input name="brokerselect" value="yes" type="radio"  
                  checked={this.state.brokerselect ==="yes"} onChange={this.handleFormChange}
                />
                <span>Yes</span>
            </label>
           
            <label>
                <input name="brokerselect" value="no" type="radio" 
                  checked={this.state.brokerselect ==="no"} onChange={this.handleFormChange} />
                <span>No</span>
            </label>
         
            
       
      
                    </Col>
                    </Row>
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

<Row>
        <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" id="brokerdetail" style={{width:"100%"}}>
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={12}>
                <h4 className="blue-text">Broker Select Information</h4>
                    </Col>
                    </Row>
                                       {/*<Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_company" id="broker_company" type="text" 
            value={this.state.broker_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>*/}
                                       <Row>
                    <Col xs={3}>
                        <h6 >*Broker account no:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_accno" id="broker_accno" type="text" 
            value={this.state.broker_accno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                                       <Row>
                    <Col xs={3}>
                        <h6 >*Broker company name:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_comp_name" id="broker_comp_name" type="text" 
            value={this.state.broker_comp_name} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                                       <Row>
                    <Col xs={3}>
                        <h6 >*Broker contact name:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_contact" id="broker_contact" type="text" 
            value={this.state.broker_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    


                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_address1" id="broker_address1" type="text" 
            value={this.state.broker_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_address2" id="broker_address2" type="text" 
            value={this.state.broker_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                
                     <Row>
                    <Col xs={3}>
                        <h6 >Postal Code:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_company" id="broker_company" type="text" 
            value={this.state.broker_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>

                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_city" id="broker_city" type="text" 
            value={this.state.broker_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='broker_country' value={this.state.broker_country} name="broker_country" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_phone" id="broker_phone" type="text" 
            value={this.state.broker_phone} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Broker tax id:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_taxid" id="broker_taxid" type="text" 
            value={this.state.broker_taxid} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>


                     </Col> 
      <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" id="commoditydetail" style={{width:"100%"}}>
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={12}>
                <h4 className="blue-text">Commodity Information</h4>
                    </Col>
                    </Row>
               
                   <Row>
                    <Col xs={3}>
                        <h6 >*Commodity description:</h6> 
                        </Col>
                        <Col xs={9}><input name="commod_desc" id="commod_desc" type="text" 
            value={this.state.commod_desc} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    {/*<Row>
                    <Col xs={3}>
                        <h6 >*Unit of measure Per Piece:</h6> 
                        </Col>
                        <Col xs={9}><input name="commod_unit" id="commod_unit" type="text" 
            value={this.state.commod_unit} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>*/}
                    <Row>
                    <Col xs={3}>
                        <h6 >*Quantity:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="commod_qty" id="commod_qty" type="number" 
            value={this.state.commod_qty} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Weight (In LBS):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="commod_weight" id="commod_weight" type="number" 
            value={this.state.commod_weight} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Declared value (In USD):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="commod_value" id="commod_value" type="number" 
            value={this.state.commod_value} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Country of manufacture:</h6> 
                        </Col>
                        <Col xs={9}><select id='commod_country' value={this.state.commod_country} name="commod_country" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>
        </Col>
  </Row>
        

 

                 
</Col>

{/* right side columns ends here */}

</Row> 
        
         
     
            </div>
            <div className="modal-footer">
           
            <ButtonGroup className="mr-2" aria-label="First group">
            <Button id="closeEditOrderBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button> 
            {/*<Button id ="saveOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.createOrderNormal}> Save</Button>*/}
            {
              this.state.ordernumber == ""
              ?<Button id ="saveOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.createOrderNormal}> Save</Button>
              :(this.state.parenttrackno == "" ||  this.state.parenttrackno == null ||  this.state.parenttrackno == undefined)
              ? <p className="right orange-text"><Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderDB}> Update</Button>
              <Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderStatus}> Ship</Button>
              </p>
              :""
            }
            {/*<Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderStatus}> Ship1</Button>*/}
            {/*{
              (this.state.parenttrackno == "" ||  this.state.parenttrackno == null ||  this.state.parenttrackno == undefined)
              ?<Button id ="updateOrderBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.updateOrderStatus}> Ship</Button>
              :""
            }*/}
         
            
        
          </ButtonGroup>
            </div>
          </div>



        {/* Model for adding products STARTS here */}
         <div id="addProductModalReturn" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Package </h4>
           
            <Row>
              <Col xs={12} >
                <form>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                         <input name="search_name_select" id="search_name_select" type="text" value={this.state.search_name_select} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="search_name_select" className="black-text" >Quantity</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter quantity</span>

                        </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name1" id="product_name1" type="text" value={this.state.product_name1} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="product_name1" className="black-text" >Weight</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter weight</span>
                      </div>
                    </Col>
                  </Row>
                  {/*<Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name1" id="product_name1" type="text" value={this.state.product_name1} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="product_name1" className="black-text" >Product Name</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter a valid Product Name</span>
                      </div>
                    </Col>
                  </Row>*/}
                   
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_quantity" className="black-text active">Length</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter length</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_price"  id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_price" className="black-text active">Width</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter width</span>
                      </div>
                    </Col>
                  </Row>


                  
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_sku"  id="product_sku" min="0" type="text" value={this.state.product_sku} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_sku" className="black-text active">Height</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter height</span>
                      </div>
                    </Col>
                  </Row>

<Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_carriage"  id="product_carriage" min="0" type="text" value={this.state.product_carriage} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_carriage" className="black-text active">Carriage Value</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter carriage value</span>
                      </div>
                    </Col>
                  </Row>

                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" >
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
              <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addProductTolist}> Add</Button>

            </ButtonGroup>

          </div>
        </div>

        {/* Model for adding produts ENDS here */}        


              </>
        )
    }
}

export default EditOrder;
