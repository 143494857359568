import React, {Component,} from 'react';
import {Redirect, useHistory } from 'react-router-dom';
import {Card,Button,ButtonGroup,Nav,Image,Row,Col,Container,Spinner} from 'react-bootstrap';
// import { Paper, TextField, FormGroup, FormControl, NativeSelect, Select, InputLabel, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard_Return';
import OrderModal from './OrderSelectionModal';
import EditProduct from './EditProductItem_Return';
import "./createorder.css";
import { base_url } from '../../globalConstants';
import { subject } from '../../service'


class CustomerDetailCard extends Component{

    constructor(props){
        super(props);
        
  this.state = ({title:"***",name:"********",
              email:"**********",address:"********",phone:"********",country:"********"
              ,tinno:"**********",
              country_list: this.props.countryList,
              pageName: this.props.pagename,
              statedata: this.props.statedata, stateCode_list: [], stateName_list:[],
      statelist: [],
      statelistto: [],
      statelistcust: [],
      firstname:"",
      lastname:"",
      email:"",
      password:"",
      cust_country:"",
      mobileno:"",
      cust_state:"",
      address:"",
      city:"",
      countrynamelist: this.props.country_name_list, countrycodelist: this.props.country_code_list
              })

    }

    componentDidUpdate(prevProps) {

      // DEFAULT WORKING EZZID
      // if (this.props.info.ezz_id !== prevProps.info.ezz_id) {
        
      //    this.setState({title:this.props.info.title,name:this.props.info.name,
      //       email:this.props.info.email,address: this.props.info.address,phone:this.props.info.phone_number
        
      //   })

      // }

      if (this.props.info.ezz_id !== prevProps.info.ezz_id || this.props.info.email !== prevProps.info.email ) {
        console.log("TINN",this.props.info.tinno)
        console.log("Method",this.props.info.shipping_method)
         
         this.setState({title:this.props.info.title,name:this.props.info.name,
            email:this.props.info.email,address: this.props.info.address,phone:this.props.info.phone_number,
            country:this.props.info.country_name,tinno:this.props.info.tinno,
            shippingTypeSelected: "2"
        
        })

      }



    }

    render(){ 
        return(
            <Row>
            <Col>
            
            <Card className="" id="customerbasicdetailsCard">
        <Card.Body>
            <Card.Text>
                <Row>
                    <Col xs={8}>
        <h6 >Name:</h6><p className="inline">{this.state.title + " " + this.state.name}</p>
                    </Col>
                    <Col className="">
                    <Image src="assets/images/avatar-big.svg"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <h6 >Address:</h6><p>{this.state.address}</p>
                    </Col>
                    <Col xs={4} className="right">
                    <h6 >Phone:</h6><p>{this.state.phone}</p>
                    </Col>
                    <Col xs = {8} className="">
                    <h6 >Email:</h6><p>{this.state.email}</p>
                    </Col>
                    <Col xs = {4} className="right">
                      <h6>Country:</h6><p>{this.state.country}</p></Col>
					  <Col xs = {12} className="">
                      <h6>CRIB/TIN #:</h6><p>{this.state.tinno}</p></Col>
                      
                </Row>
               
            
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

        )
    }
}



class CreateOrder extends Component{
    constructor(props){
        super(props);
     
        var today = new Date();
       

         
          var month = '' + (today.getMonth() + 1)
            var   day = '' + today.getDate()
           var    year = today.getFullYear()
        
           if (month.length < 2) {
             month = '0' + month;
           }
            
           if (day.length < 2) {
             day = '0' + day;
           }
           
        
           let date = [year, month, day].join('-');


        this.state = ({
		  card_not_authorized: false,	
          current_date: date,
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :[],
            product_edit_category :[],
            product_category:"",ordernumber:"",
         customer_id:"",order_date:date,
        received_date:date,tracking_number:"",shiper_order_number:"",
        weight:0,height:0,fedex_charge:0,width:0,length:0,actual_charge:0,homedelivery_charge:0,promo_type:"Not",promo_value:0,processing_fee:0,brokerage_charge:0,insurance_charge:0,
        carriage_value:0,quantity:1,
        Weight_type:null,
          orderStatusSelected:"READY",
          paymentTypeSelected:"card",showSpinner:false,
		  originSelected:"company",
          destSelected:"company",
          shippingTypeSelected:"2",
          order_shippingTypeSelected:"0",
          customer_selected_data:{},
          pendingOrders:[],ordersSelectedInModal:[],
          createNewOrder:true,holdmergedorderstatus:"RTM",
          ordernumbermerge:"",
          error:"",
          rowNumber:"",
          selectItemDetails:{},
      country_list: this.props.countryList,
          statedata: this.props.statedata,
          stateCode_list: [], stateName_list:[],
      statelist: [],
      statelistto: [],
      statelistcust: [],
          firstname:"",
          lastname:"",
          email:"",
          password:"",
          cust_country:"",
          mobileno:"",
          cust_state:"",
          address:"",
          city:"",
          countrynamelist: this.props.country_name_list, countrycodelist: this.props.country_code_list,
          generatedOrderId: "",
          generatedBoxId: "",
          isOrderUpdate: false,
          customer_email: "",
          serachInput: '',
          searchResult:'',
          from_country:"",
    from_company :"",
    from_contact :"",
    from_address1 :"",
    from_address2 :"",
    from_zip :"",
    from_city :"",
    from_state :"1",
    from_phoneno :"",
    to_country:"",
    to_company :"",
    to_contact :"",
    to_address1 :"",
    to_address2 :"",
    to_zip :"",
    to_city :"",
    to_state :"1",
    to_phoneno :"",
    pageName: this.props.pagename 
          
          })

    }



    openOrderModel = ()=>{
      this.refs.ordermodel.openModal();
    }


    showSelectedModalOrders=(orderselected)=>{
      // console.log(`order selected ${orderselected}`)
      this.setState({ordersSelectedInModal:orderselected})
    }


    getCategories = async (countryid)=>{

      let token =localStorage.getItem("token")
      let header = {'Content-Type': 'multipart/form-data',
      'Authorization':'Token '+token,

      }

      var url= base_url+`categories/?country=${countryid}`
      var categories = await Axios.get(url,header)
      // console.log(categories.data)
      
     

      await this.setState({product_category_options:categories.data})
      await this.setState({product_edit_category:categories.data})

    }

getPendingOrder= async (customerid,orderstatus)=>{
try{
  let token =localStorage.getItem("token")
  let header = {'Content-Type': 'multipart/form-data',
  'Authorization':'Token '+token,

  }

  var url
  if (this.state.pageName == "usreturn")
  {
    url= base_url+`products_return_us/?ezz_id=${customerid}&status=${orderstatus}`
  }
  else
  {
     url= base_url+`products_return/?ezz_id=${customerid}&status=${orderstatus}`
  }
  let orders = await Axios.get(url,header)
  // console.log(orders.data)
  await this.setState({pendingOrders: this.state.pendingOrders.concat(orders.data)})
  // M.toast({html: 'Pending Ordersa fetched',classes:"white-text orange rounded"});
  this.setState({error:""})
}catch(error){
  console.log(error)
  M.toast({html: 'Pending Ordersa cannot be fetched',classes:"white-text red rounded"});
  this.setState({error:<Error/>})
}

}

handletableClick=(clickedrow)=>{
  //console.log(clickedrow)
 // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
 let productdetail = this.state.productlist[clickedrow]
 let countryoptions = this.state.product_category_options
 //console.log("handle cat",countryoptions)
 //console.log("handle cat",this.state.product_edit_category)
 this.setState({rowNumber:clickedrow})
 this.setState({selectItemDetails:productdetail})

 
   this.refs.editproduct.openModal(clickedrow,productdetail,countryoptions);
}

updateProduct=(rownumber,item)=>{
  console.log("updatig list",rownumber,item)
let productlist = this.state.productlist
productlist.splice(rownumber, 1, item)
this.setState({productlist:productlist})
// this.refs.editproduct.closeModal();
// M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});

}

deleteProduct = () => {
  console.log('YEAH WE WILL WORK ON THIS');
}

    handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    // }      
    // handleCustomerInfo = async e => {  
      // if(e.key !== 'Enter'){
      //   return
      // }

      try{
      
        let token =localStorage.getItem("token")
        let header = {'Content-Type': 'multipart/form-data',
        'Authorization':'Token '+token,

        }

        // Fetching user list on input change
        // var url= base_url+`customers/?ezz_id__icontains=${target.value}`
        // var url= base_url+`customers/?ezz_id=&ezz_id__icontains=${target.value}&email__icontains=${target.value}&phone_number=`

        var url
        var searchType

        if(this.state.searchResult){
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=${this.state.searchInput}&email__icontains=&phone_number=`
          searchType = "ezzid"
        }        
        else{
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=&email__icontains=${this.state.searchInput}&phone_number=`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,header)
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"
        // DEFAULT WORKING EZZID
        // for (let customer of customer_data.data) {
        //   ezzidlist[customer.ezz_id] = customer.email;
        //   customerdataObject[customer.ezz_id] = customer;
        // }

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.ezz_id] = customer.email;
            customerdataObject[customer.ezz_id] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.email] = customer.ezz_id;
            var tempEzId = ezzidlist[customer.email]
            customerdataObject[tempEzId] = customer;

          }
        }     

        // console.log(ezzidlist)
        // console.log(customerdataObject)
        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        // /await context.setState({pendingOrders:[]})
        const elems_auto_customer = document.getElementById('customer_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {
            // context.setState({customer_id:select_customer})                // DEFAULT WORKING EZZID

            // context.setState({customer_email: select_customer})

            // console.log("select_customer",select_customer);

            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({customer_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({customer_id:tempEzzId})                
            }            
        
            // DEFAULT WORKING EZZID
            // context.setState({customer_selected_data:customerdataObject[select_customer]})
            // await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[select_customer].country)
            //       await context.getPendingOrder(select_customer,"IM")
            //        await context.getPendingOrder(select_customer,"YTA")





            context.setState({customer_selected_data:customerdataObject[tempEzzId]})
            await context.setState({pendingOrders:[]})
           
            context.getCategories(customerdataObject[tempEzzId].country)
                  await context.getPendingOrder(tempEzzId,"IM")
                   await context.getPendingOrder(tempEzzId,"YTA")
           
           context.setState({shippingTypeSelected:"2"})







            //context.set
         
            // @TODO show model only when there is any orders pending 
            //like IM or BoX YET to arrive
            if (context.state.pendingOrders.length >0){
              context.openOrderModel();
            }
            // console.log("selected cust ID AUTH", customerdataObject[select_customer].card_authorize  )
           
            const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize
			

            if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )   {       
              document.getElementById("errCustomerNotAuthorized").style.display = "none"
				//this.setState({card_not_authorized:true}) 
				//context.setState({card_not_authorized:true}) 				
			}
            else  {
              document.getElementById("errCustomerNotAuthorized").style.display = "none"
			}
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        this.setState({error:<Error/>})
      }

    }
	
	handleOriginIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      var result;
      if (this.state.originSelected == "company")
      {
        result = true;
      }
      else
      {
        result = false;
      }
      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })
      try{
      
        let token =localStorage.getItem("token")
        let header = {'Content-Type': 'multipart/form-data',
        'Authorization':'Token '+token,

        }
        var url
        var searchType

        if(this.state.searchResult){
          url= base_url+`boxes_received_return/?from_company__icontains=${this.state.searchInput}`
          searchType = "ezzid"
        }        
        else{
          url= base_url+`boxes_received_return/?from_contact__icontains=${this.state.searchInput}`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,header)
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.from_company] = customer.from_contact;
            customerdataObject[customer.from_company] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.from_contact] = customer.from_company;
            var tempEzId = ezzidlist[customer.from_contact]
            customerdataObject[tempEzId] = customer;

          }
        }     

        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        
        const elems_auto_customer = document.getElementById('origin_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {
            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({origin_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({origin_id:tempEzzId})                
            }            
        
            context.setState({
              from_company: customerdataObject[tempEzzId].from_company,
              from_contact: customerdataObject[tempEzzId].from_contact,
              from_address1: customerdataObject[tempEzzId].from_address1,
              from_address2: customerdataObject[tempEzzId].from_address2,
              from_zip: customerdataObject[tempEzzId].from_zip,
              from_city: customerdataObject[tempEzzId].from_city,
              from_phoneno: customerdataObject[tempEzzId].from_phoneno,
              from_country: customerdataObject[tempEzzId].from_country,
              from_state: customerdataObject[tempEzzId].from_state
            })
      
            const target = customerdataObject[tempEzzId].from_country
            const states = context.state.statedata[context.state.countrycodelist.indexOf(target)].slice()
            context.setState({statelist: states })
            document.getElementById("from_state").value = context.state.from_state;

          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        M.toast({html: 'Origin data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        this.setState({error:<Error/>})
      }

    }
    handleDestIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      var result;
      if (this.state.destSelected == "company")
      {
        result = true;
      }
      else
      {
        result = false;
      }
      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })
      try{
      
        let token =localStorage.getItem("token")
        let header = {'Content-Type': 'multipart/form-data',
        'Authorization':'Token '+token,

        }
        var url
        var searchType

        if(this.state.searchResult){
          url= base_url+`boxes_received_return/?to_company__icontains=${this.state.searchInput}`
          searchType = "ezzid"
        }        
        else{
          url= base_url+`boxes_received_return/?to_contact__icontains=${this.state.searchInput}`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,header)
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.to_company] = customer.to_contact;
            customerdataObject[customer.to_company] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.to_contact] = customer.to_company;
            var tempEzId = ezzidlist[customer.to_contact]
            customerdataObject[tempEzId] = customer;

          }
        }     

        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        
        const elems_auto_customer = document.getElementById('dest_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {
            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({dest_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({dest_id:tempEzzId})                
            }            
        
            context.setState({
              to_company: customerdataObject[tempEzzId].to_company,
              to_contact: customerdataObject[tempEzzId].to_contact,
              to_address1: customerdataObject[tempEzzId].to_address1,
              to_address2: customerdataObject[tempEzzId].to_address2,
              to_zip: customerdataObject[tempEzzId].to_zip,
              to_city: customerdataObject[tempEzzId].to_city,
              to_phoneno: customerdataObject[tempEzzId].to_phoneno,
              to_country: customerdataObject[tempEzzId].to_country,
              to_state: customerdataObject[tempEzzId].to_state
            })
      
            const target = customerdataObject[tempEzzId].to_country
            const states = context.state.statedata[context.state.countrycodelist.indexOf(target)].slice()
            context.setState({statelistto: states })
            document.getElementById("to_state").value = context.state.to_state;

          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        M.toast({html: 'Destination data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        this.setState({error:<Error/>})
      }

    }


   fillFormAutomatically=(data)=>{
    //  console.log(`fill auto maticaly ${data}`)
    this.setState({
      ordernumber: data.order_id,
      order_date: data.order_date,
      received_date: data.received_date,
      tracking_number: data.inbound_tracking_number,
      shiper_order_number : data.shipper_number,
      weight: data.weight,
      height: data.height,
      fedex_charge: data.freight_charge,
      width: data.breadth,
      length: data.length,
      actual_charge: data.actual_charge,
      orderStatusSelected: data.current_status,
      paymentTypeSelected: data.billing_type
      //shippingTypeSelected: data.shipping_method,
      
      
    });
    
  
  }

  getReturnSlabRate = async ()=>{
    
    try{
  let token =localStorage.getItem("token")
    var config = {
    
	};

//console.log(this.state.order_date);
  // Fetching user list on input change
  //var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`

  var url
  if (this.state.pageName == "usreturn")
  {
    url= base_url+`get_return_slab_rate_us/?weight=${this.state.weight}&method=${this.state.shippingTypeSelected}&ezz_id=${this.state.customer_id}`
  }
  else
  {

    url= base_url+`get_return_slab_rate/?weight=${this.state.weight}&method=${this.state.shippingTypeSelected}&ezz_id=${this.state.customer_id}`
  }
  var response = await Axios.get(url,config)
  // console.log(response.data)
  return response.data
}catch(error){
  console.log(error.response.data)
   if (error.response.status ===503 ){
    M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
   }
   if (error.response.status === 403 ){
    M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
   }
  return error.response.data
}



  }

 changeTo= (e) =>{

          switch (e.target.name) {
            case "weight":
              this.setState({weight: e.target.value})
              break;
            case "height" :
              this.setState({height: e.target.value})
              break;
            case "width" :
              this.setState({width: e.target.value})
              break;
            case "length" :
              this.setState({length: e.target.value})
              break;
            case "order_shippingTypeSelected" :
              this.setState({order_shippingTypeSelected: e.target.value})
              break;
          }
                //this.state.order_shippingTypeSelected
                //this.setState({weight: e.target.value})
                 //// console.log("is change to");
                  console.log("targe:" + e.target.value);
                  this.handleFormChangeFreight(e);
      }

      updateValue= (e) =>{

          switch (e.target.name) {
            case "weight":
              this.setState({weight: e.target.value})
              break;
            case "height" :
              this.setState({height: e.target.value})
              break;
            case "width" :
              this.setState({width: e.target.value})
              break;
            case "length" :
              this.setState({length: e.target.value})
              break;
            case "order_shippingTypeSelected" :
              this.setState({order_shippingTypeSelected: e.target.value})
              break;
          }
                   //this.setState({weight: e.target.value})
                  //console.log("is updtevalue");
                  //console.log(e.target.value);
          
      }


    handleFormChange= async (ev) =>{
     
      const target = ev.target
      const name = target.name
              this.setState({
            [name]: target.value
          });
     
    }
    
    handleFormChangeFreight= async (ev) =>{
     
      const target = ev.target
      const name = target.name
      this.setState({
        [name]: target.value
      });


          var weight = this.state.weight
          var width = this.state.width
          var height = this.state.height
          var length = this.state.length
          var order_shippingTypeSelected = this.state.order_shippingTypeSelected
          var field = target.name;

          switch (field) {
            case "weight":
              weight = target.value;
              break;
            case "height" :
              height = target.value;
              break;
            case "width" :
              width = target.value;
              break;
            case "length" :
              length = target.value;
              break;
            case "order_shippingTypeSelected" :
                order_shippingTypeSelected = target.value;
                break; 
           
           }
// if(width <= 0 || height <=0 || weight <= 0 || length <=0 || this.state.actual_charge <0){
//   M.toast({html: "Dimension values can not be 0/negative.",classes:"white-text green rounded"});
//   return;
// }

          if((width.toString()!=="" && width.toString()!=="0") && (height.toString() !==""&& height.toString()!=="0") && 
          (weight.toString() !==""&& weight.toString()!=="0") &&( length.toString()!==""&& length.toString() !=="0")){
            await this.setState({
              showSpinner:true
            })
            var response =  await this.getfedexActualRate(weight,height,length,width,order_shippingTypeSelected)
            console.log(response["freight_charges"] + response["actual_charges"])
              this.setState({
                est_freight_charge:response["actual_charges"],
        //         actual_charge: response["actual_charges"],
        //         Weight_type:response["Weight_type"],
        //         homedelivery_charge: response["homedelivery_charge"],
				// promo_type: response["promo_type"],
        //         promo_value: response["promo_value"],
				// insurance_charge: response["insurance"],
				// processing_fee: response["processing"],
				// brokerage_charge: response["brokerage"],
                showSpinner:false
              })
          //}
         
        }else{
          this.setState({
            [name]: target.value
          });
        }
     
    }
    getfedexActualRate = async (weight,height,length,breadth,order_shippingTypeSelected)=>{
      try{
    let token =localStorage.getItem("token")
  
    var config = {
      headers: { 
        'Content-Type': 'multipart/form-data' , 
        'Authorization':'Token '+token},
      
    };
    
    var totalcharge = 0
    //var weight = ""
    //var length = ""
    var width = ""
   // var height = ""
    width = breadth
    var packageno = this.state.packageno
    var to_city = this.state.to_city
    var to_fedexcode = this.state.to_country
    var to_zip = this.state.to_zip
    var ezzid = "EZZ000005"
    var personname = "Megs Informatics"
    var compname = "Ezzylife"
    var to_phone = "8623075438"
    var to_streetline = "95 W LakeShore Dr"
    var from_country = this.state.from_country
    var from_code = this.state.from_country
    var from_zip = this.state.from_zip
    var from_city = this.state.from_city
    var priority = order_shippingTypeSelected
    var metric = "LB"
    var dimension_unit="IN"
    //http://18.222.190.66:8080/api/v1/ezzytrace/fedex_actual_rates_ship/?weight=2&breadth=2&height=2&length=1&ezz_id=EZZ000005&country_city=Nasau&country_fedex_code=BS&country_zipcode=1242&person_name=Megs Informatics&company_name=Ezzylife&phone_no=8623075438&street_line=95 W LakeShore Dr&from_city=Rockaway&from_code=NJ&from_zip_code=07866&from_country=US
    var url = 'fedex_actual_rates_ship/?weight='+ weight +'&breadth=' + width +'&height=' + height + '&length=' + length + '&ezz_id=' + ezzid + '&country_city=' + to_city + '&country_fedex_code=' + to_fedexcode + '&country_zipcode=' + to_zip + '&person_name=' + personname + '&company_name=' + compname + '&phone_no=' + to_phone + '&street_line=' + to_streetline + '&from_city=' + from_city + '&from_code=' + from_code + '&from_zip_code=' + from_zip + '&from_country=' + from_country + '&priority=' + priority + '&weight_unit=' + metric + '&dimension_unit=' + dimension_unit
    const response = await Axios.get (base_url+url)
    console.log(response.data)
    console.log("fedex rate data" + response.data)
    totalcharge = totalcharge + parseFloat(response.data.actual_charges)
    
    return response.data //totalcharge.toFixed(2)

    // // Fetching user list on input change
    // //var url= base_url+`get_freight_charge/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&method=${order_shippingTypeSelected}`
    // var url= base_url+`get_freight_charge_return/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&method=${order_shippingTypeSelected}`
    // //var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
    // var response = await Axios.get(url,config)
    // // console.log(response.data)
    // return response.data
  }catch(error){
  
    console.log(error.response.data)
    
     if (error.response.status ===503 ){
      M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
     }
     if (error.response.status === 403 ){
      M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
     }
     
    
    // return response.data
    return error.response.data
  }
}
    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

    componentDidMount(){
      
        M.AutoInit();





        console.log("is thissssssss working");
        

        // var elems_recieved = document.getElementById('received_date');
        var elems = document.querySelectorAll('.modal');
        let model_options = {
          dismissible:false,
        }
        var instances = M.Modal.init(elems, model_options);
        
        var elems_auto_customer = document.querySelectorAll('.autocomplete');

        var options_auto_customer={
      
          data: {}, 
          limit:20,
          onAutocomplete :function(select_customer) {
           
            // context.setState({ order_date: selecteddata});
            //  console.log("slect autocomplete",select_customer); 
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        } 
        var instances_auto = M.Autocomplete.init(elems_auto_customer, options_auto_customer);
      

         
        var context = this;

       var newdate = this.newDate()
      //  console.log('new date',newdate)
        var elems_order = document.getElementById('order_date');
      
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              // console.log(context.formatDate(date))
               let selecteddata = context.formatDate(date)
              // console.log('selectedate',selecteddata)
              context.setState({ order_date: context.formatDate(selecteddata)})
              // console.log("order_date",selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_order, options_order);
    //     var elems_recieved = document.getElementById('received_date');
		// elems_recieved.disabled = true;  //220223
		
    //     var options_recieved={
    //         //selectMonths: true, // Enable Month Selection
    //         selectYears: 1, // Creates a dropdown of 10 years to control year
    //         format:'yyyy-mm-dd',
    //         autoClose :true,
    //         defaultDate:newdate,
    //         setDefaultDate :true,
    //         onSelect: function(date) {
    //           let selecteddata = context.formatDate(date)
    //           context.setState({ received_date: selecteddata.toString()});
    //           //  console.log("recived_datae"+ selecteddata); // Selected date is logged
    //         },
    //       }
    //     var instances = M.Datepicker.init(elems_recieved, options_recieved);
          instances.toString()
    
    
    }


// @TODO: handlechange for create order is pending Please implement it
addCustomer = async ()=>{
  if (this.state.confpassword != this.state.password)
  {
    alert('Password and confirm password should be same!')
    return;
  }
  const pwd = this.state.password
  const formdata={
        name: this.state.firstname +" "+ this.state.lastname ,
        phone_number: this.state.mobileno,
        email: this.state.email,
        country: this.state.cust_country,
        address: this.state.address,
        city: this.state.city,
        zipcode: this.state.zip,
        state: this.state.cust_state
      }

  
    const newdata={
      email:this.state.email,
      password: this.state.password,
      security_question:"First Dog?",
      security_answer:"romie",
      is_customer: "True"
    }

  console.log(formdata);
  
   await Axios({
    method: 'post',
    url: base_url+'customers/',
    data:formdata,
    headers: {'Content-Type': 'application/json' }
    })
    .then(function (response) {
        //handle success
        console.log(response);
        console.log('success');
       /*var alert =  <Alert  variant="success">
        Success fully created
        {console.log(Password)}
      </Alert>
        setmsg(alert)
        const {data}=response;
        const Id=data.ezz_id;
        console.log(`Ezz ID created is ${Id}`);
        setEzz(Id);
        localStorage.setItem("ezzyid", Id)*/
        
        

        const {data}=response;
        const Id=data.ezz_id;

         Axios({
          method:'post',
          url:base_url+'create_user/',
          data: newdata,
          headers: {'Content-Type': 'application/json' }
        }).then(function(response){
          console.log(response);
          console.log('django success');
          M.toast({html: 'Customer created successfully',classes:"white-text orange rounded"})
          
          const emaildata={

            type : "account_activation", 
            ezz_id :Id,
            password: pwd
          }
          Axios({
            method:'post',
            url:base_url+'send_email/',
            data: emaildata,
            headers: {'Content-Type': 'application/json' }
          }).then(function(email){
            console.log(email);
            console.log('email sent success');
            M.toast({html: 'Email sent successfully',classes:"white-text orange rounded"})
          }).catch(function(email){
            console.log(email)
            console.log('email sending failed')
            M.toast({html: 'Email sent failed',classes:"white-text orange rounded"})
          })
           
        }).catch(function(response){
          console.log(response)
          console.log('inner api failed')
          M.toast({html: 'User not created.',classes:"white-text red rounded"});
        })
    
    })
    .catch(function (response) {
        //handle error

        //setErrorMessage(response.response.data.email?"Email already exist":'Missing or Incorrect Details');
         M.toast({html: 'Email already exist',classes:"white-text red rounded"});
        // setErrorText2(response.response.data.email?"Email already exist":'Missing or Incorrect Details');
        console.log('error')     
    });
}

     addProductTolist = ()=>{
       var prodname =this.state.product_name
       var prodquant =this.state.product_quantity
       var prodcat = 0 //this.state.product_category

       if(prodname === "" || prodquant === 0 || prodcat ===""){
        M.toast({html: 'Incorrect or Empty details',classes:"white-text red rounded"});
       }else{
        var elem = document.getElementById('addProductModal')
        var instance = M.Modal.getInstance(elem);
        instance.close();
        let totalproducts = this.state.productlist.length + 1
        
        let newitem = {id:'item_'+totalproducts,productName:this.state.product_name,
        productPrice:this.state.product_price ,
        productQuantity:this.state.product_quantity,
      productCategory:this.state.product_category
      }

        // console.log("Product List",this.state.productlist)
       this.state.productlist.push(newitem)
        this.setState({productlist: this.state.productlist})
        
      try {
        // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
        this.setState({product_name:"",product_price:0,product_quantity:0 })
       M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
      } catch (error) {
        M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
      }
       
    
       }

    }

  
    cancelOrder = ()=>{
        // @TODO fucntionality not clear yet
        // M.toast({html: 'Pending for Implemention',classes:"white-text black rounded"})
        // return (<Redirect to="/Admin"/>
        window.location.reload();
   }


handleMergeCheckbox= (event)=>{
  // event.target.checked
  // console.log(this.state.holdmergedorderstatus)
  // console.log(event.target.checked)
  const name = event.target.name
  if (event.target.checked){
    this.setState({[name]:"WFC"})
  }else{
    this.setState({[name]:"RTM"})
  }
}

mergeOrder=()=>{
  
  // var elems = document.getElementById('merge_order_model');
  // var instance = M.Modal.init(elems);
  // instance.close();
  this.createOrderMerged();



}
mergerOrderCancel = ()=>{



  // var elems = document.getElementById('merge_order_model');
  // var instance = M.Modal.init(elems);
  // instance.close();
  this.createOrderNormal();







}


  createOrderMerged = async () => {
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    if (this.state.createNewOrder) {

      // new order creation
      console.log("Deleting previous orders if any")
      this.state.productlist.forEach(async (item) => {
        console.log("productlist Merged order-----")
        // console.log(item)
        try {
          let urlfordeletion
          if (this.state.pageName == "usreturn")
          {
             urlfordeletion = base_url+`products_return_us/${item.id}/`
          }
          else
          {
             urlfordeletion = base_url+`products_return/${item.id}/`
          }
          const response = await Axios.post(urlfordeletion,
            header)
          // console.log(`deletion response ${response.data}`)
          console.log("Product delete success")
          M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
        } catch (error) {
          M.toast({ html: 'No old products found', classes: "white-text green rounded" });
          console.log('error in deletion',error)
        }

      });



      const orderid = this.state.ordernumber
      const existing_order = this.state.ordernumbermerge
      // this.setState({ordernumber:orderid})

      var current_status = this.state.holdmergedorderstatus
      var Orderformdata = new FormData()
      console.log("orderid" + orderid)
      console.log(this.state.fedex_charge)
      console.log(this.state.actual_charge)
      Orderformdata.append("order_id", orderid.toString())
      Orderformdata.append("ezz_id", this.state.customer_id)
      Orderformdata.append("shipper_number", this.state.shiper_order_number)
      Orderformdata.append("billing_id", "2121")
      Orderformdata.append("billing_type", this.state.paymentTypeSelected)
      Orderformdata.append("shipping_method", this.state.shippingTypeSelected)
      
      Orderformdata.append("order_date", this.state.order_date)
      Orderformdata.append("received_date", this.state.received_date)
      Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
      Orderformdata.append("weight", this.state.weight)
      Orderformdata.append("length", this.state.length)
      Orderformdata.append("breadth", this.state.width)
      Orderformdata.append("height", this.state.height)
      Orderformdata.append("charge_type", "VAT")
      Orderformdata.append("freight_charge", this.state.fedex_charge)
      Orderformdata.append("actual_charge", this.state.actual_charge)
      Orderformdata.append("current_status", "DISC")
      Orderformdata.append("previous_status", "DISC")
      Orderformdata.append("status", "Deactive")
      Orderformdata.append("flag", "1")

      // console.log(JSON.stringify(Orderformdata))
      // console.log(JSON.stringify(this.state))

      const orderstatus_merged = {
        "remarks": "discarded after merging with order" + existing_order,
        "order_id": orderid,
        "status_id": "DISC"
      }

      const orderstatus_mergedwith = {
        "remarks": "",
        "order_id": existing_order,
        "status_id": current_status
      }

      // @Todo: APi to be called to save data for state

      try {

        const response = await Axios.post("http://localhost:8000/api/v1/ezzytrace/boxes_received_return/", Orderformdata,
          header)
        const response_newdiscrded = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_merged,
          header)
        const response_existingupdated = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_mergedwith,
          header)

        var existingOrderUpdateForm = new FormData()
        existingOrderUpdateForm.append("current_status", current_status)
        let urlforexistingOrderStatusUpdate = base_url+`boxes_received_return/${existing_order}/`
        await Axios.patch(urlforexistingOrderStatusUpdate, existingOrderUpdateForm, header)


        this.state.productlist.forEach(async (item) => {
          // console.log("productitemmerged")
          // console.log(item)
          try {
            var productformdata = new FormData()
            productformdata.append("order_id", existing_order)
            productformdata.append("description", item.productName)
            productformdata.append("quantity", item.productQuantity)
            productformdata.append("unit_price", item.productPrice)
            productformdata.append("inspection_status", "okay")
            productformdata.append("status", "Active")
            productformdata.append("category", item.productCategory)

            const response_products = await Axios.post(base_url+"products_return/", productformdata,
              header)

            console.log("Product added Successfully")
            this.setState({ error: <Success message=" Order Status Updated" /> })
            M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
          } catch (error) {
            M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
            console.log(error)
          }

        });


this.setState({error:<Success message=" New order for product items created"/>})
 M.toast({html: 'Order created successfully',classes:"white-text orange rounded"})
this.setState({error:""})    
}catch(err){
  
    M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
    
    console.log(err)
    this.setState({error:<Error/>})
        }




}else{


  try{
  
    var orderupdateForm = new FormData()
    orderupdateForm.append("shipper_number",this.state.shiper_order_number)
    // orderupdateForm.append("billing_id","2121")
    orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
    orderupdateForm.append("shipping_method",this.state.shippingTypeSelected)
    
    orderupdateForm.append("order_date",this.state.order_date)
    orderupdateForm.append("received_date",this.state.received_date)
    orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
    orderupdateForm.append("weight",this.state.weight)
    orderupdateForm.append("length",this.state.length)
    orderupdateForm.append("breadth",this.state.width)
    orderupdateForm.append("height",this.state.height)
    // orderupdateForm.append("charge_type","VAT")
    orderupdateForm.append("freight_charge",this.state.fedex_charge)
    orderupdateForm.append("actual_charge",this.state.actual_charge)
    orderupdateForm.append("current_status",this.state.orderStatusSelected)
    let urlforOrderStatusUpdate
  if (this.state.pageName == "usreturn")
  {
    urlforOrderStatusUpdate = base_url+`boxes_received_return_us/${this.state.ordernumber}/`
  }
  else
  {
    urlforOrderStatusUpdate = base_url+`boxes_received_return/${this.state.ordernumber}/`
  }
    const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm,
    header)

    console.log(`order status update succesfully ${response.data}`)
    this.setState({error:<Success message=" Order Status Updated"/>})
    M.toast({html: 'Updated SUccessfull',classes:"white-text orange rounded"});
  }catch(error){
     M.toast({html: 'Updation Failed',classes:"white-text red rounded"});
      console.log(error)
      this.setState({error:<Error/>})
  }

}
}





  createOrderNormal = async () => {

    
    // console.log('this.state.productlist', this.state.productlist);

    // const tempProductList = this.state.productlist
    
    const found = this.state.productlist.find(element => element["productPrice"]==0);

    // console.log('found', found)

    // if((this.state.width <= 0 || this.state.height <=0 || this.state.weight <= 0 || this.state.length <=0 || this.state.actual_charge <0)
    //   && this.state.orderStatusSelected !== 'YTA' ){
    //   M.toast({html: "Dimension values can not be 0/negative.",classes:"white-text red rounded"});
    //   return;
    // }

    // if(found && this.state.orderStatusSelected === 'RTM' ){
    //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
    //   return
    // }
    
    this.setState({isOrderUpdate: true})
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    console.log("create new order" + this.state.createNewOrder)
    var totalprice = 0;
    //var totalprice = getReturnSlabRate();
    var slabcharge =  await this.getReturnSlabRate()
    //return;
    if (this.state.createNewOrder) {

      // new order creation
      console.log("Deleting previous orders if any")
      this.state.productlist.forEach(async (item) => {
        console.log("productlist -----", item.id)
        console.log(item)
        totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
        //this.setState({carriage_value:parseFloat(totalprice).toFixed(2)})
        try {
          let urlfordeletion
          if (this.state.pageName == "usreturn")
          {
            urlfordeletion = base_url+`products_return_us/${item.id}/`
          }
          else
          {
           urlfordeletion = base_url+`products_return/${item.id}/`
          }
          const response = await Axios.delete(urlfordeletion,
            header)
          console.log(`deletion response ${response.data}`)
          console.log("Product delete success")
          M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
        } catch (error) {
          M.toast({ html: 'No old products found', classes: "white-text green rounded" });
          console.log(error)
        }

      });



      // const orderid = Math.floor(1000 + Math.random() * 9000)
      // this.setState({ordernumber:orderid})







      var Orderformdata = new FormData()
      // console.log("orderid"+orderid)
      console.log(this.state.fedex_charge)
      console.log(this.state.actual_charge)
      // Orderformdata.append("order_id",orderid.toString())
      Orderformdata.append("ezz_id", this.state.customer_id)
      Orderformdata.append("shipper_number", this.state.shiper_order_number)
      Orderformdata.append("billing_id", "2121")
      Orderformdata.append("billing_type", this.state.paymentTypeSelected)
      Orderformdata.append("shipping_method", this.state.shippingTypeSelected)

      Orderformdata.append("order_date", this.state.order_date)
      Orderformdata.append("received_date", this.state.received_date)
      Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
      Orderformdata.append("qty", this.state.quantity)
      Orderformdata.append("weight", this.state.weight)
      Orderformdata.append("length", this.state.length)
      Orderformdata.append("breadth", this.state.width)
      Orderformdata.append("height", this.state.height)
      Orderformdata.append("charge_type", "Due")
      Orderformdata.append("freight_charge", slabcharge)
//Orderformdata.append("freight_charge", )
      Orderformdata.append("actual_charge", totalprice)
      Orderformdata.append("current_status", this.state.orderStatusSelected)
      Orderformdata.append("previous_status", this.state.orderStatusSelected)
      Orderformdata.append("status", "Active")
      Orderformdata.append("flag", "1")


      Orderformdata.append("from_country", this.state.from_country)
      Orderformdata.append("from_company", this.state.from_company)
      Orderformdata.append("from_contact", this.state.from_contact)
      Orderformdata.append("from_address1", this.state.from_address1)
      Orderformdata.append("from_address2", this.state.from_address2)
      Orderformdata.append("from_zip", this.state.from_zip)
      Orderformdata.append("from_city", this.state.from_city)
       Orderformdata.append("from_state", this.state.from_state)
      Orderformdata.append("from_phoneno", this.state.from_phoneno)

      Orderformdata.append("to_country", this.state.to_country)
      Orderformdata.append("to_company", this.state.to_company)
      Orderformdata.append("to_contact", this.state.to_contact)
      Orderformdata.append("to_address1", this.state.to_address1)
      Orderformdata.append("to_address2", this.state.to_address2)
      Orderformdata.append("to_zip", this.state.to_zip)
      Orderformdata.append("to_city", this.state.to_city)
      Orderformdata.append("to_state", this.state.to_state)
      Orderformdata.append("to_phoneno", this.state.to_phoneno)


      //Orderformdata.append("from_state", "1")
     // Orderformdata.append("to_state", "1")

      // console.log(JSON.stringify(Orderformdata))
      // console.log("-------------------")
      // console.log(JSON.stringify(this.state))
      // console.log("-------------------")


      // @Todo: APi to be called to save data for state

      try {
      var response
      if (this.state.pageName == "usreturn")
        {
          response = await Axios.post(base_url+"boxes_received_return_us/", Orderformdata,
          header)
        }
        else
        {
         response = await Axios.post(base_url+"boxes_received_return/", Orderformdata,
          header)
        }
        const orderid = response.data.order_id
        const boxid = response.data.box_id
        console.log("orderid" + orderid)
        this.state.productlist.forEach(async (item) => {
          console.log("productitem")
          console.log(item)
          try {
            var productformdata = new FormData()
            productformdata.append("order_id", orderid.toString())
            productformdata.append("description", item.productName)
            productformdata.append("quantity", item.productQuantity)
            productformdata.append("unit_price", item.productPrice)
            productformdata.append("inspection_status", "okay")
            productformdata.append("status", "Active")
            productformdata.append("category", item.productCategory)

             var response_products
      if (this.state.pageName == "usreturn")
        {
          response_products = await Axios.post(base_url+"products_return_us/", productformdata,
              header)
        }
        else
        {
         response_products = await Axios.post(base_url+"products_return/", productformdata,
              header)
        }
            
            // alert(response.data)
            console.log("Product added Successfully")
            this.setState({ error: <Success message=" Order Status Updated" /> })
            M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
          } catch (error) {
            M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
            console.log(error)
          }

        });

		// const checkCustomerAuth = this.state.customer_selected_data.card_authorize
		// //M.toast({ html: checkCustomerAuth, classes: "white-text red rounded" });
		
		// if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )
		// {
		// 	try{
				
		// 		const emaildata_au = {
		// 		  "type": 'card_not_authorized',
		// 		  "ezz_id": this.state.customer_id,
		// 		  "order_id": orderid
		// 		}
		// 		const response = await Axios.post(base_url+"send_email/", emaildata_au,
		// 		  header)
		// 		//M.toast({ html: response, classes: "white-text red rounded" });
		// 		console.log(response)
				
		// 	}catch(err1){
		// 		console.log(err1)
		// 	}
		// }
		

        // try {
        //   var email_subject = "invoice_missing"
        //   const status = this.state.orderStatusSelected
        //   switch (status) {
        //     case "IM":
        //       email_subject = "invoice_missing";
        //       break;
        //     case "WFC":
        //       email_subject = "consolidation";
        //       break;
        //   }
        //   if (status === "IM" || status === "WFC") {
        //     const emaildata = {
        //       "type": email_subject,
        //       "ezz_id": this.state.customer_id,
        //       "order_id": orderid
        //     }


        //     Axios({
        //       method: 'post',
        //       url: base_url+'status_printer',
        //       data: {
        //         "order_id": orderid
        //       }
        //     }).then(function (response) {
        //       console.log(response)
        //       console.log("printer APi success");
        //     }).catch(function (response) {
        //       console.log(response)
        //       console.log('printer API failed');
        //     })

        //     Axios({
        //       method: 'post',
        //       url: base_url+'whatsapp_templates',
        //       data: emaildata,
        //     }).then(
        //       function (response) {
        //         console.log(response)
        //         console.log("whatsapp sent done");
        //       }
        //     ).catch(
        //       function (response) {
        //         console.log(response)
        //         console.log("whatsapp sent Failure");
        //       }
        //     )

        //     const response = await Axios.post(base_url+"send_email/", emaildata,
        //       header)
        //     console.log(response)
        //     M.toast({ html: 'Email Sent to ' + response.data["Email to"], classes: "white-text red rounded" });
        //   }

        // } catch (error) {

        //   M.toast({ html: 'Email Sending Failed to' + this.state.customer_id, classes: "white-text red rounded" });
        // }



        this.setState({ error: <Success message=" New order foproduct items created" /> })
        M.toast({ html: 'Order created successfully', classes: "white-text orange rounded" })
        this.setState({ error: "" })
        this.setState({ generatedOrderId: orderid })
         this.setState({ generatedBoxId: boxid })
        console.log('generatedOrderId',this.state.generatedOrderId);
       // document.getElementById("printLabel").disabled = false
        document.getElementById('saveOrderBtn').disabled = true
		//alert("save")
        await this.getproducts(orderid)

      } catch (err) {

        //M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

        console.log(err)
        this.setState({ error: <Error /> })
      }




    } else {


      try {
        let token = localStorage.getItem("token")
        const header = {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + token,

        }
        var orderupdateForm = new FormData()
        orderupdateForm.append("shipper_number", this.state.shiper_order_number)
        // orderupdateForm.append("billing_id","2121")
        orderupdateForm.append("billing_type", this.state.paymentTypeSelected)
        orderupdateForm.append("shipping_method", this.state.shippingTypeSelected)

        orderupdateForm.append("order_date", this.state.order_date)
        orderupdateForm.append("received_date", this.state.received_date)
        orderupdateForm.append("inbound_tracking_number", this.state.tracking_number)
        orderupdateForm.append("weight", this.state.weight)
        orderupdateForm.append("length", this.state.length)
        orderupdateForm.append("breadth", this.state.width)
        orderupdateForm.append("height", this.state.height)
        // orderupdateForm.append("charge_type","VAT")
        orderupdateForm.append("freight_charge", 1)
        orderupdateForm.append("actual_charge", this.state.actual_charge)
        orderupdateForm.append("current_status", this.state.orderStatusSelected)
        let urlforOrderStatusUpdate
if (this.state.pageName == "usreturn")
  {
     urlforOrderStatusUpdate = base_url+`boxes_received_return_us/${this.state.ordernumber}/`
  }
  else
  {
         urlforOrderStatusUpdate = base_url+`boxes_received_return/${this.state.ordernumber}/`
  }
        const response = await Axios.patch(urlforOrderStatusUpdate, orderupdateForm, header)

        console.log(`order status update succesfully ${response.data}`)
        this.setState({ error: <Success message=" Order Status Updated" /> })
        M.toast({ html: 'Updated SUccessfull', classes: "white-text orange rounded" });
      } catch (error) {
        M.toast({ html: 'Updation Failed', classes: "white-text red rounded" });
        console.log(error)
        this.setState({ error: <Error /> })
      }

    }
    
    //document.getElementById('saveOrderBtn').disabled = true

  }
   addOrderToDB = async ()=>{
     //alert(this.state.pageName)
    //return
              let token =localStorage.getItem("token")
              const  header = {'Content-Type': 'application/json',
              'Authorization':'Token '+token,
            
              }
            
                  //@TODO: FOR FUTUREjust umcomment the code for merge ,discard and hold fucntionality 
     

              // check whether there is any order which is in state of WAITING FOR CONSOLIDATION
              // try {
              //   let url_wfc_check = base_url+`boxes_received_return/?ezz_id=${this.state.customer_id}&current_status=WFC`
              //   var  wfcResponse = await Axios.get(url_wfc_check,header)
              //   console.log(`WFC Response ${wfcResponse.data}`)
              //   if (wfcResponse.data.length >0){
              //     console.log(wfcResponse.data[0].order_id)
              //     this.setState({ordernumbermerge:wfcResponse.data[0].order_id})
              //     var elems = document.getElementById('merge_order_model');
              //     var instance = M.Modal.init(elems);
              //     instance.open()

              //     const orderid = Math.floor(1000 + Math.random() * 9000)
              //     this.setState({ordernumber:orderid})

              //   }else{
                  this.createOrderNormal()
                  var btn = document.getElementById('createorderreturn')
                  btn.click()
                 // window.location.reload(false);
              // this.props.refresh();
              //   }
                
              // } catch (error) {
              //   console.log(error)
              // }



            


            



  }

  printLabelManually = () => {

    var order_id = this.state.generatedOrderId
    console.log("generatedOrderId:", order_id)

      Axios({
        method: 'post',
        url: base_url+'status_printer',
        data: {
          "order_id": order_id
        }
      }).then(function (response) {
        console.log(response)
        console.log("printer APi success");
        M.toast({
          html: `Print Label successful for orderId: ${order_id} `,
          classes: "center white-text green rounded"
        })
      }).catch(function (response) {
        console.log(response)
        console.log('printer API failed');
        M.toast({
          html: `Print Label unsuccessful`,
          classes: "white-text orange rounded"
        })
      })
      
  }

  //UPDATE ORDER START

  getproducts = async (orderid) => {
    // http://localhost:8000/api/v1/ezzytrace/products_return/?order_id=
    try {
      let token = localStorage.getItem("token")
      // let header = {'Content-Type': 'multipart/form-data',
      // 'Authorization':'Token '+token,

      // }

      var config = {
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

      };
var url
if (this.state.pageName == "usreturn")
  {
 url = base_url+`products_return_us/?order_id=${orderid}`
  }
  else
  {
       url = base_url+`products_return/?order_id=${orderid}`
  }
      let products = await Axios.get(url, config)
      // console.log("***orderlist-openmodel")
      // console.log(products.data)
      // await this.setState({productlisthtml:<ProductItemsList products={new Array()}/>})
      var items = new Array()
      products.data.forEach((product) => {
        let newitem = {
          id: product.id, productName: product.description,
          productPrice: product.unit_price, productQuantity: product.quantity,
          productCategory: product.category
        }
        items.push(newitem)
        // this.state.productlist.push(newitem)
      })

      M.toast({ html: 'Fetched products for selected order', classes: "white-text orange rounded" });
      // this.setState({error:<Success/>})


      await this.setState({ productlist: items })


      // await this.setState({ productlisthtml: <ProductItemsList orderid={orderid} products={items} rowclick={this.handletableClick} /> })


    } catch (error) {
      console.log(error)
      M.toast({ html: 'Failed to fetch products', classes: "white-text red rounded" });
      // this.setState({error:<Error/>})
    }
  }

  updateOrderDB= async(header)=>{
    const  orderid= this.state.generated_orderId

    const found = this.state.productlist.find(element => element["productPrice"]==0);

    console.log('found', found)
    // console.log('this.state.generated_orderId', this.state.generated_orderId)
    // console.log('orderid', orderid)

    if(found && this.state.orderStatusSelected === 'RTM' ){
      M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
      return
    }

      try{
          
          var orderupdateForm = new FormData()
          // orderupdateForm.append("order_id",this.state.generated_orderId)
          orderupdateForm.append("ezz_id",this.state.customer_id)
          orderupdateForm.append("shipper_number",this.state.shiper_order_number)
          orderupdateForm.append("billing_id","2121")
          orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
          orderupdateForm.append("shipping_method", this.state.shippingTypeSelected)

          orderupdateForm.append("order_date",this.state.order_date)
          orderupdateForm.append("received_date",this.state.received_date)
          orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
          orderupdateForm.append("weight",this.state.weight)
          orderupdateForm.append("length",this.state.length)
          orderupdateForm.append("breadth",this.state.width)
          orderupdateForm.append("height",this.state.height)
          orderupdateForm.append("charge_type","VAT")
          orderupdateForm.append("freight_charge",this.state.fedex_charge)
          orderupdateForm.append("actual_charge",this.state.actual_charge)
          orderupdateForm.append("current_status",this.state.orderStatusSelected)
          // orderupdateForm.append("previous_status",this.props.orderdata.current_status)
          orderupdateForm.append("status","Deactive")
          orderupdateForm.append("flag","1")
          orderupdateForm.append("homedelivery_charge", this.state.homedelivery_charge)
		  orderupdateForm.append("insurance", this.state.insurance_charge)
		  orderupdateForm.append("brokerage", this.state.brokerage_charge)
		  orderupdateForm.append("processing", this.state.processing_fee)
		  orderupdateForm.append("promo_type", this.state.promo_type)
          orderupdateForm.append("promo_value", this.state.promo_value)

let urlforOrderStatusUpdate 
if (this.state.pageName == "usreturn")
  {
    urlforOrderStatusUpdate = base_url+`boxes_received_return_us/${this.state.generatedOrderId}/`
  }
  else
  {
          urlforOrderStatusUpdate = base_url+`boxes_received_return/${this.state.generatedOrderId}/`
  }
          const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
    
          // console.log(`order status update succesfully ${response.data}`)
          this.setState({error:<Success message=" Order Updated"/>})
        
          M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});
  
  
          //Updating The Product 
  
          this.state.productlist.forEach(async(item)=>{
            // console.log("productitem")
            // console.log(item)
            // console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')
  
            
  
            try{
              var productformdata = new FormData()
              productformdata.append("order_id",this.state.generatedOrderId)
              productformdata.append("description",item.productName)
              productformdata.append("quantity",item.productQuantity)
              productformdata.append("unit_price",item.productPrice)
              productformdata.append("inspection_status","okay")
              productformdata.append("status","Active")
              productformdata.append("category",item.productCategory)
  
              console.log('ITEM-ID',item.id);

              if( typeof(item.id) === "number" && this.state.isOrderUpdate ){
                var response_products
if (this.state.pageName == "usreturn")
  {
response_products = await Axios.patch(base_url+`products_return_us/${item.id}/`, productformdata,
                  header)
  }
  else
  {
                 response_products = await Axios.patch(base_url+`products_return/${item.id}/`, productformdata,
                  header)
  }
                // alert(response.data)
                console.log("Product added Successfully")
                this.setState({ error: <Success message=" Order Status Updated" /> })
                M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
                
              }else{
                var response_addProduct
if (this.state.pageName == "usreturn")
  {
response_addProduct = await Axios.post(base_url+`products_return_us/`, productformdata,
                header )
  }
  else
  {
                 response_addProduct = await Axios.post(base_url+`products_return/`, productformdata,
                header )
  }
                
  
                M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
                
              }
  
              
              
            }catch(error){
              M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
                console.log(error)
            }
           
          });
          
  
  
  
       
          // try{
          //   var email_subject = "invoice_missing"
          //    const status = this.state.orderStatusSelected
          //    switch(status){
          //      case "IM":
          //       email_subject ="invoice_missing";
          //       break;
          //      case "WFC":
          //       email_subject ="consolidation";
          //       break;
        
        
                 
          //    }
          //   if (status===  "IM" || status === "WFC"){
          //     const  emaildata= {"type" : email_subject,
          //     "ezz_id" : this.state.customer_id,
          //     "order_id" : orderid}
  
  
          //     Axios({
          //       method:'post',
          //       url: base_url+'status_printer',
          //       data: {
          //         "order_id": orderid
          //       }
          //     }).then(function(response){
          //       console.log(response)
          //       console.log("printer APi success");
          //     }).catch(function(response){
          //       console.log(response)
          //       console.log('printer API failed');
          //     })
  
          //     Axios({
          //       method:'post',
          //       url: base_url+'whatsapp_templates',
          //       data: emaildata,
          //     }).then(
          //       function(response){ 
          //         console.log(response)
          //         console.log("whatsapp sent done");
          //       }
          //     ).catch(
          //       function(response){ 
          //         console.log(response)
          //         console.log("whatsapp sent Failure");
          //       }
          //     )
  

  
          //     const response = await Axios.post(base_url+"send_email/",emaildata,
          //   header)
          //   console.log(response)
          //   M.toast({html: 'Email Sent to '+response.data["Email to"],classes:"white-text red rounded"});
      
          //   }
           
          // }catch(error){
        
          //   M.toast({html: 'Email Sending Failed to'+ this.state.customer_id ,classes:"white-text red rounded"});
          // }
        
  
         await this.getproducts(this.state.generatedOrderId)
  
  
          // window.location.reload(false);
        }catch(error){
          M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
            console.log(error)
            this.setState({error:<Error/>})
        }
  
  }
  
  updateOrder=()=>{
      let token =localStorage.getItem("token")
      const  header = {'Content-Type': 'application/json',
      'Authorization':'Token '+token,
      }
      this.updateOrderDB(header)
  
  
  }

  //--UPDATE ORDER END

handleCountryNameChange = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelist: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    console.log(this.state.statelist)
   

  }

handleCountryNameChangeTo = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelistto: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    //console.log("state list")
    //console.log(this.state.statelist)
   

  }

  handleCountryNameChangeCust = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelistcust: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    //console.log("state list")
    //console.log(this.state.statelist)
   

  }


render(){
  const opts={};
   opts['readOnly'] = 'readOnly';
  const catlist = this.state.product_category_options;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.category_id}>{item.category_name}</option>
      )
    }, this);
  }

  var statekey = 0
  const countlist = this.state.country_list;
  if (countlist.length >0){
    var countryList = countlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.country_id}>{item.name}</option>
      )
    }, this);
  }
  
  const catlistcust = this.state.statelistcust;
  if (catlistcust.length >0){
   var selectStateOptionsCust = catlistcust.map((state,statekey) => {
      console.log("state", state)

      return (

        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    const states = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
    this.setState({  statelistcust: states })
  }

  const catlist1 = this.state.statelist;
  if (catlist1.length >0){
   var selectStateOptions = catlist1.map((state,statekey) => {
      console.log("state", state)

      return (

        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    const states = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
    this.setState({  statelist: states })
  }

const catlistto = this.state.statelistto;
  if (catlistto.length >0){
   var selectStateOptionsTo = catlistto.map((state,statekey) => {
      console.log("state", state)

      return (

        //<option  key={statekey++} value={state.state_id}>{state.state_name}</option>
        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    const states = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
    this.setState({  statelistto: states })
  }

  

    return(
        <Container>
{/* Edit product Table items */}
<EditProduct ref="editproduct" rowNumber={this.state.rowNumber}
productDetails={this.state.selectItemDetails}
 productcategory={this.state.product_edit_category}
 updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}

 disablePrice= {this.state.orderStatusSelected ==="IM"} 
 />




        {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addProductModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Product </h4>
            <Row>
              <Col xs={12} >
                <form>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name" id="product_name" type="text" value={this.state.product_name} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_name" className="black-text" >Product Name</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter a valid Product Name</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_quantity" className="black-text active">Quantity</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter number of products items</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_price" disabled={this.state.orderStatusSelected === "IM"} id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_price" className="black-text active">Product Price</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter numbers</span>
                      </div>
                    </Col>
                  </Row>


                  {/*<Row>
                    <Col>

                      <div id="categroydiv" className="input-field col s6 offset-s3 center">
              
                        <select id='category_name_select' name="product_category" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Category</option>

                          {categoryList}

                        </select>


                      </div>


                    </Col>

                  </Row>*/}


                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" >
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
              <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addProductTolist}> Add</Button>

            </ButtonGroup>

          </div>
        </div>

        {/* Model for adding produts ENDS here */}

    {/* Model for adding Origin STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addOriginModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Enter Origin Country and Address Details</h4>
            <Row>
              <Col xs={12} >
                <form>
                    {/*<Row>
                    <Col xs={3}>
                <h4 className="blue-text">From</h4>
                    </Col>
                    </Row>*/}
                <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='from_country' name="from_country"  value={this.state.from_country} className="browser-default" onChange={this.handleCountryNameChange}>
                          <option value=""  selected>Choose Country</option>

                          {countryList}

                        </select>
                    </Col>
                    </Row>
                   
				   <Row>
                   <Col xs={5}>
                   
            <label>
                <input name="originSelected" value="company" type="radio"  
                  checked={this.state.originSelected ==="company"} onChange={this.handleFormChange}
                />
                <span>Company</span>
            </label>
           
            <label>
                <input name="originSelected" value="contact" type="radio" 
                  checked={this.state.originSelected ==="contact"} onChange={this.handleFormChange} />
                <span>Contact</span>
            </label>
       
                        </Col>
                    <Col xs={7}>
                    <div className="input-field col s12">
          <input name="origin_id" id="origin_id" type="text" className="autocomplete"
            value={this.state.customer_id1} 
            onChange={this.handleOriginIDChange}
            onKeyDown={this.handleCustomerInfo}

          />
        </div>
                   </Col>
                   </Row>

                   <Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_company" id="from_company" type="text" 
            value={this.state.from_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_contact" id="from_contact" type="text" 
            value={this.state.from_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address1" id="from_address1" type="text" 
            value={this.state.from_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address2" id="from_address2" type="text" 
            value={this.state.from_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_zip" id="from_zip" type="number" 
            value={this.state.from_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_city" id="from_city" type="text" 
            value={this.state.from_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>

          <select id="from_state" name="from_state" className="browser-default" onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptions}
                              </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_phoneno" id="from_phoneno" type="number" 
            value={this.state.from_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                   </form>
              </Col>
            </Row>
 </div>
          <div className="modal-footer" >
            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button>
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Save</Button>
            </ButtonGroup>
          </div>
        </div>
   {/* Model for adding Origin ENDS here */}

{/* Model for adding Destintion STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addDestinationModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Destination Country and Address Details</h4>
            <Row>
              <Col xs={12} >
                <form>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='to_country' name="to_country" value={this.state.to_country} className="browser-default" onChange={this.handleCountryNameChangeTo}>
                          <option value="" disabled selected>Choose Country</option>

                          {countryList}

                        </select>
                    </Col>
                    </Row>
					<Row>
                   <Col xs={5}>
                   
            <label>
                <input name="destSelected" value="company" type="radio"  
                  checked={this.state.destSelected ==="company"} onChange={this.handleFormChange}
                />
                <span>Company</span>
            </label>
           
            <label>
                <input name="destSelected" value="contact" type="radio" 
                  checked={this.state.destSelected ==="contact"} onChange={this.handleFormChange} />
                <span>Contact</span>
            </label>
       
                        </Col>
                    <Col xs={7}>
                    <div className="input-field col s12">
          <input name="dest_id" id="dest_id" type="text" className="autocomplete"
            value={this.state.customer_id1} 
            onChange={this.handleDestIDChange}
            onKeyDown={this.handleCustomerInfo}

          />
        </div>
                   </Col>
                   </Row>

                   <Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_company" id="to_company" type="text" 
            value={this.state.to_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_contact" id="to_contact" type="text" 
            value={this.state.to_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address1" id="to_address1" type="text" 
            value={this.state.to_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address2" id="to_address2" type="text" 
            value={this.state.to_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_zip" id="to_zip" type="number" 
            value={this.state.to_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_city" id="to_city" type="text" 
            value={this.state.to_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>

            <select id="to_state" name="to_state"  className="browser-default"
           onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptionsTo}
                              </select>
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_phoneno" id="to_phoneno" type="number" 
            value={this.state.to_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                   </form>
              </Col>
            </Row>
 </div>
          <div className="modal-footer" >
            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Close</Button>
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Save</Button>
            </ButtonGroup>
          </div>
        </div>
   {/* Model for adding Destination ENDS here */}
{/* Model for adding Destintion STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addCustomerModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Create Account</h4>
            <Row>
              <Col xs={12} >
                <form>
                   
                   <Row>
                    <Col xs={3}>
                        <h6 >First Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="firstname" id="firstname" type="text" 
            value={this.state.firstname} 
            onChange={this.handleCustomerIDChange}
            
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Last Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="lastname" id="lastname" type="text" 
            value={this.state.lastname} 
            onChange={this.handleCustomerIDChange}
          
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Email:</h6> 
                        </Col>
                        <Col xs={9}><input name="email" id="email" type="text" 
            value={this.state.email} 
            onChange={this.handleCustomerIDChange}
           
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Password:</h6> 
                        </Col>
                        <Col xs={9}><input name="password" id="password" type="password" 
            value={this.state.password} 
            onChange={this.handleCustomerIDChange}
            
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Confirm Password:</h6> 
                        </Col>
                        <Col xs={9}><input name="confpassword" id="confpassword" type="password" 
            value={this.state.confpassword} 
            onChange={this.handleCustomerIDChange}
            
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Country:</h6> 
                        </Col>
                        <Col xs={9}><select id='cust_country' name="cust_country" value={this.state.cust_country} className="browser-default" onChange={this.handleCountryNameChangeCust}>
                          <option value="" disabled selected>Choose Country</option>

                          {countryList}

                        </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Mobile Number:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="mobileno" id="mobileno" type="number" 
            value={this.state.mobileno} 
            onChange={this.handleCustomerIDChange}
            
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="address" id="address" type="text" 
            value={this.state.address} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                      <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>

            <select id="cust_state" name="cust_state"  className="browser-default"
           onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptionsCust}
                              </select>
                    </Col>
                    
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="city" id="city" type="text" 
            value={this.state.city} 
            onChange={this.handleCustomerIDChange}
         
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="zip" id="zip" type="number" 
            value={this.state.zip} 
            onChange={this.handleCustomerIDChange}
            
          />
                    </Col>
                    </Row>
                     
                    
                    
                    
                   </form>
              </Col>
            </Row>
 </div>
          <div className="modal-footer" >
            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
               <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn modal-close" onClick={this.addCustomer}> Save</Button>
            </ButtonGroup>
          </div>
        </div>

 {/* Model for adding Destination ENDS here */}

      {/* Model for merge starts here */}
    <div id="merge_order_model" className="modal mergeordermodel modal-fixed-footer">
      <div className="modal-content">
        <br></br>
      <h5 className="center orange-text"> There is an order with status Waiting for Consolidation. 
                    <br></br>Do you want to merge this order with existing order?   </h5>
                    
                    <form>
                    <Row>
                      <Col>
                      <p className="center">
            <label>
                <input name="holdmergedorderstatus" value="WFC" type="checkbox" 
                checked={this.state.holdmergedorderstatus ==="WFC"} onChange={this.handleMergeCheckbox}/>
                <span className="red-text">Put it on Hold</span>
            </label>
            </p>
                      </Col>
                    </Row>
                    </form>
        
      </div>
      <div className="modal-footer" >
    
  <ButtonGroup className="mr-2" aria-label="Merge group">
    <Button id="cancelMergeAddBtn" variant="outline-secondary" onClick={this.mergerOrderCancel} className="mr-4 btn  modal-close "  >Don't Merge</Button> 
    <Button id ="MergeBtn" variant="outline-primary" onClick={this.mergeOrder} className="mr-2 btn  modal-close" > Merge</Button>

  </ButtonGroup>
 
    </div>
    </div>
        {/* Model for merge ends here */}

            {/* OrderModel code starts here */}
          
            <OrderModal ref="ordermodel" orders={this.state.pendingOrders}
             onOrderSelection={ v => {this.setState({ordersSelectedInModal: v})
              // console.log("hey world")  
              // console.log(v.order)
              // console.log(v.perordersdata[v.order])
              if (v.selectedproductId.length < v.perordersdata[v.order].length){
                 
                  
                  this.setState({createNewOrder:true})

              }else{
                this.setState({createNewOrder:false})
              }
              this.fillFormAutomatically(v.orderdetails) 
              for (let item in v.selectedproductId){
                // console.log(v.selectedproductId[item])
                let product = v.orderid[parseInt(v.selectedproductId[item])]
                console.log(`product orderid ${product}`)

                // let totalproducts = this.state.productlist.length + 1
        
                let newitem = {id:product.id,productName:product.description,
                productPrice:product.unit_price,productQuantity:product.quantity,
                productCategory:product.category
              }

              const producttemp = this.state.productlist;
              const isIDsame = (Productkey) => Productkey.id === product.id;
          let index  = producttemp.findIndex(isIDsame)
          if( this.state.productlist.length ===0 ){
              console.log('index is working-------')
            this.state.productlist.push(newitem)
                  this.setState({productlist: this.state.productlist})
                  
                      }else if(index === -1 &&  this.state.productlist.length > 0){
                        
                        this.state.productlist.push(newitem)
                  this.setState({productlist: this.state.productlist})
                      }else if(index !== -1){
                        M.toast({html: ' Product(s)  already in the table', classes:"white-text red rounded"})
                      }

                 // console.log("Product List",this.state.productlist)
                  

        
                  this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
              }


              // console.log(v.selectedproductId)

             }}/>
            {/* {console.log(this.state.ordersSelectedInModal)} */}
            {/* OrderModel code ends here */}


<div className="row">
          <div className="col s3">
		  {this.state.pageName === "usreturn"?
          <h4 className=" orange-text">Create US Domestic Shipment Order</h4>:
		  <h4 className=" orange-text">Create Return Shipment Order</h4>}
          </div>
       
</div>
                <Row>

                       <Col xs={12}md={7} lg={7}>
                    <div className="row">
    <form className="col s12" autocomplete="off">
      {this.state.error}
      <div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="customer_id" id="customer_id" type="text" className="autocomplete"
            value={this.state.customer_id} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}

          />
          <label htmlFor="customer_id">Enter Customer Id</label>
            <span id="errCustomerNotAuthorized" class="helper-text center yellow red-text " 
                  data-error="wrong" data-success="" 
                  style= {{ fontSize: "small", display: "none", fontWeight: "500" }} >Payment Method Not Added</span>
          <span className="helper-text" data-error="wrong" data-success="">Customer Ezzid ex: Ezz000000</span>
                    <Nav className="right">
          <Nav.Link className="red-text modal-trigger "  href="#addCustomerModal">
          Add Customer<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
          </Nav>
        </div>
      
      </div>
      <div className="row inputbox-height">
        <div className="input-field col s6">
        
          <input name="order_date" id="order_date" type="text"  className="datepicker" 
          value={this.state.order_date}
          //  onChange={this.handleFormChange}
          />
          
          <label htmlFor="order_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i></label>
          <span className="helper-text" data-error="wrong" data-success="">Select the date Order Date </span>
        </div>
    

        <div className="input-field col s6">
          <input name="tracking_number" id="tracking_number" type="text" className=""
           value={this.state.tracking_number} onChange={this.handleFormChange}
          />
          <label htmlFor="tracking_number">Enter Tracking Number</label>
          <span className="helper-text" data-error="wrong" data-success="">Enter Tracking inbound Number</span>

        </div>
      </div>
      {/*<div className="row inputbox-height">*/}
        <div className="input-field col s6">
          <Nav className="right">
          <Nav.Link className="red-text modal-trigger "  href="#addOriginModal" style={{ fontSize: "19px", fontWeight: "bold" }}>
          Enter Origin Here<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
          </Nav>
        </div>
        <div className="input-field col s6">
          <Nav className="right">
          <Nav.Link className="red-text modal-trigger "  href="#addDestinationModal" style={{ fontSize: "19px", fontWeight: "bold" }}>
          Enter Destination Here<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
          </Nav>
        </div>
      {/*</div>*/}
      {/*<div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="received_date" id="received_date" type="text" className="datepicker"
           value={this.state.received_date} 
          //  onChange={this.handleFormChange}
          />
          <label htmlFor="received_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i> </label>
          <span className="helper-text" data-error="wrong" data-success="">Select date, the order arrived at warehouse</span>
        </div>
        
        <div className="input-field col s6">
          <input name="shiper_order_number" id="shiper_order_number" type="text" className=""
           value={this.state.shiper_order_number} onChange={this.handleFormChange}
          />
          <label htmlFor="shiper_order_number">Enter Shipper Number</label>
          <span className="helper-text" data-error="wrong" >Enter Shipper Order Number</span>
        </div> 

      </div>*/}
      {
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }
        {this.state.orderStatusSelected !=="YTA" ?
      <div className="row">
          {/*<div className="input-field col s4">
          <input name="quantity" id="quantity" min="0" type="number" className=""
           value={this.state.quantity} onChange={this.handleFormChange}
          />
          <label className="active" htmlFor="quantity">Quantity </label>
          <span className="helper-text" data-error="wrong" >Enter Quantity</span>
        </div>*/}
        
        <div className="input-field col s4">
          <input name="weight" id="weight" min="0" type="number" className=""
           value={this.state.weight}  onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="weight">Weight</label>
          <span className="helper-text" data-error="wrong" >Enter Weight in pounds</span>
        </div>       

<div className="input-field col s4">
          <input name="length"  id="length" min="0" type="number" className=""
           value={this.state.length} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="length">Length</label>
          <span className="helper-text" data-error="wrong" >Enter Length in inches</span>
        </div>
        
        {/*<div className="input-field col s4">
          <input placeholder="" name="actual_charge" id="actual_charge" type="number"  className=""
          value={this.state.actual_charge} onChange={this.handleFormChange} min="0" 
          />
          <label className="active"htmlFor="actual_charge">Actual charges </label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
          <span className="helper-text green-text" >{this.state.Weight_type}</span>
        </div>*/}
      </div> : null}

      {this.state.orderStatusSelected !=="YTA" ?
      <div className="row">
        
        <div className="input-field col s4">
          <input name="width" id="width" min="0" type="number" className=""
           value={this.state.width} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="width">Width</label>
          <span className="helper-text" data-error="wrong" >Enter Width in inches</span>
        </div>
        <div className="input-field col s4">
          <input name="height" id="height" min="0" type="number" className=""
           value={this.state.height} onBlur={(e) => this.changeTo(e)} onChange={(e) => this.updateValue(e)}
          />
          <label className="active" htmlFor="height">Height</label>
          <span className="helper-text" data-error="wrong" >Enter Height in inches</span>
        </div>
      </div> : null}
      
      <div className="row">
      <div className="input-field col s4">
          <input placeholder="" readOnly name="est_freight_charge" id="est_freight_charge" type="number"  className=""
          value={this.state.est_freight_charge} onChange={this.handleFormChange} min="0" 
          />
          <label className="active"htmlFor="est_freight_charge">Estimated Freight charges </label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
          
        </div>
        </div>

      {/*{this.state.orderStatusSelected !=="YTA" ?
      <div className="row">
        <div className="input-field col s4">
          <input placeholder="" name="homedelivery_charge" id="homedelivery_charge" type="number"  className=""
          value={this.state.homedelivery_charge} onChange={this.handleFormChange} min="0" 
          />
          <label className="active"htmlFor="actual_charge">Home Delivery Charges </label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
          <span className="helper-text green-text" >{this.state.Weight_type}</span>
        </div>
      </div> : null}*/}
	  {/*{this.state.orderStatusSelected !=="YTA" ?
      <div className="row">
        <div className="input-field col s4">
          <input name="insurance_charge"  id="insurance_charge" min="0" type="number" className=""
           value={this.state.insurance_charge} onChange={this.handleInsuranceChargeChange}
          />
          <label className="active" htmlFor="insurance">Insurance</label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
        </div>
        <div className="input-field col s4">
          <input name="brokerage_charge" id="brokerage_charge" min="0" type="number" className=""
           value={this.state.brokerage_charge} onChange={this.handleBrokerageChargeChange}
          />
          <label className="active" htmlFor="brokerage">Brokerage</label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
        </div>
        <div className="input-field col s4">
          <input name="processing_fee" id="processing_fee" min="0" type="number" className=""
           value={this.state.processing_fee} onChange={this.handleProcessingFeeChange}
          />
          <label className="active" htmlFor="processing">Processing</label>
          <span className="helper-text" data-error="wrong" >Auto generated</span>
        </div>
      </div> : null}*/}
      <div className="row">
        <div className="col s6">
            {/* Order Status Radio Buttons */}
                <h4>Order Status</h4>
              
            {/*<label>
                <input name="orderStatusSelected" value="IM" type="radio" 
                checked={this.state.orderStatusSelected ==="IM"} onChange={this.handleFormChange}/>
                <span>Invoice Missing</span>
            </label>
          
            <label>
                <input name="orderStatusSelected" value="YTA" type="radio" 
                 checked={this.state.orderStatusSelected ==="YTA"} onChange={this.handleFormChange}/>
                <span>Order Yet to Arrive</span>
            </label>*/}
          
            <label>
                <input name="orderStatusSelected" value ="DISCARD" type="radio" 
                 checked={this.state.orderStatusSelected ==="DISCARD"} onChange={this.handleFormChange}
                />
                <span>Discard</span>
            </label>

            <label>
                <input name="orderStatusSelected" value ="READY" type="radio" 
                 checked={this.state.orderStatusSelected ==="READY"} onChange={this.handleFormChange}
                />
                <span>Ready</span>
            </label>
           
            {/*<label>
                <input name="orderStatusSelected" value ="WFC" type="radio" 
                 checked={this.state.orderStatusSelected ==="WFC"} onChange={this.handleFormChange}
                />
                <span>Wait for Consolidation</span>
            </label>*/}
            


       

           
    
        </div>

        <div className="col s6">
            {/* Payment Tyoe Radio Buttons */}
            <h4>Charge Type</h4>
             
            <label>
                <input name="order_shippingTypeSelected" value="0" type="radio"  
                  checked={this.state.order_shippingTypeSelected ==="0"} onChange={(e) => this.changeTo(e)}
                />
                <span>Economy</span>
            </label>
           
            <label>
                <input name="order_shippingTypeSelected" value="1" type="radio" 
                  checked={this.state.order_shippingTypeSelected ==="1"}  onChange={(e) => this.changeTo(e)} />
                <span>Priority</span>
            </label>
        </div>
    
      </div>
      <div className="row inputbox-height">
      <div className="col s6">
            {/* Payment Tyoe Radio Buttons */}
            <h4>Payment Type</h4>
             
            <label>
                <input name="paymentTypeSelected" value="cash" type="radio"  
                  checked={this.state.paymentTypeSelected ==="cash"} onChange={this.handleFormChange}
                />
                <span>Cash</span>
            </label>
           
            <label>
                <input name="paymentTypeSelected" value="card" type="radio" 
                  checked={this.state.paymentTypeSelected ==="card"} onChange={this.handleFormChange} />
                <span>Card</span>
            </label>
         
            {/* {this.state.orderStatusSelected} */}
            {/* {this.state.paymentTypeSelected} */}
            {/* {this.state.order_date} */}
            {/* {this.state.received_date} */}

        </div>
         <div className="col s6">
            {/* Payment Tyoe Radio Buttons */}
            <h4>Shipping Type</h4>
             
            <label>
                <input name="shippingTypeSelected" value="2" type="radio"  
                  checked={this.state.shippingTypeSelected ==="2"} onChange={this.handleFormChange}
                />
                <span>New</span>
            </label>
           
            <label>
                <input name="shippingTypeSelected" value="3" type="radio" 
                  checked={this.state.shippingTypeSelected ==="3"} onChange={this.handleFormChange} />
                <span>Return</span>
            </label>
         
            {/* {this.state.orderStatusSelected} */}
            {/* {this.state.paymentTypeSelected} */}
            {/* {this.state.order_date} */}
            {/* {this.state.received_date} */}

        </div>
        </div>
    </form>
  
  </div>
                    </Col>
                    {/* right side column starts here */}
                    <Col xs={12}md={5} lg={5}>


                            <CustomerDetailCard 
                            info = {this.state.customer_selected_data}
                             />

                        <Row>
                        <Col>
                        <h5>Product Details</h5>
                        </Col>
                        
                        <Col>
                          <Nav className="right">
                          <Nav.Link className="red-text modal-trigger "  href="#addProductModal">
                          Add<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
                          </Nav>
                        </Col>
                    </Row>
                    {/* product card to be shown here */}
                    {/* <div className="productlistDiv ">{this.state.productlisthtml}</div> */}
                    <div className="productlistDiv "><ProductItemsList products={this.state.productlist} rowclick={this.handletableClick}/></div>
                    <div className="right"><b>Total Price: </b>$
                    {
                    ( ()=>{
                      var totalprice =0;
                      this.state.productlist.forEach(function(item){
                        totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                      })
                      return parseFloat(totalprice).toFixed(2)  
                    })()
                      
                      }
                      
                      {/* this.state.productlist */}
                       </div>
                    {/* <ButtonGroup aria-label="create order buttons" className="right">
                        <Button id="cancelOrderBtn"onClick={this.cancelOrder} variant="secondary"  className="mr-2 ">Cancel</Button>
                        <Button id="saveOrderBtn" onClick={this.addOrderToDB} variant="primary"  >Create Order</Button>
                        
                    </ButtonGroup> */}
                    </Col>

                    {/* right side columns ends here */}
      
                </Row> 

                <Row >
                  <Col>
                    <div className="left">
                        <h5 className="orange-text">Order Id: <span className="black-text" id='generated_orderId' >{this.state.generatedOrderId}</span></h5>
                    </div>
                    </Col>
                    <Col>
                    <div className="left">
                        <h5 className="orange-text">Box Id: <span className="black-text" id='generated_orderId' >{this.state.generatedBoxId}</span></h5>
                    </div>
                  </Col>
                </Row>

          <Row>
            <Col>
              <div className="center">
                <ButtonGroup aria-label="create order buttons" className="">
                  <Button id="cancelOrderBtn"onClick={this.cancelOrder} variant="secondary"  className="">Cancel</Button>
                  <Button className="ml-5 " id="saveOrderBtn" onClick={this.addOrderToDB} variant="primary"  >Create Order</Button>
                  {/*<Button className="ml-5 white-text" id="updateOrder" variant="success" onClick={this.updateOrder} >Update Order</Button>                        */}
                  {/*<Button className="ml-5 white-text" id="printLabel" variant="warning"  onClick={this.printLabelManually}>Print Order</Button>                        */}
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>


    )
}

}

export default CreateOrder;
