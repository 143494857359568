import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const LineChartMulti = ({ datasets }) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartRef && chartRef.current && datasets) {
      if (chartInstance) {
        // If a chart instance already exists, destroy it before creating a new one
        chartInstance.destroy();
      }
      const ctx = chartRef.current.getContext('2d');
      const mergedLabels = datasets.reduce((acc, dataset) => {
        // Merge all labels from all datasets
        return [...acc, ...dataset.labels];
      }, []);

      const uniqueLabels = [...new Set(mergedLabels)]; // Remove duplicates

      const chartData = {
        labels: uniqueLabels,
        datasets: datasets.map(dataset => ({
          label: dataset.label,
          data: mapDataToLabels(uniqueLabels, dataset.labels, dataset.values),
          borderColor: dataset.borderColor,
          tension: 0.1,
        })),
      };

      const newChartInstance = new Chart(ctx, {
        type: 'line',
        data: chartData,
      });
      setChartInstance(newChartInstance);
    }
  }, [datasets]);

  const mapDataToLabels = (allLabels, labels, values) => {
    // Map the dataset values to the correct positions in the merged labels array
    return allLabels.map(label => {
      const index = labels.indexOf(label);
      return index !== -1 ? values[index] : null;
    });
  };

  return <canvas ref={chartRef} />;
};

export default LineChartMulti;
