import React, {Component,} from 'react';
import {Redirect, useHistory } from 'react-router-dom';
import {Card,Button,ButtonGroup,Nav,Image,Row,Col,Container,Spinner} from 'react-bootstrap';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard_Pack';
import OrderModal from './OrderSelectionModal';
import EditProduct from './EditProductItem_Pack';
import "./shippingrates.css";
import { base_url } from '../../globalConstants';
import { subject } from '../../service'


class CustomerDetailCard extends Component{

    constructor(props){
        super(props);
        
  this.state = ({title:"***",name:"********",
              email:"**********",address:"********",phone:"********",country:"********"
              ,country_list: this.props.countryList,
              statedata: this.props.statedata, stateCode_list: [], stateName_list:[],
      statelist: [],
      statelistto: [],
      countrynamelist: this.props.country_name_list, countrycodelist: this.props.country_code_list,
              })
              console.log("counttt",this.props.countryList);
              
    }

    componentDidUpdate(prevProps) {
      if (this.props.info.ezz_id !== prevProps.info.ezz_id || this.props.info.email !== prevProps.info.email ) {
        
         this.setState({title:this.props.info.title,name:this.props.info.name,
            email:this.props.info.email,address: this.props.info.address,phone:this.props.info.phone_number,country:this.props.info.country_name
        
        })

      }
//console.log("pk after render")

    }

    render(){ 
      
        return(
            <Row>
            <Col>
            
            <Card className="" id="customerbasicdetailsCard">
        <Card.Body>
            <Card.Text>
                <Row>
                    <Col xs={8}>
        <h6 >Name:</h6><p className="inline">{this.state.title + " " + this.state.name}</p>
                    </Col>
                    <Col className="">
                    <Image src="assets/images/avatar-big.svg"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <h6 >Address:</h6><p>{this.state.address}</p>
                    </Col>
                    <Col xs={4} className="right">
                    <h6 >Phone:</h6><p>{this.state.phone}</p>
                    </Col>
                    <Col xs = {8} className="">
                    <h6 >Email:</h6><p>{this.state.email}</p>
                    </Col>
                    <Col xs = {4} className="right">
                      <h6>Country:</h6><p>{this.state.country}</p></Col>
                </Row>
               
            
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

        )
    }
}



class CreateOrder extends Component{
    constructor(props){
        super(props);
     
        var today = new Date();
       

         
          var month = '' + (today.getMonth() + 1)
            var   day = '' + today.getDate()
           var    year = today.getFullYear()
        
           if (month.length < 2) {
             month = '0' + month;
           }
            
           if (day.length < 2) {
             day = '0' + day;
           }
           
        
           let date = [year, month, day].join('-');


        this.state = ({
          current_date: date,
            AllSelected:[],
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            search_name_select:"",product_carriage:"",product_name1:"",product_price:0,product_quantity:0 ,product_sku:"",product_selected:"",product_export:false,
            // search_name_select:"Product",
            product_category_options :[],
            product_template_options :[],
            product_edit_category :[],
            product_edit_template :[],
            product_category:"",ordernumber:"",product_template:"",
         customer_id:"",order_date:date,
        received_date:date,tracking_number:"",shiper_order_number:"",
        weight:0,height:0,fedex_charge:0,width:0,length:0,actual_charge:0,homedelivery_charge:0,promo_type:"Not",promo_value:0,
        Weight_type:null,
          //orderStatusSelected:"YTA",
          orderStatusSelected:"IM",
          paymentTypeSelected:"card",showSpinner:false,
          customer_selected_data:{},
          pendingOrders:[],ordersSelectedInModal:[],
          createNewOrder:true,holdmergedorderstatus:"RTM",
          ordernumbermerge:"",
          error:"",
          rowNumber:"",
          selectItemDetails:{},
      
          generatedOrderId: "",
          isOrderUpdate: false,
          customer_email: "",
          serachInput: '',
          searchResult:'',
          country_list: this.props.countryList,
          statedata: this.props.statedata,
          stateCode_list: [], stateName_list:[],
      statelist: [],
      statelistto: [],
          countrynamelist: this.props.country_name_list, countrycodelist: this.props.country_code_list,
          ship_date	:date,
from_country	:"",
from_company	:"",
from_contact	:"",
from_address1	:"",
from_address2	:"",
from_zip	:"",
from_city	:"",
from_state	:"",
from_phoneno	:"",
to_country	:"",
to_company	:"",
to_contact	:"",
to_address1	:"",
to_address2	:"",
to_zip	:"",
to_city	:"",
to_state	:"",
to_phoneno	:"",
packageno	:"",
weight_unit	:"0",
declared_value_unit	:"0",
total_weight	:"",
declared_value	:"",
total_custom	:"",
service_type	:"Priority",
package_type	:"Your Packaging",
package_content	:"commodities",
package_identical:"no",
purpose	:"",
broker_company	:"",
broker_accno	:"",
broker_comp_name	:"",
broker_contact	:"",
broker_address1	:"",
broker_address2	:"",
broker_city	:"",
broker_country	:"",
broker_phone	:"",
broker_taxid	:"",
commod_desc	:"",
commod_unit	:"Pieces",
commod_qty	:"",
commod_weight	:"",
commod_value	:"",

          })

    }



    openOrderModel = ()=>{
      this.refs.ordermodel.openModal();
    }


    showSelectedModalOrders=(orderselected)=>{
      // console.log(`order selected ${orderselected}`)
      this.setState({ordersSelectedInModal:orderselected})
    }


    getCategories = async (countryid)=>{

      let token =localStorage.getItem("token")
      let header = {'Content-Type': 'multipart/form-data',
      'Authorization':'Token '+token,

      }

      var url= base_url+`categories/?country=${countryid}`
      var categories = await Axios.get(url,header)
      // console.log(categories.data)
      
     

      await this.setState({product_category_options:categories.data})
      await this.setState({product_edit_category:categories.data})

    }

    getTemplate = async ()=>{

      let token =localStorage.getItem("token")
      let header = {'Content-Type': 'multipart/form-data',
      'Authorization':'Token '+token,

      }

      var url= base_url+`templates/`
      var templates = await Axios.get(url,header)
      // console.log(categories.data)
      
     

      await this.setState({product_template_options:templates.data})
      await this.setState({product_edit_template:templates.data})

    }


getPendingOrder= async (customerid,orderstatus)=>{
// try{
//   let token =localStorage.getItem("token")
//   let header = {'Content-Type': 'multipart/form-data',
//   'Authorization':'Token '+token,

//   }

//   var url= base_url+`products/?ezz_id=${customerid}&status=${orderstatus}`
//   let orders = await Axios.get(url,header)
//   // console.log(orders.data)
//   await this.setState({pendingOrders: this.state.pendingOrders.concat(orders.data)})
//   // M.toast({html: 'Pending Ordersa fetched',classes:"white-text orange rounded"});
//   this.setState({error:""})
// }catch(error){
//   console.log(error)
//   M.toast({html: 'Pending Ordersa cannot be fetched',classes:"white-text red rounded"});
//   this.setState({error:<Error/>})
// }

}

handletableClick=(clickedrow,exportstatus)=>{
  this.state.productlist[clickedrow].productExport = exportstatus
 // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
 let productdetail = this.state.productlist[clickedrow]
 let countryoptions = this.state.product_category_options
 let templateoptions = this.state.product_template_options
 //console.log("handle cat",countryoptions)
 //console.log("handle cat",this.state.product_edit_category)
 this.setState({rowNumber:clickedrow})
 this.setState({selectItemDetails:productdetail})
 
 if (productdetail.productStatus!= "Active")
   this.refs.editproduct.openModal(clickedrow,productdetail,countryoptions,templateoptions,1);
  else
   this.refs.editproduct.openModal(clickedrow,productdetail,countryoptions,templateoptions,2);
}

updateProduct=(rownumber,item)=>{
  console.log("updatig list",rownumber,item)
let productlist = this.state.productlist
productlist.splice(rownumber, 1, item)
this.setState({productlist:productlist})
// this.refs.editproduct.closeModal();
// M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});

}

// deleteProduct = () => {
//   console.log('YEAH WE WILL WORK ON THIS');
  
// }
deleteProduct= async (rownumber,item_id)=>{
  // let tempItemId = item_id.toString()
  let updated_productlist = this.state.productlist
  // console.log("DELETING ITEM HERE ",rownumber,item_id)

  // if(!tempItemId.includes('item_')){
  //   await Axios({
  //     method: 'DELETE',
  //     url: base_url + `products/${item_id}/`
  //   }).then(function (response) {
  //     // console.log(`DELETED${tempProductId}`, response);
  //     M.toast({ html: `Product deleted successfully`, classes: "white-text blue rounded" })
  //   }).catch(function (response) {
  //     // console.log("NOT DELETED ERROR",response);
  //     M.toast({ html: `Product deletion failed`, classes: "white-text red rounded" })
  //   })
  // }  

  updated_productlist.splice(rownumber, 1)
  await this.setState({productlist:updated_productlist})
  console.log(`ITEM ${item_id} DELETED AT ${rownumber}`)
  // this.refs.editproduct.closeModal();
  // M.toast({html: 'item deleted ' +rownumber,classes:"white-text blue rounded"});

}

handleCountryNameChange = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelist: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    console.log(this.state.statelist)
   

  }

handleCountryNameChangeTo = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelistto: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    //console.log("state list")
    //console.log(this.state.statelist)
   

  }


    // handleCustomerIDChange = async (ev)=>{       
    //   const target = ev.target;
    //   const name = target.name;

    //   let search_input = target.value
    //   //var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
    //   var patt = /^[0-9]/g;
    //   // var patt =  /^[EZez]\d{0,4}/g;
    //   var result = search_input.match(patt);

    //   this.setState({
    //     [name]: target.value,
    //     searchInput: search_input,
    //     searchResult: result
    //   })

    
    // }
    handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    // }      
    // handleCustomerInfo = async e => {  
      // if(e.key !== 'Enter'){
      //   return
      // }

      try{
      
        let token =localStorage.getItem("token")
        let header = {'Content-Type': 'multipart/form-data',
        'Authorization':'Token '+token,

        }

        // Fetching user list on input change
        // var url= base_url+`customers/?ezz_id__icontains=${target.value}`
        // var url= base_url+`customers/?ezz_id=&ezz_id__icontains=${target.value}&email__icontains=${target.value}&phone_number=`

        var url
        var searchType

        if(this.state.searchResult){
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=${this.state.searchInput}&email__icontains=&phone_number=`
          searchType = "ezzid"
        }        
        else{
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=&email__icontains=${this.state.searchInput}&phone_number=`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,header)
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"
        // DEFAULT WORKING EZZID
        // for (let customer of customer_data.data) {
        //   ezzidlist[customer.ezz_id] = customer.email;
        //   customerdataObject[customer.ezz_id] = customer;
        // }

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.ezz_id] = customer.email;
            customerdataObject[customer.ezz_id] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.email] = customer.ezz_id;
            var tempEzId = ezzidlist[customer.email]
            customerdataObject[tempEzId] = customer;

          }
        }     

        // console.log(ezzidlist)
        // console.log(customerdataObject)
        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        // /await context.setState({pendingOrders:[]})
        const elems_auto_customer = document.getElementById('customer_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {
            // context.setState({customer_id:select_customer})                // DEFAULT WORKING EZZID

            // context.setState({customer_email: select_customer})

            // console.log("select_customer",select_customer);

            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({customer_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({customer_id:tempEzzId})                
            }            
        
            // DEFAULT WORKING EZZID
            // context.setState({customer_selected_data:customerdataObject[select_customer]})
            // await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[select_customer].country)
            //       await context.getPendingOrder(select_customer,"IM")
            //        await context.getPendingOrder(select_customer,"YTA")





            context.setState({customer_selected_data:customerdataObject[tempEzzId]})
            // await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[tempEzzId].country)
            //       await context.getPendingOrder(tempEzzId,"IM")
            //        await context.getPendingOrder(tempEzzId,"YTA")
           
           context.setState({shippingTypeSelected:customerdataObject[tempEzzId].shipping_method.toString()})
context.setState({
to_contact:customerdataObject[tempEzzId].name,
to_address1:customerdataObject[tempEzzId].address,
to_address2:customerdataObject[tempEzzId].address_2,
to_zip:customerdataObject[tempEzzId].zipcode,
to_city:customerdataObject[tempEzzId].city,
to_state:customerdataObject[tempEzzId].state_name,
to_phoneno:customerdataObject[tempEzzId].phone_number,
to_country:customerdataObject[tempEzzId].country})






            // //context.set
         
            // // @TODO show model only when there is any orders pending 
            // //like IM or BoX YET to arrive
            // if (context.state.pendingOrders.length >0){
            //   context.openOrderModel();
            // }
            // // console.log("selected cust ID AUTH", customerdataObject[select_customer].card_authorize  )
           
      //       const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize
			

      //       if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )   {       
      //         document.getElementById("errCustomerNotAuthorized").style.display = "inline-grid"
			// 	//this.setState({card_not_authorized:true}) 
			// 	//context.setState({card_not_authorized:true}) 				
			// }
      //       else  {
      //         document.getElementById("errCustomerNotAuthorized").style.display = "none"
			// }
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        this.setState({error:<Error/>})
      }

    }

   fillFormAutomatically=(data)=>{
    //  console.log(`fill auto maticaly ${data}`)
    this.setState({
      ordernumber: data.order_id,
      order_date: data.order_date,
      received_date: data.received_date,
      tracking_number: data.inbound_tracking_number,
      shiper_order_number : data.shipper_number,
      weight: data.weight,
      height: data.height,
      fedex_charge: data.freight_charge,
      width: data.breadth,
      length: data.length,
      actual_charge: data.actual_charge,
      orderStatusSelected: data.current_status,
      paymentTypeSelected: data.billing_type,

      
    });
    
  
  }

  getfedexActualRate = async (weight,height,length,breadth)=>{
    


    try{
  let token =localStorage.getItem("token")


  var config = {
    headers: { 
      'Content-Type': 'multipart/form-data' , 
      'Authorization':'Token '+token},
    
  };
  
//console.log(this.state.order_date);
  // Fetching user list on input change
  //var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
  var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}`
  var response = await Axios.get(url,config)
  // console.log(response.data)
  return response.data
}catch(error){

  console.log(error.response.data)
  
   if (error.response.status ===503 ){
    M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
   }
   if (error.response.status === 403 ){
    M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
   }
   
  
  // return response.data
  return error.response.data
}
}

downloadReport = async () => {
    // var paramTemplate = ""
  // var paramProduct = ""
   var paramTemplate = []
   var paramProduct = []

  var paramOrderId = this.state.generatedOrderId
  this.state.productlist.forEach(async(item)=>{
          // if (item.productStatus != "Active")
          // {
          //   if (paramTemplate.length == 0)
          //         paramTemplate = '"' + item.productStatus + '"'
          //   else
          //         paramTemplate = paramTemplate + "," + '"' + item.productStatus + '"'          
          // }
          // else
          // {
          //   if (paramProduct.length == 0)
          //     paramProduct =  '"' + item.id  + '"'
          //   else
          //     paramProduct = paramProduct + "," + '"' + item.id + '"'           

          // }

          if (item.productStatus != "Active")
          {
            if (item.productExport === true)
            {
              paramTemplate.push(item.productStatus)
            }
          }
          else
          {
            if (item.productExport === true)
            {
              paramProduct.push(item.id)
            }
          }

      });
        let report_url = base_url + `get_order_product_csv`
        // console.log('url',report_url);
        // console.log('this.state.countryName',this.state.countryName);
        var FileSaver = require('file-saver');
        // const newdata={
        //       "template_name": "[" + paramTemplate + "]",
        //       "product_id": "[" + paramProduct + "]",
        //       "order_id": paramOrderId
        //     }
        const newdata={
              "template_name": paramTemplate ,
              "product_id":  paramProduct,
              "order_id": paramOrderId
            }

        await Axios({
            url: report_url,
            method: 'POST',
            responseType: 'arraybuffer',
           
            data: newdata
        })
        .then((response)=>{
            {
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, `product.xlsx`);
            }
            console.log('response',response);
        }).catch((err)=>{
            console.log('err',err);
        })

    }

 changeTo= (e) =>{

          switch (e.target.name) {
            case "weight":
              this.setState({weight: e.target.value})
              break;
            case "height" :
              this.setState({height: e.target.value})
              break;
            case "width" :
              this.setState({width: e.target.value})
              break;
            case "length" :
              this.setState({length: e.target.value})
              break;
          }
                //this.setState({weight: e.target.value})
                 //// console.log("is change to");
                  console.log(e.target.value);
                  this.handleFormChange(e);
      }

      updateValue= (e) =>{

          switch (e.target.name) {
            case "weight":
              this.setState({weight: e.target.value})
              break;
            case "height" :
              this.setState({height: e.target.value})
              break;
            case "width" :
              this.setState({width: e.target.value})
              break;
            case "length" :
              this.setState({length: e.target.value})
              break;
          }
                   //this.setState({weight: e.target.value})
                  //console.log("is updtevalue");
                  //console.log(e.target.value);
          
      }


    handleFormChange= async (ev) =>{
     
      const target = ev.target
      const name = target.name
      console.log(ev.target.name)
      this.setState({
            [name]: target.value
          });
   if (name == 'package_identical')
      {
        // if (target.value == 'yes')
        // {
          
        //   document.getElementById("pnlitemdetail").style.display = "inline-grid"
        //   document.getElementById("pnlweightunit").style.display = "none"
        //   document.getElementById("pnldeclaredvalue").style.display = "none" 
        // }
        // else
        // {
        //   document.getElementById("pnlitemdetail").style.display = "inline-grid"
        //   document.getElementById("pnlweightunit").style.display = "none"
        //   document.getElementById("pnldeclaredvalue").style.display = "none" 
        // }
        if (target.value == 'yes')
        {
          
          document.getElementById("pnlitemdetail").style.display = "none"
          document.getElementById("pnlweightunit").style.display = "inline-grid"
          document.getElementById("pnldeclaredvalue").style.display = "inline-grid" 
        }
        else
        {
          document.getElementById("pnlitemdetail").style.display = "inline-grid"
          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 
        }
      }
     
    }
    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

    componentDidMount(){
      
        M.AutoInit();

       document.getElementById("shipdetail").style.display = "none"
     document.getElementById("brokerdetail").style.display = "none"
    document.getElementById("commoditydetail").style.display = "none" 
    document.getElementById("btndetail").style.display = "none" 




        console.log("is thissssssss working");
        

        // var elems_recieved = document.getElementById('received_date');
        var elems = document.querySelectorAll('.modal');
        let model_options = {
          dismissible:false,
        }
        var instances = M.Modal.init(elems, model_options);
        
        var elems_auto_customer = document.querySelectorAll('.autocomplete');

        var options_auto_customer={
      
          data: {}, 
          limit:20,
          onAutocomplete :function(select_customer) {
           
            // context.setState({ order_date: selecteddata});
            //  console.log("slect autocomplete",select_customer); 
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        } 
        var instances_auto = M.Autocomplete.init(elems_auto_customer, options_auto_customer);
      

         
        var context = this

       var newdate = this.newDate()
       console.log('new date1',newdate)
        var elems_order = document.getElementById('ship_date');
      
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              // console.log(context.formatDate(date))
               let selecteddata = context.formatDate(date)
              console.log('selectedate',selecteddata)
              context.setState({ ship_date: context.formatDate(selecteddata)})
              console.log("ship_order_date",selecteddata); // Selected date is logged
            },
          }
           var instances = M.Datepicker.init(elems_order, options_order);
 
 instances.toString()
        // var instances = M.Datepicker.init(elems_order, options_order);
        // var elems_recieved = document.getElementById('received_date');
        // var options_recieved={
        //     //selectMonths: true, // Enable Month Selection
        //     selectYears: 1, // Creates a dropdown of 10 years to control year
        //     format:'yyyy-mm-dd',
        //     autoClose :true,
        //     defaultDate:newdate,
        //     setDefaultDate :true,
        //     onSelect: function(date) {
        //       let selecteddata = context.formatDate(date)
        //       context.setState({ received_date: selecteddata.toString()});
        //       //  console.log("recived_datae"+ selecteddata); // Selected date is logged
        //     },
        //   }
        // var instances = M.Datepicker.init(elems_recieved, options_recieved);
        //   instances.toString()
    
    
    }


// @TODO: handlechange for create order is pending Please implement it


     addProductTolist = ()=>{
      // console.log(this.state.productname + this.state.productprice +this.state.productquantity)
       var prodname =this.state.product_name1
       var prodquant =this.state.product_quantity
       var prodcat =this.state.product_category
       var templatename = this.state.product_template
      
       {
     

        let totalproducts = this.state.productlist.length + 1
        var templateprice = 0
        var templateqty = 0
        
          // if (this.state.product_selected !== this.state.product_name1)
          // {
          //   M.toast({ html: 'Product do not exist!Please contact your admin!', classes: "white-text red rounded" });
          //   return
          // }
          var elem = document.getElementById('addProductModal')
          let newitem = {id:'item_'+totalproducts,productName:this.state.product_name1,
          productPrice:this.state.product_price ,
          productQuantity:this.state.product_quantity,
          productCategory:this.state.product_category,
          productSKU:this.state.product_sku,
          productStatus: "Active",
          productTemplate:this.state.product_template,
          productExport:false,
          productCarriage:this.state.product_carriage,
          productSelect:this.state.search_name_select
          }
          this.state.productlist.push(newitem)
        
        var instance = M.Modal.getInstance(elem);
        instance.close();
        // console.log("Product List",this.state.productlist)
       //this.state.productlist.push(newitem)
        this.setState({productlist: this.state.productlist})
        
      try {
        // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
        this.setState({product_name1:"",product_price:0,product_quantity:0,product_sku:"",product_template:"" })
       M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
      } catch (error) {
        M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
      }
       
    
       }

    }

  
    cancelOrder = ()=>{
        // @TODO fucntionality not clear yet
        // M.toast({html: 'Pending for Implemention',classes:"white-text black rounded"})
        // return (<Redirect to="/Admin"/>
        window.location.reload();
   }


handleMergeCheckbox= (event)=>{
  // event.target.checked
  // console.log(this.state.holdmergedorderstatus)
  // console.log(event.target.checked)
  const name = event.target.name
  if (event.target.checked){
    this.setState({[name]:"WFC"})
  }else{
    this.setState({[name]:"RTM"})
  }
}

mergeOrder=()=>{
  
  // var elems = document.getElementById('merge_order_model');
  // var instance = M.Modal.init(elems);
  // instance.close();
  this.createOrderMerged();



}
mergerOrderCancel = ()=>{



  // var elems = document.getElementById('merge_order_model');
  // var instance = M.Modal.init(elems);
  // instance.close();
  this.createOrderNormal();







}


  createOrderMerged = async () => {
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    if (this.state.createNewOrder) {

      // new order creation
      console.log("Deleting previous orders if any")
      this.state.productlist.forEach(async (item) => {
        console.log("productlist Merged order-----")
        // console.log(item)
        try {

          let urlfordeletion = base_url+`products/${item.id}/`
          const response = await Axios.post(urlfordeletion,
            header)
          // console.log(`deletion response ${response.data}`)
          console.log("Product delete success")
          M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
        } catch (error) {
          M.toast({ html: 'No old products found', classes: "white-text green rounded" });
          console.log('error in deletion',error)
        }

      });



      const orderid = this.state.ordernumber
      const existing_order = this.state.ordernumbermerge
      // this.setState({ordernumber:orderid})

      var current_status = this.state.holdmergedorderstatus
      var Orderformdata = new FormData()
      console.log("orderid" + orderid)
      console.log(this.state.fedex_charge)
      console.log(this.state.actual_charge)
      Orderformdata.append("order_id", orderid.toString())
      Orderformdata.append("ezz_id", this.state.customer_id)
      Orderformdata.append("shipper_number", this.state.shiper_order_number)
      Orderformdata.append("billing_id", "2121")
      Orderformdata.append("billing_type", this.state.paymentTypeSelected)
      Orderformdata.append("order_date", this.state.order_date)
      Orderformdata.append("received_date", this.state.received_date)
      Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
      Orderformdata.append("weight", this.state.weight)
      Orderformdata.append("length", this.state.length)
      Orderformdata.append("breadth", this.state.width)
      Orderformdata.append("height", this.state.height)
      Orderformdata.append("charge_type", "VAT")
      Orderformdata.append("freight_charge", this.state.fedex_charge)
      Orderformdata.append("actual_charge", this.state.actual_charge)
      Orderformdata.append("current_status", "DISC")
      Orderformdata.append("previous_status", "DISC")
      Orderformdata.append("status", "Deactive")
      Orderformdata.append("flag", "1")

      // console.log(JSON.stringify(Orderformdata))
      // console.log(JSON.stringify(this.state))

      const orderstatus_merged = {
        "remarks": "discarded after merging with order" + existing_order,
        "order_id": orderid,
        "status_id": "DISC"
      }

      const orderstatus_mergedwith = {
        "remarks": "",
        "order_id": existing_order,
        "status_id": current_status
      }

      // @Todo: APi to be called to save data for state

      try {

        const response = await Axios.post("http://localhost:8000/api/v1/ezzytrace/boxes_received/", Orderformdata,
          header)
        const response_newdiscrded = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_merged,
          header)
        const response_existingupdated = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_mergedwith,
          header)

        var existingOrderUpdateForm = new FormData()
        existingOrderUpdateForm.append("current_status", current_status)
        let urlforexistingOrderStatusUpdate = base_url+`boxes_received/${existing_order}/`
        await Axios.patch(urlforexistingOrderStatusUpdate, existingOrderUpdateForm, header)


        this.state.productlist.forEach(async (item) => {
          // console.log("productitemmerged")
          // console.log(item)
          try {
            var productformdata = new FormData()
            productformdata.append("order_id", existing_order)
            productformdata.append("description", item.productName)
            productformdata.append("quantity", item.productQuantity)
            productformdata.append("unit_price", item.productPrice)
            productformdata.append("inspection_status", item.productSKU)
            //productformdata.append("status", "Active")
            productformdata.append("status", item.productTemplate)
            productformdata.append("category", item.productCategory)

            const response_products = await Axios.post(base_url+"products/", productformdata,
              header)

            console.log("Product added Successfully")
            this.setState({ error: <Success message=" Order Status Updated" /> })
            M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
          } catch (error) {
            M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
            console.log(error)
          }

        });


this.setState({error:<Success message=" New order for product items created"/>})
 M.toast({html: 'Order created successfully',classes:"white-text orange rounded"})
this.setState({error:""})    
}catch(err){
  
    M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
    
    console.log(err)
    this.setState({error:<Error/>})
        }




}else{


  try{
  
    var orderupdateForm = new FormData()
    orderupdateForm.append("shipper_number",this.state.shiper_order_number)
    // orderupdateForm.append("billing_id","2121")
    orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
    orderupdateForm.append("order_date",this.state.order_date)
    orderupdateForm.append("received_date",this.state.received_date)
    orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
    orderupdateForm.append("weight",this.state.weight)
    orderupdateForm.append("length",this.state.length)
    orderupdateForm.append("breadth",this.state.width)
    orderupdateForm.append("height",this.state.height)
    // orderupdateForm.append("charge_type","VAT")
    orderupdateForm.append("freight_charge",this.state.fedex_charge)
    orderupdateForm.append("actual_charge",this.state.actual_charge)
    orderupdateForm.append("current_status",this.state.orderStatusSelected)
    let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
    const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm,
    header)

    console.log(`order status update succesfully ${response.data}`)
    this.setState({error:<Success message=" Order Status Updated"/>})
    M.toast({html: 'Updated SUccessfull',classes:"white-text orange rounded"});
  }catch(error){
     M.toast({html: 'Updation Failed',classes:"white-text red rounded"});
      console.log(error)
      this.setState({error:<Error/>})
  }

}
}





  createOrderNormal = async () => {

    if (parseFloat(this.state.total_weight) != parseFloat(this.state.commod_weight))
    {
      alert("Total weight and commodity weight must be equal.")
      return;
    }
    
    if (parseFloat(this.state.total_custom) != parseFloat(this.state.commod_value))
    {
      alert("Total customs value and commodity declared value must be equal.")
      return;
    }

    this.setState({isOrderUpdate: true})
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    console.log("create new order" + this.state.createNewOrder)

    if (this.state.createNewOrder) {

     
      var Orderformdata = new FormData()
      // console.log("orderid"+orderid)
      console.log(this.state.fedex_charge)
      console.log(this.state.actual_charge)
      // Orderformdata.append("order_id",orderid.toString())
     //Orderformdata.append("ship_date", "2020-01-01")
      Orderformdata.append("ship_date", this.state.ship_date)
      Orderformdata.append("order_date", this.state.ship_date)
      Orderformdata.append("from_country", this.state.from_country)
      Orderformdata.append("from_company", this.state.from_company)
      Orderformdata.append("from_contact", this.state.from_contact)
      Orderformdata.append("from_address1", this.state.from_address1)
      Orderformdata.append("from_address2", this.state.from_address2)
      Orderformdata.append("from_zip", this.state.from_zip)
      Orderformdata.append("from_city", this.state.from_city)
      Orderformdata.append("from_state", this.state.from_state)
      Orderformdata.append("from_phoneno", this.state.from_phoneno)
      Orderformdata.append("to_country", this.state.to_country)
      Orderformdata.append("to_company", this.state.to_company)
      Orderformdata.append("to_contact", this.state.to_contact)
      Orderformdata.append("to_address1", this.state.to_address1)
      Orderformdata.append("to_address2", this.state.to_address2)
      Orderformdata.append("to_zip", this.state.to_zip)
      Orderformdata.append("to_city", this.state.to_city)
      Orderformdata.append("to_state", this.state.to_state)
      Orderformdata.append("to_phoneno", this.state.to_phoneno)
      Orderformdata.append("packageno", this.state.packageno)
      Orderformdata.append("weight_unit", this.state.weight_unit)

      Orderformdata.append("declared_value_unit", this.state.declared_value_unit)
      Orderformdata.append("total_weight", this.state.total_weight)
      Orderformdata.append("declared_value", this.state.declared_value)
      Orderformdata.append("total_custom", this.state.total_custom)
      Orderformdata.append("service_type", this.state.service_type)
      Orderformdata.append("package_type", this.state.package_type)
      Orderformdata.append("package_content", this.state.package_content)
      Orderformdata.append("package_identical", this.state.package_identical)
      Orderformdata.append("purpose", this.state.purpose)
      Orderformdata.append("broker_company", this.state.broker_company)
      //Orderformdata.append("broker_company", this.state.broker_comp_name)
      Orderformdata.append("broker_accno", this.state.broker_accno)
      Orderformdata.append("broker_comp_name", this.state.broker_comp_name)
      Orderformdata.append("broker_contact", this.state.broker_contact)
      Orderformdata.append("broker_address1", this.state.broker_address1)
      Orderformdata.append("broker_address2", this.state.broker_address2)
      Orderformdata.append("broker_city", this.state.broker_city)
      Orderformdata.append("broker_country",this.state.broker_country)
      Orderformdata.append("broker_phone", this.state.broker_phone)
      Orderformdata.append("broker_taxid", this.state.broker_taxid)
      Orderformdata.append("commod_desc", this.state.commod_desc)

Orderformdata.append("commod_unit", this.state.commod_unit)
      Orderformdata.append("commod_qty", this.state.commod_qty)
      Orderformdata.append("commod_weight", this.state.commod_weight)
      Orderformdata.append("commod_value", this.state.commod_value)
      Orderformdata.append("commod_country", this.state.commod_country)
     
      try {

        const response = await Axios.post(base_url+"shipment/", Orderformdata,
          header)

        const orderid = response.data.order_id
        console.log("orderid" + orderid)
        this.state.productlist.forEach(async (item) => {
          console.log("productitem")
          console.log(item)
          try {
            var productformdata = new FormData()
            productformdata.append("order_id", orderid.toString())
           
              productformdata.append("qty", item.productSelect)
              productformdata.append("weight", item.productName)
              productformdata.append("length", item.productQuantity)
              productformdata.append("width", item.productPrice)
              productformdata.append("height", item.productSKU)
              productformdata.append("carriage_value", item.productCarriage)
              productformdata.append("status", "Active")
              const response_products = await Axios.post(base_url+"shipmentpackage/", productformdata,
              header)
            

            // alert(response.data)
            //console.log(productformdata)
            console.log("Product added Successfully")
            this.setState({ error: <Success message=" Order Status Updated" /> })
            M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
          } catch (error) {
            //M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
            console.log(error)
          }

        });

        this.setState({ error: <Success message=" New order foproduct items created" /> })
        M.toast({ html: 'Order created successfully', classes: "white-text orange rounded" })
        this.setState({ error: "" })
        this.setState({ generatedOrderId: orderid })
        console.log('generatedOrderId',this.state.generatedOrderId);
        document.getElementById("printLabel").disabled = false

        await this.getproducts(orderid)

      } catch (err) {

        M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

        console.log(err)
        this.setState({ error: <Error /> })
      }




    } else {


      try {
        let token = localStorage.getItem("token")
        const header = {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + token,

        }
        var orderupdateForm = new FormData()
        orderupdateForm.append("shipper_number", this.state.shiper_order_number)
        // orderupdateForm.append("billing_id","2121")
        orderupdateForm.append("billing_type", this.state.paymentTypeSelected)
        orderupdateForm.append("order_date", this.state.order_date)
        orderupdateForm.append("received_date", this.state.received_date)
        orderupdateForm.append("inbound_tracking_number", this.state.tracking_number)
        orderupdateForm.append("weight", this.state.weight)
        orderupdateForm.append("length", this.state.length)
        orderupdateForm.append("breadth", this.state.width)
        orderupdateForm.append("height", this.state.height)
        // orderupdateForm.append("charge_type","VAT")
        orderupdateForm.append("freight_charge", this.state.fedex_charge)
        orderupdateForm.append("actual_charge", this.state.actual_charge)
        orderupdateForm.append("current_status", this.state.orderStatusSelected)
        let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
        const response = await Axios.patch(urlforOrderStatusUpdate, orderupdateForm, header)

        console.log(`order status update succesfully ${response.data}`)
        this.setState({ error: <Success message=" Order Status Updated" /> })
        M.toast({ html: 'Updated SUccessfull', classes: "white-text orange rounded" });
      } catch (error) {
        M.toast({ html: 'Updation Failed', classes: "white-text red rounded" });
        console.log(error)
        this.setState({ error: <Error /> })
      }

    }
    
    document.getElementById('saveOrderBtn').disabled = true

  }
   addOrderToDB = async ()=>{

     return;
              let token =localStorage.getItem("token")
              const  header = {'Content-Type': 'application/json',
              'Authorization':'Token '+token,
            
              }
            
                  //@TODO: FOR FUTUREjust umcomment the code for merge ,discard and hold fucntionality 
     

              // check whether there is any order which is in state of WAITING FOR CONSOLIDATION
              // try {
              //   let url_wfc_check = base_url+`boxes_received/?ezz_id=${this.state.customer_id}&current_status=WFC`
              //   var  wfcResponse = await Axios.get(url_wfc_check,header)
              //   console.log(`WFC Response ${wfcResponse.data}`)
              //   if (wfcResponse.data.length >0){
              //     console.log(wfcResponse.data[0].order_id)
              //     this.setState({ordernumbermerge:wfcResponse.data[0].order_id})
              //     var elems = document.getElementById('merge_order_model');
              //     var instance = M.Modal.init(elems);
              //     instance.open()

              //     const orderid = Math.floor(1000 + Math.random() * 9000)
              //     this.setState({ordernumber:orderid})

              //   }else{
                  this.createOrderNormal()
                  var btn = document.getElementById('createorder')
                  btn.click()
                 // window.location.reload(false);
              // this.props.refresh();
              //   }
                
              // } catch (error) {
              //   console.log(error)
              // }



            


            



  }

  printLabelManually = () => {

    var order_id = this.state.generatedOrderId
    console.log("generatedOrderId:", order_id)

      Axios({
        method: 'post',
        url: base_url+'status_printer',
        data: {
          "order_id": order_id
        }
      }).then(function (response) {
        console.log(response)
        console.log("printer APi success");
        M.toast({
          html: `Print Label successful for orderId: ${order_id} `,
          classes: "center white-text green rounded"
        })
      }).catch(function (response) {
        console.log(response)
        console.log('printer API failed');
        M.toast({
          html: `Print Label unsuccessful`,
          classes: "white-text orange rounded"
        })
      })
      
  }

  //UPDATE ORDER START

  getproducts = async (orderid) => {
    // http://localhost:8000/api/v1/ezzytrace/products/?order_id=
    try {
      let token = localStorage.getItem("token")
      // let header = {'Content-Type': 'multipart/form-data',
      // 'Authorization':'Token '+token,

      // }

      var config = {
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

      };

      var url = base_url+`products/?order_id=${orderid}`
      let products = await Axios.get(url, config)

      // var items = new Array()
      // products.data.forEach((product) => {
      //   let newitem = {
      //     id: product.id, productName: product.description,
      //     productPrice: product.unit_price, productQuantity: product.quantity,
      //     productCategory: product.category, productSKU: product.inspection_status,productTemplate:product.status
      //   }
      //   items.push(newitem)
      //   // this.state.productlist.push(newitem)
      // })
      var items = new Array()
      products.data.forEach((product) => {
        let newitem = {
            id: 1, productName: "",
            productPrice: 0, productQuantity: 0,
            productCategory: "",productSKU: "",productStatus: "",productExport:false,productCarriage:"",productSelect:""}
        if (product.status != "Active")
        {
           newitem = {
            id: product.id, productName: product.status,
            productPrice: 0, productQuantity: 0,
            productCategory: "Template",productSKU: product.inspection_status,productStatus: product.status,
            productTemplate: product.status,productExport:false
          }
          const found = items.find(element => element["productStatus"]==product.status)
          console.log('found', found)
          if(found)
          {}
          else
          {
            items.push(newitem)
          }
        }
        else
        {
            newitem = {
            id: product.id, productName: product.description,
            productPrice: product.unit_price, productQuantity: product.quantity,
            productCategory: product.category,productSKU: product.inspection_status,productStatus: product.status,
            productTemplate: product.status,productExport:false
          }
          items.push(newitem)
        }


        
        // this.state.productlist.push(newitem)
      })
      M.toast({ html: 'Fetched package detail for selected shipment', classes: "white-text orange rounded" });
      // this.setState({error:<Success/>})


      await this.setState({ productlist: items })


      // await this.setState({ productlisthtml: <ProductItemsList orderid={orderid} products={items} rowclick={this.handletableClick} /> })


    } catch (error) {
      console.log(error)
      //M.toast({ html: 'Failed to fetch products', classes: "white-text red rounded" });
      // this.setState({error:<Error/>})
    }
  }

  updateOrderDB= async(header)=>{
    const  orderid= this.state.generated_orderId

    const found = this.state.productlist.find(element => element["productPrice"]==0);

    console.log('found', found)
    // console.log('this.state.generated_orderId', this.state.generated_orderId)
    // console.log('orderid', orderid)

    // if(found && this.state.orderStatusSelected === 'RTM' ){
    //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
    //   return
    // }

      try{
          
          var orderupdateForm = new FormData()
          // orderupdateForm.append("order_id",this.state.generated_orderId)
          orderupdateForm.append("ezz_id",this.state.customer_id)
          orderupdateForm.append("shipper_number",this.state.shiper_order_number)
          orderupdateForm.append("billing_id","2121")
          orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
          orderupdateForm.append("order_date",this.state.order_date)
          orderupdateForm.append("received_date",this.state.received_date)
          orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
          orderupdateForm.append("weight",this.state.weight)
          orderupdateForm.append("length",this.state.length)
          orderupdateForm.append("breadth",this.state.width)
          orderupdateForm.append("height",this.state.height)
          orderupdateForm.append("charge_type","VAT")
          orderupdateForm.append("freight_charge",this.state.fedex_charge)
          orderupdateForm.append("actual_charge",this.state.actual_charge)
          orderupdateForm.append("current_status",this.state.orderStatusSelected)
          // orderupdateForm.append("previous_status",this.props.orderdata.current_status)
          orderupdateForm.append("status","Deactive")
          orderupdateForm.append("flag","1")
          orderupdateForm.append("homedelivery_charge", this.state.homedelivery_charge)
          orderupdateForm.append("promo_type", this.state.promo_type)
          orderupdateForm.append("promo_value", this.state.promo_value)

          let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.generatedOrderId}/`
          const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
    
          // console.log(`order status update succesfully ${response.data}`)
          this.setState({error:<Success message=" Order Updated"/>})
        
          M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});
  
  
          //Updating The Product 
  
          this.state.productlist.forEach(async(item)=>{
            // console.log("productitem")
            // console.log(item)
            // console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')
  
            
  
            try{
              var productformdata = new FormData()
              productformdata.append("order_id",this.state.generatedOrderId)
              productformdata.append("description",item.productName)
              productformdata.append("quantity",item.productQuantity)
              productformdata.append("unit_price",item.productPrice)
              productformdata.append("inspection_status",item.productSKU)
              //productformdata.append("status","Active")
              productformdata.append("status", item.productTemplate)
              productformdata.append("category",item.productCategory)
  
              console.log('ITEM-ID',item.id);

              if( typeof(item.id) === "number" && this.state.isOrderUpdate ){
                const response_products = await Axios.patch(base_url+`products/${item.id}/`, productformdata,
                  header)
                // alert(response.data)
                console.log("Product added Successfully")
                this.setState({ error: <Success message=" Order Status Updated" /> })
                M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
                
              }else{
                
                const response_addProduct = await Axios.post(base_url+`products/`, productformdata,
                header )
  
                M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
                
              }
  
              
              
            }catch(error){
              //M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
                console.log(error)
            }
           
          });
  
         await this.getproducts(this.state.generatedOrderId)
  
  
          // window.location.reload(false);
        }catch(error){
          M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
            console.log(error)
            this.setState({error:<Error/>})
        }
  
  }
  
  updateOrder=()=>{
      let token =localStorage.getItem("token")
      const  header = {'Content-Type': 'application/json',
      'Authorization':'Token '+token,
      }
      this.updateOrderDB(header)
  
  
  }

clearorigin=()=>
{
this.setState({ from_company:'', from_contact:'', from_address1:'', from_address2:'', 
from_phoneno:'', from_city:'', from_state:'' , from_zip:'', from_country:''})
}

cleardestination=()=>
{
//this.setState({ productlist: items })
this.setState({ to_company:'', to_contact:'', to_address1:'', to_address2:'', 
to_phoneno:'', to_city:'', to_state:'' , to_zip:'', to_country:''})
}

refreshpage=()=>
{
  this.setState({ from_company:'', from_contact:'', from_address1:'', from_address2:'', 
from_phoneno:'', from_city:'', from_state:'' , from_zip:'', from_country:''})

 this.setState({ to_company:'', to_contact:'', to_address1:'', to_address2:'', 
to_phoneno:'', to_city:'', to_state:'' , to_zip:'', to_country:''})
}

showcard=()=>{
       document.getElementById("shipdetail").style.display = "inline-grid"
     document.getElementById("brokerdetail").style.display = "inline-grid"
    document.getElementById("commoditydetail").style.display = "inline-grid" 
    document.getElementById("btndetail").style.display = "inline-grid" 
     document.getElementById("continue").style.display = "none" 
     document.getElementById("btnrefresh").style.display = "none"
     document.getElementById("pnlitemdetail").style.display = "inline-grid"
          document.getElementById("pnlweightunit").style.display = "none"
          document.getElementById("pnldeclaredvalue").style.display = "none" 
}
  //--UPDATE ORDER END


render(){
  const opts={};
  var statekey = 0
   opts['readOnly'] = 'readOnly';
  const catlist = this.state.country_list;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.country_id}>{item.name}</option>
      )
    }, this);
  }
  
  const catlist1 = this.state.statelist;
  if (catlist1.length >0){
   var selectStateOptions = catlist1.map((state,statekey) => {
      console.log("state", state)

      return (

        <option  key={statekey++} value={state.state_id}>{state.state_name}</option>
      )
    })
  }
  else
  {
    //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    const states = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
    this.setState({  statelist: states })
  }

const catlistto = this.state.statelistto;
  if (catlistto.length >0){
   var selectStateOptionsTo = catlistto.map((state,statekey) => {
      console.log("state", state)

      return (

        //<option  key={statekey++} value={state.state_id}>{state.state_name}</option>
        <option  key={statekey++} value={state.state_name}>{state.state_name}</option>
      )
    })
  }
  else
  {
    //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    const states = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
    this.setState({  statelistto: states })
  }

  const templist = this.state.product_template_options;
  if (templist.length >0){
    var templateList = templist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.name}>{item.name}</option>
      )
    }, this);
  }

    return(
        <Container>
{/* Edit product Table items */}
<EditProduct ref="editproduct" rowNumber={this.state.rowNumber}
productDetails={this.state.selectItemDetails}
 productcategory={this.state.product_edit_category}
 updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}
productTemplate={this.state.product_edit_template}
 disablePrice= {this.state.orderStatusSelected ==="IM"} 
 />

        {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addProductModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Package </h4>
           
            <Row>
              <Col xs={12} >
                <form>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                         <input name="search_name_select" id="search_name_select" type="text" value={this.state.search_name_select} onChange={this.handleFormChange} className="" />
                        <label htmlFor="search_name_select" className="black-text" >Quantity</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter quantity</span>

                        </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name1" id="product_name1" type="text" value={this.state.product_name1} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_name1" className="black-text" >Weight</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter weight</span>
                      </div>
                    </Col>
                  </Row>
                  {/*<Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name1" id="product_name1" type="text" value={this.state.product_name1} onChange={this.handleFormChange} className="autocomplete" />
                        <label htmlFor="product_name1" className="black-text" >Product Name</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter a valid Product Name</span>
                      </div>
                    </Col>
                  </Row>*/}
                   
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_quantity" className="black-text active">Length</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter length</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_price"  id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_price" className="black-text active">Width</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter width</span>
                      </div>
                    </Col>
                  </Row>


                  
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_sku"  id="product_sku" min="0" type="text" value={this.state.product_sku} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_sku" className="black-text active">Height</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter height</span>
                      </div>
                    </Col>
                  </Row>

<Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_carriage"  id="product_carriage" min="0" type="text" value={this.state.product_carriage} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_carriage" className="black-text active">Carriage Value</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter carriage value</span>
                      </div>
                    </Col>
                  </Row>

                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" >
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
              <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addProductTolist}> Add</Button>

            </ButtonGroup>

          </div>
        </div>

        {/* Model for adding produts ENDS here */}

      {/* Model for merge starts here */}
    <div id="merge_order_model" className="modal mergeordermodel modal-fixed-footer">
      <div className="modal-content">
        <br></br>
      <h5 className="center orange-text"> There is an order with status Waiting for Consolidation. 
                    <br></br>Do you want to merge this order with existing order?   </h5>
                    
                    <form>
                    <Row>
                      <Col>
                      <p className="center">
            <label>
                <input name="holdmergedorderstatus" value="WFC" type="checkbox" 
                checked={this.state.holdmergedorderstatus ==="WFC"} onChange={this.handleMergeCheckbox}/>
                <span className="red-text">Put it on Hold</span>
            </label>
            </p>
                      </Col>
                    </Row>
                    </form>
        
      </div>
      <div className="modal-footer" >
    
  <ButtonGroup className="mr-2" aria-label="Merge group">
    <Button id="cancelMergeAddBtn" variant="outline-secondary" onClick={this.mergerOrderCancel} className="mr-4 btn  modal-close "  >Don't Merge</Button> 
    <Button id ="MergeBtn" variant="outline-primary" onClick={this.mergeOrder} className="mr-2 btn  modal-close" > Merge</Button>

  </ButtonGroup>
 
    </div>
    </div>
        {/* Model for merge ends here */}

            {/* OrderModel code starts here */}
          
            <OrderModal ref="ordermodel" orders={this.state.pendingOrders}
             onOrderSelection={ v => {this.setState({ordersSelectedInModal: v})
              // console.log("hey world")  
              // console.log(v.order)
              // console.log(v.perordersdata[v.order])
              if (v.selectedproductId.length < v.perordersdata[v.order].length){
                 
                  
                  this.setState({createNewOrder:true})

              }else{
                this.setState({createNewOrder:false})
              }
              this.fillFormAutomatically(v.orderdetails) 
              for (let item in v.selectedproductId){
                // console.log(v.selectedproductId[item])
                let product = v.orderid[parseInt(v.selectedproductId[item])]
                console.log(`product orderid ${product}`)

                // let totalproducts = this.state.productlist.length + 1
        
                let newitem = {id:product.id,productName:product.description,
                productPrice:product.unit_price,productQuantity:product.quantity,
                productCategory:product.category,productTemplate:product.status
              }

              const producttemp = this.state.productlist;
              const isIDsame = (Productkey) => Productkey.id === product.id;
          let index  = producttemp.findIndex(isIDsame)
          if( this.state.productlist.length ===0 ){
              console.log('index is working-------')
            this.state.productlist.push(newitem)
                  this.setState({productlist: this.state.productlist})
                  
                      }else if(index === -1 &&  this.state.productlist.length > 0){
                        
                        this.state.productlist.push(newitem)
                  this.setState({productlist: this.state.productlist})
                      }else if(index !== -1){
                        M.toast({html: ' Product(s)  already in the table', classes:"white-text red rounded"})
                      }

                 // console.log("Product List",this.state.productlist)
                  

        
                  this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
              }


              // console.log(v.selectedproductId)

             }}/>
            {/* {console.log(this.state.ordersSelectedInModal)} */}
            {/* OrderModel code ends here */}


<div className="row">
          <div className="col s3">
          <h4 className=" orange-text">Create Shipment</h4>
          </div>
       
</div>
<Row>
        <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" >
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">From</h4>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='from_country' name="from_country"  value={this.state.from_country} className="browser-default" onChange={this.handleCountryNameChange}>
                          <option value=""  selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                    {/*<Row>
                    <Col xs={3}>
                    <select id="state_name_selet" name="stateName" className="browser-default" onChange={this.handleStateChange}>
                                <option value="DEFAULT" disabled>Choose state </option>
                                {selectStateOptions}
                              </select>
                              </Col>
                    </Row>*/}
                   <Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_company" id="from_company" type="text" 
            value={this.state.from_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_contact" id="from_contact" type="text" 
            value={this.state.from_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address1" id="from_address1" type="text" 
            value={this.state.from_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_address2" id="from_address2" type="text" 
            value={this.state.from_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_zip" id="from_zip" type="number" 
            value={this.state.from_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_city" id="from_city" type="text" 
            value={this.state.from_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="from_state" id="from_state" type="text" 
            value={this.state.from_state} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          <select id="from_state" name="from_state" className="browser-default" onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptions}
                              </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="from_phoneno" id="from_phoneno" type="number" 
            value={this.state.from_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

<div className="row">
      <div className="col s6">
*Required Fields
        </div>
        </div>
                    {/* right side columns ends here */}
<div className="row center">
      <div className="col s6">
<Button className="ml-5 " id="saveOrderBtn" onClick={this.clearorigin} variant="primary"  >Reset origin</Button>
        </div>
        </div>
                     </Col> 
      <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" >
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">To</h4>
                    </Col>
                    <Col xs={9}>
<div className="row" style= {{ height: "50px" }} >
        <div className="input-field col s6">
          <input name="customer_id" id="customer_id" type="text" className="autocomplete"
            value={this.state.customer_id} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}

          />
          <label htmlFor="customer_id">Enter Customer Id</label>
            <span id="errCustomerNotAuthorized" class="helper-text center yellow red-text " 
                  data-error="wrong" data-success="" 
                  style= {{ fontSize: "small", display: "none", fontWeight: "500" }} ></span>
          <span className="helper-text" data-error="wrong" data-success="">Customer Ezzid ex: Ezz000000</span>
        </div>
      
      </div>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='to_country' name="to_country" value={this.state.to_country} className="browser-default" onChange={this.handleCountryNameChangeTo}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >Company:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_company" id="to_company" type="text" 
            value={this.state.to_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Contact Name:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_contact" id="to_contact" type="text" 
            value={this.state.to_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address1" id="to_address1" type="text" 
            value={this.state.to_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_address2" id="to_address2" type="text" 
            value={this.state.to_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*ZIP:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_zip" id="to_zip" type="number" 
            value={this.state.to_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_city" id="to_city" type="text" 
            value={this.state.to_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*State:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="to_state" id="to_state" type="text" 
            value={this.state.to_state} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
            <select id="to_state" name="to_state"  className="browser-default"
           onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptionsTo}
                              </select>
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="to_phoneno" id="to_phoneno" type="number" 
            value={this.state.to_phoneno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>


                  <div className="row">
      <div className="col s6">
*Required Fields
        </div>
        </div>
                    {/* right side columns ends here */}
                          <div className="row center">
      <div className="col s6">
<Button className="ml-5 " id="saveOrderBtn" onClick={this.cleardestination} variant="primary"  >Reset Destination</Button>
        </div>
        </div>

                     </Col>   
                </Row> 
                
                <Row>
                  <Col xs={12}>
                  <div className="row center">
      <div className="col s6 center">
        <Button className="ml-5 white-text green" id="btnrefresh" variant="primary" onClick={this.refreshpage} >Refresh</Button>                        
<Button className="ml-5 white-text orange" id="continue" variant="success" onClick={this.showcard} >Continue</Button>                        
</div>
        </div>
                  </Col>
                  </Row>

                   <Row>
            <Col>
            
            <Card className="" id="shipdetail" style={{width:"100%"}} >
        <Card.Body>
            <Card.Text>
               <Row style={{width:"100%"}}>
                    <Col xs={12}>
                <h4 className="blue-text">Package and Shipment</h4>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >*Ship Date:</h6> 
                        </Col>
                        <Col xs={9}><div className="input-field col s6">
        
          <input name="ship_date" id="ship_date" type="text"  className="datepicker" 
          value={this.state.ship_date} onChange={this.handleCustomerIDChange}
         
          />
          
          <label htmlFor="ship_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i></label>
          <span className="helper-text" data-error="wrong" data-success="">Select Ship Date </span>
        </div>
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >*Number of Packages:</h6> 
                        </Col>
                        <Col xs={3}><input name="packageno" id="packageno" type="number" 
            value={this.state.packageno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row style={{visibility:"hidden"}}>
                    <Col xs={3}>
                        <h6 >*Are packages identical:</h6> 
                        </Col>
                        <Col xs={9}>  
           
             
            <label>
                <input name="package_identical" value="yes" type="radio"  
                  checked={this.state.package_identical ==="yes"} onChange={this.handleFormChange}
                />
                <span>Yes</span>
            </label>
           
            <label>
                <input name="package_identical" value="no" type="radio" 
                  checked={this.state.package_identical ==="no"} onChange={this.handleFormChange} />
                <span>No</span>
            </label>
         
            
       
                    </Col>
                    </Row>
                    <Row id="pnlitemdetail" style={{width:"100%"}}>
 {/* right side column starts here */}
                    <Col xs={12}md={12} lg={12}>


                           

                        <Row>
                        <Col>
                        <h5>Package Details</h5>
                        </Col>
                        <Col>
                        <Nav className="right">
            <Nav.Link className="red-text modal-trigger "  href="#addProductModal">
                Add Package<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
                 
                
        </Nav>
        
     
                        </Col>
                    </Row>
                    {/* product card to be shown here */}
                    {/* <div className="productlistDiv ">{this.state.productlisthtml}</div> */}
                    <div className="productlistDiv "><ProductItemsList products={this.state.productlist} rowclick={this.handletableClick}/></div>
                    
                    </Col>
  </Row>

  <Row id="pnlweightunit" style={{width:"100%"}}>
                    <Col xs={3}>
                        <h6 >*Weight Unit:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="weight_unit" id="weight_unit" type="number" 
            value={this.state.weight_unit} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row id="pnldeclaredvalue" style={{width:"100%"}}>
                    <Col xs={3}>
                        <h6 >Declared Value unit:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="declared_value_unit" id="declared_value_unit" type="number" 
            value={this.state.declared_value_unit} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>

                     <Row>
                    <Col xs={3}>
                        <h6 >*Total Weight(In LBS):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="total_weight" id="total_weight" type="number" 
            value={this.state.total_weight} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Declared Value(In USD):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="declared_value" id="declared_value" type="number" 
            value={this.state.declared_value} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*Service Type:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="service_type" id="service_type" type="text" 
            value={this.state.service_type} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          <select id='service_type' name="service_type" className="browser-default" onChange={this.handleFormChange}>
                          <option value="Priority"  selected={this.state.service_type ==="Priority"}>Priority</option>
                          <option value="Economy" selected={this.state.service_type ==="Economy"}>Economy</option>
                        </select>
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Package Type:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="package_type" id="package_type" type="text" 
            value={this.state.package_type} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
           <select id='package_type' name="package_type" className="browser-default" onChange={this.handleFormChange}>
                          <option value="Your Packaging"  selected={this.state.package_type ==="Your Packaging"}>Your Packaging</option>
                          {/*<option value="Fedex Packaging" selected={this.state.package_type ==="Fedex Packaging"}>Fedex Packaging</option>*/}
                        </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Package Content:</h6> 
                        </Col>
                        <Col xs={9}>
 
    
           
             
            <label>
                <input name="package_content" value="documents" type="radio"  
                  checked={this.state.package_content ==="documents"} onChange={this.handleFormChange}
                />
                <span>*Documents</span>
            </label>
           
            <label>
                <input name="package_content" value="commodities" type="radio" 
                  checked={this.state.package_content ==="commodities"} onChange={this.handleFormChange} />
                <span>Products/Commodities</span>
            </label>
         
            
       
      
                    </Col>
                    </Row>
                    
                    <Row>
                    <Col xs={3}>
                        <h6 >*Shipment Purpose:</h6> 
                        </Col>
                        <Col xs={9}>
{/*<input name="purpose" id="purpose" type="text" 
            value={this.state.purpose} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />*/}
          <select id='purpose' name="purpose" className="browser-default" onChange={this.handleFormChange}>
                          <option value="Commercial"  selected={this.state.purpose ==="Commercial"}>Commercial</option>
                          <option value="Private" selected={this.state.purpose ==="Private"}>Private</option>
                        </select>
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Total Customs Value(In USD):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="total_custom" id="total_custom" type="number" 
            value={this.state.total_custom} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

<Row>
        <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" id="brokerdetail" style={{width:"100%"}}>
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={12}>
                <h4 className="blue-text">Broker Select Information</h4>
                    </Col>
                    </Row>
                                       
                                       <Row>
                    <Col xs={3}>
                        <h6 >*Broker account no:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_accno" id="broker_accno" type="text" 
            value={this.state.broker_accno} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                                       <Row>
                    <Col xs={3}>
                        <h6 >*Broker company name:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_comp_name" id="broker_comp_name" type="text" 
            value={this.state.broker_comp_name} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                                       <Row>
                    <Col xs={3}>
                        <h6 >*Broker contact name:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_contact" id="broker_contact" type="text" 
            value={this.state.broker_contact} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    


                    <Row>
                    <Col xs={3}>
                        <h6 >*Address 1:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_address1" id="broker_address1" type="text" 
            value={this.state.broker_address1} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >Address 2:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_address2" id="broker_address2" type="text" 
            value={this.state.broker_address2} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                
                <Row>
                    <Col xs={3}>
                        <h6 >Postal Code:</h6> 
                        </Col>
                        <Col xs={9}><input name="broker_company" id="broker_company" type="text" 
            value={this.state.broker_company} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     
                     <Row>
                    <Col xs={3}>
                        <h6 >*City:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_city" id="broker_city" type="text" 
            value={this.state.broker_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Country/Territory:</h6> 
                        </Col>
                        <Col xs={9}><select id='broker_country' name="broker_country" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Phone no:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_phone" id="broker_phone" type="number" 
            value={this.state.broker_phone} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Broker tax id:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="broker_taxid" id="broker_taxid" type="text" 
            value={this.state.broker_taxid} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>


                     </Col> 
      <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" id="commoditydetail" style={{width:"100%"}}>
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={12}>
                <h4 className="blue-text">Commodity Information</h4>
                    </Col>
                    </Row>
               
                   <Row>
                    <Col xs={3}>
                        <h6 >*Commodity description:</h6> 
                        </Col>
                        <Col xs={9}><input name="commod_desc" id="commod_desc" type="text" 
            value={this.state.commod_desc} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Unit of measure Per Piece:</h6> 
                        </Col>
                        <Col xs={9}><input name="commod_unit" id="commod_unit" type="text" 
            value={this.state.commod_unit} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >Quantity:</h6> 
                        </Col>
                        <Col xs={9}>
<input name="commod_qty" id="commod_qty" type="number" 
            value={this.state.commod_qty} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Weight (In LBS):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="commod_weight" id="commod_weight" type="number" 
            value={this.state.commod_weight} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                     <Row>
                    <Col xs={3}>
                        <h6 >*Declared value (In USD):</h6> 
                        </Col>
                        <Col xs={9}>
<input name="commod_value" id="commod_value" type="number" 
            value={this.state.commod_value} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >*Country of manufacture:</h6> 
                        </Col>
                        <Col xs={9}><select id='commod_country' name="commod_country" className="browser-default" onChange={this.handleFormChange}>
                          <option value="" disabled selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>
        </Col>
  </Row>
        
                          

  <Row>
            <Col>
              <div className="center" id="btndetail" style={{width:"50%", textAlign: "center"}}>
                <ButtonGroup aria-label="create order buttons" className="">
                  <Button className="ml-5 white-text" id="printLabel" variant="primary"  onClick={this.createOrderNormal}>Save</Button>                        
                  {/*<Button className="ml-5 white-text" id="printLabel" variant="warning"  onClick={this.createOrderNormal}>Edit Shipment</Button>                        */}
                  <Button className="ml-5 white-text" id="printLabel" variant="success">Cancel</Button>                        
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>


    )
}

}

export default CreateOrder;
