import React from "react"
import { Redirect } from "react-router-dom"
import Axios from "axios"

// import { Button,  } from 'react-materialize';
import {  Container, Row, Col, Card, Image } from 'react-bootstrap';
import './css/login.css';
import { base_url } from "../globalConstants";
import { subject } from "../service";

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        let loggedIn = false

        const token = localStorage.getItem("token")
        if (token) loggedIn = true

        this.state = {
            username: "",
            password: "",
            loggedIn,
            error: "",
            userType: "",
            userId: ""
        }
        this.onChange = this.onChange.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
    }

    onChange(ev) {
        this.setState({
            [ev.target.name]: ev.target.value
        })
    }

    async formSubmit(ev) {
        ev.preventDefault()
        // const { username, password } = this.state
    
        var bodyFormData = new FormData()
        bodyFormData.append('email', this.state.username)
        bodyFormData.append('password', this.state.password) 
        const config={
            headers: {'Content-Type': 'multipart/form-data' }
        }
        // const headers= {'Content-Type': 'multipart/form-data' }
    
        try {
      
            const token = await Axios.post(base_url+"obtain_token/",bodyFormData, 
            config
            );
            console.log(token.data)
                        
            localStorage.setItem("token", token.data.token)
            sessionStorage.setItem("token", token.data.token)
            sessionStorage.setItem("user_id", token.data.user.Id)
            sessionStorage.setItem("user_type",token.data.user.Type)
            sessionStorage.setItem("role_det",token.data.roledet.RoleForms)
            sessionStorage.setItem("role_name",token.data.roledet.RoleName)
            sessionStorage.setItem("role_id",token.data.roledet.RoleId)
            var roleinfo = token.data.roledet.RoleForms
            var temprole = roleinfo.split(",")
            this.state = {
                arrrole: temprole
                        }
            sessionStorage.setItem("order_create","0");
            sessionStorage.setItem("order_view","0")
            sessionStorage.setItem("order_chargelist","0")
            sessionStorage.setItem("order_boxlist","0")
            sessionStorage.setItem("order_custsett","0")

            sessionStorage.setItem("order_shipped","0");
            sessionStorage.setItem("order_refrral","0")
            sessionStorage.setItem("order_recon","0")
            sessionStorage.setItem("coupon_create","0")
            sessionStorage.setItem("coupon_view","0")

                        sessionStorage.setItem("cash_create","0");
            sessionStorage.setItem("cash_view","0")
            sessionStorage.setItem("role_create","0")
            sessionStorage.setItem("role_view","0")
            sessionStorage.setItem("report","0")

                        sessionStorage.setItem("pendorder","0");
            sessionStorage.setItem("order_curcustlist","0")
            sessionStorage.setItem("cash_invreport","0")
            
                                    sessionStorage.setItem("fedex_shiprates","0");
            sessionStorage.setItem("fedex_createship","0")
            sessionStorage.setItem("fedex_viewship","0")
            sessionStorage.setItem("dashboard_create","0");

            if (temprole != undefined || temprole != null)
                    {
                    //alert(this.state.arrform);
                    temprole.map((itemdb, index) => {
                        //alert(item);
                        if (itemdb === "1")
                        {
                        sessionStorage.setItem("order_create","1")
                        }
                        else if (itemdb === "2")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_view","1")
                        }
                        else if (itemdb === "3")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_chargelist","1")
                        }
                        else if (itemdb === "4")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_boxlist","1")
                        }
                        else if (itemdb === "5")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_custsett","1")
                        }
                        else if (itemdb === "6")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_shipped","1")
                        }
                        else if (itemdb === "7")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_refrral","1")
                        }
                        else if (itemdb === "8")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_recon","1")
                        }
                        else if (itemdb === "9")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("coupon_create","1")
                        }
                        else if (itemdb === "10")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("coupon_view","1")
                        }
                        else if (itemdb === "11")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("cash_create","1")
                        }
                        else if (itemdb === "12")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("cash_view","1")
                        }
                        else if (itemdb === "13")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("role_create","1")
                        }
                        else if (itemdb === "14")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("role_view","1")
                        }
                        
                        else if (itemdb === "15")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("report","1")
                        }
                        else if (itemdb === "16")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("pendorder","1")
                        }
                        else if (itemdb === "17")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("order_curcustlist","1")
                        }
                        else if (itemdb === "18")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("cash_invreport","1")
                        }
                        else if (itemdb === "19")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("fedex_shiprates","1")
                        }
                        else if (itemdb === "20")
                            {
                            //this.state = {order_view:false}
                            sessionStorage.setItem("fedex_createship","1")
                        }
                        else if (itemdb === "21")
                        {
                        //this.state = {order_view:false}
                        sessionStorage.setItem("fedex_viewship","1")
                        }    
                        else if (itemdb === "22")
                        {
                            sessionStorage.setItem("dashboard_create","1");
                        }
            });
                }
        
            this.setState({
                loggedIn: true,
                userType: token.data.user.Type,
                userId: token.data.user.Id
            })  
            this.sendMessage(token.data.token)
        } catch (err) {
            this.setState({
                // error: err.message
                error: "Invalid Username/ Password"
            })
        }
    }

    sendMessage = (tempToken) => {
        subject.next(tempToken)
    }

    render() {
        // @TODO add fucntionality of routing respectively to admin or operator
        //Hey...fd
        //  let panel ="Operator"
         let panel = this.state.userType
        if (this.state.loggedIn === true && panel=== "Operator" ) {

            // return <Redirect to = "/Operator" />
            return <Redirect to={{
                pathname: "/Operator",
                state: { operator_id: this.state.userId }
              }} />
        }
        if (this.state.loggedIn === true && panel=== "Admin" ) {

            return <Redirect to = "/Admin" />
        }
        return ( < Container className = "" > 
        <div className="grandParentContaniner">

            <div className="parentContainer ">

            <Row>
            <Col className = "card-center" style={{ textAlign: "center", marginTop: "10px" }}>
            {/* <Image  src = "assets/images/logo.png"className = "align-row imgsize" /> */}
            <Image  src = "assets/images/Ezzyship.png" className = "" />
            </Col>
            
        </Row>
        <Row>
        <Col>
            <h5 className="text-purple align-row">Please Login to your Ezzyship Account</h5>
            </Col>

        </Row>
   <Row>
       <Col>
       
       <Card className = "" 
            style = {
                { width: '19rem' }

            } >
            
          
            <Card.Body >  
            <form onSubmit = { this.formSubmit } > < Row > < Col > 
            {/* <p>UserName</p>  */}
            <input type = "email" placeholder = "Username" className = "input-text"
            value = { this.state.username } onChange = { this.onChange } name = "username" id = 'username' /> 
            </Col>  </Row > < Row > < Col > 
            {/* <p>Password</p>    */}
            <input id = "password"   type = "password" className = "input-text" placeholder = "Password" value = { this.state.password }
            onChange = { this.onChange } name = "password" />
            </Col> 
            </Row> <Row>
            <Col >
            <input id = "submit" className = "btn  submit btn-md btn-block" type = "submit" block=
            "true" value="Login" />
            </Col> </Row>




            <p className="red-text">{ this.state.error }</p> </form> <p className="center">Copyright © EzzyShip { new Date().getFullYear() }</p>  </Card.Body > </Card>

       
       </Col>
   </Row>
        





            </div>

        </div>
       


            </Container>



        )
    }
}