import React, {Component,} from 'react';
import {Redirect, useHistory } from 'react-router-dom';
import {Card,Button,ButtonGroup,Nav,Image,Row,Col,Container,Spinner} from 'react-bootstrap';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard_Pack_Rates';
import OrderModal from './OrderSelectionModal';
import EditProduct from './EditProductItem_Pack_Rates';
import "./shippingrates.css";
import { base_url } from '../../globalConstants';
import { subject } from '../../service'


class CustomerDetailCard extends Component{

    constructor(props){
        super(props);
        
  this.state = ({title:"***",name:"********",
              email:"**********",address:"********",phone:"********",country:"********"
              ,country_list: this.props.countryList,
              statedata: this.props.statedata, stateCode_list: [], stateName_list:[],
      statelist: [],
      countrynamelist: this.props.country_name_list, countrycodelist: this.props.country_code_list
              })

    }

    componentDidUpdate(prevProps) {
      if (this.props.info.ezz_id !== prevProps.info.ezz_id || this.props.info.email !== prevProps.info.email ) {
        
         this.setState({title:this.props.info.title,name:this.props.info.name,
            email:this.props.info.email,address: this.props.info.address,phone:this.props.info.phone_number,country:this.props.info.country_name
        
        })

      }



    }

    render(){ 
        return(
            <Row>
            <Col>
            
            
            </Col>
        </Row>

        )
    }
}



class CreateOrder extends Component{
    constructor(props){
        super(props);
     
        var today = new Date();
       

         
          var month = '' + (today.getMonth() + 1)
            var   day = '' + today.getDate()
           var    year = today.getFullYear()
        
           if (month.length < 2) {
             month = '0' + month;
           }
            
           if (day.length < 2) {
             day = '0' + day;
           }
           
        
           let date = [year, month, day].join('-');


        this.state = ({
          current_date: date,
            AllSelected:[],
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            prioritycharge:"",product_name1:"",product_carriage:"",product_price:0,product_quantity:0 ,product_sku:"",product_selected:"",product_export:false,
            search_name_select:"1",
            product_category_options :[],
            product_template_options :[],
            product_edit_category :[],
            product_edit_template :[],
            product_category:"",ordernumber:"",product_template:"",
         customer_id:"",order_date:date,
        received_date:date,tracking_number:"",shiper_order_number:"",
        weight:0,height:0,fedex_charge:0,width:0,length:0,actual_charge:0,homedelivery_charge:0,promo_type:"Not",promo_value:0,
        Weight_type:null,
          //orderStatusSelected:"YTA",
          orderStatusSelected:"IM",
          paymentTypeSelected:"card",showSpinner:false,
          customer_selected_data:{},
          pendingOrders:[],ordersSelectedInModal:[],
          createNewOrder:true,holdmergedorderstatus:"RTM",
          ordernumbermerge:"",
          error:"",
          rowNumber:"",
          selectItemDetails:{},
      
          generatedOrderId: "",
          isOrderUpdate: false,
          customer_email: "",
          serachInput: '',
          searchResult:'',
          country_list: this.props.countryList,
          statedata: this.props.statedata, stateCode_list: [], stateName_list:[],
      statelist: [],
      countrynamelist: this.props.country_name_list, countrycodelist: this.props.country_code_list,
         from_city:'',
from_state:'',
from_zip:'',
from_country:'',

to_city:'',
to_state:'',
to_zip:'',
to_country:'',

packaging:'Your Packaging',
          priority:'1',
          metric:'LB'
          })

    }



//     openOrderModel = ()=>{
//       this.refs.ordermodel.openModal();
//     }


//     showSelectedModalOrders=(orderselected)=>{
//       // console.log(`order selected ${orderselected}`)
//       this.setState({ordersSelectedInModal:orderselected})
//     }


//     getCategories = async (countryid)=>{

//       let token =localStorage.getItem("token")
//       let header = {'Content-Type': 'multipart/form-data',
//       'Authorization':'Token '+token,

//       }

//       var url= base_url+`categories/?country=${countryid}`
//       var categories = await Axios.get(url,header)
//       // console.log(categories.data)
      
     

//       await this.setState({product_category_options:categories.data})
//       await this.setState({product_edit_category:categories.data})

//     }

//     getTemplate = async ()=>{

//       let token =localStorage.getItem("token")
//       let header = {'Content-Type': 'multipart/form-data',
//       'Authorization':'Token '+token,

//       }

//       var url= base_url+`templates/`
//       var templates = await Axios.get(url,header)
//       // console.log(categories.data)
      
     

//       await this.setState({product_template_options:templates.data})
//       await this.setState({product_edit_template:templates.data})

//     }


// getPendingOrder= async (customerid,orderstatus)=>{
// // try{
// //   let token =localStorage.getItem("token")
// //   let header = {'Content-Type': 'multipart/form-data',
// //   'Authorization':'Token '+token,

// //   }

// //   var url= base_url+`products/?ezz_id=${customerid}&status=${orderstatus}`
// //   let orders = await Axios.get(url,header)
// //   // console.log(orders.data)
// //   await this.setState({pendingOrders: this.state.pendingOrders.concat(orders.data)})
// //   // M.toast({html: 'Pending Ordersa fetched',classes:"white-text orange rounded"});
// //   this.setState({error:""})
// // }catch(error){
// //   console.log(error)
// //   M.toast({html: 'Pending Ordersa cannot be fetched',classes:"white-text red rounded"});
// //   this.setState({error:<Error/>})
// // }

// }

handletableClick=(clickedrow,exportstatus)=>{
  this.state.productlist[clickedrow].productExport = exportstatus
 // M.toast({html: 'Clicked row '+clickedrow,classes:"white-text red rounded"});
 let productdetail = this.state.productlist[clickedrow]
 let countryoptions = this.state.product_category_options
 let templateoptions = this.state.product_template_options
 //console.log("handle cat",countryoptions)
 //console.log("handle cat",this.state.product_edit_category)
 this.setState({rowNumber:clickedrow})
 this.setState({selectItemDetails:productdetail})
 
 if (productdetail.productStatus!= "Active")
   this.refs.editproduct.openModal(clickedrow,productdetail,countryoptions,templateoptions,1);
  else
   this.refs.editproduct.openModal(clickedrow,productdetail,countryoptions,templateoptions,2);
}

updateProduct=(rownumber,item)=>{
  console.log("updatig list",rownumber,item)
let productlist = this.state.productlist
productlist.splice(rownumber, 1, item)
this.setState({productlist:productlist})
// this.refs.editproduct.closeModal();
// M.toast({html: 'item updated at row ' +rownumber,classes:"white-text blue rounded"});

}

// // deleteProduct = () => {
// //   console.log('YEAH WE WILL WORK ON THIS');
  
// // }
// deleteProduct= async (rownumber,item_id)=>{
//   // let tempItemId = item_id.toString()
//   let updated_productlist = this.state.productlist
//   // console.log("DELETING ITEM HERE ",rownumber,item_id)

//   // if(!tempItemId.includes('item_')){
//   //   await Axios({
//   //     method: 'DELETE',
//   //     url: base_url + `products/${item_id}/`
//   //   }).then(function (response) {
//   //     // console.log(`DELETED${tempProductId}`, response);
//   //     M.toast({ html: `Product deleted successfully`, classes: "white-text blue rounded" })
//   //   }).catch(function (response) {
//   //     // console.log("NOT DELETED ERROR",response);
//   //     M.toast({ html: `Product deletion failed`, classes: "white-text red rounded" })
//   //   })
//   // }  

//   updated_productlist.splice(rownumber, 1)
//   await this.setState({productlist:updated_productlist})
//   console.log(`ITEM ${item_id} DELETED AT ${rownumber}`)
//   // this.refs.editproduct.closeModal();
//   // M.toast({html: 'item deleted ' +rownumber,classes:"white-text blue rounded"});

// }


handleCountryNameChange = async (e) => {
    // this.setState({countryname: e.target.value})
    // @TODO: on changing countryname state list should also Change
const target = e.target
      const name = target.name
      console.log(e.target.name)
      this.setState({
            [name]: target.value
          });
    //this.setState({ countryName: e.target.value })
    console.log("country selected:- " + e.target.value)
    // this.setState({countryname:e.target.value})


    // let selected_countryId = this.state.countrycodelist[this.state.countrynamelist.indexOf(e.target.value)]
    // console.log(selected_countryId)

    const states = this.state.statedata[this.state.countrycodelist.indexOf(e.target.value)].slice()
    console.log(states)

    //this.setState({ countryId: selected_countryId, statelist: states })

    this.setState({ countryId: e.target.value, statelist: states })
    // this.setState({statelist:states})
    console.log(this.state.countryId)
    console.log("state list")
    console.log(this.state.statelist)
   

  }

    handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
      //var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      var patt = /^[0-9]/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

    // }      
    // handleCustomerInfo = async e => {  
      // if(e.key !== 'Enter'){
      //   return
      // }

      // try{
      
      //   let token =localStorage.getItem("token")
      //   let header = {'Content-Type': 'multipart/form-data',
      //   'Authorization':'Token '+token,

      //   }

        // Fetching user list on input change
        // var url= base_url+`customers/?ezz_id__icontains=${target.value}`
        // var url= base_url+`customers/?ezz_id=&ezz_id__icontains=${target.value}&email__icontains=${target.value}&phone_number=`

      //   var url
      //   var searchType

      //   if(this.state.searchResult){
      //     url= base_url+`customers/?ezz_id=&ezz_id__icontains=${this.state.searchInput}&email__icontains=&phone_number=`
      //     searchType = "ezzid"
      //   }        
      //   else{
      //     url= base_url+`customers/?ezz_id=&ezz_id__icontains=&email__icontains=${this.state.searchInput}&phone_number=`
      //     searchType = "email"
      //   }
        
      //   var customer_data = await Axios.get(url,header)
      //   // console.log(customer_data.data)
      //   let ezzidlist = {}
      //   let emaillist = {}
      //   let customerdataObject ={}
        
      //   document.getElementById("errCustomerNotAuthorized").style.display = "none"
      //   // DEFAULT WORKING EZZID
      //   // for (let customer of customer_data.data) {
      //   //   ezzidlist[customer.ezz_id] = customer.email;
      //   //   customerdataObject[customer.ezz_id] = customer;
      //   // }

      //   if (searchType === "ezzid") {
      //     for (let customer of customer_data.data.results) {
      //       ezzidlist[customer.ezz_id] = customer.email;
      //       customerdataObject[customer.ezz_id] = customer;
      //     }
      //   }
      //   // console.log();
      //   if (searchType === "email") {
      //     for (let customer of customer_data.data.results) {
      //       ezzidlist[customer.email] = customer.ezz_id;
      //       var tempEzId = ezzidlist[customer.email]
      //       customerdataObject[tempEzId] = customer;

      //     }
      //   }     

      //   // console.log(ezzidlist)
      //   // console.log(customerdataObject)
      //   this.setState({customer_data:customerdataObject})
      //   // Autoselect starts here
      //   var context = this;
      //   // /await context.setState({pendingOrders:[]})
      //   const elems_auto_customer = document.getElementById('customer_id');
      //   var options={
      //     data:ezzidlist,
      //     limit:20,
      //     onAutocomplete : async function(select_customer) {
      //       // context.setState({customer_id:select_customer})                // DEFAULT WORKING EZZID

      //       // context.setState({customer_email: select_customer})

      //       // console.log("select_customer",select_customer);

      //       var tempEzzId 
      //       if (searchType === "ezzid"){
      //         tempEzzId = select_customer
      //         context.setState({customer_id:select_customer})                
      //       }
      //       if (searchType === "email"){
      //         tempEzzId = ezzidlist[select_customer]
      //         context.setState({customer_id:tempEzzId})                
      //       }            
        
      //       // DEFAULT WORKING EZZID
      //       // context.setState({customer_selected_data:customerdataObject[select_customer]})
      //       // await context.setState({pendingOrders:[]})
           
      //       // context.getCategories(customerdataObject[select_customer].country)
      //       //       await context.getPendingOrder(select_customer,"IM")
      //       //        await context.getPendingOrder(select_customer,"YTA")





      //       context.setState({customer_selected_data:customerdataObject[tempEzzId]})
      //       await context.setState({pendingOrders:[]})
           
      //      context.getTemplate()
      //       context.getCategories(customerdataObject[tempEzzId].country)
      //             await context.getPendingOrder(tempEzzId,"IM")
      //              await context.getPendingOrder(tempEzzId,"YTA")
           
           







      //       //context.set
         
      //       // @TODO show model only when there is any orders pending 
      //       //like IM or BoX YET to arrive
      //       if (context.state.pendingOrders.length >0){
      //         context.openOrderModel();
      //       }
      //       // console.log("selected cust ID AUTH", customerdataObject[select_customer].card_authorize  )
           
      //       //const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize

      //       // if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )          
      //       //   document.getElementById("errCustomerNotAuthorized").style.display = "inline-grid"
      //       // else  
      //       //   document.getElementById("errCustomerNotAuthorized").style.display = "none"
            
      //       const site_ezzid = sessionStorage.getItem('user_site_ezzid')
      //       if(tempEzzId !== site_ezzid )          
      //         document.getElementById("errCustomerNotAuthorized").style.display = "inline-grid"
      //       else  
      //         document.getElementById("errCustomerNotAuthorized").style.display = "none"
      //     },
        
      //     sortFunction:  function (a, b, inputString) {
      //       return a.indexOf(inputString) - b.indexOf(inputString);
      //     },
      //   }
      //   var instance = M.Autocomplete.init(elems_auto_customer,options)
      //   instance.open()
      // }catch(err){

      //   M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
      //   console.log(err)
      //   this.setState({error:<Error/>})
      // }

    }
//    fillFormAutomatically=(data)=>{
//     //  console.log(`fill auto maticaly ${data}`)
//     this.setState({
//       ordernumber: data.order_id,
//       order_date: data.order_date,
//       received_date: data.received_date,
//       tracking_number: data.inbound_tracking_number,
//       shiper_order_number : data.shipper_number,
//       weight: data.weight,
//       height: data.height,
//       fedex_charge: data.freight_charge,
//       width: data.breadth,
//       length: data.length,
//       actual_charge: data.actual_charge,
//       orderStatusSelected: data.current_status,
//       paymentTypeSelected: data.billing_type,

      
//     });
    
  
//   }

//   getfedexActualRate = async (weight,height,length,breadth)=>{
    


//     try{
//   let token =localStorage.getItem("token")


//   var config = {
//     headers: { 
//       'Content-Type': 'multipart/form-data' , 
//       'Authorization':'Token '+token},
    
//   };
  
// //console.log(this.state.order_date);
//   // Fetching user list on input change
//   //var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}`
//   var url= base_url+`fedex_actual_rates/?weight=${weight}&height=${height}&breadth=${breadth}&length=${length}&ezz_id=${this.state.customer_id}&orderdate=${this.state.order_date}`
//   var response = await Axios.get(url,config)
//   // console.log(response.data)
//   return response.data
// }catch(error){

//   console.log(error.response.data)
  
//    if (error.response.status ===503 ){
//     M.toast({html: ""+error.response.data.error,classes:"white-text red rounded"});
//    }
//    if (error.response.status === 403 ){
//     M.toast({html: ""+error.response.data.Error,classes:"white-text red rounded"});
//    }
   
  
//   // return response.data
//   return error.response.data
// }
// }

// downloadReport = async () => {
//     // var paramTemplate = ""
//   // var paramProduct = ""
//    var paramTemplate = []
//    var paramProduct = []

//   var paramOrderId = this.state.generatedOrderId
//   this.state.productlist.forEach(async(item)=>{
//           // if (item.productStatus != "Active")
//           // {
//           //   if (paramTemplate.length == 0)
//           //         paramTemplate = '"' + item.productStatus + '"'
//           //   else
//           //         paramTemplate = paramTemplate + "," + '"' + item.productStatus + '"'          
//           // }
//           // else
//           // {
//           //   if (paramProduct.length == 0)
//           //     paramProduct =  '"' + item.id  + '"'
//           //   else
//           //     paramProduct = paramProduct + "," + '"' + item.id + '"'           

//           // }

//           if (item.productStatus != "Active")
//           {
//             if (item.productExport === true)
//             {
//               paramTemplate.push(item.productStatus)
//             }
//           }
//           else
//           {
//             if (item.productExport === true)
//             {
//               paramProduct.push(item.id)
//             }
//           }

//       });
//         let report_url = base_url + `get_order_product_csv`
//         // console.log('url',report_url);
//         // console.log('this.state.countryName',this.state.countryName);
//         var FileSaver = require('file-saver');
//         // const newdata={
//         //       "template_name": "[" + paramTemplate + "]",
//         //       "product_id": "[" + paramProduct + "]",
//         //       "order_id": paramOrderId
//         //     }
//         const newdata={
//               "template_name": paramTemplate ,
//               "product_id":  paramProduct,
//               "order_id": paramOrderId
//             }

//         await Axios({
//             url: report_url,
//             method: 'POST',
//             responseType: 'arraybuffer',
           
//             data: newdata
//         })
//         .then((response)=>{
//             {
//                 var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//                 FileSaver.saveAs(blob, `product.xlsx`);
//             }
//             console.log('response',response);
//         }).catch((err)=>{
//             console.log('err',err);
//         })

//     }

 changeTo= (e) =>{

          switch (e.target.name) {
            case "weight":
              this.setState({weight: e.target.value})
              break;
            case "height" :
              this.setState({height: e.target.value})
              break;
            case "width" :
              this.setState({width: e.target.value})
              break;
            case "length" :
              this.setState({length: e.target.value})
              break;
          }
                //this.setState({weight: e.target.value})
                 //// console.log("is change to");
                  console.log(e.target.value);
                  this.handleFormChange(e);
      }

      updateValue= (e) =>{

          switch (e.target.name) {
            case "weight":
              this.setState({weight: e.target.value})
              break;
            case "height" :
              this.setState({height: e.target.value})
              break;
            case "width" :
              this.setState({width: e.target.value})
              break;
            case "length" :
              this.setState({length: e.target.value})
              break;
          }
                   //this.setState({weight: e.target.value})
                  //console.log("is updtevalue");
                  //console.log(e.target.value);
          
      }


    handleFormChange= async (ev) =>{
     
      const target = ev.target
      const name = target.name
      console.log(ev.target.name)
       this.setState({
             [name]: target.value
           });
    
    }
    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

    componentDidMount(){
      
        M.AutoInit();
        document.getElementById("divpackaging").style.display = "none"
        document.getElementById("divitem").style.display = "none"
        document.getElementById("divship").style.display = "none" 
        document.getElementById("divshowrates").style.display = "none" 
        document.getElementById("divapidetail").style.display = "none" 
        document.getElementById("divapidetail-1").style.display = "none" 
        document.getElementById("divapidetail-2").style.display = "none" 
        document.getElementById("divapidetail-3").style.display = "none" 
document.getElementById("divrefresh").style.display = "none" 
        console.log("is thissssssss working");
        

        // var elems_recieved = document.getElementById('received_date');
        var elems = document.querySelectorAll('.modal');
        let model_options = {
          dismissible:false,
        }
        var instances = M.Modal.init(elems, model_options);
        
        var elems_auto_customer = document.querySelectorAll('.autocomplete');

        var options_auto_customer={
      
          data: {}, 
          limit:20,
          onAutocomplete :function(select_customer) {
           
            // context.setState({ order_date: selecteddata});
            //  console.log("slect autocomplete",select_customer); 
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        } 
        var instances_auto = M.Autocomplete.init(elems_auto_customer, options_auto_customer);
      

         
        var context = this;

       var newdate = this.newDate()
      //  console.log('new date',newdate)
        var elems_order = document.getElementById('order_date');
      
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              // console.log(context.formatDate(date))
               let selecteddata = context.formatDate(date)
              // console.log('selectedate',selecteddata)
              context.setState({ order_date: context.formatDate(selecteddata)})
              // console.log("order_date",selecteddata); // Selected date is logged
            },
          }
         var instances = M.Datepicker.init(elems_order, options_order);
          instances.toString();
        // var elems_recieved = document.getElementById('received_date');
        // var options_recieved={
        //     //selectMonths: true, // Enable Month Selection
        //     selectYears: 1, // Creates a dropdown of 10 years to control year
        //     format:'yyyy-mm-dd',
        //     autoClose :true,
        //     defaultDate:newdate,
        //     setDefaultDate :true,
        //     onSelect: function(date) {
        //       let selecteddata = context.formatDate(date)
        //       context.setState({ received_date: selecteddata.toString()});
        //       //  console.log("recived_datae"+ selecteddata); // Selected date is logged
        //     },
        //   }
        // var instances = M.Datepicker.init(elems_recieved, options_recieved);
        //   instances.toString()
    
    
    }


// @TODO: handlechange for create order is pending Please implement it


     addProductTolist = ()=>{
      // console.log(this.state.productname + this.state.productprice +this.state.productquantity)
       var prodname =this.state.product_name1
       var prodquant =this.state.product_quantity
       var prodcat =this.state.product_category
       var templatename = this.state.product_template
      //  console.log("prody")
      //  console.log(prodname + prodquant+prodcat )
     
      //  if((prodname === "" || prodquant === 0 || prodcat ==="") && (templatename.length <= 0)){
      //   M.toast({html: 'Incorrect or Empty details',classes:"white-text red rounded"});
      //  }else
       {
        // var elem = document.getElementById('addTemplateModal')
        // var instance = M.Modal.getInstance(elem);
        // instance.close();

        let totalproducts = this.state.productlist.length + 1
        var templateprice = 0
        var templateqty = 0
        if (templatename.length > 0)
        {
          const found = this.state.product_template_options.find(element => element["name"]==this.state.product_template);
          //console.log('found', found)
          if(found){
              templateprice = found.totalprice
              templateqty = found.totalquantity
            }
          var elem = document.getElementById('addTemplateModal')
          let newitem = {id:'item_'+totalproducts,productName:this.state.product_template,
          productPrice:templateprice ,
          productQuantity:templateqty,
          productCategory:"Template",
          productSKU:"",
          productStatus: this.state.product_template,
          productTemplate:this.state.product_template,
          productExport:false,
          productCarriage:this.state.product_carriage,
          productSelect:this.state.search_name_select
          }
          this.state.productlist.push(newitem)
        }
        else{
          // if (this.state.product_selected !== this.state.product_name1)
          // {
          //   M.toast({ html: 'Product do not exist!Please contact your admin!', classes: "white-text red rounded" });
          //   return
          // }
          var elem = document.getElementById('addProductModal')
          let newitem = {id:'item_'+totalproducts,productName:this.state.product_name1,
          productPrice:this.state.product_price ,
          productQuantity:this.state.product_quantity,
          productCategory:this.state.product_category,
          productSKU:this.state.product_sku,
          productStatus: "Active",
          productTemplate:this.state.product_template,
          productExport:false,
          productCarriage:this.state.product_carriage,
          productSelect:this.state.search_name_select
          }
          this.state.productlist.push(newitem)
        }
        var instance = M.Modal.getInstance(elem);
        instance.close();
        // console.log("Product List",this.state.productlist)
       //this.state.productlist.push(newitem)
        this.setState({productlist: this.state.productlist})
        
      try {
        // this.setState({productlisthtml:<ProductItemsList products={this.state.productlist}/>})
        this.setState({product_name1:"",product_price:0,product_quantity:0,product_sku:"",product_template:"" })
       M.toast({html: 'Added in list successfully',classes:"white-text orange rounded"})
      } catch (error) {
        M.toast({html: 'Couldnt be added',classes:"white-text orange rounded"})
      }
       
    
       }

    }

  
//     cancelOrder = ()=>{
//         // @TODO fucntionality not clear yet
//         // M.toast({html: 'Pending for Implemention',classes:"white-text black rounded"})
//         // return (<Redirect to="/Admin"/>
//         window.location.reload();
//    }


// handleMergeCheckbox= (event)=>{
//   // event.target.checked
//   // console.log(this.state.holdmergedorderstatus)
//   // console.log(event.target.checked)
//   const name = event.target.name
//   if (event.target.checked){
//     this.setState({[name]:"WFC"})
//   }else{
//     this.setState({[name]:"RTM"})
//   }
// }

// mergeOrder=()=>{
  
//   // var elems = document.getElementById('merge_order_model');
//   // var instance = M.Modal.init(elems);
//   // instance.close();
//   this.createOrderMerged();



// }
// mergerOrderCancel = ()=>{



//   // var elems = document.getElementById('merge_order_model');
//   // var instance = M.Modal.init(elems);
//   // instance.close();
//   this.createOrderNormal();







// }


//   createOrderMerged = async () => {
//     let token = localStorage.getItem("token")
//     const header = {
//       'Content-Type': 'application/json',
//       'Authorization': 'Token ' + token,

//     }

//     if (this.state.createNewOrder) {

//       // new order creation
//       console.log("Deleting previous orders if any")
//       this.state.productlist.forEach(async (item) => {
//         console.log("productlist Merged order-----")
//         // console.log(item)
//         try {

//           let urlfordeletion = base_url+`products/${item.id}/`
//           const response = await Axios.post(urlfordeletion,
//             header)
//           // console.log(`deletion response ${response.data}`)
//           console.log("Product delete success")
//           M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
//         } catch (error) {
//           M.toast({ html: 'No old products found', classes: "white-text green rounded" });
//           console.log('error in deletion',error)
//         }

//       });



//       const orderid = this.state.ordernumber
//       const existing_order = this.state.ordernumbermerge
//       // this.setState({ordernumber:orderid})

//       var current_status = this.state.holdmergedorderstatus
//       var Orderformdata = new FormData()
//       console.log("orderid" + orderid)
//       console.log(this.state.fedex_charge)
//       console.log(this.state.actual_charge)
//       Orderformdata.append("order_id", orderid.toString())
//       Orderformdata.append("ezz_id", this.state.customer_id)
//       Orderformdata.append("shipper_number", this.state.shiper_order_number)
//       Orderformdata.append("billing_id", "2121")
//       Orderformdata.append("billing_type", this.state.paymentTypeSelected)
//       Orderformdata.append("order_date", this.state.order_date)
//       Orderformdata.append("received_date", this.state.received_date)
//       Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
//       Orderformdata.append("weight", this.state.weight)
//       Orderformdata.append("length", this.state.length)
//       Orderformdata.append("breadth", this.state.width)
//       Orderformdata.append("height", this.state.height)
//       Orderformdata.append("charge_type", "VAT")
//       Orderformdata.append("freight_charge", this.state.fedex_charge)
//       Orderformdata.append("actual_charge", this.state.actual_charge)
//       Orderformdata.append("current_status", "DISC")
//       Orderformdata.append("previous_status", "DISC")
//       Orderformdata.append("status", "Deactive")
//       Orderformdata.append("flag", "1")

//       // console.log(JSON.stringify(Orderformdata))
//       // console.log(JSON.stringify(this.state))

//       const orderstatus_merged = {
//         "remarks": "discarded after merging with order" + existing_order,
//         "order_id": orderid,
//         "status_id": "DISC"
//       }

//       const orderstatus_mergedwith = {
//         "remarks": "",
//         "order_id": existing_order,
//         "status_id": current_status
//       }

//       // @Todo: APi to be called to save data for state

//       try {

//         const response = await Axios.post("http://localhost:8000/api/v1/ezzytrace/boxes_received/", Orderformdata,
//           header)
//         const response_newdiscrded = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_merged,
//           header)
//         const response_existingupdated = await Axios.post("http://localhost:8000/api/v1/ezzytrace/order_statuses/", orderstatus_mergedwith,
//           header)

//         var existingOrderUpdateForm = new FormData()
//         existingOrderUpdateForm.append("current_status", current_status)
//         let urlforexistingOrderStatusUpdate = base_url+`boxes_received/${existing_order}/`
//         await Axios.patch(urlforexistingOrderStatusUpdate, existingOrderUpdateForm, header)


//         this.state.productlist.forEach(async (item) => {
//           // console.log("productitemmerged")
//           // console.log(item)
//           try {
//             var productformdata = new FormData()
//             productformdata.append("order_id", existing_order)
//             productformdata.append("description", item.productName)
//             productformdata.append("quantity", item.productQuantity)
//             productformdata.append("unit_price", item.productPrice)
//             productformdata.append("inspection_status", item.productSKU)
//             //productformdata.append("status", "Active")
//             productformdata.append("status", item.productTemplate)
//             productformdata.append("category", item.productCategory)

//             const response_products = await Axios.post(base_url+"products/", productformdata,
//               header)

//             console.log("Product added Successfully")
//             this.setState({ error: <Success message=" Order Status Updated" /> })
//             M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
//           } catch (error) {
//             M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
//             console.log(error)
//           }

//         });


// this.setState({error:<Success message=" New order for product items created"/>})
//  M.toast({html: 'Order created successfully',classes:"white-text orange rounded"})
// this.setState({error:""})    
// }catch(err){
  
//     M.toast({html: 'Please Try Again!',classes:"white-text red rounded"});
    
//     console.log(err)
//     this.setState({error:<Error/>})
//         }




// }else{


//   try{
  
//     var orderupdateForm = new FormData()
//     orderupdateForm.append("shipper_number",this.state.shiper_order_number)
//     // orderupdateForm.append("billing_id","2121")
//     orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
//     orderupdateForm.append("order_date",this.state.order_date)
//     orderupdateForm.append("received_date",this.state.received_date)
//     orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
//     orderupdateForm.append("weight",this.state.weight)
//     orderupdateForm.append("length",this.state.length)
//     orderupdateForm.append("breadth",this.state.width)
//     orderupdateForm.append("height",this.state.height)
//     // orderupdateForm.append("charge_type","VAT")
//     orderupdateForm.append("freight_charge",this.state.fedex_charge)
//     orderupdateForm.append("actual_charge",this.state.actual_charge)
//     orderupdateForm.append("current_status",this.state.orderStatusSelected)
//     let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
//     const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm,
//     header)

//     console.log(`order status update succesfully ${response.data}`)
//     this.setState({error:<Success message=" Order Status Updated"/>})
//     M.toast({html: 'Updated SUccessfull',classes:"white-text orange rounded"});
//   }catch(error){
//      M.toast({html: 'Updation Failed',classes:"white-text red rounded"});
//       console.log(error)
//       this.setState({error:<Error/>})
//   }

// }
// }





  // createOrderNormal = async () => {

    
  //   // console.log('this.state.productlist', this.state.productlist);

  //   // const tempProductList = this.state.productlist
    
  //   const found = this.state.productlist.find(element => element["productPrice"]==0);

  //   console.log('found', found)

  //   // if((this.state.width <= 0 || this.state.height <=0 || this.state.weight <= 0 || this.state.length <=0 || this.state.actual_charge <0)
  //   //   && this.state.orderStatusSelected !== 'YTA' ){
  //   //   M.toast({html: "Dimension values can not be 0/negative.",classes:"white-text red rounded"});
  //   //   return;
  //   // }

  //   // if(found && this.state.orderStatusSelected === 'RTM' ){
  //   //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
  //   //   return
  //   // }
    
  //   this.setState({isOrderUpdate: true})
  //   let token = localStorage.getItem("token")
  //   const header = {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Token ' + token,

  //   }

  //   console.log("create new order" + this.state.createNewOrder)

  //   if (this.state.createNewOrder) {

  //     // new order creation
  //     console.log("Deleting previous orders if any")
  //     this.state.productlist.forEach(async (item) => {
  //       console.log("productlist -----", item.id)
  //       console.log(item)
  //       try {

  //         let urlfordeletion = base_url+`products/${item.id}/`
  //         const response = await Axios.delete(urlfordeletion,
  //           header)
  //         console.log(`deletion response ${response.data}`)
  //         console.log("Product delete success")
  //         M.toast({ html: 'Product deletion Successful', classes: "white-text red rounded" });
  //       } catch (error) {
  //         M.toast({ html: 'No old products found', classes: "white-text green rounded" });
  //         console.log(error)
  //       }

  //     });



  //     // const orderid = Math.floor(1000 + Math.random() * 9000)
  //     // this.setState({ordernumber:orderid})







  //     var Orderformdata = new FormData()
  //     // console.log("orderid"+orderid)
  //     console.log(this.state.fedex_charge)
  //     console.log(this.state.actual_charge)
  //     // Orderformdata.append("order_id",orderid.toString())
  //     Orderformdata.append("ezz_id", this.state.customer_id)
  //     Orderformdata.append("shipper_number", this.state.shiper_order_number)
  //     Orderformdata.append("billing_id", "2121")
  //     Orderformdata.append("billing_type", this.state.paymentTypeSelected)
  //     Orderformdata.append("order_date", this.state.order_date)
  //     Orderformdata.append("received_date", this.state.received_date)
  //     Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
  //     Orderformdata.append("weight", this.state.weight)
  //     Orderformdata.append("length", this.state.length)
  //     Orderformdata.append("breadth", this.state.width)
  //     Orderformdata.append("height", this.state.height)
  //     Orderformdata.append("charge_type", "VAT")
  //     Orderformdata.append("freight_charge", this.state.fedex_charge)
  //     Orderformdata.append("actual_charge", this.state.actual_charge)
  //     Orderformdata.append("current_status", this.state.orderStatusSelected)
  //     Orderformdata.append("previous_status", this.state.orderStatusSelected)
  //     Orderformdata.append("status", "Active")
  //     Orderformdata.append("flag", "1")
  //     Orderformdata.append("homedelivery_charge", this.state.homedelivery_charge)
  //     Orderformdata.append("promo_type", this.state.promo_type)
  //     Orderformdata.append("promo_value", this.state.promo_value)

  //     // console.log(JSON.stringify(Orderformdata))
  //     // console.log("-------------------")
  //     // console.log(JSON.stringify(this.state))
  //     // console.log("-------------------")


  //     // @Todo: APi to be called to save data for state

  //     try {

  //       const response = await Axios.post(base_url+"boxes_received/", Orderformdata,
  //         header)

  //       const orderid = response.data.order_id
  //       console.log("orderid" + orderid)
  //       this.state.productlist.forEach(async (item) => {
  //         console.log("productitem")
  //         console.log(item)
  //         try {
  //           var productformdata = new FormData()
  //           productformdata.append("order_id", orderid.toString())
            

    

  //           //if(item.productTemplate.length > 0)
  //           if (item.productStatus != "Active")
  //           {
  //             let token = localStorage.getItem("token")
  //             var config = {
  //             headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

  //             };

  //             var url = base_url+`materials/?template_name=${item.productTemplate}`
  //             let products = await Axios.get(url, config)
  //             console.log("***orderlist-openmodel")
  //             console.log(products.data)
  //             // await this.setState({productlisthtml:<ProductItemsList products={new Array()}/>})
  //             var items = new Array()
  //             products.data.forEach(async (product) => {
  //                     var productformdata_pro = new FormData()
  //                     productformdata_pro.append("order_id", orderid.toString())
  //                     productformdata_pro.append("description", product.name)
  //                     productformdata_pro.append("quantity", product.quantity)
  //                     productformdata_pro.append("unit_price", product.rate)
  //                     productformdata_pro.append("inspection_status", product.code)
  //                     productformdata_pro.append("status", item.productTemplate)
  //                     productformdata_pro.append("category", product.category)
  //               // let newitem = {
  //               //   id: product.id, productName: product.description,
  //               //   productPrice: product.unit_price, productQuantity: product.quantity,
  //               //   productCategory: product.category,productSKU: product.inspection_status,
  //               // }
  //               // const found = items.find(element => element["productName"]==product.description)
  //               // console.log('found', found)
  //               // if(found)
  //               // {}
  //               // else
  //               // {
  //               //   items.push(newitem)
  //               // }
  //               const response_products = await Axios.post(base_url+"products/", productformdata_pro,
  //                     header)
  //                   // alert(response.data)
  //                   //console.log(productformdata_pro)
  //                   console.log("Product added Successfully")
  //             })
             
  //           }
  //           else
  //           {
  //             productformdata.append("description", item.productName)
  //             productformdata.append("quantity", item.productQuantity)
  //             productformdata.append("unit_price", item.productPrice)
  //             productformdata.append("inspection_status", item.productSKU)
  //             productformdata.append("status", "Active")
  //             productformdata.append("category", item.productCategory)
  //             const response_products = await Axios.post(base_url+"products/", productformdata,
  //             header)
  //           }

  //           // alert(response.data)
  //           //console.log(productformdata)
  //           console.log("Product added Successfully")
  //           this.setState({ error: <Success message=" Order Status Updated" /> })
  //           M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
  //         } catch (error) {
  //           M.toast({ html: 'Product Addition Failed', classes: "white-text red rounded" });
  //           console.log(error)
  //         }

  //       });


  //       try {
  //         var email_subject = "invoice_missing"
  //         const status = this.state.orderStatusSelected
  //         switch (status) {
  //           case "IM":
  //             email_subject = "invoice_missing";
  //             break;
  //           case "WFC":
  //             email_subject = "consolidation";
  //             break;
  //         }
  //         if (status === "IM" || status === "WFC") {
  //           const emaildata = {
  //             "type": email_subject,
  //             "ezz_id": this.state.customer_id,
  //             "order_id": orderid
  //           }


  //           Axios({
  //             method: 'post',
  //             url: base_url+'status_printer',
  //             data: {
  //               "order_id": orderid
  //             }
  //           }).then(function (response) {
  //             console.log(response)
  //             console.log("printer APi success");
  //           }).catch(function (response) {
  //             console.log(response)
  //             console.log('printer API failed');
  //           })

  //           Axios({
  //             method: 'post',
  //             url: base_url+'whatsapp_templates',
  //             data: emaildata,
  //           }).then(
  //             function (response) {
  //               console.log(response)
  //               console.log("whatsapp sent done");
  //             }
  //           ).catch(
  //             function (response) {
  //               console.log(response)
  //               console.log("whatsapp sent Failure");
  //             }
  //           )

  //           const response = await Axios.post(base_url+"send_email/", emaildata,
  //             header)
  //           console.log(response)
  //           M.toast({ html: 'Email Sent to ' + response.data["Email to"], classes: "white-text red rounded" });
  //         }

  //       } catch (error) {

  //         M.toast({ html: 'Email Sending Failed to' + this.state.customer_id, classes: "white-text red rounded" });
  //       }



  //       this.setState({ error: <Success message=" New order foproduct items created" /> })
  //       M.toast({ html: 'Order created successfully', classes: "white-text orange rounded" })
  //       this.setState({ error: "" })
  //       this.setState({ generatedOrderId: orderid })
  //       console.log('generatedOrderId',this.state.generatedOrderId);
  //       document.getElementById("printLabel").disabled = false

  //       await this.getproducts(orderid)

  //     } catch (err) {

  //       M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

  //       console.log(err)
  //       this.setState({ error: <Error /> })
  //     }




  //   } else {


  //     try {
  //       let token = localStorage.getItem("token")
  //       const header = {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Token ' + token,

  //       }
  //       var orderupdateForm = new FormData()
  //       orderupdateForm.append("shipper_number", this.state.shiper_order_number)
  //       // orderupdateForm.append("billing_id","2121")
  //       orderupdateForm.append("billing_type", this.state.paymentTypeSelected)
  //       orderupdateForm.append("order_date", this.state.order_date)
  //       orderupdateForm.append("received_date", this.state.received_date)
  //       orderupdateForm.append("inbound_tracking_number", this.state.tracking_number)
  //       orderupdateForm.append("weight", this.state.weight)
  //       orderupdateForm.append("length", this.state.length)
  //       orderupdateForm.append("breadth", this.state.width)
  //       orderupdateForm.append("height", this.state.height)
  //       // orderupdateForm.append("charge_type","VAT")
  //       orderupdateForm.append("freight_charge", this.state.fedex_charge)
  //       orderupdateForm.append("actual_charge", this.state.actual_charge)
  //       orderupdateForm.append("current_status", this.state.orderStatusSelected)
  //       let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.ordernumber}/`
  //       const response = await Axios.patch(urlforOrderStatusUpdate, orderupdateForm, header)

  //       console.log(`order status update succesfully ${response.data}`)
  //       this.setState({ error: <Success message=" Order Status Updated" /> })
  //       M.toast({ html: 'Updated SUccessfull', classes: "white-text orange rounded" });
  //     } catch (error) {
  //       M.toast({ html: 'Updation Failed', classes: "white-text red rounded" });
  //       console.log(error)
  //       this.setState({ error: <Error /> })
  //     }

  //   }
    
  //   document.getElementById('saveOrderBtn').disabled = true

  // }
  //  addOrderToDB = async ()=>{
  //             let token =localStorage.getItem("token")
  //             const  header = {'Content-Type': 'application/json',
  //             'Authorization':'Token '+token,
            
  //             }
            
  //                 //@TODO: FOR FUTUREjust umcomment the code for merge ,discard and hold fucntionality 
     

  //             // check whether there is any order which is in state of WAITING FOR CONSOLIDATION
  //             // try {
  //             //   let url_wfc_check = base_url+`boxes_received/?ezz_id=${this.state.customer_id}&current_status=WFC`
  //             //   var  wfcResponse = await Axios.get(url_wfc_check,header)
  //             //   console.log(`WFC Response ${wfcResponse.data}`)
  //             //   if (wfcResponse.data.length >0){
  //             //     console.log(wfcResponse.data[0].order_id)
  //             //     this.setState({ordernumbermerge:wfcResponse.data[0].order_id})
  //             //     var elems = document.getElementById('merge_order_model');
  //             //     var instance = M.Modal.init(elems);
  //             //     instance.open()

  //             //     const orderid = Math.floor(1000 + Math.random() * 9000)
  //             //     this.setState({ordernumber:orderid})

  //             //   }else{
  //                 this.createOrderNormal()
  //                 var btn = document.getElementById('createorder')
  //                 btn.click()
  //                // window.location.reload(false);
  //             // this.props.refresh();
  //             //   }
                
  //             // } catch (error) {
  //             //   console.log(error)
  //             // }



            


            



  // }

  calculaterate = async () => {
    document.getElementById("divapidetail").style.display = "inline-grid" 
    document.getElementById("divapidetail-1").style.display = "inline-grid" 
    document.getElementById("divapidetail-2").style.display = "inline-grid" 
    document.getElementById("divapidetail-3").style.display = "none" 
    document.getElementById("divshowrates").style.display = "none" 
    document.getElementById("divrefresh").style.display = "inline-grid"
    var totalcharge = 0
    var weight = ""
    var length = ""
    var width = ""
    var height = ""
    this.state.productlist.forEach(async(item)=>{
        try{
              
              weight = weight + item.productName + "S"
              length = length + item.productQuantity + "S"
              width = width + item.productPrice + "S"
              height = height + item.productSKU + "S"
            
        }catch(error){
          M.toast({html: 'Package List Failed',classes:"white-text red rounded"});
          console.log(error)
        }
      });
          
          weight = weight.replace(/S\s*$/, "");
          length = length.replace(/S\s*$/, "");
          width = width.replace(/S\s*$/, "");
          height = height.replace(/S\s*$/, "");

            try{
                var packageno = 1 //item.productSelect
                var to_city = this.state.to_city
                var to_fedexcode = this.state.to_country
                //var to_country = this.state.to_country
                var to_zip = this.state.to_zip
                var ezzid = "EZZ000005"
                var personname = "Megs Informatics"
                var compname = "Ezzylife"
                var to_phone = "8623075438"
                var to_streetline = "95 W LakeShore Dr"
                //var from_country = 'US' //this.state.from_country
                var from_country = this.state.from_country //12-06-23
                var from_code = this.state.from_state
                var from_zip = this.state.from_zip
                var from_city = this.state.from_city
                var priority = this.state.priority
                var metric = this.state.metric
                var dimension_unit="IN"
          
                //http://18.222.190.66:8080/api/v1/ezzytrace/fedex_actual_rates_ship/?weight=2&breadth=2&height=2&length=1&ezz_id=EZZ000005&country_city=Nasau&country_fedex_code=BS&country_zipcode=1242&person_name=Megs Informatics&company_name=Ezzylife&phone_no=8623075438&street_line=95 W LakeShore Dr&from_city=Rockaway&from_code=NJ&from_zip_code=07866&from_country=US
                var url = 'fedex_actual_rates_ship/?weight='+ weight +'&breadth=' + width +'&height=' + height + '&length=' + length + '&ezz_id=' + ezzid + '&country_city=' + to_city + '&country_fedex_code=' + to_fedexcode + '&country_zipcode=' + to_zip + '&person_name=' + personname + '&company_name=' + compname + '&phone_no=' + to_phone + '&street_line=' + to_streetline + '&from_city=' + from_city + '&from_code=' + from_code + '&from_zip_code=' + from_zip + '&from_country=' + from_country + '&priority=' + priority + '&weight_unit=' + metric + '&dimension_unit=' + dimension_unit
                console.log(url)
                const response = await Axios.get (base_url+url)
                console.log(response.data)
                console.log("fedex rate data" + response.data)
                //totalcharge = totalcharge + (parseFloat(packageno) * parseFloat(response.data.actual_charges))
                totalcharge = totalcharge + parseFloat(response.data.actual_charges)
                //totalcharge =  parseFloat(response.data.freight_charges)
                M.toast({ html: 'Rate calculated Successfull', classes: "white-text blue rounded" });
                this.setState({ prioritycharge: totalcharge.toFixed(2) })
                document.getElementById('intercharge').innerHTML =  totalcharge.toFixed(2);
            }catch(error){
              M.toast({html: 'API Call Failed',classes:"white-text red rounded"});
              console.log(error)
            }

      
  }

  // //UPDATE ORDER START

  // getproducts = async (orderid) => {
  //   // http://localhost:8000/api/v1/ezzytrace/products/?order_id=
  //   try {
  //     let token = localStorage.getItem("token")
  //     // let header = {'Content-Type': 'multipart/form-data',
  //     // 'Authorization':'Token '+token,

  //     // }

  //     var config = {
  //       headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

  //     };

  //     var url = base_url+`products/?order_id=${orderid}`
  //     let products = await Axios.get(url, config)

  //     // var items = new Array()
  //     // products.data.forEach((product) => {
  //     //   let newitem = {
  //     //     id: product.id, productName: product.description,
  //     //     productPrice: product.unit_price, productQuantity: product.quantity,
  //     //     productCategory: product.category, productSKU: product.inspection_status,productTemplate:product.status
  //     //   }
  //     //   items.push(newitem)
  //     //   // this.state.productlist.push(newitem)
  //     // })
  //     var items = new Array()
  //     products.data.forEach((product) => {
  //       let newitem = {
  //           id: 1, productName: "",
  //           productPrice: 0, productQuantity: 0,
  //           productCategory: "",productSKU: "",productStatus: "",productExport:false}
  //       if (product.status != "Active")
  //       {
  //          newitem = {
  //           id: product.id, productName: product.status,
  //           productPrice: 0, productQuantity: 0,
  //           productCategory: "Template",productSKU: product.inspection_status,productStatus: product.status,
  //           productTemplate: product.status,productExport:false
  //         }
  //         const found = items.find(element => element["productStatus"]==product.status)
  //         console.log('found', found)
  //         if(found)
  //         {}
  //         else
  //         {
  //           items.push(newitem)
  //         }
  //       }
  //       else
  //       {
  //           newitem = {
  //           id: product.id, productName: product.description,
  //           productPrice: product.unit_price, productQuantity: product.quantity,
  //           productCategory: product.category,productSKU: product.inspection_status,productStatus: product.status,
  //           productTemplate: product.status,productExport:false
  //         }
  //         items.push(newitem)
  //       }


        
  //       // this.state.productlist.push(newitem)
  //     })
  //     M.toast({ html: 'Fetched products for selected order', classes: "white-text orange rounded" });
  //     // this.setState({error:<Success/>})


  //     await this.setState({ productlist: items })


  //     // await this.setState({ productlisthtml: <ProductItemsList orderid={orderid} products={items} rowclick={this.handletableClick} /> })


  //   } catch (error) {
  //     console.log(error)
  //     M.toast({ html: 'Failed to fetch products', classes: "white-text red rounded" });
  //     // this.setState({error:<Error/>})
  //   }
  // }

  // updateOrderDB= async(header)=>{
  //   const  orderid= this.state.generated_orderId

  //   const found = this.state.productlist.find(element => element["productPrice"]==0);

  //   console.log('found', found)
  //   // console.log('this.state.generated_orderId', this.state.generated_orderId)
  //   // console.log('orderid', orderid)

  //   // if(found && this.state.orderStatusSelected === 'RTM' ){
  //   //   M.toast({ html: 'Ready to Manifest not allowed for product price $0', classes: "white-text red rounded" });
  //   //   return
  //   // }

  //     try{
          
  //         var orderupdateForm = new FormData()
  //         // orderupdateForm.append("order_id",this.state.generated_orderId)
  //         orderupdateForm.append("ezz_id",this.state.customer_id)
  //         orderupdateForm.append("shipper_number",this.state.shiper_order_number)
  //         orderupdateForm.append("billing_id","2121")
  //         orderupdateForm.append("billing_type",this.state.paymentTypeSelected)
  //         orderupdateForm.append("order_date",this.state.order_date)
  //         orderupdateForm.append("received_date",this.state.received_date)
  //         orderupdateForm.append("inbound_tracking_number",this.state.tracking_number)
  //         orderupdateForm.append("weight",this.state.weight)
  //         orderupdateForm.append("length",this.state.length)
  //         orderupdateForm.append("breadth",this.state.width)
  //         orderupdateForm.append("height",this.state.height)
  //         orderupdateForm.append("charge_type","VAT")
  //         orderupdateForm.append("freight_charge",this.state.fedex_charge)
  //         orderupdateForm.append("actual_charge",this.state.actual_charge)
  //         orderupdateForm.append("current_status",this.state.orderStatusSelected)
  //         // orderupdateForm.append("previous_status",this.props.orderdata.current_status)
  //         orderupdateForm.append("status","Deactive")
  //         orderupdateForm.append("flag","1")
  //         orderupdateForm.append("homedelivery_charge", this.state.homedelivery_charge)
  //         orderupdateForm.append("promo_type", this.state.promo_type)
  //         orderupdateForm.append("promo_value", this.state.promo_value)

  //         let urlforOrderStatusUpdate = base_url+`boxes_received/${this.state.generatedOrderId}/`
  //         const response = await Axios.patch(urlforOrderStatusUpdate,orderupdateForm, header)
    
  //         // console.log(`order status update succesfully ${response.data}`)
  //         this.setState({error:<Success message=" Order Updated"/>})
        
  //         M.toast({html: 'Order Updated Successfull',classes:"white-text orange rounded"});
  
  
  //         //Updating The Product 
  
  //         this.state.productlist.forEach(async(item)=>{
  //           // console.log("productitem")
  //           // console.log(item)
  //           // console.log(item.id, 'I AM THE ITEM GETTING UPDATED!!!!!!!!!!!!!!')
  
            
  
  //           try{
  //             var productformdata = new FormData()
  //             productformdata.append("order_id",this.state.generatedOrderId)
  //             productformdata.append("description",item.productName)
  //             productformdata.append("quantity",item.productQuantity)
  //             productformdata.append("unit_price",item.productPrice)
  //             productformdata.append("inspection_status",item.productSKU)
  //             //productformdata.append("status","Active")
  //             productformdata.append("status", item.productTemplate)
  //             productformdata.append("category",item.productCategory)
  
  //             console.log('ITEM-ID',item.id);

  //             if( typeof(item.id) === "number" && this.state.isOrderUpdate ){
  //               const response_products = await Axios.patch(base_url+`products/${item.id}/`, productformdata,
  //                 header)
  //               // alert(response.data)
  //               console.log("Product added Successfully")
  //               this.setState({ error: <Success message=" Order Status Updated" /> })
  //               M.toast({ html: 'Product Updation Successfull', classes: "white-text blue rounded" });
                
  //             }else{
                
  //               const response_addProduct = await Axios.post(base_url+`products/`, productformdata,
  //               header )
  
  //               M.toast({ html: 'Product Addition Successfull', classes: "white-text blue rounded" });
                
  //             }
  
              
              
  //           }catch(error){
  //             M.toast({html: 'Product Updation Failed',classes:"white-text red rounded"});
  //               console.log(error)
  //           }
           
  //         });
  
  //        await this.getproducts(this.state.generatedOrderId)
  
  
  //         // window.location.reload(false);
  //       }catch(error){
  //         M.toast({html: 'Order Updation Failed',classes:"white-text red rounded"});
  //           console.log(error)
  //           this.setState({error:<Error/>})
  //       }
  
  // }
  
  // updateOrder=()=>{
  //     let token =localStorage.getItem("token")
  //     const  header = {'Content-Type': 'application/json',
  //     'Authorization':'Token '+token,
  //     }
  //     this.updateOrderDB(header)
  
  
  // }

clearorigin=()=>
{
this.setState({ from_city:'', from_state:'' , from_zip:'', from_country:''})
}

cleardestination=()=>
{
//this.setState({ productlist: items })
this.setState({ to_city:'', to_state:'' , to_zip:'', to_country:''})
}
refreshpage=()=>
{
  this.setState({ from_city:'', from_state:'' , from_zip:'', from_country:''})
  this.setState({ to_city:'', to_state:'' , to_zip:'', to_country:'',  productlist:new Array()})
  // document.getElementById("divpackaging").style.display = "none"
  //     document.getElementById("divitem").style.display = "none"
  //     document.getElementById("divship").style.display = "none" 
  //     document.getElementById("divshowrates").style.display = "none" 
       
  //     document.getElementById("divapidetail").style.display = "inline-grid" 
  //     document.getElementById("divapidetail-1").style.display = "inline-grid" 
  //     document.getElementById("divapidetail-2").style.display = "inline-grid" 
  //     document.getElementById("divapidetail-3").style.display = "inline-grid" 
      document.getElementById("continue").style.display = "inline-grid" 
document.getElementById("divrefresh").style.display = "none" 
      document.getElementById("divpackaging").style.display = "none"
        document.getElementById("divitem").style.display = "none"
        document.getElementById("divship").style.display = "none" 
        document.getElementById("divshowrates").style.display = "none" 
        document.getElementById("divapidetail").style.display = "none" 
        document.getElementById("divapidetail-1").style.display = "none" 
        document.getElementById("divapidetail-2").style.display = "none" 
        document.getElementById("divapidetail-3").style.display = "none" 
}

showcard=()=>{
      document.getElementById("divpackaging").style.display = "inline-grid"
      document.getElementById("divitem").style.display = "inline-grid"
      document.getElementById("divship").style.display = "inline-grid" 
      document.getElementById("divshowrates").style.display = "inline-grid" 
      document.getElementById("divrefresh").style.display = "none"
      document.getElementById("divapidetail").style.display = "none" 
      document.getElementById("divapidetail-1").style.display = "none" 
      document.getElementById("divapidetail-2").style.display = "none" 
      document.getElementById("divapidetail-3").style.display = "none" 
      document.getElementById("continue").style.display = "none" 
}
  //--UPDATE ORDER END


render(){
  const opts={};
   opts['readOnly'] = 'readOnly';
  const catlist = this.state.country_list;
  if (catlist.length >0){
    var categoryList = catlist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.country_id}>{item.name}</option>
      )
    }, this);
     
  }
  
  const catlist1 = this.state.statelist;
  if (catlist1.length >0){
   var selectStateOptions = catlist1.map((state,statekey) => {
      console.log("state", state)

      return (

        <option  key={statekey++} value={state.state_code}>{state.state_name}</option>
      )
    })
   
  }
  else
  {
    //const states = this.state.statedata[this.state.countrycodelist.indexOf('US')].slice()
    const states = this.state.statedata[this.state.countrycodelist.indexOf('BS')].slice()
    this.setState({  statelist: states })
  }

  const templist = this.state.product_template_options;
  if (templist.length >0){
    var templateList = templist.map((item, i) => {
      return (
        <option className="black-text" key={i} value={item.name}>{item.name}</option>
      )
    }, this);
  }

    return(
        <Container>
{/* Edit product Table items */}
<EditProduct ref="editproduct" rowNumber={this.state.rowNumber}
productDetails={this.state.selectItemDetails}
 productcategory={this.state.product_edit_category}
 updateBtnClick={this.updateProduct} deleteBtnClick={this.deleteProduct}
productTemplate={this.state.product_edit_template}
 disablePrice= {this.state.orderStatusSelected ==="IM"} 
 />

        {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        {/* Model for adding products STARTS here */}
        {/* <!-- Modal Trigger --> */}
        <div id="addProductModal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <h4 className="center orange-text"> Add Package </h4>
           
            <Row>
              <Col xs={12} >
                <form>
                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <select id='search_name_select' name="search_name_select" className="browser-default" onChange={this.handleFormChange}>
                          <option value="1"  selected>1</option>
                          <option value="2" >2</option>
                          <option value="3"  >3</option>
                          <option value="4" >4</option>
                          <option value="5"  >5</option>
                          <option value="6" >6</option>
                          <option value="7"  >7</option>
                          <option value="8" >8</option>
                          <option value="9"  >9</option>
                          <option value="10" >10</option>
                          <option value="11"  >11</option>
                          <option value="12" >12</option>
                          <option value="13"  >13</option>
                          <option value="14" >14</option>
                        </select>
                        <span className="helper-text left" data-error="wrong" data-success="">Max 40</span>
                        </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className=" rowdivPading " xs={12}>
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_name1" id="product_name1" type="text" value={this.state.product_name1} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_name1" className="black-text" >Weight Per Package</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Max weight 2200 lb</span>
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_quantity" id="product_quantity" min="0" type="number" value={this.state.product_quantity} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_quantity" className="black-text active">Length</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter length</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_price"  id="product_price" min="0" type="number" value={this.state.product_price} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_price" className="black-text active">Width</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter width</span>
                      </div>
                    </Col>
                  </Row>


                  
                  <Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_sku"  id="product_sku" min="0" type="text" value={this.state.product_sku} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_sku" className="black-text active">Height</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter height</span>
                      </div>
                    </Col>
                  </Row>

                 {/*<Row>
                    <Col className=" rowdivPading ">
                      <div className="input-field col s6 offset-s3 center">
                        <input name="product_carriage"  id="product_carriage" min="0" type="text" value={this.state.product_carriage} onChange={this.handleFormChange} className="" />
                        <label htmlFor="product_carriage" className="black-text active">Carriage Value</label>
                        <span className="helper-text left" data-error="wrong" data-success="">Enter carriage value</span>
                      </div>
                    </Col>
                  </Row>*/}
                </form>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" >
            {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a> */}



            <ButtonGroup className="mr-2" aria-label="First group">
              <Button id="cancelItemAddBtn" variant="outline-secondary" className="mr-4 btn modal-close "  >Cancel</Button>
              <Button id="addItemBtn" variant="outline-primary" className="mr-2 btn" onClick={this.addProductTolist}> Add</Button>
            </ButtonGroup>

          </div>
        </div>

        {/* Model for adding produts ENDS here */}


<div className="row">
  <div className="col s3">
      <h4 className=" orange-text">Calculate Shipping Rates</h4>
  </div>
</div>
<Row>
    <Col xs={12}md={5} lg={6}>
        <Row>
            <Col>
               <Card className="" >
        <Card.Body>
            <Card.Text>
               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">From</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <h6 >City*:</h6> 
                      </Col>
                        <Col xs={9}><input name="from_city" id="from_city" type="text" 
            value={this.state.from_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                   {/*<Row>
                    <Col xs={3}>
                        <h6 >State:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_state" id="from_state" type="text" 
            value={this.state.from_state} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>*/}
                    <Row>
                    <Col xs={3}>
                        <h6 >State*:</h6> 
                        </Col>
                        <Col xs={9}>
                    <select id="from_state" name="from_state" value={this.state.from_state} className="browser-default" onChange={this.handleCustomerIDChange}>
                                <option value="DEFAULT" selected>Choose state </option>
                                {selectStateOptions}
                              </select>
                              </Col>
                    </Row>
                   

                    <Row>
                    <Col xs={3}>
                        <h6 >ZIP*:</h6> 
                        </Col>
                        <Col xs={9}><input name="from_zip" id="from_zip" type="text" 
            value={this.state.from_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >Country*:</h6> 
                        </Col>
                        <Col xs={9}><select id='from_country' name="from_country"  value={this.state.from_country} className="browser-default" onChange={this.handleCountryNameChange}>
                          <option value=""  selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>


                    {/* right side columns ends here */}
<div className="row inputbox-height">
      <div className="col s6 center">
          <Button className="ml-5 " id="saveOrderBtn" onClick={this.clearorigin} variant="primary"  >Reset origin</Button>
      </div>
</div>
                     </Col> 
      <Col xs={12}md={5} lg={6}>
                                <Row>
            <Col>
            
            <Card className="" >
        <Card.Body>
            <Card.Text>

                               <Row>
                    <Col xs={3}>
                <h4 className="blue-text">To</h4>
                    </Col>
                    </Row>
                               <Row>
                    <Col xs={3}>
                        <h6 >City*:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_city" id="to_city" type="text" 
            value={this.state.to_city} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                   <Row>
                    <Col xs={3}>
                        <h6 >State*:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_state" id="to_state" type="text" 
            value={this.state.to_state} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >ZIP*:</h6> 
                        </Col>
                        <Col xs={9}><input name="to_zip" id="to_zip" type="text" 
            value={this.state.to_zip} 
            onChange={this.handleCustomerIDChange}
            onKeyDown={this.handleCustomerInfo}
          />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <h6 >Country*:</h6> 
                        </Col>
                        <Col xs={9}><select id='to_country' name="to_country" value={this.state.to_country} className="browser-default" onChange={this.handleFormChange}>
                          <option value=""  selected>Choose Country</option>

                          {categoryList}

                        </select>
                    </Col>
                    </Row>
            
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>


                    {/* right side columns ends here */}
  <div className="row  center">
      <div className="col s6 center">
            <Button className="ml-5 " id="saveOrderBtn" onClick={this.cleardestination} variant="primary"  >Reset Destination</Button>
        </div>
   </div>
                     </Col>   
                </Row> 
                  
                    <div className="col s6 center">
                          <Button className="ml-5 white-text orange" id="continue" variant="success" onClick={this.showcard} >Continue</Button>                        
                    </div>
                   <Card className="" id="divpackaging" style={{width:"100%"}} >
        <Card.Body>
            <Card.Text>
               
                  <Row>
                           <Col xs={3}>
                        <h6 >Package Type:</h6> 
                        </Col>
                  <Col xs={6}>
                  <div className="row center">
                      <div className="col s6 left">
                          <select id='packaging' name="packaging" className="browser-default" onChange={this.handleFormChange}>
                          <option value="Your Packaging"  selected>Your Packaging</option>
                          {/*<option value="Fedex Packaging" >Fedex Packaging</option>*/}
                          </select>
                    </div>
                  </div>
                  </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                        <h6 >Service Type:</h6> 
                        </Col>
                        <Col xs={6}><select id='priority' name="priority" className="browser-default" onChange={this.handleFormChange}>
                          <option value="1"  selected>Priority</option>
                          <option value="0"  >Economy</option>
                        </select>
                    </Col>
                   
                  </Row>
                  <Row>
                    <Col xs={3}>
                        <h6 >Metric Type:</h6> 
                        </Col>
                        <Col xs={6}><select id='metric' name="metric" className="browser-default" onChange={this.handleFormChange}>
                          <option value="LB"  selected>lb/in</option>
                          <option value="KG"  >kg/cm</option>
                        </select>
                    </Col>
                   
                  </Row>
                   </Card.Text>
               


               </Card.Body> 
        </Card>
                  <Row id="divitem" style={{width:"100%"}}>
 {/* right side column starts here */}
                    <Col xs={12}md={12} lg={12}>
                        <Row>
                        <Col>
                        <h5>Package Details</h5>
                        </Col>
                        <Col>
                        <Nav className="right">
                          <Nav.Link className="red-text modal-trigger "  href="#addProductModal">
                            Add Package<Image className="red-text ml-1" src="assets/images/plus-circle-red.svg"/></Nav.Link>
                          </Nav>
                        </Col>
                    </Row>
                    {/* product card to be shown here */}
                    {/* <div className="productlistDiv ">{this.state.productlisthtml}</div> */}
                    <div className="productlistDiv "><ProductItemsList products={this.state.productlist} rowclick={this.handletableClick}/></div>
                    
                    <div className="left" style={{width:"200px"}}><b>Packages: </b>
                    {
                    ( ()=>{
                      var totalprice =0;
                      this.state.productlist.forEach(function(item){
                        //totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                         totalprice = totalprice + (parseFloat(item.productSelect))
                      })
                      return parseFloat(totalprice).toFixed(0)
                    })()
                      
                      }
                    </div>
                    <div className="left"><b>Total Weight: </b>
                    {
                    ( ()=>{
                      var totalweight =0;
                      this.state.productlist.forEach(function(item){
                        //totalprice = totalprice + (parseFloat(item.productPrice ).toFixed(2)) *parseInt(item.productQuantity ) 
                         totalweight = totalweight + (parseFloat(item.productName))
                      })
                      return parseFloat(totalweight).toFixed(2)
                    })()
                      
                      }
                    </div>
                    </Col>
  </Row>
    

        
                          <Row>

                       <Col xs={6}md={12} lg={6} >
                    <div className="row">
    <form className="col s4" autocomplete="off" id="divship" style={{width:"100%"}}>
     
      <div className="row center">
        <div className="input-field col s6">
          <input name=" " id="order_date" type="text"  className="datepicker" 
          value={this.state.order_date}/>
          <label htmlFor="order_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i></label>
          <span className="helper-text" data-error="wrong" data-success="">Select Ship Date </span>
        </div>
      {
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }
      </div>
    </form>
  </div>
                    </Col>
                      
                </Row> 

  <Row  id="divshowrates" style={{width:"100%"}} >
            <Col>
              <div className="center">
                <ButtonGroup aria-label="create order buttons" className="">
                  
                  <Button className="ml-5 white-text" id="printLabel" variant="warning"  onClick={this.calculaterate}>Show Rates</Button>                        
                </ButtonGroup>
              </div>
            </Col>
          </Row>
          <Row id="divapidetail" style={{width:"100%", fontWeight: 'bold', color: 'green', fontSize: '20px'}}>
            <Col>
              <div className="left">
                  <span>Here are the estimated rates </span>
                </div>
                </Col>
           </Row>
          <Row id="divapidetail-1" style={{width:"100%", fontWeight: 'bold', color: 'black', fontSize: '15px'}}>
            <Col>
              <div className="right">
                Amount are shown in USD
                </div>
                </Col>
            </Row>
            <Row id="divapidetail-2" style={{width:"100%", fontWeight: 'bold', color: 'black', fontSize: '20px'}}>
            <Col className=" rowdivPading " xs={6}>
              <div className="left">
                Fedex Freight Charge
                </div>
                 </Col>
                 <Col>
              <div className="right" xs={6}>
                 <label id = "intercharge" style={{fontWeight: 'bold', color: 'blue', fontSize: '20px'}} >
       Calculating Amount...
                </label> 
                {/*<input name="prioritycharge" style={{fontWeight: 'bold', color: 'green', fontSize: '20px'}} disabled id="prioritycharge" type="text" value={this.state.prioritycharge} onChange={this.handleFormChange}  />*/}
                </div>
                </Col>
            </Row>
            <Row id="divapidetail-3" style={{width:"100%", fontWeight: 'bold', color: 'black', fontSize: '15px'}}>
            <Col>
              <div className="left">
                Fedex International Economy Freight
              </div>
                </Col>
                </Row>

                <Row  id="divrefresh" style={{width:"100%"}} >
            <Col>
              <div className="center">
                <ButtonGroup aria-label="create order buttons" className="">
                  <Button className="ml-5 white-text green" id="btnrefresh" variant="primary" onClick={this.refreshpage} >Refresh</Button>                        
                  
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>


    )
}

}

export default CreateOrder;
