import React, {Component,} from 'react';
import {Redirect, useHistory } from 'react-router-dom';
import {Card,Button,ButtonGroup,Nav,Image,Row,Col,Container,Spinner} from 'react-bootstrap';
import M from "materialize-css";
import Axios from "axios";
import Error from '../AdminPanel/Error'
import Success from '../AdminPanel/Success'
import ProductItemsList from './ProductItemListCard';
import OrderModal from './OrderSelectionModal';
import EditProduct from './EditProductItem';
import "./coupon.css";
import { base_url } from '../../globalConstants';
import { subject } from '../../service'


class CustomerDetailCard extends Component{

    constructor(props){
        super(props);
        
  this.state = ({title:"***",name:"********",
              email:"**********",address:"********",phone:"********",country:"********"

              })

    }

    componentDidUpdate(prevProps) {

      if (this.props.info.ezz_id !== prevProps.info.ezz_id || this.props.info.email !== prevProps.info.email ) {
        
         this.setState({title:this.props.info.title,name:this.props.info.name,
            email:this.props.info.email,address: this.props.info.address,phone:this.props.info.phone_number,country:this.props.info.country_name
        
        })

      }



    }

    render(){ 
        return(
            <Row>
            <Col>
            
            <Card className="" id="customerbasicdetailsCard">
        <Card.Body>
            <Card.Text>
                <Row>
                    <Col xs={8}>
        <h6 >Name:</h6><p className="inline">{this.state.title + " " + this.state.name}</p>
                    </Col>
                    <Col className="">
                    <Image src="assets/images/avatar-big.svg"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <h6 >Address:</h6><p>{this.state.address}</p>
                    </Col>
                    <Col xs={4} className="right">
                    <h6 >Phone:</h6><p>{this.state.phone}</p>
                    </Col>
                    <Col xs = {8} className="">
                    <h6 >Email:</h6><p>{this.state.email}</p>
                    </Col>
                    <Col xs = {4} className="right">
                      <h6>Country:</h6><p>{this.state.country}</p></Col>
                </Row>
               
            
            </Card.Text>
               


               </Card.Body> 
        </Card>
            </Col>
        </Row>

        )
    }
}



class Coupon extends Component{
    constructor(props){
        super(props);
     
        var today = new Date();
       

         
          var month = '' + (today.getMonth() + 1)
            var   day = '' + today.getDate()
           var    year = today.getFullYear()
        
           if (month.length < 2) {
             month = '0' + month;
           }
            
           if (day.length < 2) {
             day = '0' + day;
           }
           
        
           let date = [year, month, day].join('-');


        this.state = ({
          current_date: date,
            productlist:new Array() ,
            productlisthtml:<div></div> ,
            product_name:"",product_price:0,product_quantity:0 ,
            product_category_options :[],
            product_edit_category :[],
            product_category:"",ordernumber:"",
         customer_id:"",order_date:date,
        received_date:date,tracking_number:"",shiper_order_number:"",
        weight:0,height:0,fedex_charge:0,width:0,length:0,actual_charge:0,homedelivery_charge:0,promo_type:"Not",promo_value:0,processing_fee:0,brokerage_charge:0,insurance_charge:0,
        Weight_type:null,
          orderStatusSelected:"YTA",
          paymentTypeSelected:"card",showSpinner:false,
          customer_selected_data:{},
          pendingOrders:[],ordersSelectedInModal:[],
          createNewOrder:true,holdmergedorderstatus:"RTM",
          ordernumbermerge:"",
          error:"",
          rowNumber:"",
          selectItemDetails:{},
      
          generatedOrderId: "",
          isOrderUpdate: false,
          customer_email: "",
          serachInput: '',
          searchResult:''
      
          
          })

    }



    handleCustomerIDChange = async (ev)=>{       
      const target = ev.target;
      const name = target.name;

      let search_input = target.value
          
      var patt = /^EZ|ez\w{0,2}\d{0,4}/g;
      // var patt =  /^[EZez]\d{0,4}/g;
      var result = search_input.match(patt);

      this.setState({
        [name]: target.value,
        searchInput: search_input,
        searchResult: result
      })

      if (search_input.length <= 2)
        return;

      try{
      
        let token =localStorage.getItem("token")
        // let header = {'Content-Type': 'multipart/form-data',
        // 'Authorization':'Token '+token,

        // }
        var config = {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Token ' + token },

              };                                                        
        var url
        var searchType

        if(this.state.searchResult){
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=${this.state.searchInput}&email__icontains=&phone_number=`
          searchType = "ezzid"
        }        
        else{
          url= base_url+`customers/?ezz_id=&ezz_id__icontains=&email__icontains=${this.state.searchInput}&phone_number=`
          searchType = "email"
        }
        
        var customer_data = await Axios.get(url,config)
        // console.log(customer_data.data)
        let ezzidlist = {}
        let emaillist = {}
        let customerdataObject ={}
        
        document.getElementById("errCustomerNotAuthorized").style.display = "none"

        if (searchType === "ezzid") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.ezz_id] = customer.email;
            customerdataObject[customer.ezz_id] = customer;
          }
        }
        // console.log();
        if (searchType === "email") {
          for (let customer of customer_data.data.results) {
            ezzidlist[customer.email] = customer.ezz_id;
            var tempEzId = ezzidlist[customer.email]
            customerdataObject[tempEzId] = customer;

          }
        }     

        this.setState({customer_data:customerdataObject})
        // Autoselect starts here
        var context = this;
        // /await context.setState({pendingOrders:[]})
        const elems_auto_customer = document.getElementById('customer_id');
        var options={
          data:ezzidlist,
          limit:20,
          onAutocomplete : async function(select_customer) {

            var tempEzzId 
            if (searchType === "ezzid"){
              tempEzzId = select_customer
              context.setState({customer_id:select_customer})                
            }
            if (searchType === "email"){
              tempEzzId = ezzidlist[select_customer]
              context.setState({customer_id:tempEzzId})                
            }            
            context.setState({customer_selected_data:customerdataObject[tempEzzId]})
            await context.setState({pendingOrders:[]})
           
            // context.getCategories(customerdataObject[tempEzzId].country)
            //       await context.getPendingOrder(tempEzzId,"IM")
            //        await context.getPendingOrder(tempEzzId,"YTA")
            // if (context.state.pendingOrders.length >0){
            //   context.openOrderModel();
            // }
           
            // const checkCustomerAuth = customerdataObject[tempEzzId].card_authorize

            // if(!checkCustomerAuth || checkCustomerAuth === "card details missing" )          
            //   document.getElementById("errCustomerNotAuthorized").style.display = "inline-grid"
            // else  
            //   document.getElementById("errCustomerNotAuthorized").style.display = "none"
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        }
        var instance = M.Autocomplete.init(elems_auto_customer,options)
        instance.open()
      }catch(err){

        //M.toast({html: 'User data cannot be fetched',classes:"white-text red rounded"});
        
        console.log(err)
        //this.setState({error:<Error/>})
      }

    }
   
    newDate=()=>{
     
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
    var   day = '' + d.getDate()
   var    year = d.getFullYear()

   if (month.length < 2) {
     month = '0' + month;
   }
    
   if (day.length < 2) {
     day = '0' + day;
   }
   

   return [year, month, day].join('-');
    }

    formatDate=(date)=> {
      var d = new Date(date)
         var month = '' + (d.getMonth() + 1)
       var   day = '' + d.getDate()
      var    year = d.getFullYear()
  
      if (month.length < 2) {
        month = '0' + month;
      }
       
      if (day.length < 2) {
        day = '0' + day;
      }
      
  
      return [year, month, day].join('-');
  }
   

    componentDidMount(){
      
        M.AutoInit();

        var elems = document.querySelectorAll('.modal');
        let model_options = {
          dismissible:false,
        }
        var instances = M.Modal.init(elems, model_options);
        
        var elems_auto_customer = document.querySelectorAll('.autocomplete');

        var options_auto_customer={
      
          data: {}, 
          limit:20,
          onAutocomplete :function(select_customer) {
           
            // context.setState({ order_date: selecteddata});
            //  console.log("slect autocomplete",select_customer); 
          },
        
          sortFunction:  function (a, b, inputString) {
            return a.indexOf(inputString) - b.indexOf(inputString);
          },
        } 
        var instances_auto = M.Autocomplete.init(elems_auto_customer, options_auto_customer);
      

         
        var context = this;

       var newdate = this.newDate()
      //  console.log('new date',newdate)
        var elems_order = document.getElementById('order_date');
      
        var options_order={
            //selectMonths: true, // Enable Month Selection
            selectYears: 10, // Creates a dropdown of 10 years to control year
            format:'yyyy-mm-dd',
            autoClose :true,
            defaultDate:newdate,
            setDefaultDate :true,
            onSelect: function(date) {
              // console.log(context.formatDate(date))
               let selecteddata = context.formatDate(date)
              // console.log('selectedate',selecteddata)
              context.setState({ order_date: context.formatDate(selecteddata)})
              // console.log("order_date",selecteddata); // Selected date is logged
            },
          }
        var instances = M.Datepicker.init(elems_order, options_order);
    }

    handleFormChange= async (ev) =>{
       const target = ev.target
      const name = target.name
      this.setState({
            [name]: target.value
          });
    }



 addCouponToDB = async ()=>{
              let token =localStorage.getItem("token")
              const  header = {'Content-Type': 'application/json',
              'Authorization':'Token '+token,
            
              }
            
                  this.saveCoupon()
                  var btn = document.getElementById('createcoupon')
                  btn.click()
 
  }

saveCoupon = async () => {

    
    this.setState({isOrderUpdate: true})
    let token = localStorage.getItem("token")
    const header = {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + token,

    }

    console.log("create new order" + this.state.createNewOrder)

    if (this.state.createNewOrder) {
      var Orderformdata = new FormData()
      Orderformdata.append("ezz_id", this.state.customer_id)
      Orderformdata.append("amount", this.state.shiper_order_number)
      Orderformdata.append("expired_on", this.state.order_date)
      //  Orderformdata.append("coupon_name", "test")
      // Orderformdata.append("coupon_code", "test")
      // Orderformdata.append("country_name", "BS")
       Orderformdata.append("status", "New")
      //Orderformdata.append("order_no", "test")
       //Orderformdata.append("email_id", "test")
      //Orderformdata.append("expired_on", this.state.order_date)
      //Orderformdata.append("received_date", this.state.received_date)
      //Orderformdata.append("inbound_tracking_number", this.state.tracking_number)
      
      
      try {

        const response = await Axios.post(base_url+"coupondetails/", Orderformdata,
          header)

        const orderid = response.data.coupon_code
        console.log("orderid" + orderid)

        try {
          var email_subject = "coupon_registration"
          //if (status === "IM" || status === "WFC") {
            const emaildata = {
              "type": email_subject,
              "ezz_id": this.state.customer_id
              //,
              //"order_id": orderid
            }


            // Axios({
            //   method: 'post',
            //   url: base_url+'status_printer',
            //   data: {
            //     "order_id": orderid
            //   }
            // }).then(function (response) {
            //   console.log(response)
            //   console.log("printer APi success");
            // }).catch(function (response) {
            //   console.log(response)
            //   console.log('printer API failed');
            // })

            // Axios({
            //   method: 'post',
            //   url: base_url+'whatsapp_templates',
            //   data: emaildata,
            // }).then(
            //   function (response) {
            //     console.log(response)
            //     console.log("whatsapp sent done");
            //   }
            // ).catch(
            //   function (response) {
            //     console.log(response)
            //     console.log("whatsapp sent Failure");
            //   }
            // )

            const response = await Axios.post(base_url+"send_email/", emaildata,
              header)
            console.log(response)
            M.toast({ html: 'Email Sent Successfully' , classes: "white-text green rounded" });
          //}

        } catch (error) {

          M.toast({ html: 'Email Sending Failed to' + this.state.customer_id, classes: "white-text red rounded" });
        }



        // this.setState({ error: <Success message=" New order foproduct items created" /> })
        M.toast({ html: 'Coupon generated successfully', classes: "white-text orange rounded" })
        this.setState({ error: "" })
        this.setState({ generatedOrderId: orderid })
        console.log('generatedOrderId',this.state.generatedOrderId);


      } catch (err) {

        M.toast({ html: 'Please Try Again!', classes: "white-text red rounded" });

        console.log(err)
        this.setState({ error: <Error /> })
      }




    }
    
    document.getElementById('saveOrderBtn').disabled = true

  }

render(){
  const opts={};
   opts['readOnly'] = 'readOnly';
 

    return(
        <Container>

<div className="row">
          <div className="col s3">
          <h4 className=" orange-text">Generate Coupon</h4>
          </div>
       
</div>
                <Row>

                       <Col xs={12}md={7} lg={7}>
                    <div className="row">
    <form className="col s12" autocomplete="off">
      {this.state.error}
      <div className="row inputbox-height">
        <div className="input-field col s6">
          <input name="customer_id" id="customer_id" type="text" className="autocomplete"
            value={this.state.customer_id} 
            onChange={this.handleCustomerIDChange}
           

          />
          <label htmlFor="customer_id">Enter Customer Id</label>
            <span id="errCustomerNotAuthorized" class="helper-text center yellow red-text " 
                  data-error="wrong" data-success="" 
                  style= {{ fontSize: "small", display: "none", fontWeight: "500" }} >Payment Method Not Added</span>
          <span className="helper-text" data-error="wrong" data-success="">Customer Ezzid ex: Ezz000000</span>
        </div>
      
      </div>
       {/*<div className="row inputbox-height">
            <div className="input-field col s6">
                <input name="tracking_number" id="tracking_number" type="text" className=""
                value={this.state.tracking_number} onChange={this.handleFormChange}
                />
                <label htmlFor="tracking_number">Enter Coupon Name</label>
                <span className="helper-text" data-error="wrong" data-success="">Enter Coupon Name</span>

              </div>
         </div>*/}
      <div className="row inputbox-height">
        
        <div className="input-field col s6">
          <input name="shiper_order_number" id="shiper_order_number" type="text" className=""
           value={this.state.shiper_order_number} onChange={this.handleFormChange}
          />
          <label htmlFor="shiper_order_number">Enter Coupon Amount</label>
          <span className="helper-text" data-error="wrong" >Enter Coupon Amount</span>
        </div> 

      </div>
      <div className="row inputbox-height">
        <div className="input-field col s6">
        
          <input name="order_date" id="order_date" type="text"  className="datepicker" 
          value={this.state.order_date}
          //  onChange={this.handleFormChange}
          />
          
          <label htmlFor="order_date" style={{ marginLeft: '80%' }}><i className="material-icons right">date_range</i></label>
          <span className="helper-text" data-error="wrong" data-success="">Select the Validity Date </span>
        </div>
      
      </div>

      {
          this.state.showSpinner
            ? < div className="center">
          
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="success" size="sm" />
            <Spinner animation="grow" variant="warning" size="sm" />
          </div>
            : null
        }
      </form>
  
  </div>
                    </Col>
                    {/* right side column starts here */}
                    <Col xs={12}md={5} lg={5}>


                            <CustomerDetailCard 
                            info = {this.state.customer_selected_data}
                             />

                        
                    </Col>

                    {/* right side columns ends here */}
      
                </Row> 

                <Row >
                  <Col>
                    <div className="left">
                        <h5 className="orange-text">Coupon Code: <span className="black-text" id='generated_orderId' >{this.state.generatedOrderId}</span></h5>
                    </div>
                  </Col>
                </Row>

          <Row>
            <Col>
              <div className="center">
                <ButtonGroup aria-label="create order buttons" className="">
                  <Button id="cancelOrderBtn"onClick={this.cancelOrder} variant="secondary"  className="">Cancel</Button>
                  <Button className="ml-5 " id="saveOrderBtn" onClick={this.addCouponToDB} variant="primary"  >Generate</Button>
                  {/*<Button className="ml-5 white-text" id="updateOrder" variant="success" onClick={this.updateOrder} >Update Order</Button>                        
                  <Button className="ml-5 white-text" id="printLabel" variant="warning"  onClick={this.printLabelManually}>Print Order</Button>                        */}
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Container>


    )
}

}

export default Coupon;
