import React, { Component, Fragment } from 'react';
import { base_url } from '../../globalConstants';
import Axios from "axios"
import { ButtonToolbar, ButtonGroup, Button, Row, Col, Container } from 'react-bootstrap';
import M from "materialize-css";
import CustomerFrag from './GetCustomer';
import Error from './Error'
import './countrydetail.css';
// import { getJSON, parseJSON } from 'jquery';


class CustomerDB extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      fragmentdiv: <CustomerFrag statedata={this.props.statedata} countrydatapaging={this.props.countrydatapaging} countrytable={this.props.countrydata} countrylist={this.props.countrylist} country_code_list={this.props.country_code_list}/>,
      error: '',
      tableHeader: "Customer List"
    })
    this.fetchCountryData = this.fetchCountryData.bind(this)
  }

    downloadReport = async () => {
        let report_url = base_url + `get_customer_list`
        console.log('url',report_url);
        //console.log('this.state.countryName',this.state.countryName);
        var FileSaver = require('file-saver');

        await Axios({
            url: report_url,
            method: 'GET',
            responseType: 'arraybuffer'
        })
        .then((response)=>{
            {
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, `customer_list.xlsx`);
            }
            console.log('response',response);
        }).catch((err)=>{
            console.log('err',err);
        })
    }

  getCountryData = async () => {

    try {
      const response = await Axios.get(base_url+'customers/?page=1')
      console.log(response.data)
      console.log("COutnry data" + response.data)

      this.setState({ fragmentdiv: <CustomerFrag countrytable={response.data.results} /> })

    }

    catch (error) {
      console.log("Error", error);
      this.setState({ error: "<h1 className= 'red-text'>${error}></h1>" })
    }



  }

  
  functionalityNotAvailable = () => {

    M.toast({ html: 'Functionality Not Available', classes: "white-text red rounded" })

  }

  fetchCountryData() {
    this.setState({
      tableHeader: "Customer List"
    })
    this.setState({
      fragmentdiv: <CustomerFrag countrytable={this.props.countrydata} />,
    })
  }
  //NEW ADMIN TABS END

  render() {
    return (
      <Fragment>

        <Row>
          <Col xs={12} md={12} lg={12}>
            {/* Todo searchbar  */}
			<Button onClick={this.downloadReport}>
				Download
			</Button>
          </Col>
        </Row>
        
        <Row>
          <Col xs={12} lg={12}>
            <h3 className = "orange-text center">{ this.state.tableHeader }</h3>
            <div className="operation-dataDiv">
              {this.state.fragmentdiv}
              {this.state.error}
            </div>
          </Col>


        </Row>

      </Fragment>
    )
  }
}

export default CustomerDB;